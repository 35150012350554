import React, { useEffect } from "react";
import "./style.scss";
import ForgotPasswordForm from "../../../components/Auth/ForgotPasswordForm";
import { useSelector } from "react-redux";

function ForgotPassword() {
  const { theme } = useSelector((state) => state.theme);
  useEffect(() => {
    document.body.classList["value"] === "" &&
      (document.body.classList = theme);
  }, [theme]);

  return (
    <div className="forgotpassword-container">
      <div className="forgotpassword-left-wrapper">
      <div className="forgotpassword-left">
          <ForgotPasswordForm />
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
