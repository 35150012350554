import React, {useEffect, useState} from "react";
import plus from "../../../assets/icons/AddIcon.svg";
import minus from "../../../assets/icons/minus.svg";
import copy from "../../../assets/icons/copy-white.svg";
import rewardIcon from "../../../assets/icons/rewardsIcon.svg";

import "./style.scss";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {returnErrors} from "../../../store/reducers/error";
import {returnMessages} from "../../../store/reducers/message";
import {ToastContainer, toast} from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import Spinner from "../../../reusableComponents/Spinner";
import {TroubleshootOutlined} from "@mui/icons-material";
import {baseUrl} from "../../../utils/apis";
import { Link } from "react-router-dom";

const Index = () => {
  //   const inputRef = useRef(null);

  //   const handleCopyClick = () => {
  //     // Select the text inside the input element
  //     inputRef.current.select();
  //     // Copy the selected text to the clipboard
  //     document.execCommand("copy");
  //   };

  const [couponData, setCouponData] = useState({
    percentage: 0,
    code: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleIncrease = () => {
    if (couponData.percentage < 10) {
      setCouponData({...couponData, percentage: couponData.percentage + 1});
    }
  };
  const handleDecrease = () => {
    if (couponData.percentage > 0) {
      setCouponData({...couponData, percentage: couponData.percentage - 1});
    }
  };
  const idToken = useSelector((state) => state.auth.idToken);
  const [codeLink, setCodeLink] = useState("");
  const [isSpinner, setIsSpinner] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    setIsSpinner(TroubleshootOutlined);
    if (couponData.code === "") {
      dispatch(returnErrors("Please enter a Coupon Code", 400));
      // alert("Please enter a Coupon Code", 400);
      setIsSpinner(false);
      return;
    } else {
      try {
        let config = {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        };
        const res = await axios.post(`${baseUrl}/affiliates/create-link/`, couponData, config);

        if (res.data.code !== couponData.code) {
          dispatch(returnErrors("Please enter a unique value.", 400));
          setIsSpinner(false);
        } else {
          setCodeLink(res.data.url);
          dispatch(returnMessages("Successfully created coupon code", 200));
          setIsSpinner(false);
        }
      } catch (error) {
        if (error) {
          dispatch(returnErrors("This code is already taken", 400));
          setIsSpinner(false);
          // alert("This code is already taken", 400);
        }
      }
    }
  };
  const handleCopyToClipboard = (text) => {
    if (text !== "") {
      toast(text ? "Text Copied to clipboard !" : "NA", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "success",
        theme: "dark",
        className: "custom-toast-container",
      });
    }
  };

  return (
    <div className="affiliate_codes">
      <h4>Get a link</h4>
      <div className="affiliate_table">
        {/* <div className="get_a_link_header">
          <div>
            <h4>Your Unique Coupon Code is -- {codeLink ? couponData.code : ""}</h4>
          </div>
          <div>
            <p>
              Lorem ipsum dolor sit amet consectetur. Purus risus lacus vulputate at. Fringilla arcu quisque pharetra risus vitae eu egestas dolor. Id viverra duis ipsum porttitor quis ut. Nunc proin
              ornare fringilla diam.
            </p>
          </div>
        </div> */}
        {/* 2nd div */}
        <div className="get_a_link_conatiner2">
          <h4>Generate Your Coupon Code</h4>
          <div className="coupon_code">
            <div className="common_container">
              <div>
                <h4>Your Unique Coupon Code is</h4>
              </div>
              <div className="input_div">
                <input
                  type="text"
                  placeholder="Coupon Code"
                  className="input_container"
                  value={couponData.code}
                  onChange={(e) => {
                    const value = e.target.value.toUpperCase();
                    setCouponData({...couponData, code: value});
                  }}
                  maxLength={15}
                />
                <ToastContainer />
                <CopyToClipboard
                  onCopy={handleCopyToClipboard}
                  text={couponData.code}
                >
                  <img
                    src={copy}
                    alt=""
                  />
                </CopyToClipboard>
              </div>
            </div>
            <div className="common_container">
              {codeLink !== "" ? (
                <div className="affiliate_container">
                  {" "}
                  <div>
                    <h4>Your Affiliate link </h4>
                  </div>
                  <div className="input_div">
                    <input
                      type="text"
                      className="copy_text"
                      value={codeLink}
                      //   ref={inputRef}
                      readOnly
                    />
                    <CopyToClipboard
                      onCopy={handleCopyToClipboard}
                      text={codeLink}
                    >
                      <img
                        src={copy}
                        alt="copylink"
                      />
                    </CopyToClipboard>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="common_container">
            <div>
              {/* <p>
                  Lorem ipsum dolor sit amet consectetur. Purus risus lacus vulputate at. Fringilla arcu quisque pharetra risus vitae eu egestas dolor. Id viverra duis ipsum porttitor quis ut. Nunc
                  proin ornare fringilla diam.
                </p> */}
            </div>
            <div>
              <h4>NOTE: Fixed Commission Income rates for company is 15%</h4>
            </div>
          </div>
        </div>
        <div className="commission_rate_container">
          <div className="commission_rate">
            {/* <div>
              <h4>Your Commision rate</h4>
            </div> */}
            <div className="code_generator">
              <div className="commission_rate_wrapper">
                <div className="commission_rate_header">
                  <h4>Your Commission rate</h4>
                </div>
                <div className="rate">
                  <div onClick={handleIncrease}>
                    <img
                      src={minus}
                      alt=""
                    />
                  </div>
                  <p>{`${15 - couponData.percentage}%`}</p>
                  <div onClick={handleDecrease}>
                    <img
                      src={plus}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="unique_code_wrapper">
                <div className="unique_code_header">
                  <h4>Unique Code</h4>
                </div>
                <div className="rate unique_code_discount">
                  <div onClick={handleDecrease}>
                    <img
                      src={minus}
                      alt=""
                    />
                  </div>
                  <p>{`${couponData.percentage}%`}</p>
                  <div onClick={handleIncrease}>
                    <img
                      src={plus}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="code_generator_footer">
              <p>NOTE: Fixed Commission Income rates for company is 15%</p>
            </div>
            <div className="code_generator_button">
              <button
                className="genrate_button"
                onClick={handleSubmit}
                disabled={isSpinner}
              >
                {isSpinner ? <Spinner /> : <>Generate</>}
              </button>
            </div>
          </div>
        </div>
        <div className="rewards_wrapper">
          <div className="rewards_container">
            <div className="rewards_label">
              <img
                src={rewardIcon}
                alt=""
              />
              <p>Your current rewards</p>
            </div>
            <div className="rewards_info">
              <p>$ 0.00</p>
            </div>
          </div>
          <div className="rewards_claim_button">
            <Link to="/request-payout/affiliate-payout">
            <button
              className="claim_button"
              disabled={isSpinner}
            >
              Claim
            </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
