import React, {useEffect, useState} from "react";
import "./style.scss";
import {Navigate, Route, Routes} from "react-router-dom";
import PaymentHistoryTable from "../../../components/FundingEvaluation/PaymentHistory";
import Tabination from "../../../reusableComponents/Tabination";
import {useDispatch, useSelector} from "react-redux";
import LoaderOverlay from "../../../reusableComponents/Loader";
import {getPaymentHistory, removeLastPayment, setPaymentStatus} from "../../../store/reducers/accountSlice";
import {getPaymentStatus} from "../../../utils/apis";
import ErrorModal from "../../../reusableComponents/ErrorModal";
import SuccessModal from "../../../components/reusableComponents/SuccessModal";

function PaymentHistory() {
  const dispatch = useDispatch();
  const items = [
    {
      name: "All",
      path: "all",
    },
    // {
    //   name: "Received",
    //   path: "received",
    // },
    // {
    //   name: "Transfer",
    //   path: "transfer",
    // },
  ];
  const idToken = useSelector((state) => state.auth.idToken);
  // useEffect(() => {
  //   dispatch(getPaymentHistory(idToken));
  // }, []);
  const paymentHistory = useSelector((state) => state.account.paymentHistory);
  const [isLoading, setIsLoading] = useState(paymentHistory.loading);
  const [errorModalOpen, setErrorModalOpen] = useState(paymentHistory.error);
  useEffect(() => {
    setErrorModalOpen(paymentHistory.error);
    setIsLoading(paymentHistory.loading);
    window.scrollTo(0, 0);
  }, [paymentHistory]);
  useEffect(() => {
    if (paymentHistory?.lastPayment?.data?.id) {
      dispatch(removeLastPayment());
    }
  }, [paymentHistory.lastPayment?.data?.id]);

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isLoading]);
  const accountList = useSelector((state) => state?.account?.accountList?.data);
  let msg = accountList?.length > 0 ? "Congratulations! Payment was successful" : "You'll shortly receive an email with your account login credentials";
  const {isPaymentDone} = useSelector((state) => state.account.paymentHistory);
  const challengeId = useSelector((state) => state.account.selectedChallenge);
  console.log(challengeId, "dklfjkldjfkjd");

  return (
    <>
      {isPaymentDone === true && (
        <SuccessModal
          setModalOpen={() => dispatch(setPaymentStatus(null))}
          title="Successful!"
          desc={msg}
          btnText="Close"
        />
      )}
      {/* <Tabination
        variant={3}
        tabItems={items}
        activeTab={0}
        style={{margin: "0 14px"}}
      /> */}
      <div className="paymenthistory">
        <div className="all_header">
          <h2>Payments</h2>
        </div>
        {errorModalOpen && (
          <ErrorModal
            desc={paymentHistory.message}
            setModalOpen={setErrorModalOpen}
            btnText="Cancel"
          />
        )}
        {isLoading && <LoaderOverlay />}
        <PaymentHistoryTable />
        {/* <Routes>
          <Route
            path="/"
            element={
              <Navigate
                to="all"
                replace={true}
              />
            }
          />
          <Route
            path="all"
            element={<PaymentHistoryTable />}
          />
          <Route
            path="received"
            element={<PaymentHistoryTable />}
          />
          <Route
            path="transfer"
            element={<PaymentHistoryTable />}
          />
        </Routes> */}
      </div>
    </>
  );
}

export default PaymentHistory;
