import React from 'react'
import './style.scss'
import ArrowIcon from '../../../assets/icons/arrow-right-blue.svg'

function CompetitionRules() {
     
    const rules = [
        { id: 1, title: 'Free & Paid entry options' },
        { id: 2, title: 'Live leader board within dashboard.' },
        { id: 3, title: 'Monthly prizes from free evaluations to a new PC' },
        { id: 4, title: 'Max dropdown 10%' },
        { id: 5, title: 'Free & Paid entry options' },
        { id: 6, title: 'Free & Paid entry options' },
        { id: 7, title: 'Free & Paid entry options' },
        { id: 8, title: 'Free & Paid entry options' },
        { id: 9, title: 'Free & Paid entry options' },
        { id: 10, title: 'Free & Paid entry options' },
        { id: 11, title: 'Free & Paid entry options' },
        { id: 12, title: 'Free & Paid entry options' },
        { id: 13, title: 'Free & Paid entry options' },
        { id: 14, title: 'Free & Paid entry options' },
      ];
      
    return (
        <div className='competition_rules'>
          {rules.map((rule) => (
        <div key={rule.id}>
          <img src={ArrowIcon} alt='' />
          <p>{rule.title}</p>
        </div>
      ))}
        </div>
    )
}

export default CompetitionRules