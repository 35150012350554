import React, {useEffect, useState} from "react";
import "./style.scss";
import AccountDetails from "../../../components/AccountMetrics/AccountDetails";
import AccountCard from "../../../components/AccountMetrics/AccountCard";
import {useSelector} from "react-redux";
import ErrorModal from "../../../reusableComponents/ErrorModal";

function AccountInfo() {
  const accountsData = useSelector((state) => state.account.accountMetrics);
  const [errorModalOpen, setErrorModalOpen] = useState(accountsData.error);

  useEffect(() => {
    setErrorModalOpen(accountsData.error);
  }, [accountsData]);
  // if (accountsData.error || accountsData.loading) {
  //   return "";
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="accountinfo-container">
      {errorModalOpen && (
        <ErrorModal
          desc={accountsData.message}
          setModalOpen={setErrorModalOpen}
          btnText="Cancel"
        />
      )}

      {/* <h4>General Information</h4> */}
      {!accountsData.loading && !accountsData.error && (
        <div className="accountinfo-subcontainer">
          <h4>Account Info</h4>
          <AccountDetails />
          {/* <AccountCard /> */}
        </div>
      )}
    </div>
  );
}

export default AccountInfo;
