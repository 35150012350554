import React, {useEffect, useState} from "react";
import "./style.scss";
import CompetitionDetailsBox from "../CompetitionDetailsBox";
import FirstPrize from "../../../assets/icons/trophy-green.svg";
import TrophyBlue from "../../../assets/icons/trophy-blue.svg";
import ThirdPrize from "../../../assets/icons/medal-orange.svg";
import bgImg1 from "../../../assets/images/competition-bg-img.png";
import bgImg2 from "../../../assets/images/competition-bg-img2.png";

function Upcoming({competitions, enterCompetition}) {
  // const competitionData = [
  //   {
  //     name: "February 2022 - 200k Challenge",
  //     date: "1 Mar - 31 Mar",
  //     winners: "200",
  //     prizes: [
  //       { position: "1st", icon: FirstPrize, name: "Macbook" },
  //       { position: "2nd", icon: TrophyBlue, name: "Macbook" },
  //       { position: "3rd", icon: ThirdPrize, name: "Macbook" },
  //     ],
  //     days: "02",
  //     hours: "02",
  //     minutes: "02",
  //     contestants: "1233",
  //     total: "123233",
  //     img: bgImg1,
  //   },
  //   {
  //     name: "February 2022 - 200k Challenge",
  //     date: "1 Mar - 31 Mar",
  //     winners: "200",
  //     prizes: [
  //       { position: "1st", icon: FirstPrize, name: "Macbook" },
  //       { position: "2nd", icon: TrophyBlue, name: "Macbook" },
  //       { position: "3rd", icon: ThirdPrize, name: "Macbook" },
  //     ],
  //     days: "02",
  //     hours: "02",
  //     minutes: "02",
  //     contestants: "1233",
  //     total: "123233",
  //     img: bgImg1,
  //   },
  //   {
  //     name: "February 2022 - 200k Challenge",
  //     date: "1 Mar - 31 Mar",
  //     winners: "200",
  //     prizes: [
  //       { position: "1st", icon: FirstPrize, name: "Macbook" },
  //       { position: "2nd", icon: TrophyBlue, name: "Macbook" },
  //       { position: "3rd", icon: ThirdPrize, name: "Macbook" },
  //     ],
  //     days: "02",
  //     hours: "02",
  //     minutes: "02",
  //     contestants: "1233",
  //     total: "123233",
  //     img: bgImg2,
  //   },
  //   {
  //     name: "February 2022 - 200k Challenge",
  //     date: "1 Mar - 31 Mar",
  //     winners: "200",
  //     prizes: [
  //       { position: "1st", icon: FirstPrize, name: "Macbook" },
  //       { position: "2nd", icon: TrophyBlue, name: "Macbook" },
  //       { position: "3rd", icon: ThirdPrize, name: "Macbook" },
  //     ],
  //     days: "02",
  //     hours: "02",
  //     minutes: "02",
  //     contestants: "1233",
  //     total: "123233",
  //     img: bgImg1,
  //   },
  //   {
  //     name: "February 2022 - 200k Challenge",
  //     date: "1 Mar - 31 Mar",
  //     winners: "200",
  //     prizes: [
  //       { position: "1st", icon: FirstPrize, name: "Macbook" },
  //       { position: "2nd", icon: TrophyBlue, name: "Macbook" },
  //       { position: "3rd", icon: ThirdPrize, name: "Macbook" },
  //     ],
  //     days: "02",
  //     hours: "02",
  //     minutes: "02",
  //     contestants: "1233",
  //     total: "123233",
  //     img: bgImg1,
  //   },
  //   {
  //     name: "February 2022 - 200k Challenge",
  //     date: "1 Mar - 31 Mar",
  //     winners: "200",
  //     prizes: [
  //       { position: "1st", icon: FirstPrize, name: "Macbook" },
  //       { position: "2nd", icon: TrophyBlue, name: "Macbook" },
  //       { position: "3rd", icon: ThirdPrize, name: "Macbook" },
  //     ],
  //     days: "02",
  //     hours: "02",
  //     minutes: "02",
  //     contestants: "1233",
  //     total: "123233",
  //     img: bgImg2,
  //   },
  //   {
  //     name: "February 2022 - 200k Challenge",
  //     date: "1 Mar - 31 Mar",
  //     winners: "200",
  //     prizes: [
  //       { position: "1st", icon: FirstPrize, name: "Macbook" },
  //       { position: "2nd", icon: TrophyBlue, name: "Macbook" },
  //       { position: "3rd", icon: ThirdPrize, name: "Macbook" },
  //     ],
  //     days: "02",
  //     hours: "02",
  //     minutes: "02",
  //     contestants: "1233",
  //     total: "123233",
  //     img: bgImg1,
  //   },
  //   {
  //     name: "February 2022 - 200k Challenge",
  //     date: "1 Mar - 31 Mar",
  //     winners: "200",
  //     prizes: [
  //       { position: "1st", icon: FirstPrize, name: "Macbook" },
  //       { position: "2nd", icon: TrophyBlue, name: "Macbook" },
  //       { position: "3rd", icon: ThirdPrize, name: "Macbook" },
  //     ],
  //     days: "02",
  //     hours: "02",
  //     minutes: "02",
  //     contestants: "1233",
  //     total: "123233",
  //     img: bgImg2,
  //   },
  // ];

  const [competitionList, setCompetitionList] = useState([]);
  useEffect(() => {
    setCompetitionList(competitions);
  }, [competitions]);

  return (
    <div className="upcoming">
      {competitionList.map((competition, index) => (
        <CompetitionDetailsBox
          enterCompetition={enterCompetition}
          key={index}
          competition={competition}
          end_date={competition.close_entry_on}
          status={"Upcoming"}
          competitionListAwards={competition?.awards}
        />
      ))}
    </div>
  );
}

export default Upcoming;
