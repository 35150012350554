import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { act } from "react-dom/test-utils";
import { PURGE } from "redux-persist";
const initialState = {
  plans: { loading: true, data: [], error: false, message: "" },
  selectedChallenge: null,
  paymentHistory: {
    loading: true,
    data: [],
    error: false,
    message: "",
    lastPayment: null,
    isPaymentDone: null
  },
  competitionList: { loading: true, data: [], error: false, message: "" },
  accountList: {
    loading: true,
    data: [],
    error: false,
    message: "",
    activeAccount: null,
  },
  payoutListPFS: { loading: true, data: [], error: false, message: "" },
  payoutListAFS: { loading: true, data: [], error: false, message: "" },

  accountMetrics: {
    loading: false,
    data: {},
    lastUpdated: "",
    error: false,
    message: "",
  },
  traderJournal: { loading: true, data: [], error: false, message: "" },
};

const handleFailure = (state, action, reducerKey) => {
  if (reducerKey && state[reducerKey]) {
    state[reducerKey] = {
      ...state[reducerKey],
      loading: false,
      error: true,
      message: action.payload.response.data.detail || `Something went wrong.`,
    };
  } else {
    console.error("Invalid reducerKey or state slice does not exist in handleFailure");
  }
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    // Plans
    getPlans: (state, action) => {
      state.plans = { ...state.plans, loading: true, error: false };
    },
    getPlansSuccess: (state, action) => {
      state.plans = { loading: false, data: action.payload, error: false };
    },
    getPlansFailure: (state, action) => {
      handleFailure(state, action, "plans");
    },
    // Selected Challenge
    setSelectedChallenge: (state, action) => {
      // if (action.payload !== null) {
      //
      //   return action.payload;
      // } else {
      //   return null;
      // }
      state.selectedChallenge = action.payload;
    },
    // Payment History
    //NOT IMPLEMENTED YET IN THE PAGE
    getPaymentHistory: (state, action) => {
      state.paymentHistory = {
        ...state.paymentHistory,
        loading: true,
        error: false,
      };
    },
    getPaymentHistorySuccess: (state, action) => {
      state.paymentHistory = {
        ...state.paymentHistory,
        loading: false,
        data: action.payload,
        error: false,
      };
    },
    getPaymentHistoryFailure: (state, action) => {
      handleFailure(state, action, "paymentHistory");
    },
    setLastPayment: (state, action) => {
      state.paymentHistory = {
        ...state.paymentHistory,
        loading: false,
        error: false,
        lastPayment: action.payload,
      };
    },
    removeLastPayment: (state, action) => {
      state.paymentHistory = {
        ...state.paymentHistory,
        loading: false,
        error: false,
        lastPayment: null,
      };
    },
    // Competition List
    getCompetitionList: (state, action) => {
      state.competitionList = {
        ...state.competitionList,
        loading: true,
        error: false,
      };
    },
    getCompetitionListSuccess: (state, action) => {
      state.competitionList = {
        loading: false,
        data: action.payload,
        error: false,
      };
    },
    getCompetitionListFailure: (state, action) => {
      handleFailure(state, action, "competitionList");
    },

    //Account List
    getAccountList: (state, action) => {
      state.accountList = { ...state.accountList, loading: true, error: false };
    },
    getAccountListSuccess: (state, action) => {
      state.accountList = {
        ...state.accountList,
        loading: false,
        data: action.payload,
      };
    },
    getAccountListFailure: (state, action) => {
      handleFailure(state, action, "accountList");
    },
    setActiveAccount: (state, action) => {
      state.accountList = {
        ...state.accountList,
        active: action.payload,
        error: false,
      };
    },
    //Account Metrics
    getAccountMetrics: (state, action) => {
      state.accountMetrics = {
        ...state.accountMetrics,
        loading: true,
        error: false,
      };
      //
    },
    getAccountMetricsSuccess: (state, action) => {
      state.accountMetrics = {
        loading: false,
        data: action.payload,
        lastUpdated: moment(Date.now()).format("D MMM YYYY HH:mm:ss"),
        error: false,
      };
    },
    getAccountMetricsFailure: (state, action) => {
      handleFailure(state, action, "accountMetrics");
    },
    // Payout split List
    getPayoutSplitList: (state, action) => {
      state.payoutListPFS = { ...state.payoutListPFS, loading: true, error: false };
    },
    getPayoutSplitListSuccess: (state, action) => {
      state.payoutListPFS = { loading: false, data: action.payload };
    },
    getPayoutSplitListFailure: (state, action) => {
      handleFailure(state, action, "payoutListPFS");
    },
    // Affiliate Payout list
    getPayoutAffiliateList: (state, action) => {
      state.payoutListAFS = { ...state.payoutListAFS, loading: true, error: false };
    },
    getPayoutAffiliateListSuccess: (state, action) => {
      state.payoutListAFS = { loading: false, data: action.payload };
    },
    getPayoutAffiliateListFailure: (state, action) => {
      handleFailure(state, action, "payoutListAFS");
    },

    //Trader Journal

    getTraderJournal: (state, action) => {
      state.traderJournal = {
        ...state.traderJournal,
        loading: true,
        error: false,
      };
    },
    getTraderJournalSuccess: (state, action) => {
      state.traderJournal = {
        ...state.traderJournal,
        loading: false,
        data: [...action.payload],
      };
    },
    getTraderJournalFailure: (state, action) => {
      handleFailure(state, action, "traderJournal");
    },

    setPaymentStatus: (state, action) => {
      state.paymentHistory.isPaymentDone = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },


});

export const {
  getPlans,
  getPlansSuccess,
  getPlansFailure,
  setSelectedChallenge,
  getPaymentHistory,
  getPaymentHistorySuccess,
  getPaymentHistoryFailure,
  setLastPayment,
  removeLastPayment,
  getCompetitionList,
  getCompetitionListSuccess,
  getCompetitionListFailure,
  getAccountList,
  getAccountListSuccess,
  getAccountListFailure,
  setActiveAccount,
  getAccountMetrics,
  getAccountMetricsSuccess,
  getAccountMetricsFailure,
  getPayoutSplitList,
  getPayoutSplitListSuccess,
  getPayoutSplitListFailure,
  getPayoutAffiliateList,
  getPayoutAffiliateListSuccess,
  getPayoutAffiliateListFailure,
  getPayoutListSuccess,
  getPayoutListFailure,
  getTraderJournal,
  getTraderJournalSuccess,
  getTraderJournalFailure,
  setPaymentStatus
} = accountSlice.actions;
export default accountSlice.reducer;
