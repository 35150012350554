import {act} from "react-dom/test-utils";
// import {axios} from "../../Axios";
import axios from "axios";
// import { sessionLogoutReducer } from "./logout";
// import { tokenConfig } from "./tokenReducer";
// import { logout } from "./authSlice";
// import {logout} from "./auth";
// import {logout} from "./authSlice";
import {baseUrl} from "../../utils/apis";
import {clearPersistedData} from "../configureStore";
import {deAuthenticate} from "./authSlice";
const initialState = {
  payoutAccess: false,
  isPaymentVerified: false,
  isKycVerified: false,
  isContractVerified: false,
  isContractIssued: false,
  kyc: [],
  payment: [],
  contract: [],
  payout_list: [],
  aff_commission: 0,
};

const tokenConfig = (getState) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (getState) {
    config.headers["AUTHORIZATION"] = getState().auth.idToken;
  }

  return config;
};

const payoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PAYOUT_ACCESS":
      return {
        ...state,
        payoutAccess: action.payload,
      };
    case "SET_PAYMENT_VERIFICATION_STATUS":
      return {
        ...state,
        isPaymentVerified: action.payload,
      };
    case "SET_KYC_VERIFICATION_STATUS":
      return {
        ...state,
        isKycVerified: action.payload,
      };
    case "SET_CONTRACT_VERIFICATION_STATUS":
      return {
        ...state,
        isContractVerified: action.payload,
      };
    case "SET_CONTRACT_ISSUE":
      return {
        ...state,
        isContractIssued: action.payload,
      };
    case "SET_VERIFICATION_DATA":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_PAYOUT_LIST":
      return {
        ...state,
        payout_list: action.payload,
      };
    case "SET_AFF_COMMISSION_SUM":
      return {
        ...state,
        aff_commission: action.payload,
      };
    default:
      return state;
  }
};

const setPayoutAccess = (payload) => ({
  type: "SET_PAYOUT_ACCESS",
  payload,
});

const setPaymentVerification = (payload) => ({
  type: "SET_PAYMENT_VERIFICATION_STATUS",
  payload,
});

const setKycVerification = (payload) => ({
  type: "SET_KYC_VERIFICATION_STATUS",
  payload,
});

const setContractVerification = (payload) => ({
  type: "SET_CONTRACT_VERIFICATION_STATUS",
  payload,
});

const setContractIssue = (payload) => ({
  type: "SET_CONTRACT_ISSUE",
  payload,
});

const setPayoutList = (payload) => ({
  type: "SET_PAYOUT_LIST",
  payload,
});

const setAffiliateCommission = (payload) => ({
  type: "SET_AFF_COMMISSION_SUM",
  payload,
});

const setVerificationData = (payload) => ({
  type: "SET_VERIFICATION_DATA",
  payload,
});
const logout = () => {
  deAuthenticate();
};
export const getPayoutVerificationStatus = () => async (dispatch, getState) => {
  try {
    const {data, status} = await axios.get(`v2/get/payout/verification-status/`, tokenConfig(getState));
    if (status === 401) {
      dispatch(logout());
      return;
    }
    if (status > 399) throw data;
    dispatch(setPayoutAccess(data.is_completely_verified));
    dispatch(setPaymentVerification(data.is_payment_verified));
    dispatch(setKycVerification(data.is_kyc_verified));
    dispatch(setContractVerification(data.is_contract_verified));
    dispatch(setContractIssue(data.issue_contract));
    dispatch(
      setVerificationData({
        canVerify: data.can_verify,
        payment: data.payment,
        kyc: data.kyc,
        contract: data.contract,
      }),
    );
  } catch (error) {
    
  }
};

export const getSumsubToken = () => async (dispatch, getState) => {
 
  try {
    const {data, status} = await axios.get(`${baseUrl}/user/veriff/get/link/`, tokenConfig(getState));
    if (data === "Please verify the Access Token!") {
      dispatch(logout());
      return false;
    }
    if (status > 399) throw data;
    return data;
  } catch (error) {
    
  }
};

export const getSumsubApplicantStatus = () => async (dispatch, getState) => {
  try {
    const {data, status} = await axios.get(`${baseUrl}/v2/sumsub/get/applicant-status/`, tokenConfig(getState));
    if (data === "Please verify the Access Token!") {
      dispatch(logout());
      return false;
    }
    if (status > 399) throw data;
    return data;
  } catch (error) {
    
  }
};

export const updatePaymentVerification = (payload) => async (dispatch, getState) => {
  try {
    const idToken = await getState().ACCtoken;
    payload["idToken"] = idToken;
    const {data, status} = await axios.post(`${baseUrl}/v2/update/payment-verification/`, payload, tokenConfig(getState));
    if (data === "Please verify the Access Token!") {
      dispatch(logout());
      return false;
    }
    if (status > 399) throw data;
    return data;
  } catch (error) {
    
    throw error;
  }
};

export const uploadKycDoc = (form_data) => async (dispatch, getState) => {
  try {
    const idToken = await getState().ACCtoken;
    form_data.append("idToken", idToken);
    const {data, status} = await axios.post(`${baseUrl}/upload-document/`, form_data);
    if (status === 401) {
      dispatch(logout());
      return;
    }
    dispatch(getPayoutVerificationStatus());
    return data;
  } catch (error) {
    
    throw error;
  }
};

export const requestPayout = (payload) => async (dispatch, getState) => {
  try {
    const {data, status} = await axios.post(`${baseUrl}/request/payout/`, payload, tokenConfig(getState));
    if (status === 401) {
      dispatch(logout());
      return;
    } else if (status > 399) throw data;
    return data;
  } catch (error) {
    
    throw error;
  }
};

export const getPayoutList = () => async (dispatch, getState) => {
  try {
    const {data, status} = await axios.get(`${baseUrl}/get/payouts/`, tokenConfig(getState));
    if (status === 401) {
      dispatch(logout());
      return;
    }
    dispatch(setPayoutList(data));
  } catch (error) {
    
    throw error;
  }
};

export const getOutstandingCommission = () => async (dispatch, getState) => {
  try {
    const {data, status} = await axios.get(`${baseUrl}/v2/get-total-commission/`, tokenConfig(getState));
    if (status === 401) {
      dispatch(logout());
      return;
    }
    dispatch(setAffiliateCommission(data.amount)); // Set affiliate commission available for withdrawal
  } catch (error) {
    
    throw error;
  }
};

export const getPayoutAccounts = () => async (dispatch, getState) => {
  try {
    const {data, status} = await axios.get(`${baseUrl}/v2/get-payout-accounts/`, tokenConfig(getState));
    if (status === 401) {
      dispatch(logout());
      return;
    }
    return data;
  } catch (error) {
    
    throw error;
  }
};

export default payoutReducer;
