import {createSlice} from "@reduxjs/toolkit";
import {compileString} from "sass";
import {PURGE} from "redux-persist";

const initialState = {
  isAuthenticated: false,
  idToken: null,
  refreshToken: null,
  loading: false,
  error: false,
  message: "",
  userId:null
};

const handleFailure = (state, action) => {
  
  state.isAuthenticated = false;
  state.idToken = null;
  state.refreshToken = null;
  state.loading = false;
  state.error = true;
  state.userId = null
  // state.message = action?.payload?.response?.data?.detail
  //   ? action?.payload?.response?.data?.detail
  //   : action?.payload?.response?.data?.non_field_errors[0]
  //   ? action.payload.response.data.non_field_errors[0]
  //   : "An error occurred.";
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticate: (state, action) => {
      // const payload = action.payload;
      state.loading = true;
      state.error = false;
    },
    setAuthenticationSuccess: (state, action) => {
      state.error = false;
      state.message = "";
      state.isAuthenticated = true;
      state.idToken = action.payload.data.idToken;
      state.refreshToken = action.payload.data.refreshToken;
      state.loading = false;
      state.userId = action.payload?.data?.id
    },
    setAuthenticationFailure: (state, action) => {
      handleFailure(state, action);
    },
    deAuthenticate: (state) => {
      state.isAuthenticated = false;
      state.idToken = null;
      state.refreshToken = null;
      state.userId = null
    },
    setAuthenticationStatus: (state, action) => {
      if (!action.payload === false) {
        state.isAuthenticated = true;
        state.idToken = action.payload.data.idToken;
        state.refreshToken = action.payload.data.refreshToken;
      } else {
        state.isAuthenticated = action.payload;
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const {authenticate, deAuthenticate, setAuthenticationStatus, setAuthenticationSuccess, setAuthenticationFailure} = authSlice.actions;
export default authSlice.reducer;
