import React, {useEffect, useRef, useState} from "react";
import "./style.scss";

import ClockIcon from "../../../assets/icons/clock.svg";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg";
import CompanyLogo from "../../../assets/icons/equityEdgeLogo.svg";
import {Link} from "react-router-dom";
import Tooltip from "../../../reusableComponents/Tooltip";

function PasswordOTPForm() {
  const [inputValue, setInputValue] = useState({
    pin1: "",
    pin2: "",
    pin3: "",
    pin4: "",
  });
  const [inputError, setInputError] = useState("");
  const [isInputError, setIsInputError] = useState(false);

  const [seconds, setSeconds] = useState(59);
  const [isTimer, setIsTimer] = useState(false);

  const inputRefs = {
    pin1: useRef(null),
    pin2: useRef(null),
    pin3: useRef(null),
    pin4: useRef(null),
  };
  const submitButtonRef = useRef(null);

  const handleInputChange = (currentPin, newValue) => {
    newValue = newValue.replace(/\D/g, "");

    setInputValue((prev) => ({
      ...prev,
      [currentPin]: newValue,
    }));

    if (newValue.length === 1) {
      const currentInputIndex = parseInt(currentPin.charAt(3), 10);
      if (currentInputIndex < 4) {
        const nextPin = `pin${currentInputIndex + 1}`;
        inputRefs[nextPin].current.focus();
        inputRefs[nextPin].current.select();
      } else if (currentInputIndex === 4) {
        submitButtonRef.current.focus();
      }
    }
  };

  const handleFocus = () => {
    setIsInputError(false);
  };

  const handleInputClick = (inputName) => {
    const inputValue = inputRefs[inputName].current.value;
    if (inputValue) {
      inputRefs[inputName].current.select();
    }
  };

  const handleResendCode = (e) => {
    e.preventDefault();
    setSeconds(59);
    setIsTimer(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {pin1, pin2, pin3, pin4} = inputValue;

    try {
      if (Object.values(inputValue).every((item) => item !== "")) {
        const input = Object.values(inputValue).join("");
      } else {
        pin1 === "" && inputRefs.pin1.current.classList.add("input-error");
        pin2 === "" && inputRefs.pin2.current.classList.add("input-error");
        pin3 === "" && inputRefs.pin3.current.classList.add("input-error");
        pin4 === "" && inputRefs.pin4.current.classList.add("input-error");
        setIsInputError(true);
        setInputError("Enter The OTP");
      }
    } catch (err) {}
  };

  const handleBlur = (e) => {
    const {id} = e.target;
    id === "pin1" && inputRefs.pin1.current.classList.remove("input-error");
    id === "pin2" && inputRefs.pin2.current.classList.remove("input-error");
    id === "pin3" && inputRefs.pin3.current.classList.remove("input-error");
    id === "pin4" && inputRefs.pin4.current.classList.remove("input-error");
  };

  useEffect(() => {
    let interval;
    if (isTimer) {
      interval = setInterval(() => {
        if (seconds === 0) {
          clearInterval(interval);
          setIsTimer(false);
        } else {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isTimer, seconds]);

  return (
    <div className="passwordotpform-container">
      <div className="companyLogo">
        <img
          src={CompanyLogo}
          alt="companyLogo"
        />
      </div>
      <div className="passwordotpform-subcontainer">
        <h2 className="passwordotpform-title">Enter 4- Digit Code</h2>
        <p className="passwordotpform-subtitle">We have sent 4 digit code to your associated email address</p>
        <form className="passwordotpform-innercontainer passwordotpform-container_form">
          <h4 className="passwordotpform-title_form">Enter 4 digit code</h4>
          <div className="passwordotpform-subcontainer_form">
            {Object.keys(inputValue).map((pin) => (
              <input
                key={pin}
                type="text"
                id={pin}
                className="passwordotpform-input"
                value={inputValue[pin]}
                ref={inputRefs[pin]}
                onClick={() => handleInputClick(pin)}
                onChange={(e) => handleInputChange(pin, e.target.value)}
                onBlur={handleBlur}
                maxLength={1}
                onFocus={handleFocus}
              />
            ))}
            <Tooltip isActive={isInputError}>{inputError}</Tooltip>
          </div>
          <div className="passwordotpform-container_timer">
            <div className="passwordotpform-timer">
              {isTimer && (
                <>
                  <img
                    src={ClockIcon}
                    alt="Clock"
                    className="passwordotpform-icon"
                  />
                  <p>0:{seconds < 10 ? `0${seconds}` : seconds}</p>
                </>
              )}
            </div>
            <button
              className={`passwordotpform-resend ${isTimer ? "timer-active" : ""}`}
              onClick={handleResendCode}
              disabled={isTimer}
            >
              Resend Code
            </button>
          </div>
          <div className="passwordotpform-container_button">
            <button
              className="passwordotpform-button"
              ref={submitButtonRef}
              onClick={handleSubmit}
            >
              Submit
              {/* <img
                src={ArrowRightIcon}
                alt=""
                className="passwordotpform-icon"
              /> */}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PasswordOTPForm;
