import React from "react";
import "./style.scss";
import {lineSpinner} from "ldrs";

lineSpinner.register();

function ProcessingModal({open}) {
  return (
    open && (
      <div className="process_modal_overlay">
        <div className="ProcessingModalWrapper">
          <div className="loaderWithMsg">
            <l-line-spinner
              size="45"
              stroke="3"
              speed="1"
              color="#36d66b"
            ></l-line-spinner>
            <p className="processingText">Processing...</p>
          </div>
          <p className="processingSubText">Processing request</p>
          <p className="processingSubText">Please wait while the request is being processed...</p>
          <p className="processingSubText">Expected time 15 to 30 seconds</p>
          <p className="processingSubText">Do not refresh the page</p>
        </div>
      </div>
    )
  );
}

export default ProcessingModal;
