import React, {useEffect, useState} from "react";
import "./style.scss";

import {Navigate, Outlet, Route, Routes, useLocation} from "react-router-dom";
import Tabination from "../../reusableComponents/Tabination";
import ProgramObjectives from "../../layouts/AccountMetrics/ProgramObjectives";
import AdvancedInsights from "../../layouts/AccountMetrics/AdvancedInsights";
import TradingJournal from "../../layouts/AccountMetrics/TradingJournal";
import AccountInfo from "../../layouts/AccountMetrics/AccountInfo";
import {useDispatch, useSelector} from "react-redux";
import {getAccountMetrics} from "../../store/reducers/accountSlice";
import LoaderOverlay from "../../reusableComponents/Loader";
import ErrorModal from "../../reusableComponents/ErrorModal";
import MainHeader from "../../layouts/pageLayout/MainHeader";
function AccountMetrics() {
  const location = useLocation();
  const path = location.pathname.split("/")[2];

  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const items = [
    {
      name: "Program Objectives",
      path: "program-objectives",
      // content: (
      //   <div className="accountmetrics-container">
      //     <ProgramObjectives />
      //   </div>
      // ),
    },
    // {
    //   name: "Advanced Insights",
    //   path: "advanced-insights",

    // },
    {
      name: "Trading History",
      path: "trading-history",
      // content: (
      //   <div className="accountmetrics-container">
      //     <TradingJournal />
      //   </div>
      // ),
    },
    {
      name: "Account Info",
      path: "account-info",
      // content: (
      //   <div className="accountmetrics-container">
      //     <AccountInfo />
      //   </div>
      // ),
    },
  ];

  useEffect(() => {
    switch (path) {
      case "program-objectives":
        setActiveTab(0);
        break;
      // case "advanced-insights":
      //   setActiveTab(1);
      //   break;
      case "trading-history":
        setActiveTab(1);
        break;
      case "account-info":
        setActiveTab(2);
        break;
      default:
        break;
    }
  }, [path]);
  const accountMetrics = useSelector((state) => state.account.accountMetrics);
  const [isLoading, setIsLoading] = useState(accountMetrics.loading);
  const [errorModalOpen, setErrorModalOpen] = useState(accountMetrics.error);

  useEffect(() => {
    setIsLoading(accountMetrics.loading);
  }, [accountMetrics.loading]);
  // useEffect(() => {
  //   dispatch(getAccountMetrics({idToken, loginId}));
  // }, [idToken, activeAccount]);
  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isLoading]);
  return (
    <div className="mainPageHolder">
      <div className="mainPageHolder-T-C">
        <div className="mainPageHolder-T">
          <MainHeader
            headerName="Account Metrics"
            showTabs={false}
            tabItems={items}
            activeTab={activeTab}
          />
        </div>
      </div>
      <div className="mainPageHolder-B">
        <>
          <div className="all_header">
            <h2>Account Metrics</h2>
          </div>
          <Tabination
            variant={3}
            tabItems={items}
            activeTab={activeTab}
            style={{margin: "0 14px"}}
            className="tabination_all"
          />
          {/* 
          <MainHeader
            headerName="Account Metrics"
            showTabs={true}
            tabItems={items}
            activeTab={activeTab}
          /> */}
          <div className="accountmetrics-container">
            {(isLoading || accountMetrics.error) && <LoaderOverlay desc={accountMetrics.error && accountMetrics.message} />}

            <Routes>
              <Route
                path="/"
                element={
                  <Navigate
                    to="program-objectives"
                    replace={true}
                  />
                }
              />
              <Route
                path="program-objectives"
                element={
                  <ProgramObjectives
                    errorModalOpen={errorModalOpen}
                    setModalOpen={setErrorModalOpen}
                  />
                }
              />
              {/* <Route
                path="advanced-insights"
                element={
                  <AdvancedInsights
                    errorModalOpen={errorModalOpen}
                    setModalOpen={setErrorModalOpen}
                  />
                }
              /> */}
              <Route
                path="trading-history"
                element={
                  <TradingJournal
                    errorModalOpen={errorModalOpen}
                    setModalOpen={setErrorModalOpen}
                  />
                }
              />
              <Route
                path="account-info"
                element={
                  <AccountInfo
                    errorModalOpen={errorModalOpen}
                    setModalOpen={setErrorModalOpen}
                  />
                }
              />
            </Routes>
          </div>
        </>
      </div>
    </div>
  );
}

//THIS IS BASE CODE FOR THE CONTAINER ,
//need to add that on all the existing propstone port pages

{
  /* <div className="mainPageHolder">
  <div className="mainPageHolder-T-C">
    <div className="mainPageHolder-T">
      <MainHeader headerName="Dashboard" />
    </div>
  </div>
  <div className="mainPageHolder-B">
  
  //THE COMPONENT CODE SHOULD BE HERE (OLD CODE OF THE PAGE)
  
  </div>
</div> */
}

export default AccountMetrics;
