export function formatDateToCustomFormat(inputDate) {
  const date = new Date(inputDate);
  const options = { day: "numeric", month: "short", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
}

export function formatDateToDateMonth(inputDate) {
  const date = new Date(inputDate);
  const options = { day: "numeric", month: "short" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
}
