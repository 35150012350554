import React, {useState, useMemo, Fragment, useEffect} from "react";
import "./style.scss";
import {useTable, usePagination, useSortBy} from "react-table";
import {Link, useNavigate} from "react-router-dom";

import Card from "../../../reusableComponents/Card";
import WebGreyIcon from "../../../assets/icons/web-grey.svg";
import CaretDownIcon from "../../../assets/icons/caret-down.svg";
import CaretLeftIcon from "../../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../../assets/icons/caret-right.svg";
import {ReactComponent as DropdownIcon} from "../../../assets/icons/dropdown-arrow-gray.svg";
import StarBlueIcon from "../../../assets/icons/star-blue.svg";
import StarGreyIcon from "../../../assets/icons/star-grey.svg";
import AddCircleIcon from "../../../assets/icons/add_black_icon.svg";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {editSupportCases} from "../../../utils/apis/support";
import {returnErrors} from "../../../store/reducers/error";
import {Pagination} from "@mui/material";
function SupportCases({onTabChange, supportCase}) {
  const [selectedRow, setSelectedRow] = useState(null);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    onTabChange(1);
    navigate("/support-center/create-case");
  };
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: (
          <>
            <div className="column_header">
              Subject <DropdownIcon />
            </div>
          </>
        ),
        accessor: "subject",
      },

      {
        Header: (
          <>
            <div className="column_header">
              Created <DropdownIcon />
            </div>
          </>
        ),
        accessor: "created",
        Cell: ({cell, row}) => <div>{moment(cell.row.original.created).format("ll")}</div>,
      },
      {
        Header: (
          <>
            <div className="column_header">
              Case ID <DropdownIcon />
            </div>
          </>
        ),
        accessor: "id",
      },
      {
        Header: (
          <>
            <div className="column_header">
              Case type <DropdownIcon />
            </div>
          </>
        ),
        accessor: "case_type",
      },
      {
        Header: (
          <>
            <div className="column_header">
              Severity <DropdownIcon />
            </div>
          </>
        ),
        accessor: "severity",
      },
      {
        Header: "Status",
        accessor: "status",
      },
    ],
    [],
  );

  const data = supportCase || [];

  return (
    <Card className="economiccalender-container">
      <div className="economiccalender-subcontainer">
        <div className="economiccalender-innercontainer selected-row-header">
          <h2 className="table-head">Support Cases</h2>
          <div
            className="create-case"
            onClick={handleButtonClick}
          >
            <img
              src={AddCircleIcon}
              alt="AddCircleIcon"
            />
            Create a Case
          </div>
        </div>
        <div className="economiccalender-container_table">
          {data && (
            <Table
              columns={columns}
              tableData={data}
              setSelectedRow={setSelectedRow}
              selectedRow={selectedRow}
            />
          )}
        </div>
      </div>
      {data.length <= 0 && (
        <h2 style={{textAlign: "center", marginTop: "20px", color: "#35d269"}}>
          <Link to="/support-center/create-case">Create your first ticket</Link>
        </h2>
      )}
    </Card>
  );
}

export default SupportCases;

function Table({columns, tableData, setSelectedRow, selectedRow}) {
  const data = tableData;

  const initialState = useMemo(
    () => [
      {
        pageSize: 10,
        pageIndex: 0,
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    pageCount,
    state: {pageIndex, pageSize},
  } = useTable({columns, data, initialState}, useSortBy, usePagination);

  // edit
  const idToken = useSelector((state) => state.auth.idToken);

  const [editData, setEditData] = useState({
    id: 32,
    subject: "",
    category: "",
    severity: "",
    comment: "",
    attachment: null,
  });

  const handleEdit = (e) => {
    setEditData(e.target.value);
    editSupportCases(idToken, editData);
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const pageButtons = [];
  const [gotoPageInput, setGotoPageInput] = useState();
  const handlePageChange = (event, newPage) => {
    gotoPage(newPage - 1); // Adjust for 0-based index used by react-table
    setCurrentPage(newPage);
  };

  const handleGotoPage = () => {
    const pageNumber = parseInt(gotoPageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= pageCount) {
      gotoPage(pageNumber - 1);
      setCurrentPage(pageNumber);
      setGotoPageInput("");
    }
  };

  return (
    <>
      <div className="table_main">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, headerIndex) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={headerIndex}
              >
                {headerGroup.headers.map((column, columnIndex) => (
                  <th
                    {...(column.getHeaderProps().key === "header_status"
                      ? {...column.getHeaderProps()}
                      : {
                          ...column.getHeaderProps(column.getSortByToggleProps()),
                        })}
                    className={column.isSorted ? (column.isSortedDesc ? "sort down" : "sort up") : ""}
                    key={columnIndex}
                  >
                    <span>{column.render("Header")} </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page?.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <Fragment key={rowIndex}>
                  <tr
                    {...row.getRowProps()}
                    className={`economiccalender-row ${selectedRow === rowIndex ? "economiccalender-row-active" : ""} `}
                    onClick={() => {
                      selectedRow === rowIndex ? setSelectedRow(null) : setSelectedRow(rowIndex);
                    }}
                  >
                    {row?.cells?.map((cell, cellIndex) => (
                      <td
                        {...cell.getCellProps()}
                        key={cellIndex}
                      >
                        {cell.column.id === "status" ? (
                          <>
                            {cell.row.values.status === "in_progress" ? (
                              <div
                                className="status-container"
                                style={{
                                  color: "#FFC43D",
                                }}
                              >
                                In Progress
                              </div>
                            ) : cell.row.values.status === "Resolved" ? (
                              <div
                                className="status-container"
                                style={{
                                  color: "#35d269",
                                }}
                              >
                                {cell.row.values.status}
                              </div>
                            ) : (
                              cell.row.values.status === 3 && (
                                <div
                                  className="status-container"
                                  style={{
                                    color: "#F25F5C",
                                  }}
                                >
                                  Error
                                </div>
                              )
                            )}
                          </>
                        ) : cell.column.id === "importance" ? (
                          <Importance activeStars={cell.value} />
                        ) : cell.column.id === "created" ? (
                          <div>{moment(cell.value).format("DD MMM YYYY")}</div>
                        ) : (
                          cell.value
                        )}
                      </td>
                    ))}
                  </tr>
                  {selectedRow === rowIndex && (
                    <tr>
                      <td
                        colSpan="7"
                        className="row-selected"
                      >
                        <div className="supportcases-td-container">
                          <div className="td-main-div">
                            <div className="header_main">
                              <h3 className="row-head">{row.original.subject}</h3>
                              {/* <button className="edit_button">Edit</button>
                            <button
                              className="edit_button"
                              onClick={handleEdit}
                            >
                              Update
                            </button> */}
                            </div>
                            <div className="supportcases-type-details">
                              <div className="supportcases-box">
                                <p>Case ID</p>
                                <h4>{row.values.id}</h4>
                              </div>
                              <div className="supportcases-box">
                                <p>Status</p>
                                <h4>
                                  {row.values.status === "in_progress" ? (
                                    <div
                                      className="status-container"
                                      style={{
                                        // background: "rgba(200, 233, 197, 0.50)",
                                        color: "rgb(255, 196, 61)",
                                      }}
                                    >
                                      In Progress
                                    </div>
                                  ) : row.values.status === "passed" ? (
                                    <div
                                      className="status-container"
                                      style={{
                                        background: "#FDEEB4",
                                        color: "#B18E0A",
                                      }}
                                    >
                                      Passed
                                    </div>
                                  ) : (
                                    row.values.status === 3 && (
                                      <div
                                        className="status-container"
                                        style={{
                                          background: "#F5BDC0",
                                          color: "#9E1D26",
                                        }}
                                      >
                                        Error
                                      </div>
                                    )
                                  )}
                                </h4>
                              </div>
                              <div className="supportcases-box">
                                <p>Case Type</p>
                                <h4>
                                  {row.values.case_type
                                    ?.toString()
                                    .split("_")
                                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(" ")}
                                </h4>
                              </div>
                              {/* <div className="supportcases-box">
                              <p>Opened by</p>
                              <h4>Abhishek</h4>
                            </div> */}
                            </div>
                            <div className="supportcases-details">
                              <div className="supportcases-comments">
                                <p>Comments</p>
                                <h4>{row.original.comment}</h4>
                              </div>
                              <div className="supportcases-comments">
                                <p>Description</p>
                                <h4>{row.original.description}</h4>
                              </div>
                              <div className="supportcases-category">
                                <div className="supportcases-comments comments_groupB">
                                  <p>Created</p>
                                  <h4>{moment(row.original.created).format("DD MMM YYYY")}</h4>
                                </div>
                                <div className="supportcases-comments comments_groupB">
                                  <p>Subject</p>
                                  <h4>{row.original.subject}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      {pageOptions.length > 1 && (
        <div className="pagination">
          <img
            src={CaretLeftIcon}
            alt="left-arrow"
          />
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </button>
          {pageOptions.map((pageNumber, index) => (
            <>
              <button
                key={index}
                onClick={() => gotoPage(index)}
                className={pageIndex === index ? "btn-active" : "non-active"}
              >
                {pageNumber < 10 ? `0${pageNumber + 1}` : pageNumber}
              </button>
            </>
          ))}
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </button>
          <img
            src={CaretRightIcon}
            alt="right-arrow"
          />
          <div className="goto-page-input">
            <input
              type="number"
              placeholder="Go to Page"
              value={gotoPageInput}
              onChange={(e) => setGotoPageInput(e.target.value)}
            />
            <button
              className="goToButton"
              onClick={handleGotoPage}
            >
              Go
            </button>
          </div>
        </div>
      )}
    </>
  );
}

function Importance({activeStars}) {
  let totalStars = 3;
  let inactiveStars = totalStars - activeStars;
  const stars = [];
  for (let i = 0; i < activeStars; i++) {
    stars?.push(
      <img
        src={StarBlueIcon}
        alt=""
        key={`active${i}`}
        className="importance-star"
      />,
    );
  }
  for (let i = 0; i < inactiveStars; i++) {
    stars?.push(
      <img
        src={StarGreyIcon}
        alt=""
        key={`inactive${i}`}
        className="importance-star"
      />,
    );
  }
  return <>{stars}</>;
}
