import React, {useEffect, useState, useRef} from "react";
import "./style.scss";

import UserIcon from "../../../assets/icons/user.svg";
import PhoneIcon from "../../../assets/icons/phone.svg";
import WebIcon from "../../../assets/icons/web.svg";
import EmailIcon from "../../../assets/icons/email.svg";
import LockIcon from "../../../assets/icons/lock.svg";
import EyeIcon from "../../../assets/icons/eye.svg";
import EyeSlashedIcon from "../../../assets/icons/eye-slashed.svg";
import CheckSolidGreenIcon from "../../../assets/icons/check-solid-green.svg";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg";
import CaretDownIcon from "../../../assets/icons/caret-down.svg";

import {Link, useNavigate, useParams} from "react-router-dom";
import {validateContact, validateEmail, validateLetters, validatePassword} from "../../../utils/helpers/string";
import {country} from "../../../utils/constants/country";
import Tooltip from "../../../reusableComponents/Tooltip";
import {signupRequest} from "../../../utils/apis";
import {useDispatch} from "react-redux";
import {authenticate, setAuthenticationStatus, setAuthenticationSuccess} from "../../../store/reducers/authSlice";
import ErrorModal from "../../../reusableComponents/ErrorModal";
import {returnErrors} from "../../../store/reducers/error";
import Spinner from "../../../reusableComponents/Spinner";
import {pushLeads} from "../../../utils/apis/auth";
import CompanyLogo from "../../../assets/icons/equityEdgeLogo.svg";
import * as Yup from "yup";
import {useFormik} from "formik";
import GoogleIcon from "../../../assets/icons/google.svg";
import customStyles from "../../../customstyle/customstyle";

function SignupForm() {
  const {referalcode} = useParams();
  const countryRef = useRef(null);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isDropdown, setIsDropdown] = useState(false);
  const [countryCode, setCountryCode] = useState("44");
  // removed log

  const countryOptions = country;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [options, setOptions] = useState(countryOptions);
  const [formData, setFormData] = useState(new FormData());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSpinner, setIsSpinner] = useState(false);

  const schema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    country: Yup.string().required("Country is required"),
    contact: Yup.number().required("Number is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required").min(8, "Password must be at least 8 characters long").max(16, "Password must not exceed 16 characters"),

    country_code: Yup.string()
      .oneOf(
        country.map((item) => item.code),
        "Invalid country code",
      )
      .required("Country code is required"),
  });

  const inputs = {
    first_name: "",
    last_name: "",
    country: "",
    contact: "",
    email: "",
    password: "",
    country_code: countryCode,
    ...(referalcode && {referal_code: referalcode}),
  };

  // removed log

  const {values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue} = useFormik({
    initialValues: inputs,
    validationSchema: schema,
    onSubmit: async (values) => {
      setIsSpinner(true);
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
      const response = await signupRequest(formData);
      
      if (response.status <= 201) {
        // dispatch(authenticate({email: values.email, password: values.password}));
        dispatch(setAuthenticationSuccess({data: {idToken: response.data.idToken}}));
        navigate("/funding-evaluation/plans/0");
        setIsSpinner(false);
      } else if (response.response) {
        let msg = "";
        setIsSpinner(false);

        if (response?.response?.data) {
          msg = response?.response?.data?.detail || response?.response?.data?.email[0];
          setIsSpinner(false);
        } else {
          msg = "Please check all the field";
        }
        dispatch(returnErrors(msg, 400));
        setIsSpinner(false);
      }
    },
  });

  useEffect(() => {
    if (referalcode) {
      formData.set("referal_code", referalcode);
      let formDataPL = new FormData();
      formDataPL.append("referal_code", referalcode);
      pushLeads(formDataPL);
    }
  }, []);

  const handleDropdownChange = (value) => {
    const selectedOption = options.find((option) => option === value);
    if (selectedOption) {
      setFieldValue("country", value.country);
      setFieldValue("country_code", value.code);
      setCountryCode(value.code);
      setSearchInput(value.country);
      setIsDropdown(false);
    }
  };

  const [serachInput, setSearchInput] = useState("United Kingdom");
  useEffect(() => {
    setFieldValue("country_code", countryCode);
    setFieldValue("country", serachInput);
  }, [countryCode, serachInput]);
  const handleSearch = (e) => {
    setIsDropdown(true);

    const {name, id, value} = e.target;
    id === "country" && setOptions(countryOptions.filter((item) => item.country.toLowerCase().includes(value.toLowerCase())));
    setSearchInput(value);
  };
  const countryMainRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (countryRef.current && !countryRef.current.contains(event.target) && countryMainRef.current && !countryMainRef.current.contains(event.target)) {
        setIsDropdown(false);
      }
    };

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleGoogleAuth = (e) => {
    e.preventDefault();
    let path =
      "https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/user.addresses.read&include_granted_scopes=true&response_type=code&state=pass-through value&redirect_uri=https://app.equityedge.co.uk/auth/google&client_id=454348839117-icvli9lq10npbbautre2h62keggrvnlp.apps.googleusercontent.com";
    window.location.replace(path);
  };
  
  return (
    <div className="signupform-container">
      <div className="companyLogo">
        <img
          src={CompanyLogo}
          alt="companyLogo"
        />
      </div>
      <div className="signupform-subcontainer">
        <h2 className="signupform-title">Sign up to choose your challenge</h2>
        {/* <p className="signupform-subtitle">Welcome! Good to See you!</p> */}

        <form className="signupform-innercontainer signupform-container_form">
          <div className="signupform-subcontainer_form">
            <div className="signupform-innercontainer_form">
              <div className="signupform-container_icon">
                <img
                  src={UserIcon}
                  alt=""
                  className="signupform-icon"
                  style={{width: "16px"}}
                />
              </div>
              <div className="signupform-container_input">
                <label
                  className="signupform-label"
                  htmlFor="first_name"
                ></label>
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  placeholder="First Name"
                  className="signupform-input"
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div style={{display: "flex", color: "red", fontSize: "15px"}}> {touched.first_name && errors.first_name && <div className="error-message">{errors.first_name}</div>}</div>
          </div>
          <div className="signupform-subcontainer_form">
            <div className="signupform-innercontainer_form">
              <div className="signupform-container_icon">
                <img
                  src={UserIcon}
                  alt=""
                  className="signupform-icon"
                  style={{width: "16px"}}
                />
              </div>
              <div className="signupform-container_input">
                <label
                  className="signupform-label"
                  htmlFor="last_name"
                ></label>
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  className="signupform-input"
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div style={{display: "flex", color: "red", fontSize: "15px"}}> {touched.last_name && errors.last_name && <div className="error-message">{errors.last_name}</div>}</div>
          </div>
          <div className="signupform-subcontainer_form">
            <div className="signupform-innercontainer_form">
              <div className="signupform-container_icon">
                <img
                  src={PhoneIcon}
                  alt=""
                  className="signupform-icon"
                />
              </div>
              <div
                className="signupform-container_input"
                style={{flexDirection: "row"}}
              >
                <div>
                  <span style={{display: "flex", color: "#f3c967", borderRight: "1px solid #242529", marginRight: "7px", alignItems: "center"}}>
                    +
                    <input
                      type="text"
                      value={values.country_code}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^-?\d*-?\d*$/.test(inputValue)) {
                          setFieldValue("country_code", inputValue);
                        }
                      }}
                      style={{width: "50px", fontSize: "16px", color: ""}}
                    />
                  </span>
                </div>
                <input
                  type="number"
                  name="contact"
                  id="contact"
                  className="signupform-input"
                  placeholder="Contact Number"
                  value={values.contact}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{width: "100%"}}
                />
              </div>
            </div>
            <div style={{display: "flex", color: "red", fontSize: "15px"}}> {touched.contact && errors.contact && <div className="error-message">{errors.contact}</div>}</div>
            <div style={{display: "flex", color: "red", fontSize: "15px"}}>{touched.country_code && errors.country_code && <div className="error-message">{errors.country_code}</div>}</div>
          </div>
          <div className="signupform-subcontainer_form">
            <div className="signupform-innercontainer_form">
              <div className="signupform-container_icon">
                <img
                  src={EmailIcon}
                  alt=""
                  className="signupform-icon"
                />
              </div>
              <div className="signupform-container_input">
                <label
                  className="signupform-label"
                  htmlFor="email"
                ></label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="signupform-input"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Email Address"
                />
              </div>

              <div className="signupform-container_icon">
                {validateEmail(values.email) && (
                  <img
                    src={CheckSolidGreenIcon}
                    alt=""
                    className="signupform-icon"
                  />
                )}
              </div>
            </div>
            <div style={{display: "flex", color: "red", fontSize: "15px"}}> {touched.email && errors.email && <div className="error-message">{errors.email}</div>}</div>
          </div>
          <div className="signupform-container_dropdown">
            {/* <label
              className="signupform-label"
              htmlFor="email"
            >
              Country
            </label> */}
            <div
              ref={countryRef}
              className="signupform-subcontainer_form"
              onClick={() => setIsDropdown(true)}
            >
              <div
                className={`signupform-innercontainer_form ${isDropdown ? "signupform-select" : ""}`}
                ref={countryMainRef}
              >
                <div className="signupform-container_icon">
                  <img
                    src={WebIcon}
                    alt=""
                    className="signupform-icon"
                  />
                </div>
                <div
                  className="signupform-container_input"
                  // style={{border: touched.country && errors.country && "1px solid red", borderRadius: "5px"}}
                >
                  <input
                    type="text"
                    name="country"
                    id="country"
                    className="signupform-input"
                    value={serachInput}
                    onChange={handleSearch}
                    onBlur={handleBlur}
                    styles={customStyles}
                  />
                  {/* <p className="signupform-input">{inputValue.country}</p> */}
                </div>

                <div className="signupform-container_icon">
                  <img
                    src={CaretDownIcon}
                    alt=""
                    className="signupform-icon"
                  />
                </div>
              </div>
              <div style={{display: "flex", color: "red", fontSize: "15px"}}>
                {" "}
                {touched.country && errors.country && (
                  <div
                    className="error-message"
                    style={{zIndex: isDropdown && "10"}}
                  >
                    {errors.country}
                  </div>
                )}
              </div>
            </div>

            {/* <Tooltip isActive={isInputError.country}>{inputError.country}</Tooltip> */}
            {isDropdown && (
              <div
                id="signupform-dropdown"
                className="signupform-dropdown signupform-innercontainer_form"
              >
                <ul>
                  {options.length !== 0 ? (
                    options.map((item, index) => (
                      <li
                        key={index}
                        className={`${serachInput === item.country ? "signupform-dropdown_active" : ""}`}
                        onClick={() => handleDropdownChange(item)}
                      >
                        {item.country}
                      </li>
                    ))
                  ) : (
                    <li className="list-no_country">No Country</li>
                  )}
                </ul>
              </div>
            )}
          </div>
          <div className="signupform-subcontainer_form">
            <div className="signupform-innercontainer_form">
              <div className="signupform-container_icon">
                <img
                  src={LockIcon}
                  alt=""
                  className="signupform-icon"
                />
              </div>
              <div className="signupform-container_input">
                <label
                  className="signupform-label"
                  htmlFor="password"
                ></label>
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  name="password"
                  id="password"
                  className="signupform-input"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Password"
                />
              </div>

              <div className="signupform-container_icon">
                <img
                  src={isPasswordVisible ? EyeIcon : EyeSlashedIcon}
                  alt=""
                  className="signupform-icon"
                  onClick={() => setIsPasswordVisible((prev) => !prev)}
                />
              </div>
            </div>
            <div style={{display: "flex", color: "red", fontSize: "15px"}}> {touched.password && errors.password && <div className="error-message">{errors.password}</div>}</div>
          </div>
          {/* <p className="signupform-error">The password entered is wrong!</p> */}
          <div className="signupform-container_button">
            <button
              className="signupform-button"
              onClick={handleSubmit}
              disabled={isSpinner}
            >
              {isSpinner ? <Spinner /> : "Sign Up"}
              {/* <img src={ArrowRightIcon} alt="" className="signupform-icon" /> */}
            </button>
          </div>
        </form>
        <p className="signupform-link">
          Already have an account?{" "}
          <Link to="/signin">
            <b className="signupform-link_bold">Sign In</b>
          </Link>
        </p>
        <h2 className="signupform-title">Sign up</h2>
        <div className="lines">
          <div className="left"></div>
          <p>OR</p>
          <div className="left"></div>
        </div>
        <p className="signupform-continue">with</p>
        <div className="signinform-innercontainer signinform-container_icon">
          <div className="signinform-subcontainer_icon">
            <img
              src={GoogleIcon}
              alt=""
              className="signinform-icon"
              onClick={handleGoogleAuth}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupForm;
