import React, {useEffect, useState} from "react";
import "./style.scss";
import Edit from "../../../../assets/icons/edit-pencil.svg";
import Profile from "../../../../assets/icons/user.png";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {getUser, patchUser, setUser} from "../../../../store/reducers/userSlice";
import {country} from "../../../../utils/constants/country";
import SuccessModal from "../../../reusableComponents/SuccessModal";
import ErrorModal from "../../../../reusableComponents/ErrorModal";
import axios from "axios";
import {baseUrl, getUserDetails} from "../../../../utils/apis";
import {returnMessages} from "../../../../store/reducers/message";
import {returnErrors} from "../../../../store/reducers/error";
import Spinner from "../../../../reusableComponents/Spinner";
import AccountPassword from "../AccountPassword";
import customStyles from "../../../../customstyle/customstyle";

// import CountryIcon from "../../../../assets/icons/country.svg";
// import UserIcon from "../../../../assets/icons/userIcons.svg";
// import PhoneIcon from "../../../../assets/icons/phone.svg";

// import EmailIcon from "../../../../assets/icons/emailIcons.svg";

import CityIcon from "../../../../assets/icons/street.svg";
import {deAuthenticate} from "../../../../store/reducers/authSlice";
import {clearPersistedData} from "../../../../store/configureStore";

function AccountDetails({userDetails, editModes, setEditMode, disabledInputs, match, setChangePassword, changePassword, confirmNewPassword, setConfirmNewPassword, handleChangePassword}) {
  // removed log
  const idToken = useSelector((state) => state.auth.idToken);
  const [countryCode, setCountryCode] = useState("");
  const editMode = true;
  // removed log

  const dispatch = useDispatch();
  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const options = country.map((item) => {
    return {
      value: item.country,
      label: item.country,
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [countryName, setCountryName] = useState({label: userDetails.country, value: userDetails.country});
  const [formData, setFormData] = useState({
    first_name: userDetails.first_name || "",
    last_name: userDetails.last_name || "",
    contact: userDetails.contact || "",
    email: userDetails.email || "",
    country: userDetails.country || countryName?.value,
    city: userDetails.city || "",
    country_code: userDetails.country_code || "",
  });
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      country: countryName?.value,
    }));
  }, [countryName]);
  // removed log

  const [isSpinner, setIsSpinner] = useState(false);
  const [inputErrors, setInputErrors] = useState({
    email: "",
    contact: "",
  });

  const handleChange = (e) => {
    const {name, value} = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCountryChange = (selectedOption) => {
    // removed log
    setCountryName(selectedOption);
  };

  const handleCityChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedCity: selectedOption,
    }));
  };
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      country: userDetails.country,
    }));
  }, []);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSave = async () => {
    setIsSpinner(true);
    // dispatch(patchUser({ formData, idToken }));
    let config = {
      headers: {Authorization: `Bearer ${idToken}`},
    };

    // axios
    //   .patch(`${baseUrl}/user/profile/`, formData, config)
    //   .then(async (res) => {
    //     const msg = "Updated Successfully";
    //     const status = res.status;
    //     dispatch(returnMessages(msg, status));
    //     const profileData = await getUserDetails(idToken);
    //     dispatch(setUser(profileData));
    //     setIsSpinner(false);
    //   })
    //   .catch((error) => {
    //     // setError(true);
    //     const msg = "error";
    //     const status = 500;
    //     dispatch(returnErrors(msg, status));
    //   });
    // if (match && changePassword !== "" && confirmNewPassword !== "") {
    handleChangePassword();
    // }
    setIsSpinner(false);

    setTimeout(() => {
      setEditMode(!editMode);
    }, 2000);
  };
  const handleLogout = () => {
    dispatch(deAuthenticate());
    clearPersistedData();
    window.location.reload()
  };
  return (
    <>
      <div className="account_details_upper">
        <div
          className="account_details_upper_left"
          style={{width: "100%"}}
        >
          <div className="account_img_wrapper">
            <img
              src={Profile}
              alt="profile icon"
            />
          </div>
          <div className="account_details_upper_content">
            <h4>{`${userDetails.first_name} ${userDetails.last_name}` || "Daniel Radcliffe"}</h4>
            {/* <p>Funded Trader</p> */}
          </div>
          <div
            className="account_edit_img_wrapper"
            style={{
              marginLeft: "auto",
            }}
            onClick={handleEditMode}
          >
            {editModes ? (
              <img
                src={Edit}
                style={{cursor: "pointer"}}
                alt="edit icon"
              />
            ) : (
              <button
                className="save-btn"
                onClick={handleSave}
                disabled={isSpinner || editModes}
                style={{backgroundColor: editModes && "#72727263"}}
              >
                {isSpinner ? <Spinner /> : <>Save Changes</>}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="account_details">
        {success ? (
          <SuccessModal
            desc={"Update Successfully"}
            setModalOpen={setSuccess}
            btnText="Cancel"
          />
        ) : null}
        {error ? (
          <ErrorModal
            desc={"Error"}
            setModalOpen={setError}
            btnText="Cancel"
          />
        ) : null}
        <div className="account_details_lower">
          <div className="account_form">
            <form>
              <div className="account_input_container_upper">
                <div className="account_input"></div>
                <div className="account_input">
                  <label
                    htmlFor="firstName"
                    className="label_black"
                  >
                    First Name
                  </label>
                  <br />
                  <div className={`account_input_wrapper ${!editMode && "editable"}`}>
                    <input
                      className="account_details_input"
                      type="text"
                      value={formData.first_name}
                      placeholder="First Name"
                      name="first_name"
                      onChange={handleChange}
                      disabled={editMode}
                    />
                  </div>
                </div>
                <div className="account_input">
                  <label htmlFor="lastName">Last Name</label>
                  <br />
                  <div className={`account_input_wrapper ${!editMode && "editable"}`}>
                    <input
                      className="account_details_input"
                      type="text"
                      placeholder="Last Name"
                      value={formData.last_name}
                      name="last_name"
                      onChange={handleChange}
                      disabled={editMode}
                    />
                  </div>
                </div>
                <div className="account_input ">
                  <label htmlFor="contact">Contact Number</label>
                  <br />
                  <div
                    className={`contactNumber_div ${!editMode && "editable"}`}
                    style={{color: "white"}}
                  >
                    <div className="countryCodeDiv">
                      <span className="contactNumber_p">+</span>

                      <input
                        className="contactNumber_input"
                        type="number"
                        value={formData.country_code}
                        onChange={(e) => setFormData((prev) => ({...prev, country_code: e.target.value}))}
                        style={{width: "min-content", maxWidth: "26px", fontSize: "16px"}}
                        disabled={editMode}
                      />
                    </div>
                    <div className="account_input_wrapper_contact ">
                      <input
                        className={`account_details_input ${inputErrors.contact ? "input_error" : ""}`}
                        type="number"
                        value={formData?.contact}
                        name="contact"
                        onChange={handleChange}
                        disabled={editMode}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="account_input_container_lower">
                <div className="account_input">
                  <label
                    htmlFor="email"
                    className="label_black"
                  >
                    Email
                  </label>
                  <br />
                  <div className={`account_input_wrapper ${!editMode && "editable"}`}>
                    <input
                      className={`account_details_input ${inputErrors.email ? "input_error" : ""}`}
                      type="text"
                      value={formData.email}
                      name="email"
                      onChange={handleChange}
                      disabled={editMode}
                      style={{marginRight: "10px"}}
                    />
                  </div>
                </div>
                <div className="account_input country_selector">
                  <label htmlFor="country">Country</label>
                  <br />
                  <div className={`account_input_wrapper country ${!editMode && "editable"}`}>
                    <Select
                      isSearchable={true}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={options}
                      styles={customStyles}
                      value={countryName}
                      onChange={handleCountryChange}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      name="selectedCountry"
                      id="country"
                      isDisabled={editMode}
                    />
                  </div>
                </div>
                <div className="account_input">
                  <label htmlFor="city">City</label>
                  <br />
                  <div className={`account_input_wrapper ${!editMode && "editable"}`}>
                    <input
                      className={`account_details_input ${inputErrors.city ? "input_error" : ""}`}
                      type="text"
                      name="city"
                      value={formData.city}
                      // placeholder={userDetails.city}
                      placeholder="City"
                      onChange={handleChange}
                      disabled={editMode}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <AccountPassword
        disabledInputs={disabledInputs}
        match={match}
        setChangePassword={setChangePassword}
        changePassword={changePassword}
        confirmNewPassword={confirmNewPassword}
        setConfirmNewPassword={setConfirmNewPassword}
        handleLogout={handleLogout}
      />
    </>
  );
}

export default AccountDetails;
