import React, {useEffect, useState} from "react";
import "./style.scss";
import clicks from "../../../assets/icons/arrow-increase.svg";
import pushedLeds from "../../../assets/icons/pushed-leads.svg";
import conversion from "../../../assets/icons/conversion.svg";
import profit from "../../../assets/icons/arrow-decrease.svg";

import Loss from "../../../assets/icons/loss-chart.svg";
import InfoBox from "../../../reusableComponents/InfoBox";
import TrophyIcon from "../../../assets/icons/trophy-white.svg";
import ContestantsIcon from "../../../assets/icons/people-white.svg";
import Gain from "../../../assets/icons/gain-chart.svg";
import PushLeads from "./PushLeads";
import Visitor from "./Visitor";
import OverviewTable from "./OverviewTable";
import AreaPreviewChart from "./AreaPreviewChart";
import {useSelector} from "react-redux";
import axios from "axios";
import {baseUrl} from "../../../utils/apis";
import LoaderOverlay from "../../../reusableComponents/Loader";

function Overview() {
  const idToken = useSelector((state) => state.auth.idToken);
  const [codeList, setCodeList] = useState({});
  const pushLeadData = codeList?.click_stats || [];
  const [isLoading, setIsLoading] = useState(false);
  
  
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setIsLoading(true);
    let config = {
      headers: {
        Authorization: "Bearer " + idToken,
      },
    };
    axios
      .get(`${baseUrl}/affiliates/affiliate-stats/`, config)
      .then((res) => {
        setCodeList(res?.data || []);
        setIsLoading(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const infoBoxArr = [
    {
      title: "Equity",
      value: "30872",
      icon: clicks,
    },
    {
      title: "Balance",
      value: "$5000",
      icon: pushedLeds,
    },
    {
      title: "PnL",
      value: "1000",
      icon: conversion,
    },
    {
      title: "Win Rate",
      value: "500",
      icon: profit,
    },
  ];

  const competitionsArr = [
    {
      title: "New Users",
      value: "+15",
      img: Gain,
    },
    {
      title: "Page Views",
      value: "-4",
      img: Loss,
    },
  ];
  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isLoading]);
  const allClicks = codeList?.click_stats?.reduce((totalClicks, currentItem) => totalClicks + currentItem.clicks, 0);

  return (
    <div className="overview">
      {isLoading && <LoaderOverlay />}
      <h4>Overview</h4>
      <div className="grid_container">
        <div className="column column-70">
          <div
            className="box row-2 push_leads"
            style={{display: codeList?.click_stats?.length > 0 ? "block" : "none"}}
          >
            {pushLeadData.length > 0 && <PushLeads codeList={pushLeadData || []} />}
          </div>
          <div className="row-1">
            <div className="infobox_col1_container">
              <InfoBox
                title="Clicks"
                value={allClicks}
                icon={clicks}
              />

              <InfoBox
                title="Pushed Leads"
                value={codeList?.pushed_leads}
                icon={pushedLeds}
              />

              <InfoBox
                title="Commissions"
                value={codeList?.commission_amount}
                // value="NA"
                icon={profit}
              />

              <InfoBox
                title="Conversions "
                value={codeList?.conversions}
                icon={conversion}
              />
            </div>
          </div>
        </div>
        {/* <div className="column column-30">
          <div className="row-1">
            <div className="infobox_col2_container">
              {competitionsArr.map((info) => (
                <InfoBox
                  key={info.title}
                  title={info.title}
                  value={info.value}
                  img={info.img}
                  variant={3}
                />
              ))}
            </div>
          </div>
          <div className="box visitor row-2">
            <Visitor />
          </div>
        </div> */}
      </div>
      <div className="box table_container">
        <OverviewTable />
      </div>
    </div>
  );
}

export default Overview;
