import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const SubMenu = ({item, handleTabClick, activeTab, subMenuActiveTab, onClick}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {}, [activeTab, subMenuActiveTab]);

  useEffect(() => {
    if (["Affiliate", "Account Metrics", "Choose challenge", "Withdrawals", "Market Analysis"].includes(activeTab)) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [activeTab]);

  const tabItems = [];
  const {plans} = useSelector((state) => state.account);
  plans?.data?.map((item, index) =>
    tabItems.push({
      title: item.name,
      subMenuActive: item.name,
      active: "Choose challenge",
      path: `/funding-evaluation/plans/${index}`,
    }),
  );

  if (item.childrens) {
    return (
      <>
        <Link
          to={item.path}
          id={item.title}
          className={activeTab === item.title ? "active" : ""}
          onClick={(e) => {
            handleTabClick(item.active, item.childrens[0].subMenuActive);
            setOpen(!open);
          }}
        >
          {item.icon}
          {item.title}
          <span className="arrow">{arrows}</span>
        </Link>
        <div className={`sidebar-content ${activeTab === item.title && open && "openNested"}`}>
          {item.title === "Choose challenge"
            ? tabItems.map((child, index) => (
                <SubMenu
                  key={index}
                  item={child}
                  handleTabClick={handleTabClick}
                  activeTab={activeTab}
                  subMenuActiveTab={subMenuActiveTab}
                />
              ))
            : item.childrens.map((child, index) => (
                <SubMenu
                  key={index}
                  item={child}
                  handleTabClick={handleTabClick}
                  activeTab={activeTab}
                  subMenuActiveTab={subMenuActiveTab}
                />
              ))}
        </div>
      </>
    );
  } else {
    return (
      <>
        <Link
          to={item.path}
          id={item.title}
          className={`${activeTab === item.title ? "active" : ""} ${subMenuActiveTab === item.subMenuActive ? "subActive" : ""}`}
          onClick={() => {
            handleTabClick(item.active, item.subMenuActive);
          }}
        >
          {item.icon || reverseArrows}
          {item.title}
          {item.title === "1 Step" ? " Challenge" : item.title === "2 Step" ? " Challenge" : ""}
        </Link>
      </>
    );
  }
};
const arrows = (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 10.3301L12 15.3301L17 10.3301"
      stroke="#6A796E"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const reverseArrows = (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75 4.33008C3.75 3.91586 3.41421 3.58008 3 3.58008C2.58579 3.58008 2.25 3.91586 2.25 4.33008H3.75ZM17.5303 14.8604C17.8232 14.5675 17.8232 14.0926 17.5303 13.7997L12.7574 9.02678C12.4645 8.73388 11.9896 8.73388 11.6967 9.02678C11.4038 9.31967 11.4038 9.79454 11.6967 10.0874L15.9393 14.3301L11.6967 18.5727C11.4038 18.8656 11.4038 19.3405 11.6967 19.6334C11.9896 19.9263 12.4645 19.9263 12.7574 19.6334L17.5303 14.8604ZM2.25 4.33008V10.3301H3.75V4.33008H2.25ZM7 15.0801H17V13.5801H7V15.0801ZM2.25 10.3301C2.25 12.9534 4.37665 15.0801 7 15.0801V13.5801C5.20507 13.5801 3.75 12.125 3.75 10.3301H2.25Z"
      fill="#071E0D"
    />
  </svg>
);
export default SubMenu;
