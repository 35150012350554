import axios from "axios";
import {baseUrl} from ".";

const signupRequest = async (formdata) => {
  const response = await axios.post(`${baseUrl}/auth/register/`, formdata);
  return response;
};

const pushLeads = async (data) => {
  const response = await axios.post(`${baseUrl}/affiliates/pushleads/`, data);
  return response;
};

const loadUser = async (idToken) => {
  let config = {
    headers: {
      Authorization: "Bearer " + idToken,
    },
  };
  let output;
  await axios
    .get(`${baseUrl}/user/`, config)
    .then((res) => {
      output = res;
      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });
};

const loginAPI = async (payload) => {
  const {email, password} = payload;
  try {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    const response = await axios.post(`${baseUrl}/auth/login/`, formData);
    return response;
  } catch (err) {
    console.error("error", err);
    throw err;
  }
};

export {loadUser, signupRequest, loginAPI, pushLeads};
