import React, {useEffect, useState} from "react";
import Logo from "../../assets/icons/EEA-Logo.svg";
import {Link, useLocation} from "react-router-dom";
import {deAuthenticate} from "../../store/reducers/authSlice";
import {useDispatch, useSelector} from "react-redux";
import {clearPersistedData} from "../../store/configureStore";
import dashboardsvg from "../../assets/icons/dashboard.svg";
import accountmetrixsvg from "../../assets/icons/account-metrics.svg";
import paymentsvg from "../../assets/icons/payment.svg";
import fundingsvg from "../../assets/icons/funding-evaluation.svg";
import marketnewssvg from "../../assets/icons/market-news.svg";
import leaderboard from "../../assets/icons/leaderboard.svg";
import settingsvg from "../../assets/icons/settings.svg";
import supportsvg from "../../assets/icons/support.svg";
import SidebarData from "./Sidebar";
import SubMenu from "./SideBarMenu";

const MainSidebar = ({mobileMenu, setMobileMenu}) => {
  const accountListRedux = useSelector((state) => state?.account?.accountList?.data);
  const [accountCount, setAccountCount] = useState(accountListRedux);
  useEffect(() => {
    setAccountCount(accountListRedux?.length);
  }, [accountListRedux]);
  const [activeTab, setActiveTab] = useState("");
  const [subMenuActiveTab, setSubMenuActiveTab] = useState(null);
  const dispatch = useDispatch();
  // const accountList = useSelector((state) => state.account.accountList.data);
  const location_path = useLocation();

  var pathnameSegments = location_path.pathname.split("/");
  var desiredPart = pathnameSegments[1];
  const {plans} = useSelector((state) => state?.account);

  useEffect(() => {
    const currentLocation = "/" + desiredPart;
    console.log(currentLocation, activeTab, "currentLocation");
    switch (currentLocation) {
      case "/":
        setActiveTab("Dashboard");
        break;

      case "/account-metrics":
        setActiveTab("Account Metrics");
        break;

      case "/funding-evaluation-payment":
        setActiveTab("Payments");
        break;

      case "/evaluation":
        setActiveTab("Buy Funded Account");
        break;

      case `/funding-evaluation`:
        setActiveTab("Choose challenge");
        break;

      case "/trademate":
        setActiveTab("Market Analysis");
        break;
      case "/trademate/economic-calendar":
        setActiveTab("Market Analysis");
        break;
      case "/trademate/market-news":
        setActiveTab("Market Analysis");
        break;

      case "/affiliate":
        setActiveTab("Affiliate");
        break;
      case "/affiliate/get-a-link":
        setActiveTab("Affiliate");
        break;

      case "/request-payout":
        setActiveTab("Withdrawals");
        break;
      case "/request-payout/affiliate-payout":
        setActiveTab("Withdrawals");
        break;

      case "/myprofile":
        setActiveTab("Settings");
        break;

      case "/support-center":
        setActiveTab("Support");
        break;
      case "/verification-kyc":
        setActiveTab("KYC");
        break;
      case "/billing-details":
        setActiveTab("Billing Details");
        break;
      case "/download":
        setActiveTab("Download");
        break;

      case "/buy-funded":
        setActiveTab("BuyFundedAccount");
        break;
      case "/certificates":
        setActiveTab("Certificates");
        break;
      case "/Contract-list":
        setActiveTab("Contract");
        break;

      default:
      // Handle default case here if needed
    }
  }, [window.location.pathname]);

  useEffect(() => {
    const location = window.location.pathname;
    switch (location) {
      case "/account-metrics/program-objectives":
        setSubMenuActiveTab("Program Objectives");
        break;
      case "/account-metrics/trading-history":
        setSubMenuActiveTab("Trading History");
        break;
      case "/account-metrics/account-info":
        setSubMenuActiveTab("Account Info");
        break;

      case "/affiliate/overview":
        setSubMenuActiveTab("Overview");
        break;
      case "/affiliate/get-a-link":
        setSubMenuActiveTab("Get a link");
        break;

      case "/request-payout/payout-split":
        setSubMenuActiveTab("Trading account");
        break;
      case "/request-payout/affiliate-payout":
        setSubMenuActiveTab("Affiliate Payout");
        break;

      case "/trademate/trade-journal":
        setSubMenuActiveTab("Trade Journal");
        break;
      case "/trademate/economic-calendar":
        setSubMenuActiveTab("Economic Calendar");
        break;
      case "/trademate/market-news":
        setSubMenuActiveTab("Market News");
        break;

      case "/Contract-list":
        setActiveTab("Contract");
        break;

      default:
        const plansIndex = parseInt(location.split("/").pop()); // Extract the last part of the path
        if (!isNaN(plansIndex) && plans && plans.data && plans.data[plansIndex]) {
          setSubMenuActiveTab(plans.data[plansIndex].name);
        }
    }
  }, [window.location.pathname, plans]);

  const handleLogout = () => {
    dispatch(deAuthenticate());
    clearPersistedData();
  };

  const handleEmail = () => {
    const emailAddress = "support@equityedge.co.uk";
    const subject = "Subject of the email";
    const body = "Body of the email";
    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  const handleTabClick = (tab, tab2) => {
    setMobileMenu(false);
    setActiveTab(tab);
    setSubMenuActiveTab(tab2);
    if (tab === "Support") {
      handleEmail();
    }
  };
  // const handleMenuAabClick = (tab) => {
  //   setMobileMenu(false);
  //   setSubMenuActiveTab(tab);
  // };

  return (
    <>
      <div className={`MainSidebar `}>
        <div className="logo">
          <img
            src={Logo}
            alt=""
          />
          <svg
            width="259"
            height="2"
            viewBox="0 0 259 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              y1="0.830078"
              x2="259"
              y2="0.830078"
              stroke="#071E0D"
              stroke-opacity="0.2"
            />
          </svg>
        </div>
        <div className="main-menu">
          <div className="menu">
            {SidebarData.map((item, index) => {
              let data;
              if (item.title === "Settings") {
                return null;
              }
              if (item.title === "Account Metrics" && accountCount <= 0) {
                data = null;
              } else {
                data = (
                  <SubMenu
                    key={index}
                    item={item}
                    onClick={handleEmail}
                    handleTabClick={handleTabClick}
                    activeTab={activeTab}
                    subMenuActiveTab={subMenuActiveTab}
                    // handleMenuAabClick={handleMenuAabClick}
                  />
                );
              }
              return data;
            })}
          </div>
        </div>
      </div>

      {mobileMenu ? (
        <MobileSidebar
          setMobileMenu={setMobileMenu}
          activeTab={activeTab}
          handleLogout={handleLogout}
          handleTabClick={handleTabClick}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default MainSidebar;

const MobileSidebar = ({setMobileMenu, mobileMenu, activeTab, handleTabClick, handleLogout}) => {
  return (
    <div className={`MobileSidebar`}>
      <button
        className="close_btn"
        onClick={() => setMobileMenu(false)}
      >
        close
      </button>
      <div className="mobile_menu">
        <Link
          to="/"
          id="Dashboard"
          className={activeTab === "Dashboard" ? "active" : ""}
          onClick={() => handleTabClick("Dashboard")}
        >
          <img
            src={dashboardsvg}
            alt="dashboardsvg"
          />
          Dashboard
        </Link>
        <Link
          to="/account-metrics"
          id="AccountMetrics"
          className={activeTab === "AccountMetrics" ? "active" : ""}
          onClick={() => handleTabClick("AccountMetrics")}
        >
          <img
            src={accountmetrixsvg}
            alt="accountmetrixsvg"
          />
          Account Metrics
        </Link>
        <Link
          to="/funding-evaluation/payment-history/all"
          id="Payment"
          className={activeTab === "Payment" ? "active" : ""}
          onClick={() => handleTabClick("Payment")}
        >
          <img
            src={paymentsvg}
            alt="paymentsvg"
          />
          Payment
        </Link>
        <Link
          to="/funding-evaluation/plans"
          id="FundingEvaluation"
          className={activeTab === "FundingEvaluation" ? "active" : ""}
          onClick={() => handleTabClick("FundingEvaluation")}
        >
          <img
            src={fundingsvg}
            alt="fundingsvg"
          />
          Funding Evaluation
        </Link>

        {/* <Link
        to='/buy-funded'
        id="BuyFundedAccount"
        className={activeTab === 'BuyFundedAccount' ? "active" : ""}
        onClick={()=>handleTabClick("BuyFundedAccount")}
        >
        <img src={buyFundedIcon} alt="" />
        Buy  Funded Account
        </Link> */}

        <Link
          to="/trademate/*"
          id="MarketNews"
          className={activeTab === "MarketNews" ? "active" : ""}
          onClick={() => handleTabClick("MarketNews")}
        >
          <img
            src={marketnewssvg}
            alt="marketnews"
          />
          Trade Journal
        </Link>
        <Link
          to="/leaderboard-2"
          id="Leaderboard"
          className={activeTab === "Leaderboard" ? "active" : ""}
          onClick={() => handleTabClick("Leaderboard")}
        >
          <img
            src={leaderboard}
            alt="leaderboardsvg"
          />
          Leaderboard
        </Link>

        <Link
          to="/myprofile/general-information"
          id="Setting"
          className={activeTab === "Setting" ? "active" : ""}
          onClick={() => handleTabClick("Setting")}
        >
          <img
            src={settingsvg}
            alt="settingsvg"
          />
          Setting
        </Link>

        <Link
          to="/support-center"
          id="Support"
          className={activeTab === "Support" ? "active" : ""}
          onClick={() => handleTabClick("Support")}
        >
          <img
            src={supportsvg}
            alt="supportsvg"
          />
          Support
        </Link>

        <a
          onClick={handleLogout}
          href="/signin"
          id="Logout"
          className={activeTab === "Logout" ? "active" : ""}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M7.2 13.2H16.8C17.14 13.2 17.425 13.085 17.655 12.855C17.885 12.625 18 12.34 18 12C18 11.66 17.885 11.375 17.655 11.145C17.425 10.915 17.14 10.8 16.8 10.8H7.2C6.86 10.8 6.575 10.915 6.345 11.145C6.115 11.375 6 11.66 6 12C6 12.34 6.115 12.625 6.345 12.855C6.575 13.085 6.86 13.2 7.2 13.2ZM12 24C10.34 24 8.78 23.685 7.32 23.055C5.86 22.425 4.59 21.57 3.51 20.49C2.43 19.41 1.575 18.14 0.945 16.68C0.315 15.22 0 13.66 0 12C0 10.34 0.315 8.78 0.945 7.32C1.575 5.86 2.43 4.59 3.51 3.51C4.59 2.43 5.86 1.575 7.32 0.945C8.78 0.315 10.34 0 12 0C13.66 0 15.22 0.315 16.68 0.945C18.14 1.575 19.41 2.43 20.49 3.51C21.57 4.59 22.425 5.86 23.055 7.32C23.685 8.78 24 10.34 24 12C24 13.66 23.685 15.22 23.055 16.68C22.425 18.14 21.57 19.41 20.49 20.49C19.41 21.57 18.14 22.425 16.68 23.055C15.22 23.685 13.66 24 12 24ZM12 21.6C14.68 21.6 16.95 20.67 18.81 18.81C20.67 16.95 21.6 14.68 21.6 12C21.6 9.32 20.67 7.05 18.81 5.19C16.95 3.33 14.68 2.4 12 2.4C9.32 2.4 7.05 3.33 5.19 5.19C3.33 7.05 2.4 9.32 2.4 12C2.4 14.68 3.33 16.95 5.19 18.81C7.05 20.67 9.32 21.6 12 21.6Z"
              fill="white"
            />
          </svg>
          Quit
        </a>
      </div>
    </div>
  );
};
