import React, {useEffect} from "react";
import "./style.scss";
import AccountDetails from "./AccountDetails";
import AccountPassword from "./AccountPassword";
import AccountSettings from "./AccountSettings";
import {useSelector} from "react-redux/es/hooks/useSelector";
import {useState} from "react";
import {getUser} from "../../../store/reducers/userSlice";
import {useDispatch} from "react-redux";
import axios from "axios";
import {returnErrors} from "../../../store/reducers/error";
import {returnMessages} from "../../../store/reducers/message";
import Spinner from "../../../reusableComponents/Spinner";
import Edit from "../../../assets/icons/edit-pencil.svg";
import {baseUrl} from "../../../utils/apis";
function GeneralInformation() {
  const userDetail = useSelector((state) => state.user);
  const [userDetails, setUserDetails] = useState(userDetail);
  const [disabledInputs, setDisabledInputs] = useState(true);
  const idToken = useSelector((state) => state.auth.idToken);
  const [editMode, setEditMode] = useState(true);
  const [isSpinner, setIsSpinner] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setUserDetails(userDetail);
  }, [userDetail, editMode]);
  useEffect(() => {
    dispatch(getUser(idToken));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [changePassword, setChangePassword] = useState({
    current_password: "",
    new_password: "",
  });
  const match = changePassword.new_password === confirmNewPassword && changePassword.current_password !== "" && changePassword.new_password !== "";
  const handleChangePassword = async () => {
    setIsSpinner(true);
    if (changePassword.new_password === confirmNewPassword && changePassword.current_password !== "" && changePassword.new_password !== "") {
      try {
        let config = {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        };
        const res = await axios.post(`${baseUrl}/auth/settings/change-password/`, changePassword, config);

        if (res?.response?.data?.detail) {
          const msg = res.response.data.detail;
          dispatch(returnErrors(msg, 400));
          setIsSpinner(false);
        } else if (res?.data?.detail) {
          const msg = res.data.detail;
          dispatch(returnMessages(msg, 200));
          setChangePassword({
            current_password: "",
            new_password: "",
          });
          setConfirmNewPassword("");
          setIsSpinner(false);
        } else {
          const msg = "Error";
          dispatch(returnErrors(msg, 500));
          setIsSpinner(false);
        }
        setDisabledInputs(true);
      } catch (error) {
        alert(error);
        setIsSpinner(false);
      }
    } else {
      dispatch(returnErrors("Didn't match new password", 400));
      setIsSpinner(false);
    }
  };

  return (
    <div className="generalinfo">
      <div className="general-info-wrapper">
        <div className="generalinfo_top">
          <div className="generalinfo_top_wrapper">
            <AccountDetails
              userDetails={userDetails}
              editModes={editMode}
              setEditMode={setEditMode}
              disabledInputs={editMode}
              match={match}
              setChangePassword={setChangePassword}
              changePassword={changePassword}
              confirmNewPassword={confirmNewPassword}
              setConfirmNewPassword={setConfirmNewPassword}
              handleChangePassword={handleChangePassword}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralInformation;
