import React, {useEffect, useState} from "react";
import "./style.scss";

import Tabination from "../../../reusableComponents/Tabination";

import PlansLayout from "../../../layouts/Plans/index";
import {useDispatch, useSelector} from "react-redux";
import {getPlans} from "../../../store/reducers/accountSlice";
import MainHeader from "../../../layouts/pageLayout/MainHeader";
import {useLocation, useParams} from "react-router-dom";

function Plans() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const {idToken} = useSelector((state) => state.auth);
  const {plans} = useSelector((state) => state.account);
  const activeTabParams = useParams();
  // 
  useEffect(() => {
    dispatch(getPlans());
  }, [idToken]);
  // useEffect(() => {
  //   setPlans(plansValue);
  // }, [plansValue]);
  useEffect(() => {
    setActiveTab(Number(activeTabParams?.id));
  }, [activeTabParams?.id]);

  const tabItems = [];
  const tabHeaders = [];

  plans?.data.forEach((item) => tabHeaders.push(item.name));
  // const data = plans?.data?.filter((plan) => plan.name === tabHeaders[1]);

  plans?.data?.map((item, index) =>
    tabItems.push({
      name: item.name,
      content: <div key={index}>{/* <PlansLayout plans={plans?.data?.filter((plan) => plan.name === tabItems[index])} /> */}</div>,
    }),
  );

  

  const location = useLocation();
  const path = location.pathname.split("/")[2];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="mainPageHolder-T-C">
        <div className="mainPageHolder-T">
          <MainHeader
            headerName="Funding Evaluation"
            showTabs={false}
            tabItems={tabItems}
            activeTab={activeTab}
            // setActiveTab={setActiveTab}
          />
        </div>
      </div>
      <div className="mainPageHolder-B">
        <div>
          <div className="all_header">
            <h2>Funding Evaluation</h2>
          </div>
          <Tabination
            variant={3}
            tabItems={tabItems}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            style={{margin: "0 14px"}}
            className="tabination_all"
          />
          <PlansLayout plans={plans?.data?.filter((item, index) => item?.name === tabHeaders[activeTab])} />
        </div>
      </div>
    </div>
  );
}

export default Plans;

/* <div className="mainPageHolder">
  <div className="mainPageHolder-T-C">
    <div className="mainPageHolder-T">
      <MainHeader headerName="Dashboard" />
    </div>
  </div>
  <div className="mainPageHolder-B">
  
  //THE COMPONENT CODE SHOULD BE HERE (OLD CODE OF THE PAGE)
  
  </div>
</div> */
