import React from "react";
import "./style.scss";
import NewsImg from "../../../assets/images/candlesticks-chart-img.png";
import BtcImg from "../../../assets/images/btc.png";
import ArrowIcon from "../../../assets/icons/arrow-right-blue.svg";
import Profile from "../../../assets/icons/profile.svg";

function MarketNews() {
  return (
    <div className="marketnews">
      <div className="marketnews_container">
        <div className="marketnews_author">
          <img
            src={Profile}
            alt=""
          />
          <div>
            <p>Shib</p>
            <p>@Shibtoken . 2 Sep 2022, 3:39 Am</p>
          </div>
        </div>
        <div className="marketnews_headline">
          <img
            src={NewsImg}
            alt="news"
          />
          <div className="marketnews_content">
            <p>
              Crude Oil Price Forecast: Could Oil Hit $100 after Marking 7 Year High?..........<span>Read More</span>
            </p>
            <div className="marketnews_category">
              <div>
                <img
                  src={BtcImg}
                  alt="btc"
                />{" "}
                <p>Btc</p>{" "}
              </div>
              <div>
                <img
                  src={BtcImg}
                  alt="btc"
                />{" "}
                <p>Btc</p>{" "}
              </div>
              <div>
                <img
                  src={BtcImg}
                  alt="btc"
                />{" "}
                <p>Btc</p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="marketnews_container-2">
        <div className="marketnews_author">
          <img
            src={Profile}
            alt=""
          />
          <div>
            <p>Shib</p>
            <p>@Shibtoken . 2 Sep 2022, 3:39 Am</p>
          </div>
        </div>
        <div className="marketnews_headline">
          <div className="marketnews_content">
            <p>Crude Oil Price Forecast: Could Oil Hit $100 after Marking 7 Year High?</p>
            <div className="marketnews_desc">
              <p>
                Crude Oil Price Forecast: Could Oil Hit $100 after Marking 7 Year High? and Loreum Ipsum.....<span>Read More</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <p className="show_all">
        Show All{" "}
        <img
          src={ArrowIcon}
          alt=""
        />
      </p>
    </div>
  );
}

export default MarketNews;
