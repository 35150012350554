import React, {useEffect, useState} from "react";
import "./style.scss";
import stripeIcon from "../../../assets/icons/stripeIcon.svg";
import InvoiceDetails from "../../../components/FundingEvaluation/PaymentModule/InvoiceDetails";
import paypal from "../../../assets/images/paypal.png";
import rapyd from "../../../assets/images/rapyd.png";
import nace from "../../../assets/images/nace.jpg";
import {useDispatch, useSelector} from "react-redux";
import Summary from "../../../components/FundingEvaluation/PaymentModule/Summary";
import customStyles from "../../../customstyle/customstyle";
import Select from "react-select";
import EmailIcon from "../../../assets/icons/email.svg";
import {validateEmail} from "../../../utils/helpers/string";
import CheckSolidGreenIcon from "../../../assets/icons/check-solid-green.svg";
import {setSelectedChallenge} from "../../../store/reducers/accountSlice";
import {useParams} from "react-router-dom";
import axios from "axios";
import {baseUrl, getUserDetails} from "../../../utils/apis";
import {returnMessages} from "../../../store/reducers/message";
import {setUser} from "../../../store/reducers/userSlice";
import {returnErrors} from "../../../store/reducers/error";
import ETH from "../../../assets/images/ETH_ICON.png";
import USDT from "../../../assets/images/USDT_ICON.png";
import COIN from "../../../assets/icons/coin_icon.svg";
import fibonatix from "../../../assets/icons/fibonatix_logo.jpg";
import squareLogo from "../../../assets/icons/squareLogo.png";
import {countryStates} from "../../../utils/constants/countriesAndStates";

const transformCountryData = (data) => {
  return data.map((country) => ({
    label: country.name,
    value: country.code2,
    states: country.states,
  }));
};
const transformStateData = (states) => {
  return states.map((state) => ({
    label: state.name,
    value: state.code,
  }));
};

const BuyFundedAc = ({publicCheckout}) => {
  const {id} = useParams();
  const plans = useSelector((state) => state.account.plans?.data);
  const selectedChallenge = useSelector((state) => state.account.selectedChallenge);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [addressDetails, setAddressDetails] = useState({
    zipCode: "",
    city: "",
    phone: "",
  });

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setSelectedState(null); // Reset the state when a new country is selected
  };

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
  };

  const countryOptions = transformCountryData(countryStates);
  const stateOptions = selectedCountry ? transformStateData(selectedCountry.states) : [];
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedChallenge !== id) {
      dispatch(setSelectedChallenge(Number(id)));
    }
  }, [plans, id]);

  const [rapydCountry, setRapydCountry] = useState();
  const [selectRapydCountry, setSelectRapydCountry] = useState(null);

  useEffect(() => {
    const getRapydCountry = async () => {
      try {
        const response = await axios.get(`https://api-uat-live.equityedge.co.uk/payment/api/rapyd-countries`);
        const countryOptions = response.data.data.map((country) => ({label: country.name, value: country.iso_alpha2}));
        setRapydCountry(countryOptions);
      } catch (error) {
        console.error("Error fetching Rapyd countries:", error);
      }
    };

    getRapydCountry();
  }, []);

  console.log(rapydCountry, "rapydCountry");
  const options1 = [
    // {
    //   active: "stripe",
    //   value: "stripe",
    //   label: (
    //     <div
    //       style={{
    //         display: "flex",
    //         width: "23px",
    //         alignItems: "center",
    //         gap: "20px",
    //       }}
    //     >
    //       {/* <img style={{ width: "100%" }} src={stripeIcon} loading="lazy" alt="" /> */}
    //       <img style={{ width: "100%" }} src={paypal} alt="" />
    //       <span style={{ fontSize: "16px", textWrap: "nowrap"}}>Pay with card</span>
    //     </div>
    //   ),
    // },

    // {
    //   active: "paypal",
    //   value: "paypal",
    //   label: (
    //     <div
    //       style={{
    //         display: "flex",
    //         width: "20px",
    //         borderRadius: "3px",
    //         padding: "2px",
    //         alignItems: "center",
    //         gap: "20px",
    //         widows: "100%",
    //       }}
    //     >
    //       <img  src={paypal} alt="" />
    //       <div style={{ fontSize: "16px" , textWrap: "nowrap"}}>Pay with card</div>
    //     </div>
    //   ),
    // },
    {
      active: "fibonatix",
      value: "fibonatix",
      label: (
        <div
          style={{
            display: "flex",
            width: "20px",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <img
            style={{width: "100%"}}
            src={paypal}
            alt=""
          />
          <span style={{fontSize: "16px", textWrap: "nowrap"}}>Pay with card</span>
        </div>
      ),
    },
    {
      active: "coinpayments",
      value: "coinpayments",
      label: (
        <div
          style={{
            display: "flex",
            width: "20px",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <img
            style={{width: "100%"}}
            src={COIN}
            alt=""
          />
          <span style={{fontSize: "16px"}}>Coin</span>
        </div>
      ),
    },
    {
      active: "rapyd",
      value: "rapyd",
      label: (
        <div
          style={{
            display: "flex",
            width: "20px",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <img
            style={{width: "100%"}}
            src={rapyd}
            alt=""
          />
          <span style={{fontSize: "16px", textWrap: "nowrap"}}>Rapyd</span>
        </div>
      ),
    },

    // {
    //   active: "nace",
    //   value: "nace",
    //   label: (
    //     <div style={{display: "flex", width: "20px", borderRadius: "3px", padding: "2px", backgroundColor: "black", alignItems: "center", gap: "20px"}}>
    //       <img
    //         style={{width: "100%"}}
    //         src={nace}
    //         alt=""
    //       />
    //       <span style={{fontSize: "16px"}}>Nace</span>
    //     </div>
    //   ),
    // },
  ];

  const paymentModeOptions = [
    // {
    //   value: "ETH",
    //   label: (
    //     <div style={{ display: "flex", width: "20px", alignItems: "center", gap: "20px" }}>
    //       <img
    //         style={{ width: "100%" }}
    //         src={ETH}
    //         alt=""
    //       />
    //       <span style={{ fontSize: "16px",  }}>ETH</span>
    //     </div>
    //   ),
    // },

    // {
    //   value: "USDC",
    //   label: (
    //     <div style={{ display: "flex", width: "20px", alignItems: "center", gap: "20px" }}>
    //       <img
    //         style={{ width: "100%" }}
    //         src='https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=029'
    //         alt=""
    //       />
    //       <span style={{ fontSize: "16px", }}>USDC</span>
    //     </div>
    //   ),
    // },
    {
      value: "TRX",
      label: (
        <div
          style={{
            display: "flex",
            width: "20px",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <img
            style={{width: "100%"}}
            src="https://cryptologos.cc/logos/tron-trx-logo.png?v=029"
            alt=""
          />
          <span style={{fontSize: "16px", display: "flex"}}>TRX</span>
        </div>
      ),
    },
    {
      value: "USDT.TRC20",
      label: (
        <div
          style={{
            display: "flex",
            width: "maxContent",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <img
            style={{width: "20px", height: "20px"}}
            src={USDT}
            alt=""
          />
          <span style={{fontSize: "16px"}}>USDT TRC20</span>
        </div>
      ),
    },
  ];
  const [paymentMethod, setPaymentMethod] = useState(options1[0]);
  console.log(paymentMethod.value, "method");
  const [paymentMode, setPaymentMode] = useState(paymentModeOptions[0]);

  const [selectedAddress, setSelectedAddress] = useState(null);
  const user = useSelector((state) => state?.user);

  const handlePaymentMethodChange = (selectedOption) => {
    setPaymentMethod(selectedOption);
  };
  const handlePaymentModChange = (selectedOption) => {
    setPaymentMode(selectedOption);
  };
  const tradingPlatform = [
    {value: "matchtrade", label: "Match Trader"},
    // {value: "tradelocker", label: "Trade Locker"},
  ];
  const [broker, setBroker] = useState("matchtrade");
  let planOptions = plans?.map((plan) => ({
    label: plan.name,
    value: plan.name,
    id: plan.id,
  }));

  const [accBalanceOptions, setAccBalanceOptions] = useState([]);
  const [currentChallenge, setCurrentChallenge] = useState(null);

  const [accBalance, setAccBalance] = useState("");
  const [selectedValue, setSelectedValue] = useState(planOptions.find((plan) => plan.id === currentChallenge?.id) || planOptions[0]);

  const paymentAsPerPlan = [
    {
      amount: 2500,
      step: "1 Step",
      link: "https://square.equityedge.co.uk/product/2-5k-challenge-1-step/5?cs=true&cst=custom",
    },
    {
      amount: 2500,
      step: "2 Step",
      link: "https://square.equityedge.co.uk/product/2-5k-challenge-2-step/7?cs=true&cst=custom",
    },

    {
      amount: 5000,
      step: "1 Step",
      link: "https://square.equityedge.co.uk/product/5k-challenge-1-step/1?cs=true&cst=custom",
    },
    {
      amount: 5000,
      step: "2 Step",
      link: "https://square.equityedge.co.uk/product/5k-challenge-2-step/8?cs=true&cst=custom",
    },

    {
      amount: 10000,
      step: "1 Step",
      link: "https://square.equityedge.co.uk/product/10k-challenge-1-step/2?cs=true&cst=custom",
    },
    {
      amount: 10000,
      step: "2 Step",
      link: "https://square.equityedge.co.uk/product/10k-challenge-2-step/9?cs=true&cst=custom",
    },

    {
      amount: 25000,
      step: "1 Step",
      link: "https://square.equityedge.co.uk/product/25k-challenge-1-step/4?cs=true&cst=custom",
    },
    {
      amount: 25000,
      step: "2 Step",
      link: "https://square.equityedge.co.uk/product/25k-challenge-2-step/10?cs=true&cst=custom",
    },

    {
      amount: 50000,
      step: "1 Step",
      link: "https://square.equityedge.co.uk/product/50k-challenge-1-step/15?cp=true&sa=false&sbp=false&q=false&category_id=AF4YMRW7MET2WSF3GVKCKOHU",
    },
    {
      amount: 50000,
      step: "2 Step",
      link: "https://square.equityedge.co.uk/product/50k-challenge-2-step/11?cs=true&cst=custom",
    },
    {
      amount: 100000,
      step: "1 Step",
      link: "https://square.equityedge.co.uk/product/100k-challenge-1-step/16?cp=true&sa=true&sbp=false&q=false",
    },
    {
      amount: 100000,
      step: "2 Step",
      link: "https://square.equityedge.co.uk/product/100k-challenge-2-step/16?cp=true&sa=true&sbp=false&q=false",
    },
  ];

  const handlePlanChange = (id) => {
    const selectedPlanId = id;

    const selectedPlan = plans?.find((plan) => plan.id === selectedPlanId);
    setSelectedValue(planOptions.find((plan) => plan.id === selectedPlanId));

    const selectedPlanChallenges = selectedPlan ? selectedPlan.challenges : [];

    setAccBalanceOptions(
      selectedPlanChallenges.map((item) => ({
        label: item?.starting_balance,
        value: item?.starting_balance,
        id: item?.id,
      })),
      setAccBalance({
        label: selectedPlanChallenges[0]?.starting_balance,
        value: selectedPlanChallenges[0]?.starting_balance,
        id: selectedPlanChallenges[0]?.id,
      }),
    );
    setCurrentChallenge(selectedPlanChallenges[0]);
  };
  useEffect(() => {
    const challenges = plans?.flatMap((plan) => plan.challenges);
    const challenge = challenges.find((item) => item.id === selectedChallenge);

    window.scrollTo(0, 0);

    setCurrentChallenge(challenge);

    const defaultSelectedPlan = challenge ? challenge.plan : "";

    const selectedPlan = plans?.find((plan) => plan.id === defaultSelectedPlan);

    setSelectedValue(planOptions.find((plan) => plan.id === defaultSelectedPlan));
    const defaultAccBalance = challenge ? challenge.starting_balance : "";
    setAccBalance(
      {
        label: defaultAccBalance,
        value: defaultAccBalance,
        id: challenge?.id,
      } || "",
    );

    const selectedPlanChallenges = selectedPlan ? selectedPlan.challenges : [];

    setAccBalanceOptions(
      selectedPlanChallenges.map((item) => ({
        label: item?.starting_balance,
        value: item?.starting_balance,
        id: item?.id,
      })),
    );
  }, [selectedChallenge, plans]);
  const handleAccBalanceChange = (e) => {
    setAccBalance(e.value);
    const selectedAccBalance = e.value;

    const matchingChallenge = plans?.flatMap((plan) => plan.challenges).find((challenge) => challenge.starting_balance === selectedAccBalance);

    if (matchingChallenge) {
      setCurrentChallenge(matchingChallenge);

      setAccBalance({
        label: selectedAccBalance,
        value: selectedAccBalance,
        id: e.id,
      });
    } else {
      console.error("No matching challenge found for the selected account balance");
    }
  };

  // for public
  const [email, setEmail] = useState(null);
  const userDetails = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    first_name: userDetails?.first_name || "",
    last_name: userDetails?.last_name || "",
    email: userDetails?.email || "",
    country: userDetails?.country || "",
  });

  const idToken = useSelector((state) => state.auth.idToken);

  const handleSave = async () => {
    let config = {
      headers: {Authorization: `Bearer ${idToken}`},
    };
    const respo = await axios
      .patch(`${baseUrl}/user/profile/`, formData, config)
      .then(async (res) => {
        // const msg = "Updated Successfully";
        // const status = res.status;
        // dispatch(returnMessages(msg, status));
        const profileData = await getUserDetails(idToken);
        dispatch(setUser(profileData));
        return profileData;
      })
      .catch((error) => {
        const msg = "error";
        const status = 500;
        dispatch(returnErrors(msg, status));
      });
    return respo;
  };

  const sequrePaymentLink = paymentAsPerPlan
    .map((item, index) => {
      return item.step === selectedValue?.value && item.amount === accBalance?.value ? item?.link : null;
    })
    .join("");
  const [isRefundPolicy, setIsRefundPolicy] = useState(false);
  const [open, setOpen] = useState(false);

  const handleConfirmClose = () => {
    setOpen(false);
    setBroker("matchtrade");
    setIsRefundPolicy(false);
  };

  const handleContinue = () => {
    if (isRefundPolicy) {
      setOpen(false);
      setBroker("tradelocker");
      setIsRefundPolicy(false);
    }
  };
  return (
    <div className="buy_funded_container">
      <div className="step_1">
        <div className="heading">
          <h1>Step 1</h1>
        </div>
        <div className="fll_details">
          <div className="payment_method">
            <span>Payment Method</span>
            <div>
              <Select
                isSearchable={false}
                value={paymentMethod}
                options={options1}
                styles={customStyles}
                className="react-select-container"
                onChange={(selectedOption) => handlePaymentMethodChange(selectedOption)}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          </div>

          {paymentMethod.value === "coinpayments" && (
            <div className="payment_method">
              <span>Coin Payments</span>
              <div>
                <Select
                  isSearchable={false}
                  value={paymentMode}
                  options={paymentModeOptions}
                  styles={customStyles}
                  className="react-select-container"
                  onChange={(selectedOption) => handlePaymentModChange(selectedOption)}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          )}

          {(paymentMethod.value === "fibonatix" || paymentMethod.value === "rapyd") && (
            <div className="payment_method">
              <span>Country</span>
              <div>
                <Select
                  // isSearchable={false}
                  options={paymentMethod.value === "rapyd" ? rapydCountry : countryOptions}
                  value={paymentMethod.value === "rapyd" ? selectRapydCountry : selectedCountry}
                  styles={customStyles}
                  className="react-select-container"
                  onChange={(selectedOption) => {
                    paymentMethod.value === "rapyd" ? setSelectRapydCountry(selectedOption) : handleCountryChange();
                  }}
                />
              </div>
            </div>
          )}

          {paymentMethod.value === "fibonatix" && selectedCountry?.states?.length > 0 && (
            <div
              style={{position: "relative", top: "4px"}}
              className="payment_method"
            >
              <span>State</span>
              <div>
                <Select
                  // isSearchable={false}
                  options={stateOptions}
                  value={selectedState}
                  styles={customStyles}
                  className="react-select-container"
                  onChange={handleStateChange}
                />
              </div>
            </div>
          )}

          {paymentMethod.value === "fibonatix" && (
            <>
              <div className="account_input new">
                <label htmlFor="firstName">Zip Code</label>
                <br />
                <div className={`account_input_wrapper `}>
                  <input
                    className="account_details_input"
                    type="text"
                    value={addressDetails.zipCode}
                    placeholder="Zip Code"
                    required
                    onChange={(e) => setAddressDetails({...addressDetails, zipCode: e.target.value})}
                  />
                </div>
              </div>
              <div className="account_input new">
                <label
                  htmlFor="firstName"
                  //  className="label_black"
                >
                  City
                </label>

                <br />
                <div className={`account_input_wrapper `}>
                  <input
                    className="account_details_input"
                    type="text"
                    value={addressDetails.city}
                    placeholder="City"
                    onChange={(e) => setAddressDetails({...addressDetails, city: e.target.value})}
                  />
                </div>
              </div>

              <div className="account_input new">
                <label htmlFor="firstName">Phone</label>

                <br />
                <div className={`account_input_wrapper `}>
                  <input
                    className="account_details_input"
                    type="text"
                    value={addressDetails.phone}
                    placeholder="Phone"
                    onChange={(e) => setAddressDetails({...addressDetails, phone: e.target.value})}
                    required

                    // disabled={editMode}
                  />
                </div>
              </div>
            </>
          )}

          <div className="challange_type">
            <span>Challenge type</span>
            <div>
              <Select
                isSearchable={false}
                value={selectedValue}
                options={publicCheckout ? planOptions.filter((plan) => plan.value !== "Free Trial") : planOptions}
                styles={customStyles}
                className="react-select-container"
                onChange={(selectedOption) => {
                  handlePlanChange(selectedOption.id);
                  console.log(selectedOption, "option");
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          </div>
          <div className="challange_amount">
            <span>Choose Challenge Amount</span>
            <div>
              <Select
                isSearchable={false}
                value={accBalance}
                options={accBalanceOptions}
                styles={customStyles}
                className="react-select-container"
                onChange={handleAccBalanceChange}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          </div>
          <div className="choose_trading_platform">
            <span>Choose your trading platform</span>
            <div>
              <Select
                isSearchable={false}
                value={tradingPlatform.find((item) => item.value === broker)}
                options={tradingPlatform}
                styles={customStyles}
                className="react-select-container"
                onChange={(selectedOption) => {
                  if (selectedOption.value === "tradelocker") {
                    setOpen(true);
                  } else {
                    setBroker(selectedOption.value);
                    setOpen(false);
                  }
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          </div>
        </div>
        <div className="invoice_details">
          {publicCheckout ? (
            <div className="addressSelecotorCon">
              <div className="userDetails">
                <div>
                  <p>Your Email</p>
                </div>
                <label className="general_info_form_input_container">
                  {/* <img
                    src={EmailIcon}
                    alt=""
                    className="input_icon"
                  /> */}
                  <div className=" general_info_input">
                    <label
                      className="general_info_label"
                      htmlFor="email"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      autocomplete="off"
                      placeholder="Email Address"
                      value={formData?.email}
                      onChange={(e) => setFormData({...formData, email: e.target.value})}
                      style={{width: "100% !important"}}
                      // onBlur={handleBlur}
                      // onFocus={handleFocus}
                    />
                  </div>
                  <div className="signinform-container_icon">
                    {validateEmail(email) && (
                      <img
                        src={CheckSolidGreenIcon}
                        alt=""
                        className="signinform-icon"
                      />
                    )}
                  </div>
                </label>
              </div>
              <div className="userDetails">
                <div>
                  <p>First Name</p>
                </div>
                <label className="general_info_form_input_container">
                  {/* <img
                    src={EmailIcon}
                    alt=""
                    className="input_icon"
                  /> */}
                  <div className=" general_info_input">
                    <label
                      className="general_info_label"
                      htmlFor="email"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      id="first_name"
                      autocomplete="off"
                      placeholder="First Name"
                      value={formData?.first_name || ""}
                      onChange={(e) => setFormData({...formData, first_name: e.target.value})}
                      style={{width: "100% !important"}}
                      // onBlur={handleBlur}
                      // onFocus={handleFocus}
                    />
                  </div>
                  {/* <div className="signinform-container_icon">
                    {validateEmail(email) && (
                      <img
                        src={CheckSolidGreenIcon}
                        alt=""
                        className="signinform-icon"
                      />
                    )}
                  </div> */}
                </label>
              </div>
              <div className="userDetails">
                <div>
                  <p>Last Name</p>
                </div>
                <label className="general_info_form_input_container">
                  {/* <img
                    src={EmailIcon}
                    alt=""
                    className="input_icon"
                  /> */}
                  <div className=" general_info_input">
                    <label
                      className="general_info_label"
                      htmlFor="email"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      id="last_name"
                      autocomplete="off"
                      placeholder="Last Name"
                      value={formData?.last_name || ""}
                      onChange={(e) => setFormData({...formData, last_name: e.target.value})}
                      style={{width: "100% !important"}}
                      // onBlur={handleBlur}
                      // onFocus={handleFocus}
                    />
                  </div>
                  {/* <div className="signinform-container_icon">
                    {validateEmail(email) && (
                      <img
                        src={CheckSolidGreenIcon}
                        alt=""
                        className="signinform-icon"
                      />
                    )}
                  </div> */}
                </label>
              </div>
            </div>
          ) : (
            <InvoiceDetails
              setFormData={setFormData}
              formData={formData}
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
            />
          )}
        </div>
      </div>

      <div className="step_2">
        <Summary
          user={user}
          email={email}
          formData={formData}
          publicCheckout={publicCheckout}
          paymentMethod={paymentMethod}
          selectedCountry={selectedCountry}
          selectedState={selectedState ? selectedState : null}
          addressDetails={addressDetails}
          selectedValues={selectedValue}
          accBalances={accBalance}
          selectedAddress={selectedAddress}
          handleSave={handleSave}
          paymentMode={paymentMode}
          sequrePaymentLink={sequrePaymentLink}
          setEmail={setEmail}
          broker={broker}
          selectRapydCountry={selectRapydCountry}
        />
      </div>
      {open && broker !== "tradelocker" && (
        <>
          <div
            className="confirm_overlay"
            onClick={(e) => e.stopPropagation()} // Prevent overlay from closing unintentionally
          >
            <div className="confirm_container">
              <div className="confirm_container_top">
                <p className="confirmation_message">Please confirm you understand that Equity Edge will not be releasing Trade locker credentials, this is a prelaunch offering prior to our merger.</p>
                <div className="term">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={isRefundPolicy}
                    onChange={() => setIsRefundPolicy((prev) => !prev)}
                  />
                  <span>I understand this is a presale, and I wish to continue</span>
                </div>
              </div>
              <div className="confirm_container_bottom">
                <button
                  className="confirm_close_btn"
                  onClick={handleConfirmClose}
                >
                  Cancel
                </button>
                <button
                  className="confirm_close_btn"
                  onClick={handleContinue}
                  style={{backgroundColor: isRefundPolicy ? "#34d068" : "#2e3035", color: !isRefundPolicy ? "#FFF" : "#000"}}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BuyFundedAc;
