import React, {useEffect, useState} from "react";
import "./style.scss";
import {Link} from "react-router-dom";
import ShowIcon from "../../../../assets/icons/arrow-down-blue.svg";
import Card from "../../../../reusableComponents/Card";
import {capitalizeFirstLetter, convertToMillions} from "../../../../utils/helpers/string";
import {setSelectedChallenge} from "../../../../store/reducers/accountSlice";
import {useDispatch} from "react-redux";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

function Tier({tiers, activeTier, setActiveTier, setFooterValue}) {
  console.log(tiers, "tires");
  const challenges = tiers?.challenges;
  const sortedNumbers = [...challenges];
  const tiersNew = sortedNumbers?.sort((a, b) => b?.starting_balance + a?.starting_balance);
  const dispatch = useDispatch();
  const [showFeatures, setShowFeatures] = useState(false);

  const handleActive = (e) => {
    setActiveTier(e);
  };

  const handleShowFeatures = () => {
    setShowFeatures(!showFeatures);
  };
  const handleMouseOver = (e) => {
    setFooterValue(e);
  };
  const handleMouseOut = (e) => {
    // setFooterValue([]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tiers]);

  return (
    <>
      {tiers?.challenges?.length > 3 ? (
        <Splide
          aria-label="My Favorite Images"
          options={{
            // type: "Loop",
            autoplay: true,
            perPage: 3,
            perMove: 1,
            width: "100%",
            drag: "free",
            gap: "10px",
            pauseOnHover: false,
            breakpoints: {
              1550: {perPage: 3, gap: "1rem"},
              1350: {perPage: 2, gap: "1rem"},
              970: {perPage: 1, gap: "1rem"},
            },
          }}
        >
          {tiersNew?.map((newTiers) => {
            return (
              <SplideSlide>
                <div
                  className="tier_wrapper"
                  onMouseOver={() => handleMouseOver(newTiers)}
                  onMouseOut={() => handleMouseOut(newTiers)}
                >
                  <div className="tier_upper">
                    <div className="tier-type">
                      <h4>{newTiers?.name}</h4>
                      {/* <p>
          {tiers?.name === "essential"
            ? "Casual part-time investor"
            : tiers?.name === "pro"
            ? "Serious part-time investor"
            : "Serious full-time investor"}
        </p> */}
                    </div>
                    <div className="tier-price">
                      <h3>${newTiers?.starting_balance / 1000}k</h3>
                      <span>Challenge</span>
                    </div>
                  </div>
                  <Card
                    className={`tier-container ${tiers?.id === activeTier ? "tier-highlight" : ""} ${showFeatures ? "show_features" : ""}`}
                    onClick={() => handleActive(tiers.id)}
                  >
                    <div className="tier-challenge">
                      <div className="tier_blank"></div>
                      {/* <div className="tier_line"></div> */}
                    </div>
                    <div className="tier-num">
                      {/* <div className="tier_val">
                        <p className="heading">Inactivity Days</p>
                        <p>{newTiers?.inactivity_days ?? "0"} days</p>
                      </div> */}

                      {/* <div className="tier_val">
          <p className="heading">Initial Withdrawal Delay</p>
          <p
            className={
              newTiers?.name === activeTier ? "" : "tier-details-highlight"
            }
          >
            {newTiers?.initial_withdrawal_delay}
          </p>
        </div> */}
                      {/* <div className="tier_val">
                        <p className="heading">Initial Withdrawal Delay</p>
                        <p>{newTiers?.initial_withdrawal_delay ?? "-"}</p>
                      </div> */}
                      {/* <div className="tier_val">
                        <p className="heading">Subsequent Withdrawal Delay</p>
                        <p>{newTiers?.subsequent_withrawal_delay ?? "-" ?? "-"}</p>
                      </div> */}
                      {/* <div className="tier_val">
                        <p className="heading">Leverage</p>
                        <p>1: {newTiers?.leverage ?? "-"}</p>
                      </div> */}
                      <div className="tier_val">
                        <p className="heading">Leverage(FX) </p>
                        <p className="leverageBox">
                          <span>1 : 50 (FX)</span>
                          <span>1 : 20 (Metals)</span>
                        </p>
                      </div>
                      <div className="tier_val">
                        <p className="heading">Leverage (Crypto) </p>
                        <p>1 : 2</p>
                      </div>
                      <div className="tier_val">
                        <p className="heading">Max Daily Loss</p>
                        <p>{parseFloat(newTiers?.max_daily_loss).toFixed(0) ?? "-"}%</p>
                      </div>
                      {/* <div className="tier_val">
                        <p className="heading">Max Days</p>
                        <p>${newTiers?.max_days ?? "-"}</p>
                      </div> */}
                      <div className="tier_val">
                        <p className="heading">Max Loss</p>
                        <p>{newTiers?.max_loss ?? "-"}%</p>
                      </div>
                      <div className="tier_val">
                        <p className="heading">Min Trading Days</p>
                        <p>{newTiers?.min_trading_days ?? "0"} days</p>
                      </div>
                      <div className="tier_val">
                        <p className="heading">Profit Target 1</p>
                        <p>{newTiers?.profit_target_1 ?? "-"} % </p>
                      </div>

                      {tiers.name === "2 Step" && (
                        <div className="tier_val">
                          <p className="heading">Profit Target 2</p>
                          <p>{newTiers?.profit_target_2 ?? "-"} %</p>
                        </div>
                      )}

                      {/* <div className="tier_val">
                        <p className="heading">Price</p>
                        <p>{newTiers?.price ?? "-"}</p>
                      </div> */}

                      <div className="tier_val">
                        <p className="heading">Profit Share</p>
                        <p>{parseFloat(newTiers?.profit_share).toFixed(0) ?? "-"}%</p>
                      </div>

                      {/* <div className="tier_val">
                        <p className="heading">Publish</p>
                        <p>{newTiers?.publish ? "Yes" : "No"}</p>
                      </div> */}
                      <div className="tier_val">
                        <p className="heading">Starting Balance</p>
                        <p>${newTiers?.starting_balance ?? "-"}</p>
                      </div>
                      <div className="tier_val">
                        <p className="heading">EA</p>
                        <p> {newTiers?.ea ? "Yes" : "No"}</p>
                      </div>
                      <div className="tier_val">
                        <p className="heading">Tradable Asset</p>
                        <p style={{color: "#071E0D", fontWeight: "700"}}>{newTiers?.tradable_asset ?? "-"}</p>
                      </div>
                      <div className="tier_val">
                        <p className="heading">Trade Through News</p>
                        <p>{newTiers?.trade_through_news ? "Yes" : "No"}</p>
                      </div>
                      <div className="tier_val">
                        <p className="heading">Hold Over The Weekend</p>
                        <p> {newTiers?.hold_over_the_weekend ? "Yes" : "No"}</p>
                      </div>
                      <div className="tier_val">
                        <p className="heading">Hedging & Stacking</p>
                        {/* <p> {newTiers?.hold_over_the_weekend ? "Yes" : "No"}</p> */}
                        <p>Yes</p>
                      </div>
                      {/* <div className="tier_val">
                        <p className="heading">Trading Requirements</p>
                        <p>{newTiers?.trading_requirements ?? "-"}</p>
                      </div> */}

                      {/* <div className="tier_val">
                        <p className="heading">Upgrade Delay Hours</p>
                        <p>{newTiers?.upgrade_delay_hours ?? "-"}</p>
                      </div> */}
                      {/* <div className="tier_val">
                        <p className="heading">Upgrade Threshold</p>
                        <p>{newTiers?.upgrade_threshold ?? "-"}</p>
                      </div> */}
                      {/* <div className="tier_val">
                        <p className="heading">Liquidate Friday</p>
                        <p>{newTiers?.liquidate_friday ? "Yes" : "No"}</p>
                      </div> */}
                      {/* <div className="tier_val">
                        <p className="heading">Require Stoploss</p>
                        <p>{newTiers?.require_stoploss ? "Yes" : "No"}</p>
                      </div> */}
                      {/* <div className="tier_val">
                        <p className="heading">Create As Disabled</p>
                        <p>{newTiers?.create_as_disabled ? "Yes" : "No"}</p>
                      </div> */}
                      {/* <div className="tier_val">
                        <p className="heading">Visible On Leaderboard</p>
                        <p>{newTiers?.visible_on_leaderboard ? "Yes" : "No"}</p>
                      </div> */}
                      {/* <div className="tier_val">
                        <p className="heading">Daily Dd By Balance Equity</p>
                        <p>{newTiers?.daily_dd_by_balance_equity ? "Yes" : "No"}</p>
                      </div> */}
                      {/* <div className="tier_val">
          <p className="heading">Publish</p>
          <p>{newTiers?.publish}</p>
        </div> */}
                      <div style={{width: "100%", padding: "0 20px"}}>
                        <Link to={`/funding-evaluation/payments/${newTiers?.id}`}>
                          <button
                            className="try_btn"
                            onClick={() => dispatch(setSelectedChallenge(newTiers?.id))}
                          >
                            {Number(newTiers?.price) === 0 ? (
                              "Try Now"
                            ) : (
                              <>
                                Buy Now -{" "}
                                {newTiers?.price === "12.00" ? (
                                  <s style={{fontSize: "15px"}}>$16</s>
                                ) : newTiers?.price === "20.00" ? (
                                  <s style={{fontSize: "15px"}}>$28</s>
                                ) : newTiers?.price === "46.00" ? (
                                  <s style={{fontSize: "15px"}}>$67</s>
                                ) : newTiers?.price === "246.00" ? (
                                  <s style={{fontSize: "15px"}}>$277</s>
                                ) : newTiers?.price === "120.00" ? (
                                  <s style={{fontSize: "15px"}}>$167</s>
                                ) : (
                                  ""
                                )}
                                $
                                {Number(newTiers?.price)
                                  .toFixed(2)
                                  .replace(/\.?0+$/, "")}
                              </>
                            )}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </Card>
                </div>
              </SplideSlide>
            );
          })}
        </Splide>
      ) : (
        tiersNew?.map((newTiers) => {
          return (
            <div className="tier_wrapper">
              <div className="tier_upper">
                <div className="tier-type">
                  <h4>{newTiers?.name}</h4>
                  {/* <p>
          {tiers?.name === "essential"
            ? "Casual part-time investor"
            : tiers?.name === "pro"
            ? "Serious part-time investor"
            : "Serious full-time investor"}
        </p> */}
                </div>
                <div className="tier-price">
                  <h3>${(newTiers?.starting_balance / 1000).toFixed(1)}k</h3>
                  <span>Challenge</span>
                </div>
              </div>
              <Card
                className={`tier-container ${tiers?.id === activeTier ? "tier-highlight" : ""} ${showFeatures ? "show_features" : ""}`}
                onClick={() => handleActive(tiers.id)}
              >
                <div className="tier-challenge">
                  <div className="tier_blank"></div>
                  {/* <div className="tier_line"></div> */}
                </div>
                <div className="tier-num">
                  {/* <div className="tier_val">
                        <p className="heading">Inactivity Days</p>
                        <p>{newTiers?.inactivity_days ?? "0"} days</p>
                      </div> */}
                  {/* <div className="tier_val">
          <p className="heading">Initial Withdrawal Delay</p>
          <p
            className={
              newTiers?.name === activeTier ? "" : "tier-details-highlight"
            }
          >
            {newTiers?.initial_withdrawal_delay}
          </p>
        </div> */}
                  {/* <div className="tier_val">
                        <p className="heading">Initial Withdrawal Delay</p>
                        <p>{newTiers?.initial_withdrawal_delay ?? "-"}</p>
                      </div> */}
                  {/* <div className="tier_val">
                        <p className="heading">Subsequent Withdrawal Delay</p>
                        <p>{newTiers?.subsequent_withrawal_delay ?? "-" ?? "-"}</p>
                      </div> */}
                  {/* <div className="tier_val">
                    <p className="heading">Leverage</p>
                    <p>1: {newTiers?.leverage ?? "-"}</p>
                  </div>{" "} */}
                  <div className="tier_val">
                    <p className="heading">Leverage(FX) </p>
                    <p className="leverageBox">
                      <span>1 : 50 (FX)</span>
                      <span>1 : 20 (Metals)</span>
                    </p>
                  </div>
                  <div className="tier_val">
                    <p className="heading">Leverage (Crypto) </p>
                    <p>1 : 2</p>
                  </div>
                  <div className="tier_val">
                    <p className="heading">Max Daily Loss</p>
                    <p>{newTiers?.max_daily_loss ?? "-"}%</p>
                  </div>
                  {/* <div className="tier_val">
                    <p className="heading">Max Days</p>
                    <p>${newTiers?.max_days ?? "-"}</p>
                  </div> */}
                  <div className="tier_val">
                    <p className="heading">Max Loss</p>
                    <p>{newTiers?.max_loss ?? "-"}%</p>
                  </div>
                  <div className="tier_val">
                    <p className="heading">Min Trading Days</p>
                    <p>{newTiers?.min_trading_days ?? "0"} days</p>
                  </div>
                  <div className="tier_val">
                    <p className="heading">Profit Target 1</p>
                    <p>{newTiers?.profit_target_1 ?? "-"} %</p>
                  </div>
                  <div className="tier_val">
                    <p className="heading">Profit Target 2</p>
                    <p>{newTiers?.profit_target_2 ?? "-"} % </p>
                  </div>
                  {/* <div className="tier_val">
                    <p className="heading">Price</p>
                    <p>{newTiers?.price ?? "-"}</p>
                  </div> */}
                  <div className="tier_val">
                    <p className="heading">Profit Share</p>
                    <p>{newTiers?.profit_share ?? "-"}</p>
                  </div>
                  {/* <div className="tier_val">
                    <p className="heading">Publish</p>
                    <p>{newTiers?.publish ? "Yes" : "No"}</p>
                  </div> */}
                  <div className="tier_val">
                    <p className="heading">Starting Balance</p>
                    <p>${newTiers?.starting_balance ?? "-"}</p>
                  </div>
                  <div className="tier_val">
                    <p className="heading">EA</p>
                    <p> {newTiers?.ea ? "Yes" : "No"}</p>
                  </div>
                  <div className="tier_val">
                    <p className="heading">Tradable Asset</p>
                    <p style={{color: "#071E0D", fontWeight: "700"}}>{newTiers?.tradable_asset ?? "-"}</p>
                  </div>
                  <div className="tier_val">
                    <p className="heading">Trade Through News</p>
                    <p>{newTiers?.trade_through_news ? "Yes" : "No"}</p>
                  </div>
                  <div className="tier_val">
                    <p className="heading">Hold Over The Weekend</p>
                    <p> {newTiers?.hold_over_the_weekend ? "Yes" : "No"}</p>
                  </div>
                  <div className="tier_val">
                    <p className="heading">Hedging & Stacking</p>
                    {/* <p> {newTiers?.hold_over_the_weekend ? "Yes" : "No"}</p> */}
                    <p>Yes</p>
                  </div>
                  {/* <div className="tier_val">
                        <p className="heading">Trading Requirements</p>
                        <p>{newTiers?.trading_requirements ?? "-"}</p>
                      </div> */}
                  {/* <div className="tier_val">
                        <p className="heading">Upgrade Delay Hours</p>
                        <p>{newTiers?.upgrade_delay_hours ?? "-"}</p>
                      </div> */}
                  {/* <div className="tier_val">
                        <p className="heading">Upgrade Threshold</p>
                        <p>{newTiers?.upgrade_threshold ?? "-"}</p>
                      </div> */}
                  {/* <div className="tier_val">
                        <p className="heading">Liquidate Friday</p>
                        <p>{newTiers?.liquidate_friday ? "Yes" : "No"}</p>
                      </div> */}
                  {/* <div className="tier_val">
                        <p className="heading">Require Stoploss</p>
                        <p>{newTiers?.require_stoploss ? "Yes" : "No"}</p>
                      </div> */}
                  {/* <div className="tier_val">
                        <p className="heading">Create As Disabled</p>
                        <p>{newTiers?.create_as_disabled ? "Yes" : "No"}</p>
                      </div> */}
                  {/* <div className="tier_val">
                        <p className="heading">Visible On Leaderboard</p>
                        <p>{newTiers?.visible_on_leaderboard ? "Yes" : "No"}</p>
                      </div> */}
                  {/* <div className="tier_val">
                        <p className="heading">Daily Dd By Balance Equity</p>
                        <p>{newTiers?.daily_dd_by_balance_equity ? "Yes" : "No"}</p>
                      </div> */}
                  {/* <div className="tier_val">
          <p className="heading">Publish</p>
          <p>{newTiers?.publish}</p>
        </div> */}
                  <div style={{width: "100%", padding: "0 20px"}}>
                    <Link to={`/funding-evaluation/payments/${newTiers?.id}`}>
                      <button
                        className="try_btn"
                        onClick={() => dispatch(setSelectedChallenge(newTiers?.id))}
                      >
                        {Number(newTiers?.price) === 0 ? "Try Now" : `Buy Now - $${newTiers?.price}`}
                      </button>
                    </Link>
                  </div>
                </div>
              </Card>
            </div>
          );
        })
      )}
    </>
  );
}

export default Tier;
