import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import Spinner from "../../../../reusableComponents/Spinner";
import {getPayoutSplitList} from "../../../../store/reducers/accountSlice";
import {returnErrors} from "../../../../store/reducers/error";
import {returnMessages} from "../../../../store/reducers/message";
import {paymentMethods, payoutRequest} from "../../../../utils/apis/payment";
import "./style.scss";
import customStyles from "../../../../customstyle/customstyle";
import RecipientBankDetails from "../../RecipientBankDetails";
import edit from "../../../../assets/icons/edit-pencil copy.svg";
import moment from "moment";
import LoaderOverlay from "../../../../reusableComponents/Loader";
function PayoutSplit() {
  const idToken = useSelector((state) => state.auth.idToken);

  const dispatch = useDispatch();
  const payoutPfs = useSelector((state) => state.account.payoutListPFS);

  const [accountListValue, setAccountListValue] = useState(payoutPfs?.data);
  useEffect(() => {
    setAccountListValue(payoutPfs?.data);
  }, [payoutPfs?.data]);

  useEffect(() => {
    dispatch(getPayoutSplitList(idToken));
  }, [idToken]);

  const [accountList, setAccountList] = useState(
    accountListValue?.data?.map((obj) => ({
      value: obj.account,
      label: obj.account,
      amount: obj.payable_amt,
    })),
  );

  // const [paymentMethodsList, setPaymentMethodsList] = useState([]);
  // const fetchPaymentMethods = async () => {
  //   const response = await paymentMethods(idToken);
  //   const verifiedKeys = Object.keys(response)
  //     .filter((key) => response[key].some((item) => item.is_verified === true))
  //     .map((key) => key.toUpperCase());
  //   setPaymentMethodsList(verifiedKeys);
  // };

  // useEffect(() => {
  //   fetchPaymentMethods();
  // }, []);

  useEffect(() => {
    const filteredList = accountListValue?.data?.map((obj) => ({
      value: obj.account,
      label: obj.account,
      amount: obj.payable_amt,
    }));
    setAccountList(filteredList);
  }, [idToken, accountListValue]);
  const [payout, setPayout] = useState({
    amount: "",
    reason: "",
    login_id: null,
    payout_type: "PFS",
    method: "",
  });

  const [maxAmount, setMaxAmount] = useState(0);

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setPayout({...payout, login_id: selectedOption.value, amount: selectedOption.amount});
    }
    setMaxAmount(selectedOption.amount);
  };

  const handleSelectMethod = (selectedOption) => {
    setPayout({...payout, method: selectedOption.value});
  };

  const handleInputChange = (e) => {
    const {name, value} = e.target;
    if (e.target.type === "number") {
      setPayout({...payout, [name]: Number(value)});
    } else {
      setPayout({...payout, [name]: value});
    }
  };
  const formData = new FormData();
  for (const key in payout) {
    formData.append(key, payout[key]);
  }

  const [successmodalOpen, setSuccessModalOpen] = useState(false);
  const [errormodalOpen, setErrorModalOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [isSpinner, setIsSpinner] = useState(false);

  const options = accountList;

  // const methodOptions = paymentMethodsList.map((method) => ({label: method, value: method}));
  const methodOptions = [
    // {
    //   label: (
    //     <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
    //       <span>SWIFT</span>{" "}
    //       <img
    //         src={edit}
    //         alt=""
    //         style={{marginRight: "15px"}}
    //         onClick={(e) => {
    //           e.stopPropagation();
    //           setValue(4);
    //         }}
    //       />
    //     </div>
    //   ),
    //   value: "SWIFT",
    // },
    {
      label: (
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
          <span>WIRE</span>{" "}
          <img
            src={edit}
            alt=""
            style={{marginRight: "15px"}}
            onClick={(e) => {
              e.stopPropagation();
              setValue(3);
            }}
          />
        </div>
      ),
      value: "SWIFT",
    },
    // {
    //   label: (
    //     <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
    //       <span>PAYPAL</span>{" "}
    //       <img
    //         src={edit}
    //         alt=""
    //         style={{marginRight: "15px"}}
    //         onClick={(e) => {
    //           e.stopPropagation();
    //           setValue(5);
    //         }}
    //       />
    //     </div>
    //   ),
    //   value: "PAYPAL",
    // },
    {
      label: (
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
          <span>USDT TRC20</span>{" "}
          <img
            src={edit}
            alt=""
            style={{marginRight: "15px"}}
            onClick={(e) => {
              e.stopPropagation();
              setValue(6);
            }}
          />
        </div>
      ),
      value: "CRYPTO",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {data} = useSelector((state) => state.account.accountList);

  // const filter = data.find((item) => item.login_id === payout.login_id);
  // console.log(filter, "filterfilter");
  // const nextPayout = filter && filter?.next_payout_date > moment(Date.now()).format("YYYY-MM-DD");
  // console.log(nextPayout, "nextPayout");
  // Assume `data` is an array of account objects, and `payout` is an object containing payout information.

  let filter = null;
  for (const item of data) {
    if (item.login_id === payout.login_id) {
      filter = item;
      break;
    }
  }

  let nextPayout = false;
  if (filter) {
    const currentDateTime = new Date();
    const currentDate = currentDateTime.toISOString().split("T")[0]; // Get today's date in "YYYY-MM-DD" format
    nextPayout = filter.next_payout_date > currentDate;
  }

  console.log(filter, "nextPayout");
  console.log(nextPayout, "nextPayout");

  const isActive = data?.filter((item) => item?.is_active === true);
  const phase = data?.filter((item) => item?.phase === "funded");

  const [refreshBanksDetails, setRefreshBanksDetails] = useState(false);

  const [paymentDetails, setPaymentdetails] = useState([]);
  const fetchPaymentdetails = async () => {
    const respo = await paymentMethods(idToken, dispatch);
    setPaymentdetails(respo);
  };
  useEffect(() => {
    fetchPaymentdetails();
  }, [refreshBanksDetails]);
  const closeBankDetailsModal = () => {
    setValue(null);
  };
  const [value, setValue] = useState(null);
  console.log(value, "valuevalue");
  useEffect(() => {
    if (payout?.method === "SWIFT") {
      //&& paymentDetails?.wire?.length === 0
      setValue(3);
    }
    // if (payout?.method === "SWIFT" && paymentDetails?.swift?.length === 0) {
    //   setValue(4);
    // }
    // if (payout?.method === "PAYPAL" && paymentDetails?.paypal?.length === 0) {
    //   setValue(5);
    // }
    if (payout?.method === "CRYPTO") {
      setValue(6);
    }
  }, [payout?.method]);
  const handleSubmit = async () => {
    // if (payout?.method === "WIRE" && paymentDetails?.wire?.length === 0) {
    //   setValue(3);
    //   return;
    // }
    // if (payout?.method === "SWIFT" && paymentDetails?.swift?.length === 0) {
    //   setValue(4);
    //   return;
    // }
    // if (payout?.method === "PAYPAL" && paymentDetails?.paypal?.length === 0) {
    //   setValue(5);
    //   return;
    // }
    // if (payout?.method === "USDT TRC20") {
    //   setValue(6);
    //   return;
    // }

    setIsSpinner(true);
    const response = await payoutRequest(idToken, formData);

    setMsg(response.status || response.message);

    if (response.status === 200 || response.status === 201) {
      // setSuccessModalOpen(true);
      dispatch(returnMessages("Payout Successful", 200));
      setIsSpinner(false);
    } else {
      dispatch(returnErrors("Something went wrong", 400));
      setIsSpinner(false);
    }
  };
  const [timeLeft, setTimeLeft] = useState(null);
  const intervalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        const now = new Date();
        const expiration = new Date(filter?.next_payout_date);
        const difference = expiration - now;

        if (difference <= 0) {
          clearInterval(intervalRef.current);
          setTimeLeft("Expired");
        } else {
          const days = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((difference / 1000 / 60) % 60);
          // const seconds = Math.floor((difference / 1000) % 60);
          //${seconds}s

          !isNaN(days) && setTimeLeft(`${days}days ${hours}hrs ${minutes}min`);
          !isNaN(days) && setIsLoading(false);
        }
      }, 1000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [filter?.next_payout_date]);

  return (
    <>
      {isLoading && nextPayout && <LoaderOverlay />}

      <div className="reqpayout">
        <RecipientBankDetails
          refreshBanksDetails={refreshBanksDetails}
          setRefreshBanksDetails={setRefreshBanksDetails}
          value={value}
          setValue={setValue}
          closeBankDetailsModal={closeBankDetailsModal}
        />
        <h4>Trading account</h4>
        {/* <CustomSeparator navigation={navigation} /> */}
        <div className="req_payout_wrapper">
          <div className="grid_container">
            <div className="col1">
              <div className="col1_lower">
                {/* <Tabination
              variant={3}
              tabItems={items}
              activeTab={0}
            /> */}
                <div className="select_container_L">
                  <div className="col1_lower_select_container">
                    <p>{isActive?.length > 0 && phase?.length > 0 ? "You can only request a payout here on the 14th day since your first trade" : ""}</p>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                      <p>Choose Account Login ID*</p> {nextPayout && timeLeft && <p>Remaining days for next payout :- {timeLeft}.</p>}
                    </div>

                    <Select
                      options={options}
                      styles={customStyles}
                      name="login_id"
                      value={options?.find((option) => option.value === payout.login_id)}
                      onChange={handleSelectChange}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      isSearchable={false}
                    />
                  </div>
                  <div className="col1_lower_select_container">
                    <p>Choose Payout Method</p>
                    <Select
                      options={methodOptions}
                      styles={customStyles}
                      name="method"
                      value={methodOptions?.find((option) => option.value === payout.method)}
                      // value={{label: payout.method, value: payout.method}}
                      onChange={handleSelectMethod}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      isSearchable={false}
                    />
                  </div>
                </div>
                {/* <div className="col1_lower_select_container">
                  <p>Choose Payout Type</p>
                  <Select
                    options={typeOptions}
                    styles={customStyles}
                    name="payout_type"
                    value={options.find((option) => option.value === payout.payout_type)}
                    onChange={handleSelectType}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div> */}
                <div className="select_container_R">
                  <div
                    style={{width: "100%"}}
                    className="col1_lower_select_container amountBtn"
                  >
                    <p>Amount ($)</p>
                    <input
                      // style={{
                      //   width: "95%",
                      //   // border: "1px solid var(--Border, #242529)",
                      //   borderRadius: "8px",
                      //   padding: "14px",
                      //   marginTop: "10px",
                      // }}
                      type="number"
                      name="amount"
                      value={payout.amount > maxAmount ? maxAmount : payout.amount}
                      onChange={handleInputChange}
                      placeholder="Amount"
                    />
                    <button
                      className="maxBtn"
                      onClick={() => setPayout({...payout, amount: maxAmount})}
                    >
                      Max
                    </button>
                  </div>
                  {/* <div className="col1_lower_select_container">
                    <p>Reason</p>
                    <textarea
                      // style={{
                      //   width: "95%",
                      //   border: "1px solid rgba(255, 255, 255, 0.20)",
                      //   background:"rgba(255, 255, 255, 0.06)",
                      //   borderRadius: "8px",
                      //   padding: "14px",
                      //   marginTop: "10px",
                      // }}
                      type="text"
                      name="reason"
                      value={payout.reason}
                      onChange={handleInputChange}
                      placeholder="Reason"
                    />
                  </div> */}
                </div>
              </div>
              <div className="col1_button">
                <button
                  style={{
                    border: "1px solid #22A6AE",
                    borderRadius: "5px",
                    padding: "14px 20px",
                    margin: "10px 0 0 0",
                    background: "#22A6AE",
                    color: "#081324",
                    fontSize: "14px",
                    fontWeight: "700",
                    marginTop: "20px",
                  }}
                  onClick={handleSubmit}
                  disabled={isSpinner || !payout.method || !payout.login_id || nextPayout}
                >
                  {isSpinner ? <Spinner /> : <>Submit</>}
                </button>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
}

export default PayoutSplit;
