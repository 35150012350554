import Router from "./routes/Router";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store, persistor, clearPersistedData } from "./store/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import { useEffect, useState } from "react";
import {
  getAccountList,
  getAccountMetrics,
  setActiveAccount,
} from "./store/reducers/accountSlice";
import axios from "axios";
import { deAuthenticate } from "./store/reducers/authSlice";
import SuccessModal from "./components/Alerts/SuccessModal";
import ErrorModal from "./components/Alerts/ErrorModal";
import "react-toastify/dist/ReactToastify.css";
import Intercom from "@intercom/messenger-js-sdk";

function App() {
  const data = useSelector((state) => state.account.accountList);
  const { userId, isAuthenticated } = useSelector((state) => state?.auth);
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();

  console.log(isAuthenticated, "isAuthenticated");
  //Auto logs out if there is an invalid Token
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401 || error?.response?.status === 403) {
        if (
          error?.response?.data?.detail === "Invalid token" ||
          error?.response?.data?.detail === "Not Allowed"
        ) {
          dispatch(deAuthenticate());
          clearPersistedData();

          //disable redux saga refresh
          window.location.reload();
          return;
        }
      }
      return error;
    }
  );

  useEffect(() => {
    const createdAt = Math.floor(Date.now() / 1000);
    let authUser = {
      app_id: "oa4kzzll",
      user_id: userId,
      name: `${user?.first_name} ${user?.last_name}`,
      email: user?.email,
      created_at: createdAt,
    };

    let unAuthUser = {
      app_id: "oa4kzzll",
    };

    if (isAuthenticated) {
      Intercom(authUser);
    } else {
      Intercom(unAuthUser);
      Intercom("shutdown");
    }
  }, [isAuthenticated, userId, user]);

  // const idToken = useSelector((state) => state.auth.idToken);
  // const activeAccount = useSelector((state) => state.account.accountList.active);
  // const firstAccount = useSelector((state) => state.account.accountList.data[0]);
  // const loginId = activeAccount || firstAccount;
  // useEffect(() => {
  //   dispatch(getAccountMetrics({idToken, loginId}));
  //
  // }, [loginId]);

  return data ? (
    <>
      <Router />
      <SuccessModal />
      <ErrorModal />
    </>
  ) : null;
}

export default App;
