import React, { useEffect, useState } from "react";
import MainHeader from "../../layouts/pageLayout/MainHeader";
import Tabination from "../../reusableComponents/Tabination";
import Verification from "../../components/MyProfile/Verification/Index";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../utils/apis";
import { useDispatch, useSelector } from "react-redux";
import { digiSigner, loadUser } from "../../utils/apis/user";
import { returnErrors } from "../../store/reducers/error";
import LoaderOverlay from "../../reusableComponents/Loader";

const Kyc = () => {
  const [activeTab, setActiveTab] = useState(0);
  const idToken = useSelector((state) => state.auth.idToken);
  const [isSingContractVisible, setIsSignContractVisible] = useState(false)
  const [kycEligible, setKycEligible] = useState(false)
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeTab]);




  useEffect(() => {
    loadUser(idToken).then(res => {
      res?.data?.issue_contract ? setIsSignContractVisible(true) : setIsSignContractVisible(false);
      res?.data?.kyc_eligiblity ? setKycEligible(true) : setKycEligible(false);
    })
  }, [])
  const dispatch = useDispatch()
  const handleSignContract = async () => {
    setLoading(true);
    const response = await digiSigner(idToken)
    if (response?.status < 399) {
      window.location.replace(response?.data?.url);
      // setLoading(false)

    } else {
      
      dispatch(returnErrors("Something went wrong", 500));
      setLoading(false)
    }
  }

  const items = [
    {
      name: "General Information",
      path: "general-information",
    },
    {
      name: "Verification",
      path: "verification",
    },
    {
      name: "Billing",
      path: "billing",
    },
    // {
    //   name: "Payout",
    //   path: "payout",
    // },
  ];
  return (
    <div className="mainPageHolder">
      {
        loading && (
          <LoaderOverlay />
        )
      }
      <div className="mainPageHolder-T-C">
        <div className="mainPageHolder-T">
          <MainHeader
            headerName="KYC"
            showTabs={false}
            tabItems={items}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      </div>
      <div className="mainPageHolder-B">
        <div className="all_header">
          <h2>KYC</h2>
        </div>
        {/* <Tabination
          variant={3}
          tabItems={items}
        /> */}
        <div className="myprofile">
          <Verification kycEligible ={kycEligible}/>
        </div>
        {/* {
          isSingContractVisible && <div className="sign_contract_button">
            <Link onClick={handleSignContract}>
              <button
                className="claim_button"
              // disabled={isSpinner}
              >
                Sign Contract
              </button>
            </Link>
          </div>
        } */}
      </div>
    </div>
  );
};

export default Kyc;
