import React, {useEffect, useState} from "react";
import "./style.scss";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import {useSelector} from "react-redux";

function NavPanel({headerName, children}) {
  const [zIndexValue, setZIndexValue] = useState(false);
  //to fix zindex conflict with modals and sidemenu

  // const [theme, setTheme] = useState("light-theme");
  const {theme} = useSelector((state) => state.theme);

  // useEffect(() => {
  //   const prefersDarkTheme = window.matchMedia("(prefers-color-scheme: dark)");
  //   const prefersLightTheme = window.matchMedia(
  //     "(prefers-color-scheme: light)"
  //   );

  //   

  //   const handleThemeChange = () => {
  //     if (prefersDarkTheme.matches) {
  //       setTheme("dark-theme");
  //     } else if (prefersLightTheme.matches) {
  //       setTheme("light-theme");
  //     }
  //   };

  //   handleThemeChange();

  //   prefersDarkTheme.addListener(handleThemeChange);
  //   prefersLightTheme.addListener(handleThemeChange);

  //   return () => {
  //     prefersDarkTheme.removeListener(handleThemeChange);
  //     prefersLightTheme.removeListener(handleThemeChange);
  //   };
  // }, []);

  // const toggleTheme = () => {
  //   setTheme(theme === "dark-theme" ? "light-theme" : "dark-theme");
  // };

  useEffect(() => {
    // theme === "light-theme";
    // ? (document.documentElement.style.background = "#fff")
    // : (document.documentElement.style.background = "#242424");

    document.body.classList = theme;
  }, [theme]);

  const [mobileMenu, setMobileMenu] = useState(false);

  return (
    <main className="navpanel-container">
      {/* <Header
        headerName={headerName}
        theme={theme}
        // setTheme={setTheme}
        // toggleTheme={toggleTheme}
        mobileMenu={mobileMenu}
        setMobileMenu={setMobileMenu}
      /> */}
      <div className="navpanel-subcontainer">
        <Sidebar
          headerName={headerName}
          zIndexValue={zIndexValue}
          setZIndexValue={setZIndexValue}
          // theme={theme}
          // setTheme={setTheme}
          // toggleTheme={toggleTheme}
          mobileMenu={mobileMenu}
          setMobileMenu={setMobileMenu}
        />
        <div className={`navpanel-container_children  ${zIndexValue ? "zIndex" : "noZIndex"}`}>
          <div className="navpanel-children">{children}</div>
        </div>
      </div>
    </main>
  );
}

export default NavPanel;
