import React, {useEffect, useMemo, useRef, useState} from "react";
import "./style.scss";
import {usePagination, useSortBy, useTable} from "react-table";
import TrophyGreen from "../../../assets/icons/trophy-green.svg";
import TrophyYellow from "../../../assets/icons/trophy-yellow.svg";
import MedalOrange from "../../../assets/icons/medal-orange.svg";
import MedalBlue from "../../../assets/icons/medal-blue.svg";
import MedalGray from "../../../assets/icons/medal-gray.svg";
import DropdownIcon from "../../../assets/icons/dropdown-arrow-gray.svg";
import BlueTickIcon from "../../../assets/icons/tick-blue-circle.svg";
import CalendarIcon from "../../../assets/icons/calender.svg";

import CaretDownIcon from "../../../assets/icons/caret-down.svg";
import CaretLeftIcon from "../../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../../assets/icons/caret-right.svg";
import Select from "react-select";
import Calendar from "../../../reusableComponents/Calendar";
import LoaderOverlay from "../../../reusableComponents/Loader";

function LeaderboardTable({data, loading}) {
  const [isCalender, setIsCalender] = useState(false);
  const [date, setDate] = useState(new Date());
  const [tableData, setTableData] = useState(data);
  
  useEffect(
    () => setTableData(data),

    [data],
  );

  // useEffect(() => {
  //   setRefresh((pre));
  // }, []);
  return (
    <div className="leaderboard_table">
      {loading && <LoaderOverlay />}
      <div className=" table_wrapper">
        <div className="table_header">
          <p>Competition Leaderboard</p>
          <div className="select_wrapper">
            <div
              className="calendar-button"
              onClick={() => setIsCalender((prev) => !prev)}
            >
              <img
                src={CalendarIcon}
                alt="calender icon"
              />
              <p>Past 90 Days</p>
              <img
                src={CaretDownIcon}
                alt=""
                className="arrow_down"
              />
            </div>

            <div className="calendar_wrapper">
              {isCalender && (
                <Calendar
                  onChange={(date) => setDate(date)}
                  value={date}
                  // formatMonth={"MMM"}
                  selectRange={true}
                  showNeighboringMonth={false}
                />
              )}
            </div>
          </div>
        </div>
        <div className="table_scroll_wrapper">
          <Table tableData={tableData} />
        </div>
        {tableData.length <= 0 && <h2 style={{textAlign: "center", marginTop: "20px", color: "#35d169"}}>No Competition Leaderboard</h2>}
      </div>{" "}
    </div>
  );
}

export default LeaderboardTable;

function Table({tableData}) {
  const columns = useMemo(
    () => [
      {
        Header: (
          <>
            <div className="column_one_header">#</div>
          </>
        ),
        accessor: "id",
        Cell: ({row}) => (
          <>
            <div className="column_one_wrapper">
              <div className={`sno_wrapper ${row.index + 1 === 1 ? "bg_green" : row.index + 1 === 2 ? "bg_yellow" : row.index + 1 === 3 ? "bg_orange" : "bg_default"}`}>{row.index + 1}</div>
              <div className="icon_wrapper">
                <img
                  src={`${row.index + 1 === 1 ? TrophyGreen : row.index + 1 === 2 ? TrophyYellow : row.index + 1 === 3 ? MedalOrange : MedalGray}`}
                  alt="trophy icon"
                />{" "}
              </div>
            </div>
          </>
        ),
      },
      {Header: "Name", accessor: "email"},
      {Header: "Account Balance", accessor: "account_balance"},
      {Header: "Balace", accessor: "balance"},
      {Header: "Profit", accessor: "profit"},
      {Header: "Max loss", accessor: "max_loss"},
      {
        Header: "Status",
        accessor: "is_Active",
        Cell: ({row}) => (row.original.is_Active ? "Active" : "Inactive"),
      },
    ],
    [],
  );

  const data = useMemo(() => tableData, [tableData]);

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({columns, data});

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
