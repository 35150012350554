import React, {useEffect, useState} from "react";
import "./style.scss";
import {Link, useLocation, useNavigate} from "react-router-dom";
import CameraIcon from "../../assets/icons/camera.svg";
import {useSelector} from "react-redux";
import CaretIcon from "../../assets/icons/caret-down.svg";
import LoaderOverlay from "../Loader";
import MainHeader from "../../layouts/pageLayout/MainHeader";
import Select from "react-select";
import customStyles from "../../customstyle/customstyle";
import {FilterChart} from "../../components/AccountMetrics/FinancialPerformance";
import {number} from "echarts";

export default function Tabination({
  variant = 1,
  tab,
  className,
  tabItems,
  setActiveTab,
  activeTab = 0,
  style,
  tabStyle,
  balanceChart,
  radioButton = false,
  onTabChange = false,
  isChart = false,
  showTabs,
  isToolbar = false,
  resetClickEvent = () => {},
}) {
  const [items, setItems] = useState([]);
  const [active, setActive] = useState(activeTab);
  useEffect(() => {}, [active]);
  const [none, setNone] = useState([1, 2, 3]);
  useEffect(() => {}, [active]);

  const [chartTimeline, setChartTimeline] = useState("1M");

  const navigate = useNavigate();
  function handleTabMobile(e, idx) {
    if (setActiveTab) {
      setActiveTab(Number(idx));
    }
    // navigate(e);
    setActive(Number(idx));
    resetClickEvent(false);
    setNone(none);
    if (tab === true) {
      localStorage.setItem("activeTab", JSON.stringify(tabItems[idx]));
    }
  }
  function handleTab(e, idx) {
    if (setActiveTab) {
      setActiveTab(Number(idx));
    }
    navigate(e);
    setActive(Number(idx));
    resetClickEvent(false);
    setNone(none);
    if (tab === true) {
      localStorage.setItem("activeTab", JSON.stringify(tabItems[idx]));
    }
  }

  useEffect(() => {
    const handleTabChange = (activeTab) => {
      setActive(activeTab);
      resetClickEvent(false);
    };
    handleTabChange(activeTab);
  }, [onTabChange]);

  useEffect(() => {
    setItems(tabItems);
  }, [tabItems]);
  const support = useSelector((state) => state.support);

  const options = items.map((item, index) => ({
    label: item?.name,
    value: [item?.path, index].join(","),
  }));

  const [isLoading, setIsLoading] = useState(support.isLoading);

  if (isLoading === true) {
    return <div style={{position: "absolute", width: "100%", height: "100%"}}>{/* <LoaderOverlay /> */}</div>;
  } else {
    return (
      <>
        {/* {variant === 1 && (
        <div className="tabination-container">
          <div className="tabination_subcontainer_wrapper">
            <ul style={style} className="tabination-subcontainer">
              {items.map((item, index) => {
                return (
                  <li
                    style={tabStyle}
                    key={index}
                    onClick={(e) => handleTab(e, index)}
                    className={index === active ? "tabination-toggle" : ""}
                  >
                    {radioButton ? (
                      <>
                        <input
                          type="radio"
                          id={`tab${index}`}
                          checked={index === active}
                          onChange={() => {}}
                        />
                        <label htmlFor={`tab${index}`}>{item["name"]}</label>
                      </>
                    ) : (
                      item["name"]
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="tabination_wrapper">{items[active]?.content}</div>
        </div>
      )} */}

        <div
          // style={{marginTop: "20px"}}
          className="spacerCon"
        ></div>
        {variant === 2 && (
          <div className="tabination-container_variant2">
            <div
              style={style}
              className="tabination-subcontainer"
            >
              <div>
                <ul className="tabination-container_toggle">
                  {items.map((item, index) => {
                    return (
                      <li
                        style={tabStyle}
                        key={index}
                        onClick={(e) => handleTabMobile(e, index)}
                        className={index === active ? "tabination-toggle" : ""}
                      >
                        {item["name"]}
                      </li>
                    );
                  })}
                </ul>
                {isChart ? (
                  <ul className="tabination-container_chart_toggle">
                    {/* {['1M', '5M', '1D', '1W', '1MO'].map((item, index) => (
                    <li
                      className={
                        chartTimeline === item ? 'chartToggle_active' : ''
                      }
                      key={index}
                      id={item}
                      onClick={() => setChartTimeline(item)}
                    >
                      {item}
                    </li>
                  ))} */}
                  </ul>
                ) : (
                  <></>
                )}
              </div>
              {isToolbar ? (
                <div className="tabination-container_toolbar">
                  {/* <div>
                  <p>Lots of</p>
                  <img src={CaretIcon} alt="camera" />
                </div>
                <img src={CameraIcon} alt="camera" /> */}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="tabination_wrapper">{items[active]?.content}</div>
          </div>
        )}

        {variant === 3 && (
          <div className="tabination-container variant_3">
            <div className="tabination_subcontainer_wrapper ">
              <ul
                style={style}
                className="tabination-subcontainer tabination_normal"
              >
                {items?.map((item, index) => {
                  return (
                    <li
                      style={tabStyle}
                      key={index}
                      onClick={(e) => handleTab(e, index)}
                    >
                      <Link to={item?.path}>
                        <span className={index === active ? "tabination-toggle" : ""}>{item && item["name"]}</span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
              <div style={{padding: "0"}}>
                {" "}
                {showTabs === false ? (
                  ""
                ) : (
                  <Select
                    name="tabs"
                    id="tabs"
                    className="tabination_mobile"
                    options={options}
                    styles={customStyles}
                    value={options[activeTab]}
                    classNamePrefix="react-select"
                    onChange={(selectedOption) => {
                      const [path, index] = selectedOption.value.split(",");
                      handleTab(path, index);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="tabination_wrapper">{items[active]?.content}</div>
          </div>
        )}
        {/* 
        {variant === 3 ? (
          <div className="tabination-container variant_3">
            <div className="tabination_subcontainer_wrapper">
              <div className="tabination-subcontainer">
                <ul className="tabination-container_toggle">
                  {items.map((item, index) => {
                    return (
                      <li
                        style={tabStyle}
                        key={index}
                        onClick={(e) => handleTab(e, index)}
                        // className={location.pathname.includes(item.path) ? "tabination-toggle active" : ""}
                      >
                        <Link to={item.path}>{item["name"]}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="tabination_wrapper">{items[active]?.content}</div>
          </div>
        ) : (
          ""
        )} */}

        {variant === 4 ? (
          <div className="tabination-container variant_4">
            <ul
              style={style}
              className={className === "account-tabination" ? "account-tabination tabination-subcontainer" : "tabination-subcontainer tabination_normal_5"}
            >
              {items.map((item, index) => {
                return (
                  <li
                    style={tabStyle}
                    key={index}
                    onClick={(e) => handleTabMobile(e, index)}
                    className={index === active ? "active" : ""}
                  >
                    {radioButton ? (
                      <>
                        <input
                          type="radio"
                          id={`tab${index}`}
                          checked={index === active}
                          onChange={() => {}}
                        />
                        <label htmlFor={`tab${index}`}>{item["name"]}</label>
                      </>
                    ) : (
                      item["name"]
                    )}
                  </li>
                );
              })}
            </ul>
            <div className="tabination_wrapper">{items[active]?.content}</div>
          </div>
        ) : (
          ""
        )}
        {variant === 5 && (
          <div className="tabination-container variant_5">
            <div className="tabination_subcontainer_wrapper">
              <div className="Chart_tabination">
                <ul
                  style={style}
                  className="tabination-subcontainer tabination_normal_5"
                >
                  {items?.map((item, index) => (
                    <li
                      style={tabStyle}
                      key={index}
                      onClick={(e) => handleTabMobile(e, index)}
                    >
                      <Link to={item?.path}>
                        <span className={index === active ? "tabination-toggle" : ""}>{item.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
                <div>{balanceChart && Number(active) === 0 && <FilterChart />}</div>
              </div>
              <Select
                name="tabs"
                id="tabs"
                className="tabination_mobile"
                options={options}
                styles={customStyles}
                value={options[activeTab]}
                onChange={(selectedOption) => {
                  const [path, index] = selectedOption.value.split(",");
                  handleTab(path, index);
                }}
              />
            </div>
            <div className="tabination_wrapper">{items[active]?.content}</div>
          </div>
        )}
      </>
    );
  }
}
