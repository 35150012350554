import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
const initialState = {
  support: [],
  isLoading: false,
  error: false,
  message: "",
};

const supportSlice = createSlice({
  name: "support",
  initialState,
  reducers: {
    getSupportData: (state) => {
      state.isLoading = true;
    },
    getSupportDataSuccess: (state, action) => {
      
      state.support = action.payload;
      state.isLoading = false;
    },
    getSupportDataFailure: (state) => {
      state.isLoading = false;
    },
    createSupportCase: (state) => {},
    createSupportCaseSuccess: (state, action) => {
      state.case = action.payload;
    },
    createSupportCaseFailure: (state) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const {
  getSupportData,
  getSupportDataSuccess,
  getSupportDataFailure,
  createSupportCase,
  createSupportCaseSuccess,
  createSupportCaseFailure,
} = supportSlice.actions;
export default supportSlice.reducer;
