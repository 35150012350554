import React, {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {setAuthenticationSuccess} from "../../../store/reducers/authSlice";
import {getUser} from "../../../store/reducers/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import axios from "axios";
import {baseUrl} from "../../../utils/apis";

const GoogleSignin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      if (window.location.pathname === "/signup") {
        navigate("/funding-evaluation/plans/0");
      } else {
        navigate("/");
      }
    }
  }, [isAuthenticated]);
  const googleLogin = () => {
    const code = searchParams.get("code");
    axios
      .get(`${baseUrl}/auth/google/user/profile/?code=${code}`)
      .then((res) => {
        dispatch(setAuthenticationSuccess(res));
        dispatch(getUser(res.data.idToken));
      })
      .catch((err) => {
        navigate("/login");
      });
  };
  useEffect(() => {
    googleLogin();
  }, []);
  return (
    <div style={{height: "100vh", color: "white", position: "relative"}}>
      <h3
        style={{
          position: "absolute",
          transform: "translate(-50%, 0)",
          left: "50%",
          top: "40%",
        }}
      >
        Please wait...
      </h3>
    </div>
  );
};

export default GoogleSignin;
