import { all, fork } from "redux-saga/effects";

import authSaga from "./handlers/authSaga";
import userSaga from "./handlers/userSaga";
import supportSaga from "./handlers/supportSaga";
import accountSaga from "./handlers/accountSaga";

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(userSaga),
    fork(supportSaga),
    fork(accountSaga),
  ]);
}
