import React, {useState} from "react";
import "./style.scss";
import AddAddressModal from "../../AddAddressModal";
import EditAddressModal from "../../EditAddressModal";
import AddIcon from "../../../../assets/icons/add-circle.svg";

import {useSelector} from "react-redux";
import {useEffect} from "react";
import {getUserAddress} from "../../../../utils/apis";
import LoaderOverlay from "../../../../reusableComponents/Loader";
import Select from "react-select";
import {country} from "../../../../utils/constants/country";
import customStyles from "../../../../customstyle/customstyle";

const EditIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clip-path="url(#clip0_1_3223)">
      <path
        d="M11.8161 0.260258C11.9828 0.0936147 12.2089 0 12.4446 0C12.6803 0 12.9064 0.0936147 13.073 0.260258L15.7397 2.92696C15.9064 3.09365 16 3.3197 16 3.55541C16 3.79111 15.9064 4.01717 15.7397 4.18386L7.73965 12.184C7.57299 12.3507 7.34693 12.4444 7.1112 12.4444H4.4445C4.20875 12.4444 3.98265 12.3508 3.81595 12.1841C3.64925 12.0173 3.5556 11.7913 3.5556 11.5555V8.8888C3.55565 8.65307 3.64933 8.42702 3.81605 8.26035L11.8161 0.260258ZM5.3334 9.25681V10.6666H6.74319L13.8544 3.55541L12.4446 2.14561L5.3334 9.25681ZM0 3.55541C0 3.08391 0.187303 2.63172 0.520705 2.29831C0.854107 1.96491 1.3063 1.77761 1.7778 1.77761H6.2223C6.45805 1.77761 6.68414 1.87126 6.85084 2.03796C7.01754 2.20466 7.1112 2.43076 7.1112 2.66651C7.1112 2.90226 7.01754 3.12835 6.85084 3.29506C6.68414 3.46176 6.45805 3.55541 6.2223 3.55541H1.7778V14.2222H12.4446V9.7777C12.4446 9.54195 12.5382 9.31586 12.7049 9.14916C12.8716 8.98246 13.0977 8.8888 13.3335 8.8888C13.5692 8.8888 13.7953 8.98246 13.962 9.14916C14.1287 9.31586 14.2224 9.54195 14.2224 9.7777V14.2222C14.2224 14.6937 14.0351 15.1459 13.7017 15.4793C13.3683 15.8127 12.9161 16 12.4446 16H1.7778C1.3063 16 0.854107 15.8127 0.520705 15.4793C0.187303 15.1459 0 14.6937 0 14.2222V3.55541Z"
        fill="#071E0D"
        fill-opacity="0.65"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_3223">
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
const EditIconAfter = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1_3316)">
      <path
        d="M11.8161 0.260258C11.9828 0.0936147 12.2089 0 12.4446 0C12.6803 0 12.9064 0.0936147 13.073 0.260258L15.7397 2.92696C15.9064 3.09365 16 3.3197 16 3.55541C16 3.79111 15.9064 4.01717 15.7397 4.18386L7.73965 12.184C7.57299 12.3507 7.34693 12.4444 7.1112 12.4444H4.4445C4.20875 12.4444 3.98265 12.3508 3.81595 12.1841C3.64925 12.0173 3.5556 11.7913 3.5556 11.5555V8.8888C3.55565 8.65307 3.64933 8.42702 3.81605 8.26035L11.8161 0.260258ZM5.3334 9.25681V10.6666H6.74319L13.8544 3.55541L12.4446 2.14561L5.3334 9.25681ZM0 3.55541C0 3.08391 0.187303 2.63172 0.520705 2.29831C0.854107 1.96491 1.3063 1.77761 1.7778 1.77761H6.2223C6.45805 1.77761 6.68414 1.87126 6.85084 2.03796C7.01754 2.20466 7.1112 2.43076 7.1112 2.66651C7.1112 2.90226 7.01754 3.12835 6.85084 3.29506C6.68414 3.46176 6.45805 3.55541 6.2223 3.55541H1.7778V14.2222H12.4446V9.7777C12.4446 9.54195 12.5382 9.31586 12.7049 9.14916C12.8716 8.98246 13.0977 8.8888 13.3335 8.8888C13.5692 8.8888 13.7953 8.98246 13.962 9.14916C14.1287 9.31586 14.2224 9.54195 14.2224 9.7777V14.2222C14.2224 14.6937 14.0351 15.1459 13.7017 15.4793C13.3683 15.8127 12.9161 16 12.4446 16H1.7778C1.3063 16 0.854107 15.8127 0.520705 15.4793C0.187303 15.1459 0 14.6937 0 14.2222V3.55541Z"
        fill="#36D66B"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_3316">
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
function InvoiceDetails({data, selectedAddress, setSelectedAddress, formData, setFormData}) {
  const [isLoading, setIsLoading] = useState(false);
  const idToken = useSelector((state) => state.auth.idToken);
  const [userAddress, setUserAddress] = useState([]);
  const [activeAddress, setActiveAddress] = useState(false);
  const fetchData = async () => {
    setIsLoading(true);
    const data = await getUserAddress(idToken);
    setUserAddress(data);
    setSelectedAddress(data[0]?.id || null);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const [isAddAddressModalOpen, setIsAddAddressModalOpen] = useState(false);

  const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false);

  const [addressId, setaddressId] = useState();
  const [idx, setIdx] = useState(null);

  const openAddAddressModal = () => {
    setIsAddAddressModalOpen(true);
  };

  const closeAddAddressModal = () => {
    setIsAddAddressModalOpen(false);
  };

  const openEditAddressModal = (id, idx) => {
    setaddressId(id);
    setIdx(idx);
    setIsEditAddressModalOpen(true);
  };

  const closeEditAddressModal = () => {
    setIsEditAddressModalOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const options = country.map((item) => {
    return {
      value: item.country,
      label: item.country,
    };
  });
  const userDetails = useSelector((state) => state.user);
  // const [formData, setFormData] = useState({
  //   first_name: userDetails.first_name,
  //   last_name: userDetails.last_name,
  //   email: userDetails.email,
  //   country: userDetails.country,
  // });
  const [countryName, setCountryName] = useState({label: userDetails.country, value: userDetails.country});
  const handleCountryChange = (selectedOption) => {
    setCountryName(selectedOption);
    setFormData((prevData) => ({
      ...prevData,
      country: selectedOption.value,
    }));
  };

  useEffect(() => {
    setCountryName({label: userDetails.country, value: userDetails.country});
  }, [userDetails]);

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [userDetail, setuserDetail] = useState([]);
  const userData = useSelector((state) => state.user);
  useEffect(() => {
    setuserDetail(userData);
  }, [userData]);

  return (
    <>
      <div className="invoicedetails">
        {isLoading && <LoaderOverlay />}
        <div className="invoicedetails_header">
          <h4>Invoice Details</h4>
          {/* <div
            className="add_address_btn"
            onClick={openAddAddressModal}
          >
            <img
              src={AddIcon}
              alt="plus icon"
            />
            <p>Add New Address</p>
          </div> */}
        </div>

        <div className="address_containers">
          {/* {userAddress?.length > 0 && (
            <>
              {(() => {
                const addressContainers = [];
                for (let i = 0; i < userAddress.length; i++) {
                  addressContainers.push(
                    <AddressContainer
                      setSelectedAddress={setSelectedAddress}
                      selectedAddress={selectedAddress}
                      key={i}
                      data={userAddress[i]}
                      openEditAddressModal={openEditAddressModal}
                      idx={i}
                      setActiveAddress={setActiveAddress}
                      activeAddress={activeAddress}
                    />,
                  );
                }
                return addressContainers;
              })()}
            </>
          )} */}
          <div className="account_input">
            <label
              htmlFor="firstName"
              className="label_black"
            >
              First Name
            </label>
            <br />
            <div className={`account_input_wrapper `}>
              <input
                disabled={userDetail?.first_name?.trim() !== ""}
                className="account_details_input"
                type="text"
                value={formData.first_name}
                placeholder="First Name"
                name="first_name"
                required
                onChange={handleChange}
                // disabled={editMode}
              />
            </div>
          </div>
          <div className="account_input">
            <label htmlFor="lastName">Last Name</label>
            <br />
            <div className={`account_input_wrapper`}>
              <input
                disabled={userDetail?.last_name?.trim() !== ""}
                className="account_details_input"
                type="text"
                placeholder="Last Name"
                value={formData.last_name}
                name="last_name"
                onChange={handleChange}
                // disabled={editMode}
              />
            </div>
          </div>
          <div className="account_input">
            <label htmlFor="lastName">Email</label>
            <br />
            <div className={`account_input_wrapper`}>
              <input
                disabled={userDetail?.email?.trim() !== ""}
                className="account_details_input"
                type="text"
                placeholder="Email Address"
                value={formData.email}
                name="email"
                required
                onChange={handleChange}
                // disabled={editMode}
              />
            </div>
          </div>
          <div className="account_input country_selector">
            <label htmlFor="country">Country</label>
            <br />
            <div className={`account_input_wrapper country `}>
              <Select
                isSearchable={true}
                className="react-select-container"
                classNamePrefix="react-select"
                options={options}
                styles={customStyles}
                value={countryName}
                onChange={handleCountryChange}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                name="selectedCountry"
                id="country"
                isDisabled={userDetail?.country !== null && userDetail?.country?.trim() !== ""}
              />
            </div>
          </div>
        </div>
      </div>

      {isAddAddressModalOpen ? (
        <div className="modal">
          <div className="modal_overlay">
            <AddAddressModal
              closeAddAddressModal={closeAddAddressModal}
              fetchData={fetchData}
            />
          </div>{" "}
        </div>
      ) : null}

      {isEditAddressModalOpen ? (
        <div className="modal">
          <div className="modal_overlay">
            <EditAddressModal
              addressId={addressId}
              data={userAddress[idx]}
              setUserAddress={setUserAddress}
              closeEditAddressModal={closeEditAddressModal}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default InvoiceDetails;

const AddressContainer = ({openEditAddressModal, data, idx, selectedAddress, setSelectedAddress}) => {
  const user = useSelector((state) => state.user);

  const {first_name, last_name, email, contact, country_code} = user;

  return (
    <div
      className={`address_container ${selectedAddress === data.id && "active_address"}`}
      onClick={() => (setSelectedAddress ? setSelectedAddress(data.id) : null)}
    >
      <div className="address_container_header">
        <div className="address_container_header_left">
          <input
            disabled
            type="radio"
            id="radio_button"
            name="radio-address-group"
            style={{accentColor: selectedAddress === data.id ? "red" : ""}}
            checked={selectedAddress === data.id}
            // onClick={(e) => setActiveAddress(e.target.checked ? true : false)}
          />
          <p>{data?.name}</p>
        </div>
      </div>

      <div className={`address_container_details ${selectedAddress === data.id && "active_address"}`}>
        <div className="address_container_details_left">
          <p>{data?.email}</p>
          {/* <p>{data?.number}</p> */}
          <p>
            {/* {country_code} */}
            {data?.number}
          </p>
        </div>
        <div className="address_container_details_right">
          <p>
            {data?.apartment_no} <br></br>
            {data?.street_address} {data?.city} {data?.zip_code}
            <br></br>
            {data?.country}
          </p>
        </div>
        <div
          onClick={() => openEditAddressModal(data.id, idx)}
          className="address_container_header_right"
        >
          <p>Edit Address</p>
          {selectedAddress === data.id && "active_address" ? EditIconAfter : EditIcon}
        </div>
      </div>
    </div>
  );
};
