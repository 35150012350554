import React, {useState} from "react";
import {useTable} from "react-table";
import "./ReactTable.scss";
import CaretLeftIcon from "../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../assets/icons/caret-right.svg";

const ReactTable = ({columns, data}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 10; // Number of items per page

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, gotoPage} = useTable({
    columns,
    data,
  });

  const totalItems = rows.length;
  const totalPages = Math.ceil(totalItems / pageSize);

  const handleNext = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
  };

  const handlePrevious = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages - 1);
  };

  const getPageItems = () => {
    const startIndex = currentPage * pageSize;
    const endIndex = Math.min(startIndex + pageSize, totalItems);
    return rows.slice(startIndex, endIndex);
  };
  const [gotoPageInput, setGotoPageInput] = useState();

  const handleGotoPage = () => {
    const pageNumber = parseInt(gotoPageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber - 1);
      setGotoPageInput("");
    }
  };

  return (
    <div className="main_table">
      <div className="table_container">
        <table
          {...getTableProps()}
          className="table"
        >
          <thead className="table_head">
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="head_row"
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="table_tbody"
          >
            {getPageItems().map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="tbody_row"
                  key={index}
                >
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {totalPages > 1 && (
        <div className="pagination_container">
          <button
            onClick={handleFirstPage}
            disabled={currentPage === 0}
          >
            <img
              src={CaretLeftIcon}
              alt="left-arrow"
            />
          </button>
          <button
            onClick={handlePrevious}
            disabled={currentPage === 0}
          >
            Previous
          </button>
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentPage(index)}
              className={currentPage === index ? "active" : "page_numbers"}
            >
              {index < 10 ? `0${index + 1}` : index + 1}
            </button>
          ))}
          <button
            onClick={handleNext}
            disabled={currentPage === totalPages - 1}
          >
            Next
          </button>
          <button
            onClick={handleLastPage}
            disabled={currentPage === totalPages - 1}
          >
            <img
              src={CaretRightIcon}
              alt="right-arrow"
            />
          </button>
          <div className="goto-page-input">
            <input
              type="number"
              placeholder="Go to Page"
              value={gotoPageInput}
              onChange={(e) => setGotoPageInput(e.target.value)}
            />
            <button
              className="goToButton"
              onClick={handleGotoPage}
            >
              Go
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReactTable;
