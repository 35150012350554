import {all, call, put, takeEvery} from "redux-saga/effects";
import {authenticate, deAuthenticate, setAuthenticationStatus, setAuthenticationSuccess, setAuthenticationFailure} from "../../reducers/authSlice";
import {getUser, setUser} from "../../reducers/userSlice";
import {authApi} from "../apis/authApi";

function* authUser({payload}) {
  try {
    const response = yield call(authApi, payload);

    if (response.status < 202) {
      yield put(setAuthenticationSuccess(response));

      yield put(getUser(response.data.idToken));
    } else {
      yield put(setAuthenticationFailure(response));
    }
  } catch (error) {
    yield put(setAuthenticationFailure(error));
  }
}
function* getUserFun({payload}) {
  
  try {
    yield put(getUser(payload.data.idToken));
  } catch (error) {
    yield put(setAuthenticationFailure(error));
  }
}

function* deAuthUser() {}

export default function* authSaga() {
  yield all([takeEvery(authenticate.type, authUser), takeEvery(deAuthenticate.type, deAuthUser), takeEvery(setAuthenticationSuccess.type, getUserFun)]);
}
