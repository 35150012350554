import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {returnMessages} from "../../store/reducers/message";
import SuccessModal from "../../reusableComponents/SuccessModal";
import {useNavigate} from "react-router-dom";
import {veriffStatus} from "../../utils/apis/payment";
import {returnErrors} from "../../store/reducers/error";
import ErrorModal from "../../reusableComponents/ErrorModal";

export const KycVerification = () => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/");
  };
  const idToken = useSelector((state) => state.auth.idToken);
  const [kycStatus, setKycStatus] = useState(null);
  const dispatch = useDispatch();
  const verifySumsub = async (idToken) => {
    const sumsubResponse = await veriffStatus(idToken);

    if (sumsubResponse?.status < 399) {
      setKycStatus(sumsubResponse?.data?.review_status);
    } else {
      dispatch(returnErrors("Something went wrong", sumsubResponse?.status));
    }
  };
  useEffect(() => {
    verifySumsub(idToken);
  }, []);
  console.log(kycStatus,"kycStatus")
  return (
    <div>
      {kycStatus === null ? (
        <ErrorModal
          handleNavigation={() => navigate("/verification-kyc")}
          desc={"KYC verification is pending. Please complete KYC to proceed."}
          btnText={"Close"}
        />
      ) : (
        <SuccessModal
          handleNavigation={handleNavigation}
          desc={"KYC verification is completed. Thank you for providing the required information."}
          btnText={"Close"}
        />
      )}
    </div>
  );
};
