import React, {useEffect, useMemo, useState} from "react";
import "./style.scss";
import {useTable, usePagination} from "react-table";

import CaretLeftIcon from "../../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../../assets/icons/caret-right.svg";
import HamburgerIcon from "../../../assets/icons/hamburger.svg";
import HamburgerBlueIcon from "../../../assets/icons/hamburger-blue.svg";
import CaretDownWhiteIcon from "../../../assets/icons/caret-down-white.svg";
import DollarCircleIcon from "../../../assets/icons/dollar-circle.svg";
import DollarBoxIcon from "../../../assets/icons/dollar-box.svg";
import DiscountCircleIcon from "../../../assets/icons/discount-circle.svg";
import TickBlueIcon from "../../../assets/icons/tick-blue.svg";
import CaretDownGrayIcon from "../../../assets/icons/caret-down-gray.svg";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import ChartRepGain from "../../../assets/images/chart-rep-gain.png";
import ChartRepLoss from "../../../assets/images/chart-rep-loss.png";
import {useSelector} from "react-redux";
import moment from "moment";
import ErrorModal from "../../../reusableComponents/ErrorModal";
import {useNavigate} from "react-router-dom";
import {Add, Delete, Edit} from "@mui/icons-material";
import {Pagination} from "@mui/material";
import {convertTimestamp} from "../../../utils/helpers/string";

function TradingActivity() {
  const [isDropdown, setDropdown] = useState(false);
  const accountsData = useSelector((state) => state.account.accountMetrics);
  const [Ametrics, setAmetrics] = useState(accountsData?.data?.account_metrics?.deals || []);
  console.log(Ametrics, "AmetricsAmetrics");
  useEffect(() => {
    setAmetrics(accountsData?.data?.account_metrics?.deals || []);
  }, [accountsData]);

  return (
    <div className="tradingactivity-container">
      <div className="tradingactivity-subcontainer">
        <div className="tradingactivity-container_header">
          {/* <h2>Trading Activity</h2> */}
          <h2>Trading History</h2>
          {/* <img
            src={isDropdown ? HamburgerBlueIcon : HamburgerIcon}
            alt="ham icon"
            onClick={() => setDropdown((prev) => !prev)}
          /> */}
        </div>
        {isDropdown ? (
          <div className="tradingactivity-dropdown">
            <h4>SL/TP Format</h4>
            <button>
              <p>Profit</p>
              <img
                src={CaretDownWhiteIcon}
                alt=""
              />
            </button>
            <ul>
              <li>
                <div>
                  <img
                    src={DollarCircleIcon}
                    alt=""
                  />
                  <p>Buy</p>
                </div>
                <img
                  src={TickBlueIcon}
                  alt=""
                />
              </li>
              <li>
                <div>
                  <img
                    src={DollarBoxIcon}
                    alt=""
                  />
                  <p>Sell</p>
                </div>
                <img
                  src={TickBlueIcon}
                  alt=""
                />
              </li>
              <li>
                <div>
                  <img
                    src={DollarBoxIcon}
                    alt=""
                  />
                  <p>Withdrawal</p>
                </div>
                <img
                  src={TickBlueIcon}
                  alt=""
                />
              </li>
              <li>
                <div>
                  <img
                    src={DollarCircleIcon}
                    alt=""
                  />
                  <p>Deposite</p>
                </div>
                <img
                  src={TickBlueIcon}
                  alt=""
                />
              </li>
              <li>
                <div>
                  <img
                    src={DiscountCircleIcon}
                    alt=""
                  />
                  <p>Interest</p>
                </div>
                <img
                  src={TickBlueIcon}
                  alt=""
                />
              </li>
            </ul>
          </div>
        ) : (
          <></>
        )}
        <div className="tradingactivity-container_table">
          <Table Ametric={Ametrics} />
        </div>
      </div>
    </div>
  );
}

export default TradingActivity;

function Table({Ametric}) {
  const data = Ametric;
  const navigate = useNavigate();
  const handleEdit = (row) => {
    navigate("/trademate/create-trade", {state: {data: row.row.original}});
  };
  const [paramID, setParamID] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const handleDelete = (row) => {
    setConfirmationModal(true);
    setParamID(row?.row?.original?.id);
  };

  const handleConfirmDelete = async () => {
    try {
      // const res = await deleteJournalTraderRequest(idToken, paramID);
      //
    } catch (error) {}
  };

  const columns = useMemo(
    () => [
      {
        Header: "Order",
        accessor: "order",
      },
      // {
      //   Header: "Action",
      //   accessor: "action",
      // },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({value}) => (
          <div className="column_one_wrapper">
            <div className={value === "Buy" ? "profit" : "loss"}>{value}</div>
          </div>
        ),
      },
      // {
      //   Header: "Deal",
      //   accessor: "deal",
      // },
      {
        Header: "Profit/Loss",
        accessor: "profit",
        Cell: ({value}) => (
          <div className="column_one_wrapper">
            <div className={value < 0 ? "loss" : value > 0 ? "profit" : null}>{`$ ${Number(value)?.toFixed(2)}`}</div>
          </div>
        ),
      },
      {
        Header: "Commission",
        accessor: "commission",
      },

      {Header: "Position", accessor: "position"},
      // {Header: "Profit", accessor: "profit"},
      
      {Header: "Swap", accessor: "swap"},
      // { Header: "Stop Loss", accessor: "stopLoss" },
      {Header: "Symbol", accessor: "symbol"},
      // {Header: "Price", accessor: "price"},
      {
        Header: "Price",
        accessor: "price",
        Cell: ({value}) => (
          <div className="column_one_wrapper">
            <div>{`$ ${Number(value)?.toFixed(2)}`}</div>
          </div>
        ),
      },
      {
        Header: "Volume",
        accessor: "volume",
      },
      {
        Header: (
          <Tooltip
            title="SymbolQuoteCurrency"
            placement="top"
            arrow
          >
            <span>S Q C</span>
          </Tooltip>
        ),
        accessor: "symbolQuoteCurrency",
      },
      {
        Header: (
          <Tooltip
            title="SymbolContractSize"
            placement="top"
            arrow
          >
            <span>S C S</span>
          </Tooltip>
        ),
        accessor: "symbolContractSize",
      },
      {
        Header: (
          <Tooltip
            title="SymbolContractSize"
            placement="top"
            arrow
          >
            <span>S B C</span>
          </Tooltip>
        ),
        accessor: "symbolBaseCurrency",
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: ({value}) => (
          <div className="date_format">
            {/* <div>{moment(value).format("DD MMM YYYY")} </div>
            <div>{moment(value).format("HH:mm:ss")}</div> */}
            <div>{convertTimestamp(value)}</div>
          </div>
        ),
      },
      {
        Header: "Entry",
        accessor: "entry",
        Cell: ({value}) => (
          <div className="date_format" style={{paddingLeft:"20px"}}>
            <div>{value || "NA"}</div>
          </div>
        ),
      },
      {Header: "Login", accessor: "login"},
      // {
      //   Header: "Actions",
      //   accessor: "id",
      //   Cell: ({cell}) => {
      //     return (
      //       <div
      //         className=""
      //         style={{display: "block !important"}}
      //       >
      //         <p onClick={() => handleEdit(cell)}>
      //           <Tooltip
      //             title={<p style={{color: "white"}}>Create Journal</p>}
      //             placement="top"
      //             arrow
      //           >
      //             <Edit style={{color: "#071E0D", cursor: "pointer"}} />
      //           </Tooltip>
      //         </p>
      //       </div>
      //     );
      //   },
      // },
      // { Header: "Take Profit", accessor: "takeProfit" },
      // {
      //   Header: "",
      //   accessor: "chartRep",
      //   Cell: ({ value }) =>
      //     value === "profit" ? (
      //       <AreaPreviewChart
      //         isGain={+1}
      //         outerHeight={40}
      //         innerWidth={150}
      //         innerHeight={90}
      //         style={{ transform: "translate(-10px,-30px)" }}
      //       />
      //     ) : (
      //       <AreaPreviewChart
      //         isGain={-1}
      //         outerHeight={40}
      //         innerWidth={150}
      //         innerHeight={90}
      //         style={{ transform: "translate(-10px,-30px)" }}
      //       />
      //     ),
      // },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    pageOptions,
    state: {pageIndex, pageSize},
    rows,
    prepareRow,
  } = useTable({columns, data}, usePagination);

  const [currentPage, setCurrentPage] = useState(1);
  const pageButtons = [];

  const [gotoPageInput, setGotoPageInput] = useState("");
  const handlePageChange = (event, newPage) => {
    gotoPage(newPage - 1); // Adjust for 0-based index used by react-table
    setCurrentPage(newPage);
  };

  const handleGotoPage = () => {
    const pageNumber = parseInt(gotoPageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= pageCount) {
      gotoPage(pageNumber - 1);
      setCurrentPage(pageNumber);
      setGotoPageInput("");
    }
  };

  // for (let i = 1; i <= pageCount; i++) {
  //   pageButtons?.push(
  //     <button
  //       key={i}
  //       className={`${i === currentPage ? "button-active" : "button-inactive"}`}
  //       onClick={() => {
  //         gotoPage(i - 1);
  //         setCurrentPage(i);
  //       }}
  //     >
  //       {i < 10 ? `0${i}` : i}
  //     </button>,
  //   );
  // }

  return (
    <>
      <div className="table_scroll">
        <table {...getTableProps()}>
          <thead>
            {headerGroups?.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup?.headers?.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <span>
                      {column.render("Header")}
                      {column["Header"] !== "" ? (
                        <img
                          src={CaretDownGrayIcon}
                          alt=""
                        />
                      ) : (
                        <></>
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page?.map((row) => {
              prepareRow(row);
              return (
                <tr {...row?.getRowProps()}>
                  {row.cells?.map((cell, index) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* {pageOptions.length > 1 && (
        <div className="pagination_main">
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            boundaryCount={1}
          />
        </div>
      )} */}
      {pageOptions.length > 1 && (
        <div className="economiccalender-pagination pagination">
          <img
            src={CaretLeftIcon}
            alt="left-arrow"
          />
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </button>
          {pageOptions.map((pageNumber, index) => (
            <>
              <button
                key={index}
                onClick={() => gotoPage(index)}
                className={pageIndex === index ? "btn-active" : "non-active"}
              >
                {pageNumber < 10 ? `0${pageNumber + 1}` : pageNumber}
              </button>
            </>
          ))}
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </button>
          <img
            src={CaretRightIcon}
            alt="right-arrow"
          />
          <div className="goto-page-input">
            <input
              type="number"
              placeholder="Go to Page"
              value={gotoPageInput}
              onChange={(e) => setGotoPageInput(e.target.value)}
            />
            <button
              className="goToButton"
              onClick={handleGotoPage}
            >
              Go
            </button>
          </div>
        </div>
      )}
    </>
  );
}
