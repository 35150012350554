import axios from "axios";
import {baseUrl} from "../../../utils/apis";
const urls = {login: `${baseUrl}/auth/login/`};

const authApi = async (payload) => {
  const {email, password} = payload;
  
  try {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    const response = await axios.post(urls.login, formData);

    return response;
  } catch (err) {
    console.error("error", err);
    throw err;
  }
};

export {authApi};
