import React, {useEffect, useState} from "react";
import "./style.scss";
import RequestToChange from "../RequestToChange";
import InvoiceDetails from "../../FundingEvaluation/PaymentModule/InvoiceDetails";
import Banks from "./Banks";
import VerifyNow from "./VerifyNow";
import Warning from "./Warning";
import RecipientBankDetails from "../RecipientBankDetails";
import {useDispatch, useSelector} from "react-redux";
import {sumsubVerification, veriffStatus} from "../../../utils/apis/payment";
import SumsubModal from "./SumsubModal";
import {getPayoutList} from "../../../store/reducers/accountSlice";
import LoaderOverlay from "../../../reusableComponents/Loader";
import {getSumsubToken} from "../../../store/reducers/payoutReducer";
import {returnErrors} from "../../../store/reducers/error";

function Verification({kycEligible}) {
  const [value, setValue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isbankDetailsModal, setBankDetailsModal] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const openBankDetailsModal = () => {
    setBankDetailsModal(true);
  };

  const closeBankDetailsModal = () => {
    setValue(false);
    // setBankDetailsModal(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const idToken = useSelector((state) => state.auth.idToken);
  const [kycStatus, setKycStatus] = useState(null);

  const verifySumsub = async (idToken) => {
    const sumsubResponse = await veriffStatus(idToken);
    
    if (sumsubResponse?.status < 399) {
      setKycStatus(sumsubResponse?.data?.review_status);
    } else {
      dispatch(returnErrors("Something went wrong", sumsubResponse?.status));
    }
  };
  useEffect(() => {
    verifySumsub(idToken);
  }, []);

  const [isVerificationModal, setVerificationModal] = useState(false);
  const [processError, setProcessError] = useState({
    paymentProcess: false,
    kycProcess: false,
  });

  const openVerificationModal = () => {
    setVerificationModal(true);
  };

  const getAccessToken = () => {
    // return
    setLoading(true);
    dispatch(getSumsubToken())
      .then((res) => {
        window.location.replace(res.verification.url);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isVerificationModal) {
      getAccessToken();
      setVerificationModal(false);
    }
  }, [isVerificationModal]);
  const closeVerificationModal = () => {
    setVerificationModal(false);
    setProcessError((prev) => ({...prev, kycProcess: true}));
  };
  const [refreshBanksDetails, setRefreshBanksDetails] = useState(false);

  return (
    <>
      <div className="verification">
        {isLoading && <LoaderOverlay />}
        <div className="grid_container">
          {/* <div className="col1">
            <RequestToChange text={"KYC Details"} />
          </div> */}
          <div className="col2">
            {/* <Banks refreshBanksDetails={refreshBanksDetails} setRefreshBanksDetails={setRefreshBanksDetails} value={value} setValue={setValue} setIsLoading={setIsLoading} /> */}
            <>
              <VerifyNow
                kycStatus={kycStatus}
                openVerificationModal={openVerificationModal}
                loading={loading}
              />
            </>

            {/* <Warning /> */}
          </div>
        </div>
      </div>

      {
        <RecipientBankDetails
          refreshBanksDetails={refreshBanksDetails}
          setRefreshBanksDetails={setRefreshBanksDetails}
          value={value}
          setValue={setValue}
          closeBankDetailsModal={closeBankDetailsModal}
        />
      }
      {/* {isbankDetailsModal ? <RecipientBankDetails closeBankDetailsModal={closeBankDetailsModal} /> : null}{" "} */}
    </>
  );
}

export default Verification;
