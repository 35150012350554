import React, {useEffect, useState} from "react";
import MainHeader from "../../../../layouts/pageLayout/MainHeader";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import PayoutSplit from "./PayoutSplit";
import AffiliatePayout from "./AffiliatePayout";
import Tabination from "../../../../reusableComponents/Tabination";

const PayoutIndex = () => {
  const location = useLocation();

  const path = location.pathname.split("/")[2];
  const [activeTab, setActiveTab] = useState(0);
  const items = [
    {
      name: "Trading Account",
      path: "payout-split",
    },
    {
      name: "Affiliate",
      path: "affiliate-payout",
    },
  ];
  useEffect(() => {
    switch (path) {
      case "payout-split":
        setActiveTab(0);
        break;
      case "affiliate-payout":
        setActiveTab(1);
        break;
      default:
        break;
    }
  }, [path]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mainPageHolder">
      <div className="mainPageHolder-T-C">
        <div className="mainPageHolder-T">
          <MainHeader
            headerName="Withdrawals"
            showTabs={false}
            tabItems={items}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      </div>
      <div className="mainPageHolder-B">
        <div className="all_header">
          <h2>Payout Request</h2>
        </div>
        <Tabination
          variant={3}
          tabItems={items}
          activeTab={activeTab}
          style={{margin: "0 14px"}}
          className="tabination_all"
        />
        <Routes>
          <Route
            path="/"
            element={
              <Navigate
                to="payout-split"
                replace={true}
              />
            }
          />
          <Route
            path="payout-split"
            element={<PayoutSplit />}
          />
          <Route
            path="affiliate-payout"
            element={<AffiliatePayout />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default PayoutIndex;
