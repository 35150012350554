import React from "react";
import "./style.scss";
import VerifiedIcon from "../../assets/icons/tick-green-circle.svg";

const Accordion = ({title, children, icon, isOpen, onToggle, is_verified}) => {
  
  const toggleAccordion = () => {
    onToggle(title);
  };

  return (
    <div className={`accordion ${isOpen ? "open" : ""}`}>
      <div
        className={`accordion-header icon ${isOpen ? "open" : ""}`}
        onClick={toggleAccordion}
      >
        <div className="title">
          {icon}
          {title}
        </div>
        <div className="verified">
          {is_verified && is_verified[0] === true ? (
            <div>
              <img
                src={VerifiedIcon}
                alt={title}
              />
              Verified
            </div>
          ) : null}
        </div>
      </div>
      {
        <div
          className="accordion-content"
          style={isOpen ? {maxHeight: "300px", transition: "all 0.2s"} : {height: "0px", padding: "0 20px"}}
        >
          {children}
        </div>
      }
    </div>
  );
};

export default Accordion;
