import React, {useEffect, useState} from "react";
import "./style.scss";

import TradingActivity from "../../../components/AccountMetrics/TradingActivity";
import {useSelector} from "react-redux";
import ErrorModal from "../../../reusableComponents/ErrorModal";
import BarCharts from "./BarChart/BarCharts";
import LoaderOverlay from "../../../reusableComponents/Loader";

function TradingJournal() {
  const accountsData = useSelector((state) => state?.account?.accountMetrics);
  const [errorModalOpen, setErrorModalOpen] = useState(accountsData.error);

  useEffect(() => {
    setErrorModalOpen(accountsData.error);
  }, [accountsData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        {errorModalOpen && (
          <ErrorModal
            desc={accountsData.message}
            setModalOpen={setErrorModalOpen}
            btnText="Cancel"
          />
        )}

        {!accountsData.loading && !accountsData.error ? "" : <LoaderOverlay />}

        <div>
          <TradingActivity />
          {/* <div className="barChart_main">
            <BarCharts title={"Long & Short analysis"} />
            <BarCharts title={"Instrument analysis"} />
            <BarCharts title={"Trading Volume"} />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default TradingJournal;
