import React from "react";
import "./style.scss";
import ResetPasswordForm from "../../../components/Auth/ResetPasswordForm";

function ResetPassword() {
  return (
    <div className="resetpassword-container">
      <div className="resetpassword-left-wrapper">
        <div className="resetpassword-left">
        <ResetPasswordForm />
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
