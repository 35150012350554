import React, {useEffect, useState} from "react";
import "./style.scss";
import Overview from "../../components/Affiliate/Overview";
import AffilateCodes from "../../components/Affiliate/AffiliateCodes";
import Commission from "../../components/Affiliate/Commission";
import {Navigate, Route, Routes} from "react-router-dom";
import Tabination from "../../reusableComponents/Tabination";
import GetALink from "../../components/Affiliate/GetALink/index";
import MainHeader from "../../layouts/pageLayout/MainHeader";
import PushLeads from "../../components/Affiliate/Overview/PushLeads";

function Affiliate() {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const items = [
    {
      name: "Overview",
      path: "overview",
    },
    // {
    //   name: "Affiliate Codes",
    //   path: "affiliate-codes",
    // },
    // {
    //   name: "Commission Payment",
    //   path: "commission-payment",
    // },
    {
      name: "Get a Link",
      path: "get-a-link",
    },
  ];
  // const items = [
  //   {
  //     name: "Overview",
  //     path: "overview",
  //   },
  //   {
  //     name: "Affiliate Codes",
  //     path: "affiliate-codes",
  //   },
  //   {
  //     name: "Commission Payment",
  //     path: "commission-payment",
  //   },
  //   {
  //     name: "Get a Link",
  //     path: "get-a-link",
  //   },
  // ];

  return (
    <>
      <div className="mainPageHolder">
        <div className="mainPageHolder-T-C">
          <div className="mainPageHolder-T">
            <MainHeader
              headerName="Affiliates"
              showTabs={false}
              tabItems={items}
              activeTab={activeTab}
            />
          </div>
        </div>
        <div className="mainPageHolder-B">
          <div className="all_header">
            <h2>Affiliate</h2>
          </div>
          <Tabination
            variant={3}
            tabItems={items}
            activeTab={activeTab}
            style={{margin: "0 14px"}}
            className="tabination_all"
          />
          <div className="affiliate">
            <Routes>
              <Route
                path="/"
                element={
                  <Navigate
                    to="overview"
                    replace={true}
                  />
                }
              />
              <Route
                path="overview"
                element={<Overview />}
              />
              <Route
                path="affiliate-codes"
                element={<AffilateCodes />}
              />
              <Route
                path="commission-payment"
                element={<Commission />}
              />
              <Route
                path="get-a-link"
                element={<GetALink />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}

export default Affiliate;
