export function capitalizeFirstLetter(str) {
  return str.slice(0, 1).toUpperCase() + str.slice(1, str.length);
}

export function convertSnakeCaseToDefault(str) {
  return str
    .split("_")
    .map((item) => capitalizeFirstLetter(item))
    .join(" ");
}

export function convertToMillions(num) {
  const str = num.toString().split("").reverse().join("");
  const parts = str.match(/.{1,3}/g);
  const result = parts.join(",").split("").reverse().join("");
  return result;
}

export function validateLetters(key) {
  const validRegex = /^[a-zA-Z\s]$/;
  return validRegex.test(key) || key === "Backspace";
}

export function validateContact(key) {
  const validRegex = /^[0-9+]+$/;
  return validRegex.test(key) || key === "Backspace";
}

export function validateEmail(email) {
  const validRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return validRegex.test(email);
}

export function validatePassword(password) {
  return password.length >= 8;
}

export function truncateString(str, length) {
  if (str.length <= length) {
    return str;
  } else {
    return str.slice(0, length) + "...";
  }
}

export  const convertTimestamp = (timestamp) => {
  // Convert the string timestamp to a number
  const timestampNumber = Number(timestamp);
  
  // Convert milliseconds to a Date object
  const date = new Date(timestampNumber);

  // Extract date components
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = date.getUTCFullYear();

  // Format to DD-MM-YYYY
  return `${day}-${month}-${year}`;
};