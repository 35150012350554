import React, {useEffect, useState} from "react";
import "./style.scss";
import Select from "react-select";
import EditIcon from "../../../assets/icons/edit-pencil.svg";
import ProfileIcon from "../../../assets/icons/profile.svg";
import CopyIcon from "../../../assets/icons/copy-blue.svg";
import CopyButton from "../../../reusableComponents/CopyButton";
import {useSelector} from "react-redux";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import moment from "moment";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {Link} from "react-router-dom";
import {CopyToClipboard} from "react-copy-to-clipboard";
function AccountDetails() {
  // const [copyToClipboard, setCopyToClipboard] = useState({
  //   value: "",
  //   copied: false,
  // });
  const accountsData = useSelector((state) => state.account.accountMetrics);
  
  const userDetails = useSelector((state) => state.user);
  const [Ametrics, setAmetrics] = useState([]);
  
  useEffect(() => {
    setAmetrics(accountsData?.data || []);
  }, [accountsData]);

  const [eye, setEye] = useState(false);
  const [eye2, setEye2] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [isTooltipOpen1, setTooltipOpen1] = useState(false);
  const [isLoginCopy, setIsLoginCopy] = useState(false);
  const [isTooltipOpen2, setTooltipOpen2] = useState(false);




  const handleTooltipClose1 = () => {
    setTooltipOpen1(false);
  };
  const handleCopyLogin = () => {
    setIsLoginCopy(false);
  };
  const handleCopyClick2 = async (text) => {
    try {
      setTooltipOpen2(true);
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
  };

  const handleTooltipClose2 = () => {
    setTooltipOpen2(false);
  };
  return (
    <div className="accountdetails-container">
      {/* <h2>General Information</h2> */}
      <div className="accountdetails-subcontainer">
        <img
          src={ProfileIcon}
          alt="profile icon"
          className="accountdetails-image_dp"
        />
        <div className="accountdetails-innercontainer">
          <div>
            <h4>{userDetails.first_name + " " + userDetails.last_name}</h4>
            <div style={{display: "flex", flexDirection: "row", alignItems: "end", marginTop: "0", justifyContent: "center"}}>
              <p>{accountsData.data?.login_id}</p>
              <CopyToClipboard text={accountsData.data?.login_id}>
                <div>
                  <Tooltip
                    arrow
                    onClose={handleCopyLogin}
                    open={isLoginCopy}
                    title="Copied Login Id"
                  >
                    {/* <p onClick={() => handleCopyClick1(Ametrics?.password)}> */}
                    <img
                      style={{cursor: "pointer"}}
                      onClick={() => {
                        setIsLoginCopy(true);
                        // handleCopyClick1();
                      }}
                      src={CopyIcon}
                      alt="copy"
                    />
                    {/* </p> */}
                  </Tooltip>
                </div>
              </CopyToClipboard>
            </div>
            <p>{accountsData.data?.phase?.replace("_", " ")}</p>
          </div>
          {/* <Link
            to="/myprofile"
            style={{display: "flex", gap: "5px", alignItems: "center"}}
          >
            <img
              src={EditIcon}
              alt="edit icon"
            />
            <p>Edit</p>
          </Link> */}
        </div>
      </div>
      <div className="accountdetails-subcontainer accountdetails-container_details">
        <div className="accountdetails-groupA">
          <div>
            <h5>Challenge</h5>
            <p>{accountsData.data?.challenge}</p>
          </div>
          <div>
            <h5>Leverage </h5>
            <p
              style={{color: "#36D66B"}}
              className="accountdetails-profit"
            >
              1 : {Ametrics?.account_metrics?.leverage}
            </p>
          </div>
          <div>
            <h5>Server </h5>
            {/* <p>{accountsData.data?.trading_platform}</p> */}
            <p>{accountsData?.data?.server}</p>
          </div>
          <div>
            <h5>Server name</h5>
            {/* <p>{Ametrics.server}</p> */}
            <p>{accountsData?.data?.server}</p>
          </div>
        </div>
        <div className="accountdetails-groupB">
          <div>
            <h5>Investor password</h5>
            <div
              style={{position: "relative", display: "flex", gap: "10px", width: "fit-content", flexDirection: "row", alignItems: ""}}
              className="role-p"
            >
              <div className="password_main">{eye ? Ametrics?.investor_password : "*********"}</div>
              <div onClick={() => setEye(!eye)}>{eye ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}</div>
              <div>
                {/* <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div>
                    <Tooltip
                      arrow
                      onClose={handleTooltipClose}
                      open={isTooltipOpen}
                      title={<span className="custom-tooltip-title">Copied to Clipboard</span>}
                    >
                      <p onClick={() => handleCopyClick(Ametrics?.investor_password)}>
                        <img
                          src={CopyIcon}
                          alt="copy"
                        />
                      </p>
                    </Tooltip>
                  </div>
                </ClickAwayListener> */}
                <CopyToClipboard text={Ametrics?.investor_password}>
                  <div>
                    <Tooltip
                      arrow
                      onClose={handleTooltipClose1}
                      open={isTooltipOpen1}
                      title="Copied Investor password"
                    >
                      {/* <p onClick={() => handleCopyClick1(Ametrics?.password)}> */}
                      <img
                        style={{cursor: "pointer"}}
                        onClick={() => {
                          setTooltipOpen1(true);
                          // handleCopyClick1();
                        }}
                        src={CopyIcon}
                        alt="copy"
                      />
                      {/* </p> */}
                    </Tooltip>
                  </div>
                </CopyToClipboard>
              </div>
            </div>
          </div>

          <div>
            <h5>Master password </h5>
            <div
              style={{position: "relative", display: "flex", gap: "10px", width: "fit-content", flexDirection: "row", alignItems: ""}}
              className="role-p"
            >
              <div className="password_main">{eye2 ? Ametrics?.password : "*********"}</div>
              <div onClick={() => setEye2(!eye2)}>{eye2 ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}</div>
              <div>
                {/* <ClickAwayListener onClickAway={handleTooltipClose1}>
                  <div>
                    <Tooltip
                      arrow
                      onClose={handleTooltipClose1}
                      open={isTooltipOpen1}
                      title="Copied to Clipboard"
                    >
                      <p onClick={() => handleCopyClick1(Ametrics?.password)}>
                        <img
                          src={CopyIcon}
                          alt="copy"
                        />
                      </p>
                    </Tooltip>
                  </div>
                </ClickAwayListener> */}
                <CopyToClipboard text={Ametrics?.password}>
                  <div>
                    <Tooltip
                      arrow
                      onClose={handleTooltipClose2}
                      open={isTooltipOpen2}
                      title="Copied Master password"
                    >
                      {/* <p onClick={() => handleCopyClick1(Ametrics?.password)}> */}
                      <img
                        style={{cursor: "pointer"}}
                        onClick={() => setTooltipOpen2(true)}
                        src={CopyIcon}
                        alt="copy"
                      />
                      {/* </p> */}
                    </Tooltip>
                  </div>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div>
            <h5>Starting balance </h5>
            <p>$ {Ametrics?.starting_balance?.toLocaleString("en-US")}</p>
          </div>
          <div>
            <h5>Account start date</h5>
            <p>{moment(Ametrics.start_date).format("ll")}</p>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default AccountDetails;
