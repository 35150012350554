import { all, call, put, takeEvery } from "redux-saga/effects";

import {
  setUser,
  getUser,
  patchUser,
  patchUserSuccess,
  patchUserFailure,
} from "../../reducers/userSlice";
import { getUserDetails, updateUserDetailsRequest } from "../../../utils/apis";

function* fetchUserDetails(action) {
  
  try {
    const userDetails = yield call(getUserDetails, action.payload);
    yield put(setUser(userDetails));
  } catch (error) {
  }
}

function* updateUserDetails(action) {
  try {
    const updatedDetails = yield call(updateUserDetailsRequest, action.payload);
    yield put(patchUserSuccess());
    yield put(setUser(updatedDetails));
  } catch (err) {
    yield put(patchUserFailure(err));
  }
}

export default function* userSaga() {
  yield all([
    takeEvery(getUser.type, fetchUserDetails),
    takeEvery(patchUser.type, updateUserDetails),
  ]);
}
