import {Link as RouterLink} from "react-router-dom";
// @mui
import {styled} from "@mui/material/styles";
import {Button, Typography, Container, Box} from "@mui/material";
import img from "../../assets/images/error404.jpg";
import "./error.scss";

// ----------------------------------------------------------------------

const StyledContent = styled("div")(({theme}) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
  fontFamily: "",
}));

// ----------------------------------------------------------------------

const ErrorPage = () => {
  return (
    <>
      <Container>
        <StyledContent sx={{textAlign: "center", alignItems: "center"}}>
          <Typography
            variant="h3"
            paragraph
            style={{
              fontSize: "2rem",
              fontWeight: "900",
              marginBottom: "40px",
            }}
          >
            Sorry, page not found!
          </Typography>

          <Typography sx={{color: "text.secondary"}}>Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.</Typography>

          <Box
            component="img"
            src={img}
            sx={{height: 260, mx: "auto", my: {xs: 5, sm: 10}}}
          />

          <Button
            to="/"
            size="large"
            variant="outlined"
            component={RouterLink}
          >
            Go to Home
          </Button>
        </StyledContent>
      </Container>
    </>
  );
};
export default ErrorPage;
