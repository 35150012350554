import React, {useEffect, useState} from "react";
import "./style.scss";
import {usePagination, useTable} from "react-table";
import CopyIcon from "../../../assets/icons/copy.svg";
import CalendarIcon from "../../../assets/icons/calender.svg";
import Search from "../../../assets/icons/search.svg";
import CaretLeftIcon from "../../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../../assets/icons/caret-right.svg";
import Calendar from "../../../reusableComponents/Calendar";
import {useDispatch, useSelector} from "react-redux";
import {getAffiliateData} from "../../../store/reducers/affiliateSlice";
import plus from "../../../assets/icons/AddIcon.svg";
import minus from "../../../assets/icons/minus.svg";
import LoaderOverlay from "../../../reusableComponents/Loader";

function AffilateCodes() {
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  const affiliate = useSelector((state) => state?.affiliate?.affiliate || []);

  

  useEffect(() => {
    dispatch(getAffiliateData(idToken));
  }, [idToken]);

  const columns = React.useMemo(
    () => [
      {Header: "Commission %", accessor: "percentage"},
      {Header: "%(Repeat)", accessor: "percentage_repeat"},
      {
        Header: "Code",
        accessor: "code",
        Cell: ({value}) => (
          <div className="column_wrapper">
            {value}{" "}
            <img
              src={CopyIcon}
              alt=""
            />{" "}
          </div>
        ),
      },
      {Header: "URL", accessor: "url", Cell: ({value}) => <button className="tracking_btn">{value}</button>},
      {Header: "PushLeads", accessor: "pushed_leads"},
      {Header: "Conversions", accessor: "consumed_by_count"},
      {Header: "Referred List", accessor: "referredList", Cell: () => <button className="view_details_btn">View Details</button>},
    ],
    [],
  );
  const data = affiliate?.data || [];
  return (
    <>
      <div className="affiliate_codes">
        <div className="affiliate_table">
          <div className="table_scroll_wrapper">
            {affiliate && (
              <Table
                columns={columns}
                data={data}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AffilateCodes;

const Table = ({data, columns}) => {
  const [filter, setFilter] = useState("All");
  const [isCalender, setIsCalender] = useState(false);
  const [date, setDate] = useState(new Date());

  // const data = React.useMemo(
  //   () => [
  //     { id: 1, status: "active", date: "2023-07-19", commission: "$150", repeat: "Yes", code: "ABC123", url: "https://example.com/abc", pushLeads: 25, conversions: 15, referredList: "John, Jane, Smith" },
  //     { id: 2, status: "inactive", date: "2023-07-18", commission: "$80", repeat: "No", code: "XYZ456", url: "https://example.com/xyz", pushLeads: 10, conversions: 8, referredList: "Alice, Bob, Michael" },
  //     { id: 3, status: "active", date: "2023-07-17", commission: "$120", repeat: "Yes", code: "DEF789", url: "https://example.com/def", pushLeads: 18, conversions: 12, referredList: "Sarah, David, Emily" },
  //     { id: 4, status: "active", date: "2023-07-16", commission: "$200", repeat: "Yes", code: "GHI012", url: "https://example.com/ghi", pushLeads: 30, conversions: 20, referredList: "Alex, Olivia, Lucas" },
  //     { id: 5, status: "inactive", date: "2023-07-15", commission: "$90", repeat: "No", code: "JKL345", url: "https://example.com/jkl", pushLeads: 12, conversions: 9, referredList: "Sophia, Ethan, Ava" },
  //     { id: 6, status: "active", date: "2023-07-14", commission: "$130", repeat: "Yes", code: "MNO678", url: "https://example.com/mno", pushLeads: 22, conversions: 16, referredList: "Noah, Mia, Liam" },
  //     { id: 7, status: "active", date: "2023-07-13", commission: "$100", repeat: "Yes", code: "PQR901", url: "https://example.com/pqr", pushLeads: 20, conversions: 14, referredList: "Elijah, Isabella, James" },
  //     { id: 8, status: "active", date: "2023-07-12", commission: "$180", repeat: "Yes", code: "STU234", url: "https://example.com/stu", pushLeads: 28, conversions: 18, referredList: "Aiden, Sophia, William" },
  //     { id: 9, status: "inactive", date: "2023-07-11", commission: "$220", repeat: "No", code: "VWX567", url: "https://example.com/vwx", pushLeads: 14, conversions: 10, referredList: "Lucas, Mia, Ethan" },
  //     { id: 10, status: "active", date: "2023-07-10", commission: "$70", repeat: "No", code: "YZA890", url: "https://example.com/yza", pushLeads: 8, conversions: 5, referredList: "Ella, Oliver, Charlotte" }
  //   ],
  //   []
  // );

  const handleFilter = (status) => {
    setFilter(status);
  };

  const filteredData = filter === "All" ? data : data?.filter((item) => item.status.toLowerCase() === filter.toLowerCase());

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: {pageIndex, pageSize},
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: {pageIndex: 0, pageSize: 7},
    },
    usePagination,
  );

  return (
    <div className="table_scroll">
      <div className="table_wrapper">
        <div className="table_header">
          <div className="search">
            <div className="search_bar">
              <input
                type="text"
                className="search_input"
                placeholder="Search..."
              />
              <img
                className="search_icon"
                src={Search}
                alt="search icon"
              />
            </div>
            <div className="select_wrapper">
              <div
                className="calendar-button"
                onClick={() => setIsCalender((prev) => !prev)}
              >
                <img
                  src={CalendarIcon}
                  alt="calender icon"
                />
              </div>
              <div className="calendar_wrapper">
                {isCalender && (
                  <Calendar
                    onChange={(date) => setDate(date)}
                    value={date}
                    // formatMonth={"MMM"}
                    selectRange={true}
                    showNeighboringMonth={false}
                  />
                )}
              </div>{" "}
            </div>{" "}
          </div>
          <div className="filter_buttons">
            <button
              className={filter === "All" ? "active_btn" : ""}
              onClick={() => handleFilter("All")}
            >
              All
            </button>
            <button
              className={filter === "Active" ? "active_btn" : ""}
              onClick={() => handleFilter("Active")}
            >
              Active
            </button>
            <button
              className={filter === "Inactive" ? "active_btn" : ""}
              onClick={() => handleFilter("Inactive")}
            >
              Inactive
            </button>
          </div>
        </div>
        <table
          {...getTableProps()}
          className="leaderboard-table"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          <img
            src={CaretLeftIcon}
            alt=""
          />
          <button
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            Previous
          </button>
          {pageOptions.map((pageNumber, index) => (
            <>
              <button
                key={index}
                onClick={() => gotoPage(index)}
                className={pageIndex === index ? "btn-active" : "non-active"}
              >
                {pageNumber < 10 ? `0${pageNumber + 1}` : pageNumber}
              </button>
            </>
          ))}
          <button
            onClick={nextPage}
            disabled={!canNextPage}
          >
            Next
          </button>
          <img
            src={CaretRightIcon}
            alt=""
          />
        </div>{" "}
      </div>
    </div>
  );
};
