import React, { useState } from "react";
import "./style.scss";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";

function DashboardChart() {
  const options = [
    { value: "monthly", label: "Monthly" },
    { value: "yearly", label: "Yearly" },
    { value: "quarterly", label: "Quarterly" },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleSelectChange = (selected) => {
    setSelectedOption(selected);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "110px",
      height: "20px",
      boxSizing: "content-box",
      fontSize: "12px",
      color: "#133C55",
      fontWeight: "500",
      fontFamily: "Space Grotesk",
      borderRadius: "8px",
      float: "right",
      menu: (provided) => ({
        ...provided,
        fontSize: '12px',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        color: "#133C55",
        width: "20px",
      }),
    }),
  };
  return (
    <div className="dashboard_chart_wrapper">
      <div className="chart_header">
        <h4>Dashboard</h4>
        <Select
          options={options}
          value={selectedOption}
          onChange={handleSelectChange}
          styles={customStyles}
          components={{
            IndicatorSeparator: () => null,
          }}
          menuPortalTarget={document.body}
          menuPlacement="bottom"
          menuPosition="fixed"
          menuStyle={{ width: "120px" , fontSiz:'12px' }}
        />
      </div>
      <div className="chart_wrapper">
        <LineChart />
      </div>
    </div>
  );
}

export default DashboardChart;

const LineChart = () => {
  const series = [
    {
      name: "Equity",
      data: [30, 40, 35, 60, 70, 91, 125],
    },
    {
      name: "Balance",
      data: [45, 55, 60, 75, 80, 95, 110],
    },
    
  ];

  const options = {
    chart: {
      id: "line-chart",
      toolbar: {
        show: true,
        offsetY: -2,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: false,
        },
        zoom: {
          enabled: false,
        },
      },
      fontFamily: "DM Sans, sans-serif", 
    },
    legend: {
      position: "bottom",
      horizontalAlign: "left",
      offsetY: 10,
      offsetX: 10, 
      fontFamily: "DM Sans, sans-serif", 
      fontWeight:600,
      markers: {
        radius: 12,
        offsetY: 1, 
      },
    },
    
    xaxis: {
      type: "category",
      categories: [
        "12 Jan, 21",
        "14 Feb, 21",
        "18 Mar, 21",
        "20 Apr, 21",
        "22 May, 21",
        "24 Jun, 21",
        "26 Jul, 21",
      ],
      labels: {
        style: {
          fontFamily: "DM Sans, sans-serif",
          fontWeight: 600 
        },
      },
      offsetX: 6,
    },
    yaxis: {
      labels: {
        formatter: (value) => `$${value}K`,
        style: {
          fontFamily: "DM Sans, sans-serif",
          fontWeight: 600
        },
      },
    },
    stroke: {
      width: [3, 3], 
    },
    colors: ["#F75C03", "#1877F2"]
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={320}
    />
  );
};
