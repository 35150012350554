import React, {useEffect, useState} from "react";
import "./style.scss";
import verif from "../../../../assets/images/verif.jpg";
import sumsuberIcon from "../../../../assets/icons/sumsuberIcon.webp";
import warningSvg from "../../../../assets/icons/warning.svg";
import verifySvg from "../../../../assets/icons/verifyNowIcon.svg";
import unverifiedSvg from "../../../../assets/icons/unverifiedIcon.svg";
import {loadUser} from "../../../../utils/apis/user";
import {useSelector} from "react-redux";
import SumsubModal from "../SumsubModal";

function VerifyNow({kycStatus, openVerificationModal, loading}) {
  const [isVerifyVisible, setIsVerifyVisible] = useState(false);
  const idToken = useSelector((state) => state.auth.idToken);
  const accountListRedux = useSelector((state) => state.account.accountList.data);
  const [accountCount, setAccountCount] = useState(accountListRedux);
  useEffect(() => {
    setAccountCount(accountListRedux);
  }, [accountListRedux]);
  const [open, setOpen] = useState(false);
  return (
    <div className="verifynow">
      <div className="verifynow_container">
        <div className="verifynow_header">
          <div>
            <img
              src={verif}
              alt=""
              style={{width: "40px", height: "40px", borderRadius: "4px"}}
            />
            {/* <p>Veriff</p> */}
            <p>Veriff</p>
          </div>

          {/* <input type='radio' name='bank-radio' /> */}
        </div>
        <div className="verifynow_details">
          <h4>One verification platform to secure the whole user journey. Secure every step of the your customer journey.</h4>
          <div className="verifynow_details_warning">
            <div className="verifynow_details_warning_header">
              <img
                src={warningSvg}
                style={{opacity: "1 !important"}}
                alt=""
              />{" "}
              <p>In order to complete, upload and avoid delays when verifying account. Please make sure below:</p>
            </div>
            <div className="details_container">
              <ul className="verify_now_details_warning_list">
                <li>Chosen credential must not be expired.</li>
                <li>Documents should be good condition and clearly visible.</li>
                <li>Make sure that there is no light glare on the card.</li>
              </ul>
              <div className="buttons_containe">
                {loading ? (
                  <>
                    <>
                      <button
                        style={{
                          textDecoration: "none",
                          cursor: "pointer",
                          backgroundColor: `${kycStatus === "pending" ? "#f48b22" : kycStatus === "verified" ? "#00c65b" : kycStatus === "failed" ? "#ff2147" : "#35d269"}`,
                        }}
                      >
                        <img
                          src={verifySvg}
                          alt=""
                        />
                        Loading ...
                      </button>
                    </>
                  </>
                ) : kycStatus === "approved" ? (
                  <button
                    disabled
                    style={{
                      cursor: "pointer",
                      backgroundColor: `${kycStatus === "pending" ? "#f48b22" : kycStatus === "verified" ? "#00c65b" : kycStatus === "failed" ? "#ff2147" : "#35d269"}`,
                    }}
                    onClick={kycStatus === "pending" || kycStatus === "verified" ? null : openVerificationModal}
                  >
                    <img
                      src={verifySvg}
                      alt=""
                    />
                    Completed
                  </button>
                ) : accountCount.length <= 0 ? (
                  <button
                    disabled
                    style={{
                      cursor: "pointer",
                      backgroundColor: `${kycStatus === "pending" ? "#f48b22" : kycStatus === "verified" ? "#00c65b" : kycStatus === "failed" ? "#ff2147" : "#35d269"}`,
                    }}
                    onClick={kycStatus === "pending" || kycStatus === "verified" ? null : openVerificationModal}
                  >
                    <img
                      src={verifySvg}
                      alt=""
                    />
                    Not eligible
                  </button>
                ) : (
                  <>
                    <button
                      style={{
                        cursor: "pointer",
                        backgroundColor: `${kycStatus === "pending" ? "#f48b22" : kycStatus === "verified" ? "#00c65b" : kycStatus === "failed" ? "#ff2147" : "#35d269"}`,
                      }}
                      onClick={kycStatus === "pending" || kycStatus === "verified" ? null : openVerificationModal}
                    >
                      <img
                        src={verifySvg}
                        alt=""
                      />
                      Verify
                    </button>
                  </>
                )}

                {kycStatus === "pending" || kycStatus === "failed" ? (
                  <button
                    style={{
                      cursor: "pointer",
                      background: "00c65b",
                    }}
                    onClick={openVerificationModal}
                  >
                    Retry
                  </button>
                ) : null}
              </div>
              {/* <div className="buttons_containe">
                <button onClick={() => setOpen(true)}>Verify Now</button>
              </div> */}

              {/* <SumsubModal
                open={open}
                sumHandleClose={() => setOpen(() => false)}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyNow;
