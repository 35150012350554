import axios from "axios";
import {baseUrl} from ".";

const createSupportCases = async (idToken, data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };
    const response = await axios.post(`${baseUrl}/support/case/`, data, config);
    return response;
  } catch (error) {
    throw error;
  }
};

const getSupportTableDetails = async (idToken) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  let output;
  await axios
    .get(`${baseUrl}/support/case/`, config)
    .then((res) => {
      output = res.data;
      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });
  return output;
};

// Edit
const editSupportCases = async (idToken, data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };
    const response = await axios.patch(`${baseUrl}/support/case/`, data, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export {createSupportCases, getSupportTableDetails, editSupportCases};
