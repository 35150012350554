import {useEffect, useState} from "react";
import {digiSignerStatus} from "../../utils/apis/payment";
import {useDispatch, useSelector} from "react-redux";
import ErrorModal from "../../reusableComponents/ErrorModal";
import {returnErrors} from "../../store/reducers/error";
import {returnMessages} from "../../store/reducers/message";
import {useNavigate} from "react-router-dom";

const DocSigning = () => {
  const idToken = useSelector((state) => state.auth.idToken);
  const [errorModal, setErrorModal] = useState(null);
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await digiSignerStatus(idToken);

        setData(response);

        if (response?.status <= 202) {
          // setErrorModal(response.error);
          dispatch(returnMessages("hello", 400));
        } else {
          // dispatch(returnErrors("Something Went Wrong", 400));
        }
      } catch (error) {
        setErrorModal("SomeThing Went Wrong");
        // dispatch(returnErrors("SomeThing Went Wrong", 400));
      }
    };
    fetchData();
    navigate("/");
  }, []);
  return <div>he</div>;
};

export default DocSigning;
