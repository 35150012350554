import React from "react";
import "./style.scss";
import TickIcon from "../../assets/icons/successfulTickIcon.svg";

function SuccessModal({setModalOpen, handleNavigation, title, desc, btnText}) {
  return (
    <div className="success_modal_overlay">
      <div className="successModalWrapper">
        <div className="success_modal_container_top">
          <div className="tick_icon">
            <img
              src={TickIcon}
              alt="successful tick"
            />{" "}
          </div>
        </div>
        <div className="success_modal_container">
          <div className="success_modal_container_bottom">
            <h1>Success!</h1>
            <p>{desc}</p>
            <button
              className="success_modal_close_btn"
              onClick={() => {
                {
                  setModalOpen && setModalOpen();
                }
                {
                  handleNavigation && handleNavigation();
                }
              }}
            >
              {btnText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessModal;
