import React from "react";
import "./style.scss";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";

function PushLeads({codeList}) {
  const options = [
    {value: "monthly", label: "Monthly"},
    {value: "yearly", label: "Yearly"},
    {value: "quarterly", label: "Quarterly"},
  ];

  const dateOptions = [{value: "d", label: "Dec 2023"}];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "110px",
      height: "20px",
      boxSizing: "content-box",
      fontSize: "12px",
      color: "#fff",
      fontWeight: "500",
      fontFamily: "Space Grotesk",
      borderRadius: "8px",
      float: "right",
      background: "#111512",
      dropdownIndicator: (provided) => ({
        ...provided,
        color: "#35d269",
        width: "20px",
      }),
    }),
  };
  return (
    <div className="pushleads">
      <div className="pushleads_wrapper">
        <div className="chart_wrapper">
          <div className="chart_header">
            <p className="chart_title">Clicks Statistic</p>
            <div>
              {/* <Select
                options={options}
                value={options[0]}
                styles={customStyles}
                components={{
                  IndicatorSeparator: () => null,
                }}
                menuPortalTarget={document.body}
                menuPlacement="bottom"
                menuPosition="fixed"
                menuStyle={{width: "120px"}}
              />

              <Select
                options={dateOptions}
                value={dateOptions[0]}
                styles={customStyles}
                components={{
                  IndicatorSeparator: () => null,
                }}
                menuPortalTarget={document.body}
                menuPlacement="bottom"
                menuPosition="fixed"
                menuStyle={{width: "120px"}}
              /> */}
            </div>
          </div>
          <Chart codeList={codeList} />
        </div>
      </div>
    </div>
  );
}

export default PushLeads;

function Chart({codeList}) {
  const chartData = codeList || [];

  // const date = chartData?.created_day || [];
  const formattedDates = chartData?.map((dateString) => {
    const date = new Date(dateString?.created_day);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    });
  });
  // const chartData = [
  //   {
  //     x: new Date("2023-07-01").getTime(),
  //     y: 15,
  //   },
  //   {
  //     x: new Date("2023-07-02").getTime(),
  //     y: 25,
  //   },
  // ];
  const clicks = codeList?.map((item) => item.clicks);
  const created_date = codeList?.map((item) => item.created_day);

  const series = [
    {
      name: "clicks",
      data: clicks,
    },
  ];

  const options = {
    chart: {
      type: "area",
      stacked: false,
      toolbar: {
        show: false,
      },
    },

    xaxis: {
      type: "datetime",
      categories: created_date,
      labels: {
        style: {
          colors: "#526c7b",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#526c7b",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: ["#35d269"],
    },
    fill: {
      colors: ["#35d269"],
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
  };

  return (
    <div className="area-chart">
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={280}
      />
    </div>
  );
}
