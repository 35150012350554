import React, {useEffect, useState} from "react";
import "./style.scss";
import rankSvg from "../../../assets/icons/rank.svg";
import certificateCard from "../../../assets/images/certificates.png";
import certificateBigImg from "../../../assets/images/certificateBig.png";
import ImageEnlarger from "../../../reusableComponents/ImageEnlarger";
import shareIcon from "../../../assets/icons/shareIcon.svg";
import {getCertificateListRequest} from "../../../utils/apis/account";
import Frame from "../../../assets/icons/Frame.svg";
import {useSelector} from "react-redux";
import NoData from "../../../components/NoDataFound/NoData";

const AllCertificates = () => {
  const [openModal, setOpenModal] = useState(false);
  const idToken = useSelector((state) => state.auth.idToken);
  const [certificateList, setCertificateList] = useState([]);
  const fetchCertificates = async () => {
    const response = await getCertificateListRequest(idToken);

    setCertificateList(response);
  };
  useEffect(() => {
    fetchCertificates();
  }, [idToken]);
  return (
    <>
      {certificateList.length <= 0 && <NoData msg={"No certificates found"} />}
      <div className="main_certificates">
        {certificateList?.map((item, idx) => (
          <div
            className="certificate-cards"
            key={idx}
          >
            <div
              className="inner-card"
              onClick={() => setOpenModal(true)}
            >
              <div className="certificate-img">
                {/* <div
                className="shareIcon"
                onClick={(e) => e.stopPropagation()}
              >
                <img
                  src={shareIcon}
                  alt="shareIcon"
                />
              </div> */}
                <img
                  src={item.file}
                  alt="certificate card"
                  className="card-img"
                />
                {/* {openModal && (
                  <ImageEnlarger
                    src={item.file}
                    alt="certificate card"
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    title="Feburary 2022 - 200k Challenge Certificate"
                    date="1 Mar - 31 Mar"
                    rank="23"
                  />
                )} */}
              </div>
              <div className="cert-card-info">
                <h2 className="card-title">{item.title}</h2>
                <h3 className="card-date-rank">
                  {item.created_at}
                  {/* <span>
                    <img
                      src={rankSvg}
                      alt="rank svg"
                    />{" "}
                    Your Rank : 23
                  </span> */}
                </h3>
              </div>
              <div
                className="card-download"
                onClick={(e) => e.stopPropagation()}
                style={{cursor: "pointer"}}
              >
                <a
                  href={item.file}
                  target="_blank"
                >
                  <button>Download </button>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AllCertificates;
