import React, { useEffect, useState } from "react";
import "./style.scss";

import { ReactComponent as DollarIcon } from "../../../assets/icons/dollar-normal.svg";
import Accordion from "../../../reusableComponents/Accordion";
import Card from "../../../reusableComponents/Card";

const RadioDisabled = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="9.5"
      cy="9.5"
      r="9.5"
      fill="#35d269"
    />
    <circle
      cx="9.5"
      cy="9.5"
      r="5.9375"
      fill="black"
    />
  </svg>
);

const Faq = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [openAccordion, setOpenAccordion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("option1");

  const handleRadioClick = (value) => {
    setSelectedOption(value);
    setOpenAccordion(0);
  };
  const handleToggleAccordion = (index) => {
    setOpenAccordion((prevIndex) => (prevIndex === index ? null : index));
  };
  const accordionData1 = [
    {
      title: "How Do I Get Started With Equity Edge?",
      content: (
        <div>
          <p>
            Start your journey with Equity Edge by signing up with us at – <a href="https://app.ascendxcapital.com">https://app.ascendxcapital.com</a>
          </p>
          <br />
          <br />
          <p>
            After signing up to our dashboard, navigate to Funding Evaluations tab. From there, you will be able to purchase your evaluation starting from $10K – $200K. After your evaluation is
            purchased, our system will send your account credentials to your email address instantly.
          </p>
        </div>
      ),

      icon: <DollarIcon />,
    },
    {
      title: "Am I Trading With Real Funds?",
      content: (
        <div>
          Equity Edge provides traders with simulated assessment accounts, and you are not trading real funds. These accounts are used to evaluate and identify effective trading strategies.
          Passing the assessment allows you to continue trading in a simulated market environment and earn performance fees as an Equity Edge funded trader. This helps ensure that traders can
          demonstrate their skills and strategy effectiveness before trading with real funds.
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "How Much Leverage Is Offered?",
      content: (
        <div>
          <p>We provide leverage of up to 100:1 on our simulated trading accounts. This means that for every $1 in your trading account, you can influence a position size of up to $100.</p>
          <br />
          <br />
          <p>
            We urge traders to exercise responsibility and remain cognizant of the risks associated with leverage and its impact on trading performance. While no actual capital is at risk in our
            assessments or Qualified Trader accounts, it’s crucial to recognize that leverage can significantly affect your trading strategy. Being mindful of the potential benefits and pitfalls of
            leverage is fundamental to sound trading practices.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "How Long Does The Evaluation Stage Last?",
      content: (
        <div>
          <p>The time period for Equity Edge Evaluation accounts does not have any specified time limits imposed on reaching the trading targets for each phase.</p> <br />
          <p>You have the flexibility to take as much time as you need to trade the account,whether it takes you 1 month or 1 year. There is no expiration date for the account.</p> <br />
          <p>This means you have the freedom to trade at your own pace without time constraints within the evaluation period.</p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "What Is The Required Profit Target To Pass The Evaluation Stage",
      content: (
        <div>
          <p> The required profit targets for each phase of the evaluation are as follows: </p> <br />
          <p> ● Phase 1: 7% total profit target </p> <br /> <p>● Phase 2: 5% total profit target </p> <br />
          <p>
            These targets are calculated based on the initial starting balance of the account. For example, if your starting balance is $100,000, the Phase 1 profit target would be $7,000. To pass,
            your account balance must reach $107,000, including account equity, meaning all positions must be closed.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "I Passed The Challenge, What's Next?",
      content: (
        <div>
          <p>Once you have completed your stage 2 Evaluation, you will automatically be emailed with a trading objective complete email & a link to complete a KYC.</p>
          <br />
          <p> The duration from completing the stage 2 objectives to receiving your qualified trader credentials is a maximum of 5 working days.</p>
          <br />
          <p>
            This is to ensure the team have adequate time to check the account correctly met the objectives without using prohibited trading strategies or violating any of the other restrictions set
            out in this help center.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "What payment methods are available for performance fees?",
      content: (
        <div>
          <p>
            Performance fees can be received through bank wire transfer via Wise. We have also forged a partnership with a deliverable FX institution, providing our clients with the benefit of a
            favorable 0.3% exchange rate fee when receiving their performance fees in their domestic currencies. To initiate requests for performance fee payouts, please submit them through your
            dashboard profile.
          </p>
          <br />
          <p>
            Additionally, we accept cryptocurrencies such as BTC, USDC, ETH, and XRP as alternative forms of payment for performance fees. This flexible approach ensures that traders have multiple
            options for receiving their earnings in a manner that best suits their preferences and needs.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "Can I Hold Positions Over the Weekend?",
      content: (
        <div>
          <p>Yes, you can hold trades over the weekend. </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "Can I trade through high impact news on qualified accounts?",
      content: (
        <div>
          <p>
            Indeed, it’s important to acknowledge that trades initiated shortly before a news event and swiftly closed, particularly those with fills that appear unrealistic within the simulated
            trading environment, may be subject to invalidation.
          </p>
          <br />
          <p>
            I would like to emphasize that the objective is not to jeopardize your account; rather, trades that are found to breach realistic fill expectations during high-impact news events may be
            invalidated. This measure aims to ensure fairness and integrity in our trading environment.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "Two-Phase Challenge / Funded Rules",
      content: (
        <div>
          <p>● Account Type: Two-Phase Challenge</p> <br />
          <p>● Profit Target: 7% and 4%</p> <br />
          <p>● Daily Loss Limit: 4% (Can be removed after your first payout if requested, account must be 60 days old)</p> <br />
          <p>● Maximum Loss Limit: 10% (Smart Drawdown)</p> <br />
          <p>● Time Limit: No Limit</p> <br />
          <p>● Scale Target: 10%</p> <br />
          <p>● Scale Speed: 0.5x (half each time)</p> <br />
          <p>● Scale Limit: $400,000 for all accounts</p> <br />
          <p>● Are EAs Allowed: yes</p> <br />
          <p>● Is Copy Trading Allowed: no</p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "What can I Trade?",
      content: (
        <div>
          <p>You can trade the following assets:</p> <br />
          <p>● FX (Forex)</p> <br />
          <p>● Commodities</p> <br />
          <p>● Indices</p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "Can I Trade Martingale On A Qualified Account?",
      content: (
        <p>
          No, Martingale strategies or Martingale EAs are not allowed on either evaluation or qualified trader accounts. Equity Edge Group considers Martingale strategies to include opening
          consecutive positions in the same direction with an exponent greater than 1x. Using such strategies is against their rules.
        </p>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "What Is Classified As A Martingale Strategy?",
      content: (
        <div>
          <p>At Equity Edge, Martingale strategies or Martingale Expert Advisors (EAs) are strictly prohibited. We define Martingale strategies as follows:</p> <br />
          <p>
            Opening a position in the same direction while the initial position is in a state of drawdown. If the subsequent position is opened with a lot size exceeding 1x of the original position,
            it is considered a Martingale strategy.
          </p>
          <br />
          <p>
            For example, if you initiate a 1-lot buy on GBPUSD, and this position experiences a drawdown, you are permitted to open additional buy positions on GBPUSD as long as the lot size is equal
            to or smaller than the initial position. Increasing the position size is not allowed in this scenario. Only after all buy positions on GBPUSD are closed can you then open a new initial
            trade on GBPUSD at a position size of your choosing without restrictions.
          </p>
          <br />
          <p>
            To illustrate further, if you purchased gold at 1900 with 1 lot and the price subsequently dropped to 1899, and you buy 0.8 lots, if the price continues to decline to 1895, you cannot
            purchase more than 0.8 lots; the lot size must be either 0.8 lots or less. Soft Breach Policy: In the initial stage of the Equity Edge 2-step evaluation, if you successfully pass but
            have been flagged for Martingale, this is considered a soft breach. You will receive a detailed explanation of the rule above and the specific trades that were flagged. Your Phase 1
            Martingale status will be reset, and you will continue your evaluation from where you left off.
          </p>
          <br />
          <p>
            Important Notes: These guidelines are in place to maintain a fair and responsible trading environment, promoting good trading practices while safeguarding against the risks associated with
            Martingale strategies.
          </p>
          <br />
          <p>
            ● For Stage 2 and analyst accounts, resets and free retries are not given, as we expect traders at this stage to fully understand the rules and requirements once they achieve analyst
            accounts.
          </p>
          <br />
          <p>● Please be aware that there is a Martingale counter on the dashboard, and if 3 Martingale trades are placed, the account will be closed.</p>
          <br />
          <p>● If you are on a stage 1 account and need further assistance, please email … for clarification and guidance.</p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "Is There A Lot Size Limit During The Challenge?",
      content: (
        <p>There are no maximum lot size limits during the challenge phase. However, please note that on qualified trader accounts, there are specific maximum lot limits based on the account size.</p>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "What Are Prohibited Trading Strategies",
      content: (
        <div>
          <p>Prohibited trading strategies at our firm encompass the following:</p> <br />
          <p>● Taking advantage of unrealistic prices or trade opportunities, including practices such as arbitrage, latency trading, front-running price feeds, and exploiting mispricing</p> <br />
          <p>● Latency trading</p> <br />
          <p>● Arbitrage trading</p> <br />
          <p>● High-frequency trading</p> <br />
          <p>● Reverse trading or group hedging</p> <br />
          <p>● Martingale Strategy</p> <br />
          <p>
            Furthermore, we do not permit account management services, and all accounts and trades must be conducted by the trader whose name is associated with the account. If you have uncertainties
            about the compliance of your Expert Advisor (EA) or manual scalping strategy, we encourage you to create a free trial account in our dashboard and seek our review and approval of the
            strategy before proceeding with an evaluation. It’s important to emphasize that taking these rules seriously is crucial, especially if your goal is to attain a qualified trader account
            with an allocation of up to $400,000. Dedicate the necessary time to thoroughly comprehend the rules, objectives, and requirements to ensure a successful evaluation process.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "What Is Equity Edge’s Policy Against Gambling In Trading?",
      content: (
        <div>
          <p>
            At Equity Edge, we maintain a stringent policy against trading strategies that fall under the category of “all or nothing.” Such strategies are not compatible with our approach, and we
            do not entertain or endorse trading styles that rely on this principle.
          </p>
          <br />
          <p>
            Our commitment lies in identifying exceptional and successful traders who can effectively manage risk, generate profits, and sustain consistency in the financial markets. These are the
            traders we choose to partner with and provide qualified trader accounts of up to $400,000.
          </p>
          <br />
          <p>
            We firmly reject trades that exhibit characteristics of “all or nothing,” and we do not accept strategies reliant on this type of trading. We firmly believe that any trading activity
            categorized as gambling does not align with our values of risk management, profitability, and market consistency. Engaging in such activities will lead to the termination of your contract
            and the removal of your Qualified Trader Account.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "Can I Use An Expert Advisor (EA)?",
      content: (
        <div>
          <p>
            Expert Advisors (EAs) are indeed permitted for trading at Equity Edge. EAs are also allowed to employ virtual or hidden stop losses. However, there are specific guidelines that must be
            followed: Pre-Approval: Prior to using EAs, we kindly request that they go through a pre-approval process. To initiate this process, please create a free trial account at
            <a href="https://app.ascendxcapital.com/login">https://app.ascendxcapital.com/login</a> and then contact our support team via email at support@ascendxcapital.com
          </p>
          <br />
          <p>This step is essential to ensure the compatibility and compliance of your EAs with our trading environment.</p>
          <p>
            To enable an Expert Advisor (EA) during the checkout process, please ensure that you click on “Enable EA.” However, be aware that you must still reach out to our support team for the
            actual activation of the EA. It’s crucial to understand that we have strict prohibitions against High-Frequency Trading (HFT), latency arbitrage trading bots, EAs, or strategies. The use
            of these prohibited tools will result in the closure of the account. Utilizing third-party EAs carries inherent risks for both our company and the trader. Additionally, if multiple traders
            are detected using the same EA and placing identical trades, the account will be flagged for violating our “No Group Trading” rules, which may lead to account failure. Complying with these
            guidelines is imperative to ensure a successful and compliant trading experience.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "Is The Use Of VPNs And VPS Allowed?",
      content: <p>No we do not allow the use of VPNs and VPS</p>,
      icon: <DollarIcon />,
    },
    {
      title: "What Is The 2 Minute Average Trade Duration Rule ?",
      content: (
        <div>
          <p>
            We have a requirement that the average duration of all your trades should be greater than 2 minutes. You can access this information on your dashboard by navigating to the “account
            metrics” page and clicking on the “insights” tab.
          </p>
          <br />
          <p>
            To clarify, we do understand that some trades may have durations of less than 2 minutes, and that is acceptable. Our primary requirement is that the majority of your trades and the average
            duration of all trades should exceed 2 minutes.
          </p>
          <br />
          <p>
            If you successfully pass the assessment but are found to be in breach of this rule, we will offer you a free opportunity to retake that stage in order to meet the trading objectives. This
            policy is implemented to prevent Tick scalping and high-frequency traders from attempting to manipulate our price feed, which we provide in good faith.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "Which Broker Do We Use?",
      content: (
        <div>
          <p>We exclusively utilize ACG Markets, a third-party brokerage, to provide our traders with an assessment environment that replicates the following features:</p> <br />
          <p> ● Extensive Institutional Trading Experience: ACG Markets offers an environment that emulates the experience of institutional trading.</p> <br />
          <p> ● Prime or Prime Liquidity: The platform provides access to prime liquidity sources, ensuring optimal trading conditions.</p> <br />
          <p> ● Ultra-low Latency: Traders benefit from ultra-low latency, enabling rapid order execution.</p> <br />
          <p> ● Targeted Execution Time: ACG Markets strives for an execution time of less than 30 milliseconds, facilitating timely and efficient trading.</p> <br />
          <p> ● Raw Spreads: Trading begins with raw spreads starting from 0.1 pips, providing competitive pricing.</p> <br />
          <p> ● Commission-Free Trading: The platform offers commission-free trading, reducing trading costs.</p> <br />
          <p> ● Offerings: ACG Markets provides a wide range of trading instruments, including FX, Indices, and Commodities.</p> <br />
          <p>These features collectively contribute to a trading environment that supports our traders in their pursuit of success while ensuring a professional and reliable experience.</p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "Are You A Broker?",
      content: (
        <div>
          <p>
            No, we are not a broker. We are a proprietary trading company. We do not accept deposits from traders to trade their own money. Clients simply pay for the assessment of their trading
            strategy, We do not accept deposits from traders, to trade their own money.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "What Payment Methods Are Available For Performance Fees?",
      content: (
        <div>
          <p>
            Performance fees can be received through bank wire transfer via Wise. We have also forged a partnership with a deliverable FX institution, providing our clients with the benefit of a
            favorable 0.3% exchange rate fee when receiving their performance fees in their domestic currencies. To initiate requests for performance fee payouts, please submit them through your
            dashboard profile. Additionally, we accept cryptocurrencies such as BTC, USDC, ETH, and XRP as alternative forms of payment for performance fees. This flexible approach ensures that
            traders have multiple options for receiving their earnings in a manner that best suits their preferences and needs.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "Third Party Payments",
      content: (
        <div>
          <p>
            We do not accept payments made via a third-party card or account. All assessment purchases and account funding must be conducted in the name of the cardholder. During the onboarding
            process for a qualified trader account, we cross-verify the name on the payment card with the KYC profile to ensure they match. This precautionary measure is in place to safeguard against
            credit card fraud.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "Is It Possible To Receive A Refund For The Challenge Fees Once I Have Successfully Completed My Evaluation?",
      content: <p>No, evaluation fees are nonrefundable.</p>,
      icon: <DollarIcon />,
    },
    {
      title: "How Does Trading On Equity Edge Compare To A Real Market Environment?",
      content: (
        <div>
          <p>
            ACG MARKETS provides our traders with an assessment environment that effectively emulates the following key features: Extensive Institutional Trading Experience: The platform offers an
            environment akin to institutional trading, providing traders with valuable experience and tools.
          </p>
          <p>
            Ultra-Low Latency: ACG MARKETS ensures that traders can execute orders with minimal latency, facilitating quick and efficient trading. Targeted Execution Time: Traders benefit from a
            targeted execution time of less than 30 milliseconds, ensuring timely order execution. Raw Spreads: Trading starts with competitive raw spreads, commencing from as low as 0.1 pips, which
            is advantageous for traders. Commission-Free Trading: The platform enables commission-free trading, reducing trading costs for traders. Offerings in FX, Indices, and Commodities: ACG
            MARKETS provides a wide range of trading instruments, including FX, Indices, and Commodities, allowing traders to diversify their portfolios. These features collectively contribute to an
            assessment environment that mirrors institutional trading conditions, while offering a range of assets and trading tools to support our traders in their evaluation and trading journey.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "Are There Commission Fees?",
      content: (
        <div>
          <p>There are no commission fees for trading across all asset classes with Equity Edge.</p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "Who Can I Contact for Technical Support?",
      content: (
        <div>
          <p>You can contact our technical support team through the following channels:</p>
          <p>Email: support@ascendxcapital.com</p>
          <p>Support ticket on our Discord channel</p>
          <p>Live chat support</p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "How Should I Handle Taxes When Trading with Equity Edge Group?",
      content: (
        <div>
          <p>
            Equity Edge does not advise on tax. For tax-related inquiries, it is advisable to speak with a financial advisor or accountant who can provide guidance and ensure compliance with tax
            regulations in your jurisdiction.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "Are There Free Retakes?",
      content: (
        <div>
          <p>
            Equity Edge does not offer free retries, as the evaluation programs provide unlimited trading days without time restrictions. This means that your account will not expire in profit in
            order to be eligible for a free retry. This approach offers traders more flexibility and opportunities to succeed in their trading journey without the pressure of time constraints.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "How Long Will It Take To Get My Credentials?",
      content: (
        <div>
          <p>The timeframe for receiving your credentials depends on how you purchased the evaluation program:</p>
          <p>
            ● Instant Access: If you purchased the evaluation program through our website using your card, you will receive your account credentials immediately upon completing the purchase process.
          </p>
          <p>
            ● Cryptocurrency Payment: If you made a purchase using cryptocurrency, account credentials are typically sent within 24 hours. Our team processes these requests promptly, and you can often
            expect to receive your credentials within just a few hours for quicker access.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "Are Stop Loss and Take Profit Orders Required?",
      content: (
        <div>
          <p>
            While stop loss and take profit orders are not mandatory, it is highly advisable to use them as risk management tools. They can help safeguard your trading positions and define your profit
            objectives.
          </p>
          <br />
          <p>
            Additionally, Expert Advisors (EAs) that utilize virtual or hidden stop loss and take profit levels are permitted for your convenience. These EAs can enhance your trading strategy by
            automating certain aspects of risk management.
          </p>
          <br />
          <p>By utilizing these tools and EAs effectively, you can enhance your trading approach and risk management, ultimately improving your trading performance.</p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "If I Pass Before The Minimum Trading Days, What Is Required For The Remaining Days?",
      content: (
        <div>
          <p>
            If you successfully pass the evaluation before meeting the minimum trading days requirement of 3 days, you are still required to continue trading until you fulfill this requirement. We
            recognize that you may be hesitant to risk losing profits, but in order to meet the minimum trading days requirement, you must open and close a minimum of one trade per day for three
            consecutive days. This rule is in place to ensure that traders demonstrate consistent and active trading behavior over a minimum period.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "Do I Need Experience Before I Apply?",
      content: (
        <div>
          <p>
            No prior experience is required to purchase and attempt an evaluation. However, it’s important to note that your chances of passing an evaluation without trading experience are very
            unlikely. For newcomers to trading, we recommend visiting our academy section and completing educational courses crafted by industry professionals to gain a better understanding of the
            trading process. What is the required profit target to pass the evaluation? The required profit targets for each phase of the evaluation are as follows:
          </p>
          <br />
          <p>Phase 1: 7% total profit target</p> <br />
          <p>Phase 2: 5% total profit target</p> <br />
          <p>
            These targets are calculated based on the initial starting balance of the account. For example, if your starting balance is $100,000, the Phase 1 profit target would be $7,000. To pass,
            your account balance must reach $107,000, including account equity, meaning all positions must be closed.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "What Are The Daily Risk Limits And How Do They Work?",
      content: (
        <div>
          <p>The daily risk limits are calculated as follows:</p> <br />
          <p>
            Daily Loss Limit Calculation: The daily loss limit is determined based on 5% of your starting balance for each trading day. This starting balance is the account balance at the close of the
            daily candle on broker time, which is at 00:00 GMT+2. For example, if your account balance is $100,000, your maximum daily risk for the next day will be 5% of the starting balance, which
            is $100,000, resulting in a $5,000 maximum daily loss. This means your minimum equity must remain above $95,000. The open equity (including any floating gains or losses) from the previous
            trading day is not factored into the maximum daily loss calculation.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "What Are The Minimum And Maximum Trading Days For The Evaluation?",
      content: (
        <div>
          <p>For the Equity Edge 2-stage evaluation, the minimum trading days required are as follows:</p> <br />
          <p>Phase 1: minimum trading days = 3</p> <br />
          <p>Phase 2: minimum trading days = 3</p> <br />
          <p>Importantly, there are no maximum trading days imposed during the Equity Edge 2 stage evaluation. You can trade as many days as needed to complete each phase successfully.</p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "Can I Pass Using Someone's Signals?",
      content: (
        <div>
          <p>
            No, using signals is considered group trading, which we do not allow. We are looking for unique and skilled traders who have developed their own understanding of the markets and risk
            management. We seek individuals who are successful based on their independent trading abilities.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "When Does My Evaluation Start?",
      content: (
        <p>
          Your assessment begins from the day of your first trade. When you log into the dashboard and view your account metrics tab, you will see a start date and end date. By default, the start date
          shows the account creation date. If you choose to wait a few days before placing your first trade, the start date will adjust to the day of your first trade.
        </p>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "What Happens If I Violate A Rule?",
      content: (
        <div>
          <p>
            The consequences of violating a rule depend on the specific rule you breach. Some rules are classified as soft breaches, while others are hard breaches that result in account failure.
            Certain rule violations can lead to a complete ban from our platform and funding. We strongly recommend thoroughly reading through our help center to ensure you understand the requirements
            and rules. Real capital is at stake, and if you aim to secure funding of any level up to $200,000, it is imperative to take this seriously and invest the time to understand the rules,
            objectives, and requirements.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "How Do I Withdraw My Profits?",
      content: (
        <div>
          <p>Performance fees on qualified trader accounts are processed as follows:</p> <br />
          <p>● Requests are available bi-weekly, starting 14 days after your initial trade on your qualified trader account.</p> <br />
          <p>● The minimum performance fee amount is $100.</p> <br />
          <p>● To request performance fees, please use the dashboard.</p> <br />
          <p>● Request dates are now rolling every 14 days from your account’s start date. You can find this date in the account metrics section of your trader’s dashboard.</p> <br />
          <p>● All performance fee requests will be processed and paid within 2 business days.</p> <br />
          <p>● For your first performance fee request, you must complete a minimum of 5 trading days.</p> <br />
          <p>● Ensure that all trades are closed on your account when making a request.</p> <br />
          <p>● You can resume trading once the account balance has been reset to the starting balance.</p> <br />
          <p>● We offer multiple methods to pay your performance fees, including bank wire (Wise), PayPal, and Deel.</p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "How Many Accounts Can I Have?",
      content: (
        <p>
          You can trade up to a total of $400,000 across multiple accounts at any one time, including both evaluation and qualified trader accounts. However, if your maximum capital exceeds $400,000
          and reaches the limit of $500,000, please note that above $400,000, you must employ a different trading strategy. Duplicating trades on accounts totaling $500,000 is not allowed. Each
          separate account must be traded with a different strategy, and duplicate trades or opposite trades on multiple qualified trader accounts are not allowed.
        </p>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "How Does The Scaling Plan Work?",
      content: (
        <p>
          The maximum capital growth on the Equity Edge Plan is $400,000. Traders who achieve a 10% growth on their trading account can request scaling. The scaling amount will be 10%, allowing the
          trader to request performance fees and scale simultaneously. To scale the account, each time there must be a 10% gain achieved of the account balance. The 10% gain must be present in the
          account at the time of requesting scaling (previous withdrawals are not counted). For example, starting balance $100,000, gain 10% ($10,000), current balance $110,000. You may request
          scaling of the account and your performance fee of 85%. Scale 2: Starting balance $110,000, gain 10% ($11,000), current balance $121,000. You may request scaling of the account and your
          performance fee of 85%.
        </p>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "Do I Need To Leave Profits In The Account To Achieve Capital Growth?",
      content: (
        <p>
          No, you do not need to leave your profits in the account to achieve capital growth. After requesting scaling, you can withdraw the profits. We will then deposit 10% into the trading account
          for capital growth purposes. This approach allows for flexibility in managing your profits while still contributing to account growth.
        </p>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "Is There A Legal Agreement For Qualified Trader Accounts With Equity Edge Group?",
      content: (
        <p>
          Yes, once a trader successfully passes the evaluation phase, an external Analyst agreement will be signed between Equity Edge Group Limited and the trader prior to receiving account
          credentials.
        </p>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "Are There Any Profit Targets On Qualified Trader Accounts?",
      content: (
        <div>
          <p>
            No, once you are a qualified trader with Equity Edge Group, there are no profit requirements or pressure to make a specific amount each month. You are required to maintain the same
            level of risk in accordance with the selected assessment plan. Please submit all performance fee requests via your dashboard profile.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "What Is The Maximum Total Loss?",
      content: (
        <div>
          <p>
            The maximum total loss is a static absolute drawdown, set at a maximum of 10% loss on the initial starting balance. For instance, on a $100,000 evaluation, the maximum loss allowed is
            $10,000. This rule is breached if the account balance or equity drops below $90,000 at any time. Similar to the maximum daily loss, if the minimum equity limit is reached, all trades will
            be automatically closed by the system, resulting in the account’s closure.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "What Is The Lot Size Limit On Qualified Trader Accounts?",
      content: (
        <div>
          <p>On qualified trader accounts, the maximum lot exposure varies based on the account size. Here are the maximum lot exposures for each account size:</p> <br /> <br />
          <p>● $10,000 = Max 5 lots</p>
          <p>● $25,000 = Max 10 lots</p>
          <p>● $50,000 = Max 20 lots</p>
          <p>● $100,000 = Max 40 lots</p>
          <p>● $200,000 = Max 80 lots</p> <br /> <br />
          <p>You can request an increase in the limit on qualified trader accounts, but we require sufficient trading data on your qualified trader account to grant an increase.</p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "What Is The Ask Price And Bid Price?",
      content: (
        <div>
          <p>
            The ask price is the price at which you can buy a financial instrument, while the bid price is the price at which you can sell it. The difference between the ask and bid prices is known as
            the “spread.”
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    // {
    //   title: "What Is Slippage?",
    //   content: (
    //     <p>
    //       The ask price is the price at which you can buy a financial instrument, while the bid price is the price at which you can sell it. The difference between the ask and bid prices is known as
    //       the “spread.”
    //     </p>
    //   ),
    //   icon: <DollarIcon />,
    // },
    {
      title: "What Is Slippage?",
      content: (
        <div>
          <p>
            Slippage occurs when the execution price of a trade differs from the expected price due to rapid market fluctuations or delays in order execution. It can result in trades being executed at
            prices different from the ones anticipated.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "What Is Swap, Triple Swap, and Rollover Period?",
      content: (
        <div>
          <p>
            Swap is the interest rate differential between two currencies in a currency pair. Triple swap is an additional interest rate charged or earned over the weekend, accounting for weekend
            interest rate variations. The rollover period is when trades are rolled over from one trading day to the next, with swaps calculated and applied to open positions held overnight.
          </p>
        </div>
      ),
      icon: <DollarIcon />,
    },
    {
      title: "What Is Margin, Leverage, and Equity?",
      content: (
        <p>
          Margin is the amount of money required to open and maintain a trading position, serving as collateral. Leverage allows traders to control larger positions with a smaller amount of capital.
          Equity represents the real-time value of a trading account, considering open positions and their unrealized profits or losses.
        </p>
      ),
      icon: <DollarIcon />,
    },
  ];

  const accordionData2 = [
    {
      title: "Profit withdrawal payment methods?",
      content: "This is the content of Section 2.",
      icon: <DollarIcon />,
    },
    {
      title: "If I complete all the trading objectives, am I guaranteed funding?",
      content: "This is the content of Section 3.",
      icon: <DollarIcon />,
    },
    {
      title: "If I complete all the trading objectives, am I guaranteed funding?",
      content: "This is the content of Section 5.",
      icon: <DollarIcon />,
    },
  ];
  return (
    <Card className="economiccalender-container ">
      <div className="faq-container">
        <div className="faq-sidecontainer">
          {/* <img src={FaqImg} alt="FaqImg" /> */}
          <h2>Frequently Asked Questions</h2>
          <p>Quick answers to questions you may have. Can’t find what you’re looking for? Check out here</p>
          {/* <div className="selectors">
            <label
              htmlFor="general-tab"
              className={`radio-label ${selectedOption === "option1" ? "selected" : ""}`}
              onClick={() => handleRadioClick("option1")}
            >
              {selectedOption === "option1" ? <SelectorIcon /> : <RadioDisabled />}
              General
            </label>

            <label
              htmlFor="your-company-tab"
              className={`radio-label ${selectedOption === "option2" ? "selected" : ""}`}
              onClick={() => handleRadioClick("option2")}
            >
              {selectedOption === "option2" ? <SelectorIcon /> : <RadioDisabled />}
              Your company
            </label>
          </div> */}
        </div>
        <div className="faq-accordian">
          {selectedOption === "option1" ? (
            <>
              {accordionData1.map((section, index) => (
                <Accordion
                  key={index}
                  title={section.title}
                  icon={section.icon}
                  isOpen={openAccordion === index}
                  onToggle={() => handleToggleAccordion(index)}
                >
                  <p>{section.content}</p>
                </Accordion>
              ))}
            </>
          ) : (
            <>
              {accordionData2.map((section, index) => (
                <Accordion
                  key={index}
                  title={section.title}
                  icon={section.icon}
                  isOpen={openAccordion === index}
                  onToggle={() => handleToggleAccordion(index)}
                >
                  <p>{section.content}</p>
                </Accordion>
              ))}
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default Faq;
