import React, { useRef, useState } from "react";
import "./style.scss";
import { useEffect } from "react";
import EmailIcon from "../../../assets/icons/email.svg";
import LockIcon from "../../../assets/icons/lock.svg";
import EyeIcon from "../../../assets/icons/eye.svg";
import EyeSlashedIcon from "../../../assets/icons/eye-slashed.svg";
import CheckSolidGreenIcon from "../../../assets/icons/check-solid-green.svg";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg";
import GoogleIcon from "../../../assets/icons/google.svg";
import FacebookIcon from "../../../assets/icons/facebook.svg";
import DiscordIcon from "../../../assets/icons/discord.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { validateEmail, validatePassword } from "../../../utils/helpers/string";
import Tooltip from "../../../reusableComponents/Tooltip";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../reusableComponents/Spinner";
import { clearPersistedData } from "../../../store/configureStore";
import { loginAPI } from "../../../utils/apis/auth";
import { setAuthenticationFailure, setAuthenticationSuccess } from "../../../store/reducers/authSlice";
import { getUser } from "../../../store/reducers/userSlice";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import CompanyLogo from "../../../assets/icons/equityEdgeLogo.svg";
import { returnErrors } from "../../../store/reducers/error";
import Loader from "../../../reusableComponents/Loader";

function SigninForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [isGoogleAuthLoading, setIsGoogleAuthLoading] = useState(false);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuthenticated) {
      if (window.location.pathname === "/signup") {
        navigate("/funding-evaluation/plans/0");
      } else {
        navigate("/");
      }
    }
  }, [isAuthenticated]);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  let emailRef = useRef(null);
  let passwordRef = useRef(null);

  //3rd party Auth
  const handleGoogleAuth = async (e) => {
    e.preventDefault();
    setIsGoogleAuthLoading(true);
    // Set loading state to true when Google Auth starts

    // Create a promise to await the completion of the redirection
    const googleAuthPromise = new Promise((resolve) => {
      let path =
      "https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/user.addresses.read&include_granted_scopes=true&response_type=code&state=pass-through value&redirect_uri=https://app.equityedge.co.uk/auth/google&client_id=454348839117-icvli9lq10npbbautre2h62keggrvnlp.apps.googleusercontent.com";
      // Redirect to Google authentication URL
      window.location.replace(path);

      // Resolve the promise after redirection
      resolve();
    }).then(res => {
      setIsGoogleAuthLoading(false);
    });

    // Await the completion of the redirection
    await googleAuthPromise;

    // Set loading state to false when Google Auth finishes
  };



  const schema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
    // .min(8, "Password must be at least 8 characters long")
    // .matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, "Password must contain at least one uppercase letter, one number, one special character, and be at least 8 characters long")
    // .max(16, "Password must not exceed 16 characters"),
  });

  const inputs = {
    email: "",
    password: "",
  };
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: inputs,
    validationSchema: schema,
    onSubmit: async (values) => {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
      // const response = await signupRequest(formData);
      try {
        setIsLoading(true);
        const handleLoginRes = await loginAPI(values);

        setIsLoading(false);
        if (handleLoginRes.status === 200) {
          dispatch(setAuthenticationSuccess(handleLoginRes));
          dispatch(getUser(handleLoginRes.data.idToken));
        } else {
          dispatch(setAuthenticationFailure(handleLoginRes));
          const msg = handleLoginRes?.response?.data?.detail
            ? handleLoginRes?.response?.data?.detail
            : handleLoginRes?.response?.data?.non_field_errors[0]
              ? handleLoginRes.response.data.non_field_errors[0]
              : "An error occurred.";

          dispatch(returnErrors(msg, handleLoginRes?.response?.status));
        }
      } catch (err) {
        dispatch(setAuthenticationFailure(err));
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    },
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isGoogleAuthLoading) {
    return <Loader />
  }

  return (
    <div className="signinform-container">

      <div className="companyLogo">
        <img
          src={CompanyLogo}
          alt="companyLogo"
        />
      </div>
      <div className="signinform-subcontainer">
        <h2 className="signinform-title">Sign In</h2>
        <p className="signinform-continue">with</p>
        <div className="signinform-innercontainer signinform-container_icon">
          <div className="signinform-subcontainer_icon">
            <img
              src={GoogleIcon}
              alt=""
              className="signinform-icon"
              onClick={handleGoogleAuth}
            />
          </div>
        </div>
        <div className="lines">
          <div className="left"></div>
          <p>OR</p>
          <div className="left"></div>
        </div>
        <form className="signinform-innercontainer signinform-container_form">
          <div className="signinform-subcontainer_form">
            <label
              ref={emailRef}
              className="signinform-innercontainer_form"
            >
              <div className="signinform-container_input">
                <div className="signinform-container_icon email">
                  <img
                    src={EmailIcon}
                    alt=""
                    className="signinform-icon"
                  />
                </div>
                <input
                  style={{ color: "#000 !important" }}
                  type="text"
                  name="email"
                  id="email"
                  className="signinform-input"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Email"
                />
                <div className="signinform-container_icon emailVerify">
                  {validateEmail(values.email) && (
                    <img
                      src={CheckSolidGreenIcon}
                      alt=""
                      className="signinform-icon"
                    />
                  )}
                </div>
              </div>
            </label>
            <div style={{ display: "flex", color: "red", fontSize: "15px", marginLeft: "2rem" }}> {touched.email && errors.email && <div className="error-message">{errors.email}</div>}</div>
          </div>
          <div className="signinform-subcontainer_form">
            <label
              ref={passwordRef}
              className="signinform-innercontainer_form"
            >
              <div className="signinform-container_input">
                <div className="signinform-container_icon password">
                  <img
                    src={LockIcon}
                    alt=""
                    className="signinform-icon"
                  />
                </div>
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  name="password"
                  id="password"
                  className="signinform-input"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Password"
                />
                <div className="signinform-container_icon eyeIcon">
                  <img
                    src={isPasswordVisible ? EyeIcon : EyeSlashedIcon}
                    alt=""
                    className="signinform-icon"
                    onClick={() => setIsPasswordVisible((prev) => !prev)}
                  />
                </div>
              </div>
            </label>
            <div style={{ display: "flex", color: "red", fontSize: "15px", marginLeft: "2rem" }}> {touched.password && errors.password && <div className="error-message">{errors.password}</div>}</div>
          </div>

          <div className="signinform-subcontainer_form signinform-container_remember-me">
            <div className="signinform_subcontainer_wrapper">
              {/* <input
                type="checkbox"
                name="checkbox"
                id="checkbox"
                style={{backgroundColor: "red"}}
              />
              <label
                className="signinform-label signinform-label_checkbox"
                htmlFor="checkbox"
              >
                Remember me
              </label> */}
              <FormControlLabel
                control={<Checkbox />}
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: "#0C2D44", // Default color
                    borderRadius: "11px",
                  },
                  "&.Mui-checked": {
                    backgroundColor: "#158F91", // Background color when checked
                  },
                }}
                label="Remember Me"
              />
            </div>
            <div className="signinform_subcontainer_wrapper">
              <Link to="/forgot-password">
                <label
                  className="signinform-label forgot_password"
                  htmlFor="checkbox"
                >
                  Forgot Password ?
                </label>
              </Link>
            </div>
          </div>
          <div className="signinform-container_button">
            <button
              className="signinform-button"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? <Spinner /> : <>Submit</>}
            </button>
          </div>
          <p className="signinform-link">
            Don&#39;t have an account?{" "}
            <Link to="/signup">
              <b className="signinform-link_bold">Sign Up</b>
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
}

export default SigninForm;
