import React from "react";
import "./style.scss";
import Card from "../../../reusableComponents/Card";

function LeaderboardStatistics() {
  return (
    <Card className="statistics_wrapper">
      <h4>Statistics</h4>
      <div className="statistics_content">
        <div className="statistics_content_list_one">
          <div>
            <span>Current Equity</span>
            <p>$123432</p>
          </div>
          <div>
            <span>Starting Balance</span>
            <p className="green">$123432</p>
          </div>
          <div>
            <span>No of Trades</span>
            <p>$123432</p>
          </div>
          <div>
            <span>% Return</span>
            <p>$123432</p>
          </div>
          <div>
            <span>Largest winning Trade</span>
            <p>$123432</p>
          </div>
        </div>
        <div className="statistics_content_list_two">
          <div>
            <span>Current Equity</span>
            <p className="green">$123432</p>
          </div>
          <div>
            <span>Cumulative PnL</span>
            <p>$123432</p>
          </div>
          <div>
            <span>Lots</span>
            <p>$123432</p>
          </div>
          <div>
            <span>Highest Rank</span>
            <p>$123432</p>
          </div>
          <div>
            <span>Most Traded Profit</span>
            <p>$123432</p>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default LeaderboardStatistics;
