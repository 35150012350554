import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
// import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {Link} from "react-router-dom";



export default function CustomSeparator({navigation}) {
  const breadcrumbs = [
    navigation.map((item, ind) => (
      <Link
        underline="hover"
        key={ind}
        color="inherit"
        to={item.link}
        // onClick={handleClick}
        style={navigation?.length - 1 === ind ? {color: "#071E0D"} : {color: "#6A796E"}}
      >
        {item.pathName}
      </Link>
    )),
    // <Link
    //   underline="hover"
    //   key="1"
    //   color="inherit"
    //   to="/"
    //   onClick={handleClick}
    // >
    //   MUI
    // </Link>,
    // <Link
    //   underline="hover"
    //   key="2"
    //   color="inherit"
    //   to="/material-ui/getting-started/installation/"
    //   onClick={handleClick}
    // >
    //   Core
    // </Link>,
    // <Typography
    //   key="3"
    //   color="text.primary"
    // >
    //   Breadcrumb
    // </Typography>,
    // <Typography
    //   key="4"
    //   color="text.primary"
    // >

    // </Typography>,
  ];

  return (
    <Stack
      spacing={2}
      style={{paddingBottom: "20px"}}
      className="breadcrumbs_main"
    >
      <Breadcrumbs
        separator="/"
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
