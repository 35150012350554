import {combineReducers} from "redux";
import themeReducer from "./themeSlice";
import authReducer from "./authSlice";
import userReducer from "./userSlice";
import supportReducer from "./supportSlice";
import accountReducer from "./accountSlice";
import errorAlertReducer from "./error";
import msgAlertReducer from "./message";
import payoutReducer from "./payoutReducer";

const rootReducer = combineReducers({
  theme: themeReducer,
  auth: authReducer,
  user: userReducer,
  support: supportReducer,
  account: accountReducer,
  error: errorAlertReducer,
  message: msgAlertReducer,
  payout: payoutReducer,

  // Add your reducers here
});

export default rootReducer;
