import React, {useEffect, useState} from "react";
import "./style.scss";
import SigninForm from "../../../components/Auth/SigninForm";
import {useSelector} from "react-redux";
import ErrorModal from "../../../reusableComponents/ErrorModal";
import {clearPersistedData} from "../../../store/configureStore";
import SuccessModal from "../../../components/reusableComponents/SuccessModal";
import {useLocation} from "react-router-dom";
function Signin() {
  const [errormodalOpen, setErrorModalOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const {theme} = useSelector((state) => state.theme);
  useEffect(() => {
    document.body.classList["value"] === "" && (document.body.classList = theme);
  }, [theme]);
  const authState = useSelector((state) => state.auth);
  useEffect(() => {
    setMsg(authState.message);
    setErrorModalOpen(authState.error);
  }, [authState]);

  useEffect(() => {
    clearPersistedData();
  }, []);
  const location = useLocation();

  const message = location.state?.message;
  const [openModal, setOpenModal] = useState(message && true);
  return (
    <div className="signin-container">
      {/* {errormodalOpen && (
        <ErrorModal
          setModalOpen={setErrorModalOpen}
          desc={msg}
          btnText="Close"
        />
      )} */}
      <div className="signin-left-wrapper">
        <div className="signin-left">
          <SigninForm />
        </div>
      </div>
      {message && openModal && (
        <SuccessModal
          setModalOpen={() => setOpenModal(false)}
          title="Successful!"
          desc={message}
          btnText="Close"
        />
      )}
    </div>
  );
}

export default Signin;
