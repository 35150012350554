import React, { useEffect } from "react";
import "./style.scss";
import SignupForm from "../../../components/Auth/SignupForm";
import { useSelector } from "react-redux";

function Signup() {
  const { theme } = useSelector((state) => state.theme);
  useEffect(() => {
    document.body.classList["value"] === "" &&
      (document.body.classList = theme);
  }, [theme]);

  return (
    <div className="signup-container">
      <div className="signup-right">
        <SignupForm />
      </div>
    </div>
  );
}

export default Signup;
