import React, {useEffect, useState} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import Billing from "../../components/MyProfile/Billing";
import GeneralInformation from "../../components/MyProfile/GeneralInformation";
import Payout from "../../components/MyProfile/Payout";
import Verification from "../../components/MyProfile/Verification/Index";
import Tabination from "../../reusableComponents/Tabination";
import "./style.scss";
import MainHeader from "../../layouts/pageLayout/MainHeader";

function MyProfile() {
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeTab]);
  const items = [
    {
      name: "General Information",
      path: "general-information",
    },
    {
      name: "Verification",
      path: "verification",
    },
    {
      name: "Billing",
      path: "billing",
    },
    // {
    //   name: "Payout",
    //   path: "payout",
    // },
  ];

  return (
    <>
      <div className="mainPageHolder">
        <div className="mainPageHolder-T-C">
          <div className="mainPageHolder-T">
            <MainHeader
              headerName="Profile"
              showTabs={false}
              tabItems={items}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>
        <div className="mainPageHolder-B">
          {/* <div className="all_header">
            <h2>My Profile</h2>
          </div> */}
          {/* <Tabination
            variant={3}
            tabItems={items}
          /> */}
          <div className="myprofile">
          <h4 className="myprofile_header">Account Details</h4>
            <Routes>
              <Route
                path="/"
                element={
                  <Navigate
                    to="general-information"
                    replace={true}
                  />
                }
              />
              <Route
                path="general-information"
                element={<GeneralInformation />}
              />
              {/* <Route
                path="verification"
                element={<Verification />}
              />
              <Route
                path="billing"
                element={<Billing />}
              />
              <Route
                path="payout"
                element={<Payout />}
              /> */}
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyProfile;
