import React, { Fragment } from "react";
import "./style.scss";
import LeaderboardWinners from "../../LeaderboardList/LeaderboardWinners/Index";
import InfoBox from "../../../reusableComponents/InfoBox";
import LeaderboardTable from "../../LeaderboardList/LeaderboardTable.js";
import ArrowIncrease from "../../../assets/icons/arrow-increase.svg";
import ArrowDecrease from "../../../assets/icons/arrow-decrease.svg";
import CompetitionRules from "../CompetitionRules";
import MarketNews from "../MarketNews";

function Leaderboard() {
  const infoBoxArr = [
    {
      title: "Equity",
      value: "$30,872",
      icon: ArrowIncrease,
      percentage: "+2.53%",
      color: "#F75C03",
    },
    {
      title: "Balance",
      value: "$5000",
      icon: ArrowIncrease,
      percentage: "-1.2%",
      color: "#4CB944",
    },
    {
      title: "PnL",
      value: "0.51",
      icon: ArrowIncrease,
      percentage: "+5.8%",
      color: "#1877F2",
      textcolor: "red",
    },
    {
      title: "Win Rate",
      value: "500%",
      icon: ArrowDecrease,
      percentage: "+10%",
      color: "#DF2935",
      textcolor: "green",
    },
  ];

  return (
    <div className="leaderboard_container">
      <div className="leaderboard_upper">
        <div className="grid_container">
          <div className="leaderboard_winner_wrapper">
            <LeaderboardWinners />
          </div>
          <div className="leaderboard_infobox_wrapper">
            {infoBoxArr.map((info, index) => (
              <Fragment key={index}>
                <InfoBox
                  title={info.title}
                  value={info.value}
                  icon={info.icon}
                  percentage={info.percentage}
                  color={info.color}
                  textcolor={info.textcolor}
                />
              </Fragment>
            ))}
          </div>
          <div className="leaderboard_competition_wrapper">
            {/* <LeaderboardNews /> */}
            <img src={"LaptopSignin"} alt="" />
            <div className="leaderboard_competition_content">
              <h3>
                February 2022 <br></br>
                Trading Competition
              </h3>
              <button>Enter Competition</button>
            </div>
          </div>
        </div>
      </div>
      <div className="leaderboard_lower">
        <LeaderboardTable />
      </div>

      <div className="bottom_grid_container">
        <div className="column1">
          <p>Competiton Rules</p>
          <CompetitionRules />
        </div>

        <div className="column2">
          <div className="column2_header">
            <p>Market News</p>
          </div>
          <MarketNews />
        </div>
      </div>
    </div>
  );
}

export default Leaderboard;
