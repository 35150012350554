import React, {useState} from "react";
import "./style.scss";
import Tabination from "../../reusableComponents/Tabination";
import AllCertificates from "../../layouts/Certificates/AllCertificates";
import EvaluationProcess from "../../layouts/Certificates/EvaluationProcess";
import CreateCaseCertificate from "../../layouts/Certificates/CreateCase";
import Payouts from "../../layouts/Certificates/Payouts";
import Funded from "../../layouts/Certificates/Funded";
import MaxAllocation from "../../layouts/Certificates/MaxAllocation";
import MainHeader from "../../layouts/pageLayout/MainHeader";
import {Navigate, Route, Routes} from "react-router-dom";

const Certificates = () => {
  const [activeTab, setActiveTab] = useState(0);
  const items = [
    {
      name: "All Certificates",
      path: "all-certificated",
    },
    // {
    //   name: "Evaluation Process",
    //   path: "evaluation-process",
    // },
    // {
    //   name: "Create Case",
    //   path: "create-case",
    // },
    // {
    //   name: "Payouts",
    //   path: "payouts",
    // },
    // {
    //   name: "Funded",
    //   path: "funded",
    // },
    // {
    //   name: "Max Allocation",
    //   path: "max-allocation",
    // },
  ];

  return (
    <>
      <div className="mainPageHolder">
        <div className="mainPageHolder-T-C">
          <div className="mainPageHolder-T">
            <MainHeader
              headerName="Certificate"
              showTabs={false}
              tabItems={items}
              activeTab={activeTab}
            />
          </div>
        </div>
        <div className="mainPageHolder-B">
          <div className="accountmetrics-container">
            <div className="all_header">
              <h2>Certificate</h2>
            </div>
            {/* {(isLoading || accountMetrics.error) && <LoaderOverlay desc={accountMetrics.error && accountMetrics.message} />} */}

            <Routes>
              <Route
                path="/"
                element={
                  <Navigate
                    to="all-certificated"
                    replace={true}
                  />
                }
              />
              <Route
                path="all-certificated"
                element={
                  <AllCertificates
                  // errorModalOpen={errorModalOpen}
                  // setModalOpen={setErrorModalOpen}
                  />
                }
              />
              {/* <Route
                path="evaluation-process"
                element={
                  <EvaluationProcess
                  // errorModalOpen={errorModalOpen}
                  // setModalOpen={setErrorModalOpen}
                  />
                }
              /> */}
              {/* <Route
                path="create-case"
                element={
                  <CreateCaseCertificate
                  // errorModalOpen={errorModalOpen}
                  // setModalOpen={setErrorModalOpen}
                  />
                }
              /> */}
              {/* <Route
                path="payouts"
                element={
                  <Payouts
                  // errorModalOpen={errorModalOpen}
                  // setModalOpen={setErrorModalOpen}
                  />
                }
              /> */}
              {/* <Route
                path="funded"
                element={
                  <Funded
                  // errorModalOpen={errorModalOpen}
                  // setModalOpen={setErrorModalOpen}
                  />
                }
              /> */}
              {/* <Route
                path="max-allocation"
                element={
                  <MaxAllocation
                  // errorModalOpen={errorModalOpen}
                  // setModalOpen={setErrorModalOpen}
                  />
                }
              /> */}
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default Certificates;
