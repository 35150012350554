import React, {useEffect, useState} from "react";
import "./style.scss";

import startdate from "../../../assets/icons/start-date.svg";
import expirydate from "../../../assets/icons/Expiry-date.svg";
import trade from "../../../assets/icons/tradesicon.svg";
import leverage from "../../../assets/icons/leverageicon.svg";

import ChartRepGain from "../../../assets/images/chart-rep-gain.png";
import ChartRepLoss from "../../../assets/images/chart-rep-loss.png";

import RevenueGrowth from "../../../components/AccountMetrics/RevenueGrowth";
import Statistic from "../../../components/AccountMetrics/Statistic";
import InfoBox from "../../../reusableComponents/InfoBox";
import FinancialPerformance from "../../../components/AccountMetrics/FinancialPerformance";
import DailyDataBanner from "../../../components/DailyDataBanner";
import {useSelector} from "react-redux";
import moment from "moment/moment";
import ErrorModal from "../../../reusableComponents/ErrorModal";
// import Card from "../../../components/Card";
import calenderLight from "../../../assets/icons/calenderLight.svg";
import tradeLight from "../../../assets/icons/tradeLight.svg";
import levarageLight from "../../../assets/icons/levrageLight.svg";
import expiryLight from "../../../assets/icons/expiryLight.svg";
import Goals from "../../../components/AccountMetrics/GoalsOverview/Goals";

function ProgramObjectives() {
  const accountsData = useSelector((state) => state?.account?.accountMetrics);
  const [Ametrics, setAmetrics] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [programObjective, setProgramObjective] = useState([]);
  const [lastUpdated, setLastUpdated] = useState("");
  const [errorModalOpen, setErrorModalOpen] = useState(accountsData.error);
  

  useEffect(() => {
    setAmetrics(accountsData.data?.account_advance_metrics);
    setMetrics(accountsData.data?.account_metrics);
    setProgramObjective(accountsData.data?.program_objectives);
    setLastUpdated(accountsData.lastUpdated);
  }, [accountsData]);
  useEffect(() => {
    setErrorModalOpen(accountsData.error);
  }, [accountsData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  // if (accountsData.error || accountsData.loading) {
  //   return "";
  // }
  const startingBalance = accountsData?.data?.starting_balance || 0;

  const formattedBalance = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    // minimumFractionDigits: 2,
  }).format(startingBalance);
  return (
    <>
      <div className="programobjectives-container">
        <h4 className="programobjectives_header">Program Objectives</h4>
        {errorModalOpen && (
          <ErrorModal
            desc={accountsData.message}
            setModalOpen={setErrorModalOpen}
            btnText="Cancel"
          />
        )}

        {/* <div className="col1">
        <DailyDataBanner value={metrics} />

        <div className="dropdown_footer">
          <div className="first_box_1">
            <div className="mainDiv">
              <div>
                <img
                  src={calenderLight}
                  alt=""
                />
                <p className="title">Start Date</p>
              </div>

              <p className="value">{moment(accountsData.data?.start_date).format("ll")}</p>
            </div>
            <div className="mainDiv">
              <div>
                <img
                  src={calenderLight}
                  alt=""
                />
                <p className="title">Server</p>
              </div>
              <p className="value">{accountsData?.data?.server || "Na"}</p>
            </div>
          </div>

          <div className="first_box_1 secound_box_2">
            <div className="mainDiv">
              <div>
                <img
                  src={tradeLight}
                  alt=""
                />
                <p className="title">Login Id</p>
              </div>
              <p className="value">{accountsData?.data?.login_id || "Na"}</p>
            </div>
            <div className="mainDiv">
              <div>
                <img
                  src={levarageLight}
                  alt=""
                />
                <p className="title">Starting Balance</p>
              </div>
              <p className="value">{formattedBalance}</p>
            </div>
          </div>
        </div>
      </div> */}

        <div className="row1 infobox_main">
          {/* <hr className="account_metrics_line_div" /> */}
          {!accountsData.error && !accountsData.loading && <FinancialPerformance />}
          {/* <hr className="account_metrics_line_div" /> */}
        </div>

        <div className="row2">
          <Goals programObjective={programObjective} />
          <Statistic
            value={Ametrics}
            value2={metrics}
            challenge = {accountsData?.data?.challenge}
            lastUpdated={lastUpdated}
          />
        </div>

        {/* <div className="col3">
        <RevenueGrowth programObjective={programObjective} />
      </div> */}
      </div>
    </>
  );
}

export default ProgramObjectives;
