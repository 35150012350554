import React, {useEffect, useState} from "react";
import MainHeader from "../../layouts/pageLayout/MainHeader";
import Tabination from "../../reusableComponents/Tabination";
import Billing from "../../components/MyProfile/Billing";

const BillingDetails = () => {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeTab]);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const items = [
    {
      name: "General Information",
      path: "general-information",
    },
    {
      name: "Verification",
      path: "verification",
    },
    {
      name: "Billing",
      path: "billing",
    },
    // {
    //   name: "Payout",
    //   path: "payout",
    // },
  ];
  return (
    <div className="mainPageHolder">
      <div className="mainPageHolder-T-C">
        <div className="mainPageHolder-T">
          <MainHeader
            headerName="Billing Details"
            showTabs={false}
            tabItems={items}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      </div>
      <div className="mainPageHolder-B">
        <div className="all_header">
          <h2>Billing Details</h2>
        </div>
        <Tabination
          variant={3}
          tabItems={items}
        />
        <div className="myprofile">
          <Billing />
        </div>
      </div>
    </div>
  );
};

export default BillingDetails;
