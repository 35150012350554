import React from 'react'
import './style.scss'
import Leaderboard from '../../components/CompetitionList/Leaderboard'

function CompetitionLeaderboard() {
  return (
    <div className='competition_leaderboard'>
        <Leaderboard />
    </div>
  )
}

export default CompetitionLeaderboard