import React, {useState, useEffect} from "react";
import "./style.scss";
import SendIcon from "../../../assets/icons/send-blue.svg";
import TrophyBlue from "../../../assets/icons/trophy-blue.svg";
import FirstTrophy from "../../../assets/icons/trophy-green.svg";
import SecondTrophy from "../../../assets/icons/trophy-yellow.svg";
import ThirdTrophy from "../../../assets/icons/trophy-red.svg";
import bgImg1 from "../../../assets/images/competition-bg-img.png";
import {formatDateToDateMonth} from "../../../utils/helpers/date";
import {enterCompetitionRequest, getCompetitionListRequest} from "../../../utils/apis/account";
import {useDispatch, useSelector} from "react-redux";

// import SuccessModal from "../../reusableComponents/SuccessModal";
import SuccessModal from "../../../reusableComponents/SuccessModal";
import ErrorModal from "../../../reusableComponents/ErrorModal";
import moment from "moment";
import {Link, useNavigate} from "react-router-dom";
import LoaderOverlay from "../../../reusableComponents/Loader";
import {getCompetitionListSuccess} from "../../../store/reducers/accountSlice";

function CompetitionDetailsBox({competition, end_date, closed, timer, enterCompetition, status, competitionListAwards}) {
  const idToken = useSelector((state) => state.auth.idToken);

  const [iconClicked, setIconClicked] = useState(false);
  const [successmodalOpen, setSuccessModalOpen] = useState(false);
  const [errormodalOpen, setErrorModalOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const targetDate = moment(competition?.close_entry_on);
  const [timeRemaining, setTimeRemaining] = useState(targetDate.diff(moment(), "milliseconds"));
  // const participationPercent = (competition?.total_contestants - 20) / 100;

  const duration = moment.duration(timeRemaining);
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const dispatch = useDispatch();

  const handleEnterCompetition = async () => {
    setIsLoading(true);
    const response = await enterCompetitionRequest(idToken, {
      competition: competition?.id,
    });

    setMsg(response?.response?.data?.detail || response.message);

    if (response.status === 200 || response.status === 201) {
      setSuccessModalOpen(true);
      const response = await getCompetitionListRequest(idToken);
      dispatch(getCompetitionListSuccess(response));
      setIsLoading(false);
    } else {
      setErrorModalOpen(true);
      setIsLoading(false);
    }
  };

  const handleIconClick = () => {
    setIconClicked(true);

    setTimeout(() => {
      setIconClicked(false);
    }, 1000);
  };
  const participationPercentage = (competition.participated_contestants / competition.total_contestants) * 100;
  useEffect(() => {
    const midnightEndDate = moment(competition.end_date + "T23:59:59").endOf("day");
    const calculateTimeRemaining = () => midnightEndDate.diff(moment(), "milliseconds");
    setTimeRemaining(calculateTimeRemaining());

    const timerInterval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [competition.end_date]);
  const navigate = useNavigate();
  const handleSeeCompetition = (e) => {
    navigate(`/leaderboard-2?id=${e}`, {state: {awards: competitionListAwards}});
  };

  const [sortedAwardsList, setShortedAwardsList] = useState(competitionListAwards);
  useEffect(() => {
    if (competitionListAwards.length > 2) {
      const data = [...competitionListAwards];

      data.sort((a, b) => a.rank - b.rank);

      setShortedAwardsList(data);
    }
  }, [competitionListAwards]);

  return (
    <div className={`competition_details_box ${iconClicked ? "icon-clicked" : ""}`}>
      {successmodalOpen && (
        <SuccessModal
          setModalOpen={setSuccessModalOpen}
          btnText="Done"
        />
      )}
      {errormodalOpen && (
        <ErrorModal
          setModalOpen={setErrorModalOpen}
          desc={msg}
          btnText="Close"
        />
      )}
      {isLoading && <LoaderOverlay desc={"Loading...,,,"} />}

      <div className="competition_details_box_upper_image">
        <div
          className="bg_img_container"
          onClick={handleIconClick}
          // style={{backgroundImage: `url(${bgImg1})`}}
        ></div>
      </div>
      <div className="competitionDetails">
        <div className="competition_details_box_upper">
          <div className="competition_details_box_upper_wrapper">
            <div className="competiton_details_container">
              {/* <h2>{competition?.name}</h2> */}
              <h2>{competition?.name}</h2>
              <div>
                <p>
                  {formatDateToDateMonth(competition?.start_date)} - {formatDateToDateMonth(competition?.end_date)}
                </p>
                <span>
                  <img
                    src={TrophyBlue}
                    alt="winners"
                  />
                  Winners: {competition?.winners}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="competiton_details_box_middle">
          <div className="competition_details_box_middle_wrapper">
            <p className="top_3">Top winner gets</p>
            <div className="main-winner">
              {sortedAwardsList?.map((item, ind) => (
                <>
                  {item.rank === 1 ? (
                    <div
                      className="winners"
                      key={ind}
                    >
                      <img
                        src={FirstTrophy}
                        alt=""
                      />
                      <p>
                        <span style={{color: "#4CB944"}}>1st Prize</span>
                        <span>-{item.prize}</span>
                      </p>
                    </div>
                  ) : item.rank === 2 ? (
                    <div className="winners">
                      <img
                        src={SecondTrophy}
                        alt=""
                      />
                      <p>
                        <span style={{color: "#F9C80E"}}> 2nd Prize </span>
                        <span>-{item.prize}</span>
                      </p>
                    </div>
                  ) : item.rank === 3 ? (
                    <div className="winners">
                      <img
                        src={ThirdTrophy}
                        alt=""
                      />
                      <p>
                        <span style={{color: "#F25F5C"}}> 3rd Prize</span>
                        <span>-{item.prize}</span>
                      </p>
                    </div>
                  ) : null}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="competitionLowerWrapper">
        {timer === false ? null : (
          <div className="competition_details_box_lower">
            <div className="competition_details_box_lower_wrapper">
              {status === "Ongoing" || status === "Ended" || status === "paticipated" ? (
                <div className="clock_wrapper">
                  <h5>Ends in</h5>
                  <div className="time_container">
                    <div>
                      <h4>{days}</h4>
                      <span>days</span>
                    </div>
                    <div className="colon">:</div>
                    <div>
                      <h4>{hours}</h4>
                      <span>hours</span>
                    </div>
                    <div className="colon">:</div>
                    <div>
                      <h4>{minutes}</h4>
                      <span>mins</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="clock_wrapper">
                  <h5>Starts in</h5>
                  <div className="time_container">
                    <div>
                      <h4>{days}</h4>
                      <span>days</span>
                    </div>
                    <div className="colon">:</div>
                    <div>
                      <h4>{hours}</h4>
                      <span>hours</span>
                    </div>
                    <div className="colon">:</div>
                    <div>
                      <h4>{minutes}</h4>
                      <span>mins</span>
                    </div>
                  </div>
                </div>
              )}
              <div className="contestants_details_container">
                <div className="grey_text">Total Entries</div>
                <div className="progress_bar">
                  <div
                    className="progress"
                    style={{width: `${participationPercentage}%`}}
                  ></div>
                </div>
                <div className="contestants_participated">
                  <p className="grey_text">{competition.participated_contestants} contestants</p>
                  <p className="grey_text">{competition.total_contestants}</p>
                </div>
              </div>
            </div>
          </div>
        )}
        {enterCompetition === false || competition?.participated === true ? (
          <div className="bg_img_container _btn">
            <button
              className="enter_competition_btn"
              onClick={() => handleSeeCompetition(competition?.id)}
            >
              See Leaderboard
            </button>
          </div>
        ) : (
          <div className="bg_img_container _btn">
            <button
              className="enter_competition_btn"
              onClick={handleEnterCompetition}
            >
              Enter Competition
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default CompetitionDetailsBox;
