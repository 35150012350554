import React, {useEffect, useState} from "react";
import "./style.scss";
import minimunTradingDay from "../../../assets/icons/mininumTradingDays.svg";
import profitTarget from "../../../assets/icons/profitTarget.svg";
import DailyLoss from "../../../assets/icons/DailyLoss.svg";
import initialBalance from "../../../assets/icons/initialBalance.svg";

const Goals = ({programObjective}) => {
  const calculateRemainingTime = () => {
    const currentGMTTime = new Date();
    const targetGMT2Time = new Date();

    targetGMT2Time.setUTCHours(21, 0, 0, 0);

    let remaining = targetGMT2Time - currentGMTTime;

    if (remaining <= 0) {
      const nextDayGMT2Time = new Date(targetGMT2Time);
      nextDayGMT2Time.setDate(nextDayGMT2Time.getDate() + 1);
      remaining = nextDayGMT2Time - currentGMTTime;
    }

    return remaining;
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateRemainingTime());
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(timeRemaining - 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, [timeRemaining]);

  const showRemaining = (endTime) => {
    const days = Math.floor(timeRemaining / (24 * 60 * 60 * 1000));
    const hours = Math.floor((timeRemaining % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    const minutes = Math.floor((timeRemaining % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((timeRemaining % (60 * 1000)) / 1000);

    return `${hours}:${minutes}:${seconds}`;
  };

  const GoalsOverview = [
    {
      heading: "Max loss",
      minMax: programObjective?.max_loss,
      status: programObjective?.max_loss_status,
      target: programObjective?.max_loss_target,
      remaining: programObjective?.remaining_max_loss,
      icon: minimunTradingDay,
      percentage: `${(programObjective?.max_loss / programObjective?.max_loss_target) * 100}`,
    },
    {
      heading: "Profit",
      minMax: programObjective?.profit,
      status: programObjective?.profit_status,
      target: programObjective?.profit_target,
      remaining: programObjective?.remaining_profit,
      icon: profitTarget,
      percentage: `${(programObjective?.profit / programObjective?.profit_target) * 100}`,
    },
    {
      heading: "Daily loss",
      minMax: programObjective?.max_daily_loss,
      status: programObjective?.max_daily_loss_status,
      target: programObjective?.max_daily_loss_target,
      remaining: programObjective?.remaining_daily_max_loss,
      icon: DailyLoss,
      percentage: `${(programObjective?.max_daily_loss / programObjective?.max_daily_loss_target) * 100}`,
    },
    {
      heading: "Trading days",
      minMax: programObjective?.trading_days,
      status: programObjective?.trading_days_status,
      target: programObjective?.trading_days_target,
      remaining: programObjective?.remaining_trading_days,
      icon: initialBalance,
      percentage: `${(programObjective?.trading_days / programObjective?.trading_days_target) * 100}`,
    },
  ];
  let accountPassed = true;

  for (const goal of GoalsOverview) {
    if (goal.status !== "Passed") {
      accountPassed = false;
      break;
    }
  }
  return (
    <div className="goals_overview_contaniner">
      <div className="goalsHeading">
        <h1>Goals Overview</h1>
      </div>
      <div className="goals_lines">
        {accountPassed && (
          <p>You have successfully passed this phase of your evaluation! You will receive an e-mail with confirmation that your new account has been set up to continue to the next phase.</p>
        )}
      </div>
      <div className="goalsContent">
        {GoalsOverview?.map((item, index) => {
          return (
            <div
              className="goalBox"
              key={index}
            >
              <div className="goalBox_status">
                <p
                  // className="passFailTag"
                  className={`${
                    item.status === "in_progress" ? "status status-progress" : item.status === "failed" ? "status-error status" : item.status === "passed" ? "status-passes status" : "status"
                  }`}
                >
                  {item.status?.replace("_", " ")}
                </p>
              </div>
              <div className="goalBox_details">
                <h1>
                  {" "}
                  <img
                    src={item.icon}
                    alt=""
                  />{" "}
                  {item.heading}
                </h1>
                <div className="minMax">
                  <p>{item.heading} target</p>

                  <p>
                    {item.target} {item.heading === "Trading days" && " Days"}
                  </p>
                </div>
                <div className="resultRecord">
                  <h2>
                    {item.heading} {item.result ? "result" : "recorded"}
                  </h2>
                  <p>
                    {item.minMax}
                    {item.heading === "Trading days" && " Days"}
                  </p>
                </div>
              </div>
              {item.heading === "Daily loss" && <p style={{color: "red", fontFamily: "DM Sans"}}>Timer Reset In : {showRemaining()}</p>}
              <div className="goalBox_progress">
                <span
                  className="progress_bar"
                  style={{width: `${item.percentage}%`}}
                ></span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Goals;
