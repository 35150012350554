import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {getPayoutAffiliateList} from "../../../../store/reducers/accountSlice";
import {paymentMethods, payoutRequest} from "../../../../utils/apis/payment";
import "./style.scss";

import Spinner from "../../../../reusableComponents/Spinner";
import {returnErrors} from "../../../../store/reducers/error";
import {returnMessages} from "../../../../store/reducers/message";
import customStyles from "../../../../customstyle/customstyle";
import RecipientBankDetails from "../../RecipientBankDetails";
import edit from "../../../../assets/icons/edit-pencil copy.svg";

function AffiliatePayout({isReqPayout, setReqPayout}) {
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  const payoutAfs = useSelector((state) => state.account.payoutListAFS);

  useEffect(() => {
    dispatch(getPayoutAffiliateList(idToken));
  }, [idToken]);
  const [totalAmount, setTotalAmount] = useState(payoutAfs?.data);
  useEffect(() => {
    setTotalAmount(payoutAfs?.data);
  }, [payoutAfs]);

  const methodOptions = [
    {
      label: (
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
          <span>WIRE</span>{" "}
          <img
            src={edit}
            alt=""
            style={{marginRight: "15px"}}
            onClick={(e) => {
              e.stopPropagation();
              setValue(3);
            }}
          />
        </div>
      ),
      value: "SWIFT",
    },

    {
      label: (
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
          <span>USDT TRC20</span>{" "}
          <img
            src={edit}
            alt=""
            style={{marginRight: "15px"}}
            onClick={(e) => {
              e.stopPropagation();
              setValue(6);
            }}
          />
        </div>
      ),
      value: "CRYPTO",
    },
  ];

  const totalAmounts = totalAmount.reduce((acc, item) => acc + item.amount, 0);
  const commission = totalAmount.map((item) => item.id);

  const [payout, setPayout] = useState({
    amount: totalAmounts,
    reason: "",
    login_id: null,
    payout_type: "AFS",
    method: "",
    commission: [],
  });
  console.log(payout, "payout");
  useEffect(() => {
    setPayout({...payout, commission: commission, amount: totalAmounts});
  }, [totalAmount]);

  const handleInputChange = (e) => {
    const {name, value} = e.target;
    if (e.target.type === "number") {
      setPayout({...payout, [name]: Number(value)});
    } else {
      setPayout({...payout, [name]: value});
    }
  };
  const handleSelectMethod = (selectedOption) => {
    setPayout({...payout, method: selectedOption.value});
  };

  const formData = payout; //new FormData();
  // for (const key in payout) {
  //   formData.append(key, payout[key]);
  // }

  const [isSpinner, setIsSpinner] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [refreshBanksDetails, setRefreshBanksDetails] = useState(false);

  const [paymentDetails, setPaymentdetails] = useState([]);
  const fetchPaymentdetails = async () => {
    const respo = await paymentMethods(idToken, dispatch);
    setPaymentdetails(respo);
  };
  useEffect(() => {
    fetchPaymentdetails();
  }, [refreshBanksDetails]);
  const closeBankDetailsModal = () => {
    setValue(false);
  };
  const [value, setValue] = useState(null);
  useEffect(() => {
    if (payout?.method === "SWIFT") {
      setValue(3);
    }

    if (payout?.method === "CRYPTO") {
      setValue(6);
    }
  }, [payout?.method]);

  const handleSubmit = async () => {
    if (payout.commission.length <= 0) {
      dispatch(returnErrors("Not a Valid Amount", 400));
      return;
    } else if (payout.method === undefined) {
      dispatch(returnErrors("Select Payout Method", 400));
      return;
    }
    setIsSpinner(true);

    const response = await payoutRequest(idToken, formData);

    if (response.status === 200 || response.status === 201) {
      dispatch(returnMessages("Payout Successful", 200));
      setIsSpinner(false);
    } else {
      dispatch(returnErrors("  ", 400));
      setIsSpinner(false);
    }
  };

  return (
    <div className="reqpayout">
      <h4>Affiliate</h4>
      <RecipientBankDetails
        refreshBanksDetails={refreshBanksDetails}
        setRefreshBanksDetails={setRefreshBanksDetails}
        value={value}
        setValue={setValue}
        closeBankDetailsModal={closeBankDetailsModal}
      />
      <div className="affiliate_payout_wrapper">
        <div className="grid_container">
          <div className="col1">
            <div className="col1_lower">
              <div className="select_container_L">
                <div className="withdrawal_amount">
                  <p>
                    Total Withdrawal Amount*:- <span style={{fontWight: "500"}}> {totalAmounts || 0} </span>
                    <span></span>
                  </p>
                </div>
                <div className="col1_lower_select_container">
                  <p>Choose Payout Method</p>
                  <Select
                    options={methodOptions}
                    styles={customStyles}
                    name="method"
                    value={methodOptions?.find((option) => option.value === payout.method)}
                    onChange={handleSelectMethod}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    isSearchable={false}
                  />
                </div>
              </div>
              <div className="select_container_R">
                {/* <div
                style={{width: "100%"}}
                className="col1_lower_select_container amountBtn"
              >
                <p>Total Amount ($)*</p>
                <input
                  type="number"
                  name="amount"
                  value={totalAmounts}
                  placeholder="Amount"
                  disabled
                />
              </div> */}
                <div className="col1_lower_select_container">
                  <p>Reason</p>
                  <textarea
                    type="text"
                    name="reason"
                    value={payout.reason}
                    onChange={handleInputChange}
                    placeholder="Reason"
                  />
                </div>
              </div>
            </div>
            <div className="col1_button">
              <button
                style={{
                  // border: "1px solid #eaf0f8",
                  borderRadius: "5px",
                  padding: "14px 20px",
                  margin: "10px 0 0 0",
                  background: "#22a6ae",
                  color: "#070C09",
                }}
                onClick={handleSubmit}
                disabled={isSpinner || !totalAmounts}
              >
                {isSpinner ? <Spinner /> : <>Submit</>}
              </button>
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

export default AffiliatePayout;
