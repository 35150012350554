import React, {useState, useMemo, Fragment, useEffect} from "react";
import {useTable, usePagination, useSortBy} from "react-table";
import {Link} from "react-router-dom";
import WebGreyIcon from "../../../assets/icons/web-grey.svg";
import CaretDownIcon from "../../../assets/icons/caret-down.svg";
import CaretLeftIcon from "../../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../../assets/icons/caret-right.svg";
import {ReactComponent as DropdownIcon} from "../../../assets/icons/dropdown-arrow-gray.svg";
import StarBlueIcon from "../../../assets/icons/star-blue.svg";
import StarGreyIcon from "../../../assets/icons/star-grey.svg";
import AddCircleIcon from "../../../assets/icons/add-circle.svg";
import Card from "../../../reusableComponents/Card";

const CaseHistory = ({onTabChange, setActiveTab, activeTab, tabItems}) => {
  const handleButtonClick = () => {
    if (tabItems) {
      setActiveTab(activeTab);
      alert("hey", activeTab);
    }
    // onTabChange(activeTab);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Card className="economiccalender-container">
      <div className="economiccalender-subcontainer">
        <div className="economiccalender-innercontainer selected-row-header">
          <h2 className="table-head">Cases History</h2>
          <div
            className="create-case"
            onClick={handleButtonClick}
          >
            <img
              src={AddCircleIcon}
              alt="AddCircleIcon"
            />
            Create a Case
          </div>
        </div>
        <div className="economiccalender-container_table">
          <Table />
        </div>
      </div>
    </Card>
  );
};

export default CaseHistory;

function Table() {
  const columns = useMemo(
    () => [
      {
        Header: (
          <>
            <div className="column_header">
              Subject <DropdownIcon />
            </div>
          </>
        ),
        accessor: "subject",
      },
      {
        Header: (
          <>
            <div className="column_header">
              Created <DropdownIcon />
            </div>
          </>
        ),
        accessor: "created",
      },
      {
        Header: (
          <>
            <div className="column_header">
              Case ID <DropdownIcon />
            </div>
          </>
        ),
        accessor: "caseId",
      },
      {
        Header: (
          <>
            <div className="column_header">
              Case type <DropdownIcon />
            </div>
          </>
        ),
        accessor: "caseType",
      },
      {
        Header: (
          <>
            <div className="column_header">
              Severity <DropdownIcon />
            </div>
          </>
        ),
        accessor: "severity",
      },
      {
        Header: "Status",
        accessor: "status",
      },
    ],
    [],
  );

  const data = useMemo(
    () => [
      {
        subject: "Access Granted",
        created: "05 Jan, 2022  06:11:42",
        caseId: "8224407931",
        caseType: "Account",
        severity: "General question",
        status: 1,
        importance: 1,
        statusCode: 1,
        category: "Loreum Ipsum",
        description:
          "The Average Earnings Index measures change in the price businesses and the government pay for labor, including bonuses. The Average Earnings figure gives us a good indication of personal income growth during the given month.",
      },
      {
        subject: "Production access",
        created: "29 Jan, 2022  06:11:42",
        caseId: "234445533",
        caseType: "Account",
        severity: "General question",
        status: 1,
        statusCode: 1,
        importance: 3,
        category: "Loreum Ipsum",
        description:
          "The Average Earnings Index measures change in the price businesses and the government pay for labor, including bonuses. The Average Earnings figure gives us a good indication of personal income growth during the given month.",
      },
      {
        subject: "New access",
        created: "22 Jan, 2022  06:11:42",
        caseId: "5156007931",
        caseType: "Account",
        severity: "General question",
        status: 2,
        importance: 1,
        statusCode: 1,
        category: "Loreum Ipsum",
        description:
          "The Average Earnings Index measures change in the price businesses and the government pay for labor, including bonuses. The Average Earnings figure gives us a good indication of personal income growth during the given month.",
      },
      {
        subject: "Issue Generated",
        created: "13 Jan, 2022  06:11:42",
        caseId: "9996007931",
        caseType: "Account",
        severity: "General question",
        status: 1,
        importance: 1,
        statusCode: 1,
        category: "Loreum Ipsum",
        description:
          "The Average Earnings Index measures change in the price businesses and the government pay for labor, including bonuses. The Average Earnings figure gives us a good indication of personal income growth during the given month.",
      },
    ],
    [],
  );
  const initialState = useMemo(
    () => [
      {
        pageSize: 10,
        pageIndex: 0,
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: {pageIndex, pageSize},
  } = useTable({columns, data, initialState}, useSortBy, usePagination);

  const [selectedRow, setSelectedRow] = useState(null);

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...(column.getHeaderProps().key === "header_status"
                    ? {...column.getHeaderProps()}
                    : {
                        ...column.getHeaderProps(column.getSortByToggleProps()),
                      })}
                  className={column.isSorted ? (column.isSortedDesc ? "sort down" : "sort up") : ""}
                >
                  <span>{column.render("Header")} </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <Fragment key={rowIndex}>
                <tr
                  {...row.getRowProps()}
                  className={`economiccalender-row ${selectedRow === rowIndex ? "economiccalender-row-active" : ""} `}
                  onClick={() => setSelectedRow(rowIndex)}
                >
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>
                      {cell.column.id === "status" ? (
                        <>
                          {cell.row.values.status === 1 ? (
                            <div
                              className="status-container"
                              style={{
                                background: "rgba(200, 233, 197, 0.50)",
                                color: "#4CB944",
                              }}
                            >
                              Resolved
                            </div>
                          ) : (
                            cell.row.values.status === 2 && (
                              <div
                                className="status-container"
                                style={{
                                  background: "#F5BDC0",
                                  color: "#9E1D26",
                                }}
                              >
                                Dismissed
                              </div>
                            )
                          )}
                        </>
                      ) : cell.column.id === "importance" ? (
                        <Importance activeStars={cell.value} />
                      ) : (
                        cell.value
                      )}
                    </td>
                  ))}
                </tr>
                {selectedRow === rowIndex && (
                  <tr>
                    <td
                      colSpan="7"
                      className="row-selected"
                    >
                      <div className="supportcases-td-container">
                        <div className="td-main-div">
                          <div className="selected-row-header">
                            <h3 className="row-head">{row.original.subject}</h3>
                            <p>Reopen case</p>
                          </div>

                          <div className="supportcases-type-details">
                            <div className="supportcases-box">
                              <p>Case ID</p>
                              <h4>{row.values.caseId}</h4>
                            </div>
                            <div className="supportcases-box">
                              <p>Case Type</p>
                              <h4>{row.values.caseType}</h4>
                            </div>
                            <div className="supportcases-box">
                              <p>Status</p>
                              <h4>
                                {row.values.status === 1 ? (
                                  <div
                                    className="status-container"
                                    style={{
                                      background: "rgba(200, 233, 197, 0.50)",
                                      color: "#4CB944",
                                    }}
                                  >
                                    Resolved
                                  </div>
                                ) : (
                                  row.values.status === 2 && (
                                    <div
                                      className="status-container"
                                      style={{
                                        background: "#F5BDC0",
                                        color: "#9E1D26",
                                      }}
                                    >
                                      Dismissed
                                    </div>
                                  )
                                )}
                              </h4>
                            </div>
                            <div className="supportcases-box">
                              <p>Opened by</p>
                              <h4>Abhishek</h4>
                            </div>
                          </div>
                          <div className="supportcases-comments">
                            <p>Comments</p>
                            <h4>{row.original.description}</h4>
                            <Link to="">Click here for full event details.</Link>
                          </div>
                          <div className="supportcases-category">
                            <div className="category-details">
                              <p>Importance:</p>
                              <h5>
                                <Importance activeStars={row.original.importance} />
                              </h5>
                            </div>
                            <div className="category-details">
                              <p>Created</p>
                              <h5>{row.original.created}</h5>
                            </div>
                            <div className="category-details">
                              <p>Category</p>
                              <h5>{row.original.category}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
      {pageOptions.length > 1 && (
        <div className="economiccalender-pagination pagination">
          <img
            src={CaretLeftIcon}
            alt="left-arrow"
          />
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </button>
          {pageOptions.map((pageNumber, index) => (
            <>
              <button
                key={index}
                onClick={() => gotoPage(index)}
                className={pageIndex === index ? "btn-active" : "non-active"}
              >
                {pageNumber < 10 ? `0${pageNumber + 1}` : pageNumber}
              </button>
            </>
          ))}
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </button>
          <img
            src={CaretRightIcon}
            alt="right-arrow"
          />
        </div>
      )}
    </>
  );
}

function Importance({activeStars}) {
  let totalStars = 3;
  let inactiveStars = totalStars - activeStars;
  const stars = [];
  for (let i = 0; i < activeStars; i++) {
    stars.push(
      <img
        src={StarBlueIcon}
        alt=""
        key={`active${i}`}
        className="importance-star"
      />,
    );
  }
  for (let i = 0; i < inactiveStars; i++) {
    stars.push(
      <img
        src={StarGreyIcon}
        alt=""
        key={`inactive${i}`}
        className="importance-star"
      />,
    );
  }
  return <>{stars}</>;
}
