import React, {useEffect, useRef, useState} from "react";
import "./style.scss";
import {usePagination, useTable} from "react-table";
import {useDispatch, useSelector} from "react-redux";

import TrophyGreen from "../../../assets/icons/trophy-green.svg";
import DropdownIcon from "../../../assets/icons/dropdown-arrow-gray.svg";

import CaretDownIcon from "../../../assets/icons/caret-down.svg";
import CaretLeftIcon from "../../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../../assets/icons/caret-right.svg";
import BlueTickIcon from "../../../assets/icons/tick-blue-circle.svg";
import CalenderIcon from "../../../assets/icons/calender.svg";
import InvoiceIcon from "../../../assets/icons/download.svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import Calendar from "../../../reusableComponents/Calendar";
import Card from "../../../reusableComponents/Card";
// import { getPaymentHistory } from "../../../utils/apis";
// import { setPaymentHistory } from "../../../store/reducers/accountSlice";
import {formatDateToCustomFormat} from "../../../utils/helpers/date";
import {capitalizeFirstLetter} from "../../../utils/helpers/string";
import {getPaymentHistory} from "../../../store/reducers/accountSlice";
import {ToastContainer, toast} from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import {Pagination} from "@mui/material";
import ReactTable from "../../ReactTable/ReactTable";

function PaymentHistoryTable() {
  const dispatch = useDispatch();

  const idToken = useSelector((state) => state.auth.idToken);
  const paymentValue = useSelector((state) => state.account.paymentHistory);
  const [paymentHistory, setPaymentHistory] = useState(paymentValue);

  useEffect(() => {
    dispatch(getPaymentHistory(idToken));
  }, [idToken]);
  useEffect(() => {
    setPaymentHistory(paymentValue);
  }, [paymentValue]);
  const [data, setData] = useState([]);
  const [isCalender, setIsCalender] = useState(false);
  const [date, setDate] = useState(new Date());
  const [isFundingDropdown, setFundingDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [isBalanceDropdown, setBalanceDropdown] = useState(false);
  const balanceRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !event.target.classList.contains("column_header")) {
        setFundingDropdown(false);
      }

      if (balanceRef.current && !balanceRef.current.contains(event.target) && !event.target.classList.contains("column_header")) {
        setBalanceDropdown(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  const handleCopyToClipboard = (text) => {
    toast("Copied Transaction Id", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      type: "success",
      theme: "dark",
      className: "custom-toast-container",
    });
  };
  const truncateString = (inputString, maxLength) => {
    const formattedString = inputString.toString();
    if (formattedString.length > maxLength) {
      return inputString.toString().substring(0, maxLength) + "...";
    }
    return inputString;
  };

  const fundingBackgroundColors = {
    "Free Plan": "free_background",
    "Aggressive Plan": "aggressive_background",
    "Standard Plan": "standard_background",
    "Swing Plan": "swing_background",
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <>
            <div className="column_header">
              Funding Evaluation{" "}
              <img
                src={DropdownIcon}
                alt="arrow-down"
              />
            </div>
            {isFundingDropdown && (
              <div
                className="table_dropdown funding_dropdown"
                ref={dropdownRef}
              >
                <ul>
                  <li>
                    All
                    <img
                      src={BlueTickIcon}
                      alt="blue tick"
                    />
                  </li>
                  <li>Standard Plan</li>
                  <li>Aggressive Plan</li>
                  <li>Swing Plan</li>
                  <li>Instant Plan</li>
                </ul>
              </div>
            )}
          </>
        ),
        accessor: "funding_evaluation",
        Cell: ({value, row}) => (
          <div className="column_one_wrapper">
            {/* <div className={`sno_wrapper ${fundingBackgroundColors[value]}`}>{`${row.index + 1}.`}</div> */}
            <div>{value}</div>
          </div>
        ),
      },
      {
        Header: (
          <>
            <ToastContainer />
            <div
              // onClick={(e) => {
              //   e.stopPropagation();
              //   setBalanceDropdown(!isBalanceDropdown);
              // }}
              className="column_header"
            >
              Account Number{" "}
              <img
                src={DropdownIcon}
                alt="arrow-down"
              />{" "}
            </div>
            {isBalanceDropdown && (
              <div
                className="table_dropdown balance_dropdown"
                ref={balanceRef}
              >
                <ul>
                  <li>
                    All
                    <img
                      src={BlueTickIcon}
                      alt="blue tick"
                    />
                  </li>
                  <li>25K Challenge</li>
                  <li>50K Challenge</li>
                  <li>100K Challenge</li>
                  <li>200K Challenge</li>
                </ul>
              </div>
            )}
          </>
        ),
        accessor: "account",
        Cell: ({value}) => (
          <div className="column_one_wrapper">
            <div>{value ? value : "-"}</div>
          </div>
        ),
      },
      // {
      //   Header: (
      //     <div className="column_header">
      //       Transaction Id{" "}
      //       <img
      //         src={DropdownIcon}
      //         alt="arrow-down"
      //       />
      //     </div>
      //   ),
      //   // accessor: "transactionId",
      //   accessor: "id",
      // },
      {
        Header: (
          <div className="column_header CUSTOM">
            Transaction Id
            <img
              src={DropdownIcon}
              alt="arrow-down"
            />
          </div>
        ),
        accessor: "id",
        Cell: ({value}) => (
          <>
            <div
              className="column_one"
              style={{
                display: "flex",
                alignItems: "center",
                width: "250px",
                justifyContent: "space-between",
              }}
            >
              <p> {truncateString(value, 20)}</p>
              <CopyToClipboard
                onCopy={handleCopyToClipboard}
                text={value}
              >
                {/* <button> */}
                <p
                  className="table_copy_button"
                  style={{
                    marginRight: "20px",
                    // backgroundColor: "#35d269",
                    cursor: "pointer",
                  }}
                  // onClick={() => handleCopyToClipboard(row.original?.payment_id)}
                >
                  {/* <ContentCopyIcon sx={{fontSize: "20px", backgroundColor: "#35d269", color: "black"}} /> */}
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="24"
                      height="24"
                      rx="12"
                      fill="#071E0D"
                    />
                    <path
                      d="M17.886 12.574C18 12.2987 18 11.9487 18 11.25C18 10.5513 18 10.2013 17.886 9.926C17.8106 9.74395 17.7001 9.57854 17.5608 9.43922C17.4215 9.29989 17.256 9.18938 17.074 9.114C16.7987 9 16.4487 9 15.75 9H11.4C10.56 9 10.14 9 9.81933 9.16333C9.53684 9.30719 9.30719 9.53684 9.16333 9.81933C9 10.1393 9 10.5593 9 11.4V15.75C9 16.4487 9 16.7987 9.114 17.074C9.26667 17.4413 9.55867 17.734 9.926 17.886C10.2013 18 10.5513 18 11.25 18C11.9487 18 12.2987 18 12.574 17.886M17.886 12.574C17.8106 12.756 17.7001 12.9215 17.5608 13.0608C17.4215 13.2001 17.256 13.3106 17.074 13.386C16.7987 13.5 16.4487 13.5 15.75 13.5C15.0513 13.5 14.7013 13.5 14.426 13.614C14.244 13.6894 14.0785 13.7999 13.9392 13.9392C13.7999 14.0785 13.6894 14.244 13.614 14.426C13.5 14.7013 13.5 15.0513 13.5 15.75C13.5 16.4487 13.5 16.7987 13.386 17.074C13.3106 17.256 13.2001 17.4215 13.0608 17.5608C12.9215 17.7001 12.756 17.8106 12.574 17.886M17.886 12.574C17.5275 13.8107 16.8678 14.9391 15.9661 15.8582C15.0645 16.7774 13.9489 17.4585 12.7193 17.8407L12.574 17.886M15 9V8.4C15 7.56 15 7.14 14.8367 6.81933C14.693 6.53694 14.4636 6.3073 14.1813 6.16333C13.86 6 13.44 6 12.6 6H8.4C7.56 6 7.14 6 6.81933 6.16333C6.53684 6.30719 6.30719 6.53684 6.16333 6.81933C6 7.13933 6 7.55933 6 8.4V12.6C6 13.44 6 13.86 6.16333 14.1807C6.30733 14.4633 6.53667 14.6927 6.81933 14.8367C7.13933 15 7.56 15 8.40067 15H9"
                      stroke="#36D66B"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </p>
                {/* </button> */}
              </CopyToClipboard>
            </div>
          </>
        ),
      },
      {
        Header: (
          <div className="column_header">
            Date
            <img
              src={DropdownIcon}
              alt="arrow-down"
            />
          </div>
        ),
        accessor: "created_at",
        sortType: "datetime",
        Cell: ({value}) => (
          <div className="column_one_wrapper">
            <div>{formatDateToCustomFormat(value)}</div>
          </div>
        ),
      },
      {
        Header: (
          <div className="column_header">
            Amount{" "}
            <img
              src={DropdownIcon}
              alt="arrow-down"
            />
          </div>
        ),
        accessor: "amount",
        Cell: ({value}) => (
          <div className="column_one_wrapper">
            <div>{`$ ${Number(value)?.toFixed(2)}`}</div>
          </div>
        ),
      },

      {
        Header: "Invoice",
        accessor: "invoice",
        Cell: ({value}) => (
          <div className="column_one_wrapper">
            {value ? (
              <a
                href={value}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <DownloadIcon />
              </a>
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        Header: (
          <div className="column_header">
            Status
            <img
              src={DropdownIcon}
              alt="arrow-down"
            />
          </div>
        ),
        accessor: "payment_status",
        Cell: ({value}) => (
          <div className="column_one_wrapper">
            <div className={`status_tag ${value === "open" ? "status-open" : "successed" === "successed" ? "status-sucsses" : "status-error"}`}>{capitalizeFirstLetter(value)}</div>
          </div>
        ),
      },
    ],
    [isFundingDropdown, isBalanceDropdown],
  );
  return (
    <Card className="paymenthistory_table">
      {/* <div className="all_header">
        <h2>Payments</h2>
      </div> */}
      <div className=" table_wrapper">
        <div className="table_header">
          {/* <p>Payment History</p> */}
          <div className="select_wrapper">
            {/* <div
              className="calendar-button"
              onClick={() => setIsCalender((prev) => !prev)}
            >
              <img src={CalenderIcon} alt="calender icon" />
              <p>Past 90 Days</p>
              <img src={CaretDownIcon} alt="" />
            </div> */}

            <div className="calendar_wrapper">
              {isCalender && (
                <Calendar
                  onChange={(date) => setDate(date)}
                  value={date}
                  // formatMonth={"MMM"}
                  selectRange={true}
                  showNeighboringMonth={false}
                />
              )}
            </div>
          </div>
        </div>
        <div className="table_scroll_wrapper">
          {/* <Table data={(Array.isArray(paymentHistory?.data) && paymentHistory.data) || []} /> */}
          <ReactTable
            data={Array.isArray(paymentHistory?.data) && paymentHistory.data}
            columns={columns}
          />
        </div>
      </div>
    </Card>
  );
}

export default PaymentHistoryTable;

// const Table = ({data}) => {

//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     prepareRow,
//     page,
//     pageOptions,
//     gotoPage,
//     nextPage,
//     previousPage,
//     canNextPage,
//     canPreviousPage,
//     pageCount,
//     state: {pageIndex, pageSize},
//   } = useTable(
//     {
//       columns,
//       data,
//       initialState: {pageIndex: 0, pageSize: 10},
//     },
//     usePagination,
//   );
//   const [currentPage, setCurrentPage] = useState(1);
//   const pageButtons = [];
//   const [gotoPageInput, setGotoPageInput] = useState("");
//   const handlePageChange = (event, newPage) => {
//     gotoPage(newPage - 1); // Adjust for 0-based index used by react-table
//     setCurrentPage(newPage);
//   };

//   const handleGotoPage = () => {
//     const pageNumber = parseInt(gotoPageInput, 10);
//     if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= pageCount) {
//       gotoPage(pageNumber - 1);
//       setCurrentPage(pageNumber);
//       setGotoPageInput("");
//     }
//   };
//   for (let i = 1; i <= pageCount; i++) {
//     pageButtons?.push(
//       <button
//         key={i}
//         className={`${i === currentPage ? "button-active" : "button-inactive"}`}
//         onClick={() => {
//           gotoPage(i - 1);
//           setCurrentPage(i);
//         }}
//       >
//         {i < 10 ? `0${i}` : i}
//       </button>,
//     );
//   }

//   return (
//     <div className="table_scroll">
//       <div className="table_main">
//         <table
//           {...getTableProps()}
//           className="leaderboard-table"
//         >
//           <thead>
//             {headerGroups.map((headerGroup) => (
//               <tr {...headerGroup.getHeaderGroupProps()}>
//                 {headerGroup.headers.map((column) => (
//                   <th {...column.getHeaderProps()}>{column.render("Header")}</th>
//                 ))}
//               </tr>
//             ))}
//           </thead>
//           <tbody {...getTableBodyProps()}>
//             {page.map((row) => {
//               prepareRow(row);
//               return (
//                 <tr {...row.getRowProps()}>
//                   {row.cells.map((cell) => (
//                     <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
//                   ))}
//                 </tr>
//               );
//             })}
//           </tbody>
//         </table>
//       </div>

//       {pageOptions.length > 1 && (
//         <div className="pagination_main">
//           <Pagination
//             count={pageCount}
//             page={currentPage}
//             onChange={handlePageChange}
//             color="primary"
//             boundaryCount={1}
//             className="Pagination_btn"
//           />
//           <div className="goto-page-input">
//             <input
//               type="number"
//               placeholder="Go to Page"
//               value={gotoPageInput}
//               onChange={(e) => setGotoPageInput(e.target.value)}
//             />
//             <button
//               className="goToButton"
//               onClick={handleGotoPage}
//             >
//               Go
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

function DownloadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g opacity="0.41">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.7396 3.25C9.96556 3.25 9.32056 3.841 9.25256 4.612C9.1114 6.22294 9.07565 7.84139 9.14556 9.457L8.89256 9.475L7.40256 9.583C7.18496 9.5989 6.97522 9.67103 6.79386 9.79233C6.6125 9.91363 6.46576 10.0799 6.36798 10.275C6.2702 10.47 6.22475 10.6871 6.23607 10.905C6.24739 11.1229 6.3151 11.3341 6.43256 11.518C7.52898 13.2337 8.94176 14.7252 10.5956 15.913L11.1916 16.342C11.4276 16.5116 11.7109 16.6029 12.0016 16.6029C12.2922 16.6029 12.5755 16.5116 12.8116 16.342L13.4076 15.913C15.0614 14.7253 16.4742 13.2337 17.5706 11.518C17.688 11.3341 17.7557 11.1229 17.7671 10.905C17.7784 10.6871 17.7329 10.47 17.6351 10.275C17.5374 10.0799 17.3906 9.91363 17.2093 9.79233C17.0279 9.67103 16.8182 9.5989 16.6006 9.583L15.1106 9.475C15.0262 9.46875 14.9419 9.46275 14.8576 9.457C14.9276 7.842 14.8916 6.223 14.7506 4.612C14.718 4.24011 14.5473 3.89392 14.272 3.64177C13.9967 3.38962 13.6369 3.24983 13.2636 3.25H10.7396ZM10.6836 10.115C10.5697 8.32763 10.5907 6.53421 10.7466 4.75H13.2566C13.4128 6.53418 13.4342 8.3276 13.3206 10.115C13.3144 10.2141 13.3279 10.3134 13.3603 10.4073C13.3928 10.5011 13.4436 10.5875 13.5097 10.6616C13.5759 10.7356 13.6561 10.7958 13.7456 10.8386C13.8352 10.8814 13.9324 10.906 14.0316 10.911C14.3556 10.927 14.6786 10.947 15.0016 10.971L16.0826 11.05C15.1224 12.464 13.9207 13.6979 12.5326 14.695L12.0026 15.076L11.4706 14.695C10.0824 13.6979 8.88069 12.464 7.92056 11.05L9.00156 10.97C9.32456 10.947 9.64856 10.927 9.97156 10.911C10.0708 10.9062 10.1681 10.8816 10.2578 10.8389C10.3475 10.7961 10.4278 10.736 10.494 10.6619C10.5603 10.5878 10.6111 10.5014 10.6437 10.4075C10.6762 10.3136 10.6898 10.2142 10.6836 10.115Z"
          fill="#6A796E"
        />
        <path
          d="M5.75 17C5.75 16.8011 5.67098 16.6103 5.53033 16.4697C5.38968 16.329 5.19891 16.25 5 16.25C4.80109 16.25 4.61032 16.329 4.46967 16.4697C4.32902 16.6103 4.25 16.8011 4.25 17V19C4.25 19.966 5.034 20.75 6 20.75H18C18.4641 20.75 18.9092 20.5656 19.2374 20.2374C19.5656 19.9092 19.75 19.4641 19.75 19V17C19.75 16.8011 19.671 16.6103 19.5303 16.4697C19.3897 16.329 19.1989 16.25 19 16.25C18.8011 16.25 18.6103 16.329 18.4697 16.4697C18.329 16.6103 18.25 16.8011 18.25 17V19C18.25 19.0663 18.2237 19.1299 18.1768 19.1768C18.1299 19.2237 18.0663 19.25 18 19.25H6C5.9337 19.25 5.87011 19.2237 5.82322 19.1768C5.77634 19.1299 5.75 19.0663 5.75 19V17Z"
          fill="#6A796E"
        />
      </g>
    </svg>
  );
}
