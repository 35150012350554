import React, {useState} from "react";
import "./style.scss";
import Eye from "../../../../assets/icons/eye-close-svgrepo-com.svg";
import EyeBlue from "../../../../assets/icons/eye-blue.svg";

function AccountPassword({changePassword, setChangePassword, setConfirmNewPassword, confirmNewPassword, disabledInputs, handleLogout}) {
  const [focusedInput, setFocusedInput] = useState(null);
  const [showPassword, setShowPassword] = useState({
    password: false,
    newPassword: false,
    verifyPassword: false,
  });

  const handleFocus = (inputName) => {
    setFocusedInput(inputName);
  };

  const handleBlur = () => {
    setFocusedInput(null);
  };

  const togglePasswordVisibility = (inputName) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [inputName]: !prevState[inputName],
    }));
  };
  const handlePasswordChange = (e) => {
    const {name, value} = e.target;
    setChangePassword({...changePassword, [name]: value});
  };

  const isFocused = (inputName) => focusedInput === inputName;

  return (
    <div className="account_password">
      <div className="account_password_input_container">
        <h3 className="password_header">Password</h3>
        <div className="account_input">
          <label htmlFor="password">Current Password</label>
          <br />
          <div className="account_input_inner_wrapper">
            <div className={`password_conatiner ${!disabledInputs && "editable"}`}>
              <input
                className={`account_password_input ${isFocused("password") ? "focused" : ""}`}
                type={showPassword.password ? "text" : "password"}
                name="current_password"
                placeholder="Current Password"
                // value={changePassword}
                onFocus={() => handleFocus("password")}
                onBlur={handleBlur}
                onChange={(e) => handlePasswordChange(e)}
                disabled={disabledInputs}
              />
              <img
                className="eye_icon"
                src={isFocused("password") ? Eye : EyeBlue}
                alt="eye icon"
                onClick={() => togglePasswordVisibility("password")}
              />
            </div>
          </div>
        </div>
        <div className="account_input">
          <label htmlFor="newPassword">New Password</label>
          <br />
          <div className="account_input_inner_wrapper">
            <div className={`password_conatiner ${!disabledInputs && "editable"}`}>
              <input
                className={`account_password_input ${isFocused("newPassword") ? "focused" : ""}`}
                type={showPassword.newPassword ? "text" : "password"}
                // value={confirmNewPassword}
                placeholder="New Password"
                name="new_password"
                onFocus={() => handleFocus("newPassword")}
                onBlur={handleBlur}
                onChange={(e) => handlePasswordChange(e)}
                disabled={disabledInputs}
              />
              <img
                className="eye_icon"
                src={isFocused("newPassword") ? Eye : EyeBlue}
                alt="eye icon"
                onClick={() => togglePasswordVisibility("newPassword")}
              />
            </div>
          </div>
        </div>
        <div className="account_input">
          <label htmlFor="verifyPassword">Verify Password</label>
          <br />
          <div className="account_input_inner_wrapper">
            <div className={`password_conatiner ${!disabledInputs && "editable"}`}>
              <input
                className={`account_password_input ${isFocused("verifyPassword") ? "focused" : ""}`}
                type={showPassword.verifyPassword ? "text" : "password"}
                // value={verifyPassword}
                name="verifyPassword"
                placeholder="Verify Password"
                // value={confirmNewPassword}
                onFocus={() => handleFocus("verifyPassword")}
                onBlur={handleBlur}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                disabled={disabledInputs}
              />
              <img
                className="eye_icon"
                src={isFocused("verifyPassword") ? Eye : EyeBlue}
                alt="eye icon"
                onClick={() => togglePasswordVisibility("verifyPassword")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-btn">
        <div className="action_btns">
          <button
            className="logout-btn"
            onClick={handleLogout}
          >
            Log Out
          </button>
        </div>
      </div>
      {/* <hr /> */}
    </div>
  );
}

export default AccountPassword;
