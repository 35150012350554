import React, {Fragment, useEffect, useState} from "react";
import "./style.scss";

import TierHeader from "../../components/FundingEvaluation/Plans/TierHeader";
import Tier from "../../components/FundingEvaluation/Plans/Tier";
import PlanFooter from "../../components/FundingEvaluation/Plans/PlanFooter";
import LoaderOverlay from "../../reusableComponents/Loader";
import {useSelector} from "react-redux";
import ErrorModal from "../../reusableComponents/ErrorModal";

function Plans({plans}) {
  
  const [activeTier, setActiveTier] = useState(null);
  
  const plansRedux = useSelector((state) => state?.account?.plans);

  const [errorModalOpen, setErrorModalOpen] = useState(plansRedux.error);
  const [isLoading, setIsLoading] = useState(plansRedux.loading);

  const tier = plans;
  const challenges = plans;

  plans?.challenges?.forEach((item) => challenges?.push(item?.name));

  //
  challenges?.forEach((name) => {
    const challenge = plans?.challenges?.find((item) => item?.name === name);
    if (challenge) {
      tier.push(challenge);
    }
  });

  useEffect(() => {
    setIsLoading(plansRedux.loading);
    setErrorModalOpen(plansRedux.error);
  }, [plansRedux]);
  const [footerValue, setFooterValue] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isLoading]);
  return (
    <div
      className="plans-container"
      // style={{marginTop: "100px"}}
    >
      {/* <h1>ok</h1> */}
      {errorModalOpen && (
        <ErrorModal
          desc={plansRedux.message}
          setModalOpen={setErrorModalOpen}
          btnText="Cancel"
        />
      )}
      {isLoading && <LoaderOverlay />}
      {challenges?.length !== 0 ? (
        <>
          <div className="plans-subcontainer">
            <TierHeader tier={tier} />
            <div className="plans-plan-container">
              {challenges.reverse()?.map((item, index) => (
                <Fragment key={index}>
                  <Tier
                    tiers={item}
                    activeTier={activeTier}
                    setActiveTier={setActiveTier}
                    setFooterValue={setFooterValue}
                  />
                </Fragment>
              ))}
            </div>
          </div>
          {/* <PlanFooter tier={footerValue} /> */}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Plans;
