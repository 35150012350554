import React, {useEffect, useMemo, useRef, useState} from "react";
import "./style.scss";
import InfoBox from "../../reusableComponents/InfoBox/index";
import FinancialPerformance from "../../components/Dashboard/FinancialPerformance";
import IncomeStatistics from "../../components/Dashboard/IncomeStatistics";
import Transaction from "../../components/Dashboard/Transaction";
import RevenueGrowth from "../../components/Dashboard/RevenueGrowth";
import ArrowIncrease from "../../assets/icons/arrow-increase.svg";
import ArrowDecrease from "../../assets/icons/arrow-decrease.svg";
import LayoutBox from "../../reusableComponents/LayoutBox";
import {useDispatch, useSelector} from "react-redux";
import dollarWhite from "../../assets/icons/dollar-white.svg";
import dollarGreen from "../../assets/icons/dollar-green.svg";
import dollarYellow from "../../assets/icons/dollar-normal.svg";
import award from "../../assets/icons/award.svg";
import blueRing from "../../assets/icons/blueRing.svg";
import grayRing from "../../assets/icons/gray.svg";
import filter from "../../assets/icons/filter.svg";
import downArrow from "../../assets/icons/downArrow.svg";
import addIcon from "../../assets/icons/AddIcon.svg";
import {Link, useNavigate} from "react-router-dom";
import {getAccountList, setActiveAccount, setSelectedChallenge} from "../../store/reducers/accountSlice";
import moment from "moment";
import ErrorModal from "../../reusableComponents/ErrorModal";
import LoaderOverlay from "../../reusableComponents/Loader";
import MainHeader from "../../layouts/pageLayout/MainHeader";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {Button, Grid, Pagination} from "@mui/material";
import SettingIcon from "../../assets/icons/Toolbar.svg";
import axios from "axios";
import Select from "react-select";
import {baseUrl} from "../../utils/apis";
import {truncateString} from "../../utils/helpers/string";
import lockIcon from "../../assets/icons/lock_icon.svg";
import equty_icon from "../../assets/icons/edge pro.svg";
import cross_iconnn from "../../assets/icons/cross_icon___.svg";
import done_icon from "../../assets/icons/done_icon.svg";
import filter_icon from "../../assets/icons/filterIcon.svg";
import downArroww from "../../assets/icons/downArrowNormalWhite.svg";
import plusIcon from "../../assets/icons/add_black_icon.svg";
import {usePagination, useTable} from "react-table";
import {toast} from "react-toastify";
import ReactTable from "../../components/ReactTable/ReactTable";
import Tabination from "../../reusableComponents/Tabination";
import customStyles from "../../customstyle/customstyle";

function Dashboard() {
  const {data, error, loading, message, active} = useSelector((state) => state.account.accountList);
  console.log(active, "activeAccountId");

  const idToken = useSelector((state) => state.auth.idToken);

  const [errorModalOpen, setErrorModalOpen] = useState(error);
  const [isLoading, setIsLoading] = useState(error);
  const plansValue = useSelector((state) => state.account?.plans?.data || []);
  const proPlan = plansValue[1]?.challenges?.find((item) => item.name === "100K Challenge");
  const proPlan1 = plansValue[2]?.challenges?.find((item) => item.name === "100K Challenge");
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isLoading]);
  const freeTril = [
    {
      text: "1:" + proPlan1?.leverage,
      icon: done_icon,
    },
    {
      text: `$${(proPlan1?.starting_balance / 100) * proPlan1?.max_daily_loss} (${proPlan1?.max_daily_loss}%) Max Daily Loss`,
      icon: done_icon,
    },
    {
      text: "EA",
      icon: `${proPlan1?.ea ? done_icon : cross_iconnn}`,
    },
    {
      text: `${proPlan1?.min_trading_days} Min Trading Days`,
      icon: done_icon,
    },
    {
      text: `${proPlan1?.profit_share}% Profit Split`,
      icon: done_icon,
    },
    {
      text: ` $${proPlan1?.starting_balance} Starting Balance`,
      icon: done_icon,
    },
  ];
  const freeTril1 = [
    {
      text: "1:" + proPlan?.leverage,
      icon: done_icon,
    },
    {
      text: `$${(proPlan?.starting_balance / 100) * proPlan?.max_daily_loss} (${proPlan?.max_daily_loss}%) Max Daily Loss`,
      icon: done_icon,
    },
    {
      text: "EA",
      icon: `${proPlan?.ea ? done_icon : cross_iconnn}`,
    },
    {
      text: `${proPlan?.min_trading_days} Min Trading Days`,
      icon: done_icon,
    },
    {
      text: `${proPlan?.profit_share}% Profit Split`,
      icon: done_icon,
    },
    {
      text: ` $${proPlan?.starting_balance} Starting Balance`,
      icon: done_icon,
    },
  ];

  const status = ["All", "Active", "Inactive"];

  useEffect(() => {
    setErrorModalOpen(error);
    setIsLoading(loading);
  }, [error, loading]);

  useEffect(() => {
    // dispatch(getAccountList(idToken));
  }, []);
  useEffect(() => {
    if (active == null) {
      data && dispatch(setActiveAccount(data[0]?.login_id));
    } else return;
  }, [data]);

  const active_id = useSelector((state) => state.account.accountList);

  const navigate = useNavigate();

  const GroupIcon = (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="35"
        height="35"
        rx="17.5"
        fill="#ffffff"
      />
      <path
        d="M21.2365 17.2763L21.7127 17.8557L21.2365 17.2763ZM13.4563 17.2863L13.955 16.7261L13.4563 17.2863ZM18.6965 23.9243L18.2638 23.3118L18.6965 23.9243ZM16.9979 25.1243L17.4307 25.7369L16.9979 25.1243ZM10.6103 14.7521L10.1115 15.3122L10.6103 14.7521ZM24.3168 14.7446L23.8406 14.1651L24.3168 14.7446ZM21 13.875C21.4142 13.875 21.75 13.5392 21.75 13.125C21.75 12.7108 21.4142 12.375 21 12.375V13.875ZM14 12.375C13.5858 12.375 13.25 12.7108 13.25 13.125C13.25 13.5392 13.5858 13.875 14 13.875V12.375ZM12.6634 10.375H22.3862V8.875H12.6634V10.375ZM18.2638 23.3118L16.5652 24.5118L17.4307 25.7369L19.1293 24.5369L18.2638 23.3118ZM15.625 24.0742V20.419H14.125V24.0742H15.625ZM13.955 16.7261L11.109 14.192L10.1115 15.3122L12.9575 17.8464L13.955 16.7261ZM23.8406 14.1651L20.7603 16.6969L21.7127 17.8557L24.793 15.324L23.8406 14.1651ZM18.9375 20.5206V22.034H20.4375V20.5206H18.9375ZM20.7603 16.6969C19.608 17.644 18.9375 19.0417 18.9375 20.5206H20.4375C20.4375 19.4993 20.9002 18.5236 21.7127 17.8557L20.7603 16.6969ZM15.625 20.419C15.625 19.0099 15.0159 17.6708 13.955 16.7261L12.9575 17.8464C13.7047 18.5117 14.125 19.4457 14.125 20.419H15.625ZM19.1293 24.5369C19.9462 23.9598 20.4375 23.031 20.4375 22.034H18.9375C18.9375 22.5327 18.692 23.0093 18.2638 23.3118L19.1293 24.5369ZM16.5652 24.5118C16.1454 24.8083 15.625 24.4871 15.625 24.0742H14.125C14.125 25.7838 16.0834 26.6887 17.4307 25.7369L16.5652 24.5118ZM8.875 12.5764C8.875 13.6217 9.32682 14.6135 10.1115 15.3122L11.109 14.192C10.6381 13.7726 10.375 13.1858 10.375 12.5764H8.875ZM24.625 12.5282C24.625 13.1534 24.3418 13.7531 23.8406 14.1651L24.793 15.324C25.634 14.6327 26.125 13.6111 26.125 12.5282H24.625ZM22.3862 10.375C23.6433 10.375 24.625 11.3594 24.625 12.5282H26.125C26.125 10.4902 24.4304 8.875 22.3862 8.875V10.375ZM12.6634 8.875C10.5918 8.875 8.875 10.5118 8.875 12.5764H10.375C10.375 11.381 11.3789 10.375 12.6634 10.375V8.875ZM21 12.375L14 12.375V13.875L21 13.875V12.375Z"
        fill="#36D66B"
      />
    </svg>
  );

  const [filterData, setFilterData] = useState(data);

  const [params, setParams] = useState({
    active: "True",
    _competition: null,
    phase: null,
    tab: null,
  });

  const DataFilterFunction = async () => {
    setIsLoading(true);
    let conditionalParams = {
      active: params.active,
    };
    if (params._competition !== null && params._competition !== "") {
      // conditionalParams._competition = params._competition;
      conditionalParams.tab = params.tab;
    }
    if (params.phase !== null && params.phase !== "" && params.phase !== "all") {
      conditionalParams.phase = params.phase.replace(/step_(\d)/, "$1_step");
    }

    let config = {
      headers: {Authorization: `Bearer ${idToken}`},
      params: conditionalParams,
    };

    //Test
    const response = await axios.get(`${baseUrl}/account/list/`, config);
    if (response.data) {
      setFilterData(response.data);
      setIsLoading(false);
    }
  };

  const [anchorElLive, setAnchorElLive] = React.useState(null);
  const [filterLive, setFilterLive] = useState("all");
  const openLive = Boolean(anchorElLive);
  const [live, setLive] = useState(false);

  const handleClickLive = (event) => {
    setAnchorElLive(event.currentTarget);
  };
  const handleCloseLive = (values) => {
    setAnchorElLive(null);
    if (typeof values !== "object" || values === null) setFilterLive(values);
    if (values === "all") {
      setParams({...params, phase: "all"});
    } else if (typeof values !== "object") {
      setParams({...params, phase: values});
    }
  };
  const ActiveOption = [
    {label: "All", value: "False"},
    // {label: "Competition", value: "_competition"},
    // {label: "Live", value: ""},
    // {label: "Live", value: ""},
    {label: "1 Step", value: "step_1"},
    {label: "2 Step", value: "step_2"},
    // {label: "Freetrial", value: "free_trial"},
  ];
  const [anchorElOne, setAnchorElOne] = React.useState(ActiveOption[0]);
  const [filterOne, setFilterOne] = useState("all");
  const openOne = Boolean(anchorElOne);
  const handleClickOne = (event) => {
    setAnchorElOne(event.currentTarget);
  };
  const handleCloseOne = (selectedOption) => {
    const {label, value} = selectedOption;
    setAnchorElOne(selectedOption);
    if (typeof value !== "object" || value === null) {
      setFilterOne(label);
    }
    if (label === "1 Step") {
      setLive(true);
      setParams({...params, phase: value, _competition: ""});
    } else if (label === "2 Step") {
      setLive(true);
      setParams({...params, phase: value, _competition: ""});
    } else if (label === "All") {
      setLive(false);
      setParams({...params, _competition: "", phase: ""});
    } else if (label === "Freetrial") {
      setLive(false);
      setParams({...params, phase: value});
    }
    //  else if (typeof value !== "object") {
    //   setParams({...params, [name]: value});
    // }
    if (label === "Competition") {
      setLive(false);
      setParams({...params, _competition: "False", tab: "competition"});
    }
  };
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {}, [activeTab]);
  useEffect(() => {}, [activeTab]);
  const initialLoad = useRef(true);

  const ActiveInctive = () => {
    if (!initialLoad.current) {
      if (activeTab === 0) {
        setParams({...params, active: "True", phase: ""});
        setLive(false);
        setFilterOne("all");
      } else {
        setParams({...params, active: "False", phase: ""});
        setLive(false);
        setFilterOne("all");
      }
    } else {
      initialLoad.current = false;
    }
  };

  useEffect(() => {
    ActiveInctive();
  }, [activeTab]);

  useEffect(() => {
    DataFilterFunction();
  }, [params]);

  const items = [
    {
      name: "Active",
      path: "",
    },
    {
      name: "Inactive",
      path: "",
    },
  ];
  const phaseOptions = [
    {label: "All", value: "all"},
    {label: "Stage 1", value: "stage_1"},
    {label: "Stage 2", value: "stage_2"},
    {label: "Funded", value: "funded"},
  ];

  const columns = useMemo(
    () => [
      {
        Header: "Login Id",
        accessor: "login_id",
        Cell: ({row}) => (
          <div style={{display: "flex", flexDirection: "column"}}>
            <span>{row.original.login_id?.replace("_", " ")}</span>
            <span
              className={`${
                row.original.status === "in_progress"
                  ? "status status-progress"
                  : row.original.status === "failed"
                  ? "status-error status"
                  : row.original.status === "Passes"
                  ? "status-passes status"
                  : "status"
              }`}
              style={{backgroundColor: "transparent", padding: "0"}}
            >
              {row.original.status?.replace("_", " ")}
            </span>
          </div>
        ),
      },
      {Header: "Trading Platform", accessor: "trading_platform", Cell: ({value}) => <>{value?.toUpperCase()}</>},
      {Header: "Challenge", accessor: "challenge"},
      {Header: "Challenge Type", accessor: "challenge_type", Cell: ({value}) => <>{value?.replace("_", " ")}</>},
      {Header: "Phase", accessor: "phase", Cell: ({value}) => <>{value?.replace("_", " ")}</>},
      {Header: "Start Date", accessor: "start_date", Cell: ({value}) => <>{moment(value).format("DD-MM-YYYY")}</>},
      {
        Header: "Action",
        accessor: "action",
        Cell: ({row}) => (
          <>
            <p
              className="view_details"
              onClick={() => {
                navigate("/account-metrics/program-objectives");
                dispatch(setActiveAccount(row?.original?.login_id?.replace("#", "%23")));
              }}
            >
              View Details
            </p>
          </>
        ),
      },
    ],
    [],
  );
  return (
    <div className="mainPageHolder">
      <div className="mainPageHolder-T-C">
        <div className="mainPageHolder-T">
          <MainHeader
            headerName="Dashboard"
            showTabs={false}
            tabItems={items}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      </div>
      <div className="mainPageHolder-B">
        <div className="dashboard">
          {errorModalOpen && (
            <ErrorModal
              desc={message}
              setModalOpen={setErrorModalOpen}
              btnText="Cancel"
            />
          )}

          <div
            className="dashboard_wrapper"
            style={{height: "100%", width: "100%"}}
          >
            {(isLoading || error) && <LoaderOverlay desc={error && message} />}
            {
              <div className="dashboard_top">
                <div className="all_header">
                  <h2> Dashboard </h2>
                </div>
                <div className="accounts_header">
                  <div className="dashboard_sub_heading">
                    <p>Accounts</p>
                  </div>
                </div>
                <div className="dashboard_box">
                  {/* <div className="plans_container">
                    <div className="available_plan_pro_plan">
                      <div className="available_plan_wrapper">
                        <div className="available_plan">
                          <h1>Available Plan</h1>
                          <div className="free_trial">
                            <img
                              src={lockIcon}
                              alt=""
                            />
                            <div>
                              <h2>{proPlan?.name}</h2>
                              <p>Casual part-time investor</p>
                            </div>
                          </div>
                          <div className="free_trial_details">
                            {freeTril?.map((info, index) => {
                              const {icon, text} = info;
                              return (
                                <div
                                  className="info"
                                  key={index}
                                >
                                  <img
                                    src={icon}
                                    alt=""
                                  />
                                  <p>{text}</p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="hr_line"></div>
                      <div className="pro_plan_wrapper">
                        <div className="pro_plan">
                          <h1>Pro Plan</h1>
                          <div className="edge_pro">
                            <img
                              src={equty_icon}
                              alt=""
                            />
                            <div>
                              <h2>{proPlan1?.name}</h2>
                              <p>Serious part-time investor</p>
                            </div>
                          </div>
                          <div className="edge_pro_details">
                            {freeTril1?.map((info, index) => {
                              const {icon, text} = info;
                              return (
                                <div
                                  className="info"
                                  key={index}
                                >
                                  <img
                                    src={icon}
                                    alt=""
                                  />
                                  <p>{text}</p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="upgrade_bt"
                      onClick={() => {
                        dispatch(setSelectedChallenge(proPlan1?.id));
                        navigate(`/funding-evaluation/payments/${proPlan1?.id}`);
                      }}
                    >
                      <button>Upgrade to Equity Edge Pro</button>
                    </div>
                  </div> */}
                  <div className="dashboard_table_inforamation">
                    <div className="table_header">
                      <div className="dashboard_nav_options">
                        <div className="dashboard_nav">
                          <Tabination
                            variant={5}
                            tabItems={items}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            isChart={true}
                            isToolbar={true}
                          />
                        </div>
                      </div>
                      {/* <div className="dashboard_filter_options">
                        <div className="filter_btn">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="0.8">
                              <path
                                d="M3.33333 2.5H16.6667C16.8877 2.5 17.0996 2.5878 17.2559 2.74408C17.4122 2.90036 17.5 3.11232 17.5 3.33333V4.655C17.5 4.876 17.4121 5.08792 17.2558 5.24417L11.9108 10.5892C11.7545 10.7454 11.6667 10.9573 11.6667 11.1783V16.4325C11.6667 16.5592 11.6378 16.6842 11.5822 16.798C11.5267 16.9118 11.4459 17.0115 11.346 17.0894C11.2461 17.1674 11.1298 17.2215 11.0059 17.2477C10.882 17.274 10.7537 17.2716 10.6308 17.2408L8.96417 16.8242C8.78396 16.779 8.62401 16.675 8.50971 16.5285C8.39542 16.3821 8.33334 16.2016 8.33333 16.0158V11.1783C8.33329 10.9573 8.24546 10.7454 8.08917 10.5892L2.74417 5.24417C2.58788 5.08792 2.50005 4.876 2.5 4.655V3.33333C2.5 3.11232 2.5878 2.90036 2.74408 2.74408C2.90036 2.5878 3.11232 2.5 3.33333 2.5Z"
                                stroke="#071E0D"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                          </svg>
                          <>
                            <Select
                              options={ActiveOption}
                              value={anchorElOne}
                              onChange={(selectedOption) => handleCloseOne(selectedOption)}
                              styles={customStyles}
                              classNamePrefix="react-select"
                              className="react-select-container"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </>
                        </div>
                        {live === true && (
                          <div className="account_ACTIVE_inactive_con">
                            <div className="toolbar pill">
                              <div>
                                <Select
                                  styles={customStyles}
                                  placeholder="Phase"
                                  classNamePrefix="react-select"
                                  className="react-select-container custom_select"
                                  options={phaseOptions?.filter((items) => (params.phase === "step_1" ? items.value !== "stage_2" : items))}
                                  components={{
                                    IndicatorSeparator: () => null,
                                  }}
                                  value={
                                    params?.phase && {
                                      label: params?.phase
                                        ?.split("_")
                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(" "),
                                      value: params?.phase
                                        ?.split("_")
                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(" "),
                                    }
                                  }
                                  onChange={(selectedOption) => handleCloseLive(selectedOption.value)}

                                  // selectedOption.value === null ? setPhase(null) : setPhase(selectedOption))}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div> */}
                      <div className="add_acount_btn">
                        <Link to="/funding-evaluation/plans/0">
                          <div className="addAccount">
                            <img
                              src={plusIcon}
                              alt=""
                            />
                            <p>Choose challenge</p>
                          </div>
                        </Link>
                      </div>
                    </div>
                    {/* <Table tableData={data} /> */}
                    <ReactTable
                      data={filterData || []}
                      columns={columns}
                    />
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
