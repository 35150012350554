import React, { useEffect } from 'react'
import './AdminLogin.scss'
import { useDispatch } from "react-redux";
import { useParams,useNavigate } from "react-router-dom";
import { setAuthenticationSuccess } from '../../store/reducers/authSlice';
const AdminLogin = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(setAuthenticationSuccess({ data: { idToken: id } }));
        navigate('/')
    }, [])
    return (
        <div className='adminLOGIN-con'>Loading ....</div>
    )
}

export default AdminLogin