import React, {useEffect} from "react";
import "./style.scss";

import Card from "../../../../reusableComponents/Card";
import {convertSnakeCaseToDefault} from "../../../../utils/helpers/string";

function TierHeader({tier}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formattedTitles = [
    // "leverage",
    "leverage(FX)",
    "leverage(Crypto)",
    "max_daily_loss",
    "max_loss",
    "min_trading_days",
    "profit_target_1",
    // "profit_target_2",
    "profit_share",
    "starting_balance",
    "EAS",
    "Tradable Asset",
    "Trade through News",
    "Hold over the Weekend",
    "Hedging & Stacking",
  ];
  if (tier[0].name === "2 Step") {
    formattedTitles.splice(5, 0, "profit_target_2");
  }
  return (
    <Card className="tierheader-container">
      <h3></h3>
      {formattedTitles?.map((item, index) => (
        <p key={index}>{item === "ea" ? "EA" : convertSnakeCaseToDefault(item)}</p>
      ))}
    </Card>
  );
}

export default TierHeader;
