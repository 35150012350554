import React, {useEffect} from "react";
import "./style.scss";
import BuyFundedAc from "../BuyFundedAc";
import {useDispatch, useSelector} from "react-redux";
import {getPlans, setSelectedChallenge} from "../../../store/reducers/accountSlice";
import {useParams} from "react-router-dom";

function FundingEvaluationPublic() {
  const plans = useSelector((state) => state.account.plans.data);

  const {id} = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPlans());
  }, []);
  useEffect(() => {
    dispatch(setSelectedChallenge(Number(id)));
  }, [plans, id]);

  return (
    <div
      className="mainPageHolder-B public"
      style={{padding: "0 10%"}}
    >
      <BuyFundedAc publicCheckout={true} />
    </div>
  );
}

export default FundingEvaluationPublic;
