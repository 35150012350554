import React, {useState} from "react";
import MainHeader from "../../layouts/pageLayout/MainHeader";
import {Contactlits} from "./Contactlits";

const ContactList = () => {
  const [activeTab, setActiveTab] = useState(0);
  const items = [
    {
      name: "Contract List",
      path: "all-contract-list",
    },
  ];

  return (
    <>
      <div className="mainPageHolder">
        <div className="mainPageHolder-T-C">
          <div className="mainPageHolder-T">
            <MainHeader
              headerName="Contract List"
              showTabs={false}
              tabItems={items}
              activeTab={activeTab}
            />
          </div>
        </div>
        <div className="mainPageHolder-B">
          <div className="accountmetrics-container">
            <div className="all_header">
              <h2>Contract List</h2>
            </div>
            {/* {(isLoading || accountMetrics.error) && <LoaderOverlay desc={accountMetrics.error && accountMetrics.message} />} */}

            {/* <Routes>
              <Route
                path="/"
                element={
                  <Navigate
                    to="all-contract-list"
                    replace={true}
                  />
                }
              />
              <Route
                path="all-contract-list"
                element={}
              />
            </Routes> */}
            <Contactlits />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactList;
