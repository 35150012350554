import { takeEvery, call, put, all } from "redux-saga/effects";
import {
  createSupportCases,
  getSupportTableDetails,
} from "../../../utils/apis";
import {
  createSupportCase,
  createSupportCaseFailure,
  createSupportCaseSuccess,
  getSupportDataSuccess,
  getSupportData,
} from "../../reducers/supportSlice";

function* fetchSupportData(action) {
  try {
    const idToken = action.payload;

    const supportData = yield call(getSupportTableDetails, idToken);
    yield put(getSupportDataSuccess(supportData));
  } catch (error) {
    console.error("Error fetching support data:", error);
  }
}

function* addSupportCase(action) {
  try {
    const { idToken, data } = action.payload;
    const response = yield call(createSupportCases, idToken, data);
    yield put(createSupportCaseSuccess(response.data));
  } catch (error) {
    yield put(createSupportCaseFailure(error));
  }
}

export default function* supportSaga() {
  yield all([
    takeEvery(getSupportData.type, fetchSupportData),
    takeEvery(createSupportCase.type, addSupportCase),
  ]);
}
