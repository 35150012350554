import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import ErrorModal from "../../../reusableComponents/ErrorModal";
import SuccessModal from "../../../reusableComponents/SuccessModal";
import {removeLastPayment} from "../../../store/reducers/accountSlice";
import {getPaymentStatus} from "../../../utils/apis";
import "./index.scss";
import {getPaymentStatusPublic} from "../../../utils/apis/payment";
import ProcessingModal from "../../../reusableComponents/processing modal";

function PostPayment({status}) {
  const challengeId = useSelector((state) => state.account.selectedChallenge);
  const paymentHistory = useSelector((state) => state.account.paymentHistory);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const {idToken} = useSelector((state) => state.auth);

  const handleModal = () => {
    setModalOpen(false);
  };
  console.log(paymentHistory, "paymentHistorypaymentHistory");

  useEffect(() => {
    setModalOpen(true);
  }, [status]);

  const [response, setResponse] = useState(null);

  useEffect(() => {
    const getPaymentStatusForChallenge = async () => {
      try {
        setIsLoading(true);
        const payloadPublic = {payment_id: paymentHistory?.lastPayment?.data?.id, email: paymentHistory?.lastPayment?.data?.email};
        const payload = {payment_id: paymentHistory?.lastPayment?.data?.id};

        // Simulate delay for processing
        await new Promise((resolve) => setTimeout(resolve, 15000));

        if (idToken) {
          // Handle token scenario
          const responseAPI = await getPaymentStatus(idToken, payload);
          console.log(responseAPI, "successPaymentStatus");
          setResponse(responseAPI?.response?.data);

          window.fbq("track", "Purchase", {
            currency: "USD",
            value: paymentHistory?.lastPayment?.data?.amount,
          });

          if (responseAPI?.status === 200) {
            setPaymentStatus("success");
            window.fbq("track", "Purchase", {currency: "USD", value: paymentHistory?.lastPayment?.data?.amount});
            await new Promise((resolve) => setTimeout(resolve, 2000));
            navigate("/funding-evaluation-payment/payment-history/");
          } else {
            throw new Error("Payment status not successful");
          }
        } else {
          const responseAPI = await getPaymentStatusPublic(payloadPublic);
          setResponse(responseAPI?.response?.data);
          // Handle no token scenario
          console.log("No token found, redirecting to sign-in page");
          setPaymentStatus("failure");
          setResponse({detail: "No authentication token found"});

          // Redirect to sign-in with message
          await new Promise((resolve) => setTimeout(resolve, 10000));
          navigate("/signin", {state: {message: "Your payment is being processed! Please visit your dashboard payment history to check transaction status."}});
        }
      } catch (error) {
        console.error("Error fetching payment status:", error);
        setIsLoading(false);
        setPaymentStatus("failure");
        await new Promise((resolve) => setTimeout(resolve, 10000));
        navigate(`/funding-evaluation/payments/${challengeId}`);
      } finally {
        dispatch(removeLastPayment());
        setIsLoading(false);
      }
    };

    getPaymentStatusForChallenge();
  }, [idToken, navigate, dispatch, paymentHistory, challengeId]);

  const accountList = useSelector((state) => state?.account?.accountList?.data);
  let msg = accountList?.length > 0 ? "Congratulations! Payment was successful" : "You'll shortly receive an email with your account login credentials";

  return (
    <>
      {isLoading && <ProcessingModal open={isLoading} />}
      {!isLoading &&
        isModalOpen &&
        (paymentStatus === "success" ? (
          <SuccessModal
            setModalOpen={handleModal}
            title="Successful!"
            desc={msg}
            btnText="Close"
          />
        ) : (
          <ErrorModal
            handleNavigation={() => navigate(`/funding-evaluation/payments/${challengeId}`)}
            setModalOpen={handleModal}
            desc={response?.detail === "Unable to fetch the payment status" ? "Payment is in progress, if successful you will receive your account in 15-30 mins" : "Payment is in progress."}
            btnText="Close"
          />
        ))}
    </>
  );
}

export default PostPayment;
