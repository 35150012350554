// export const baseUrl = "http://139.5.189.142:8000";
// export const baseUrl = "http://13.41.53.26";
export const baseUrl = "https://api.equityedge.co.uk";
// export const baseUrl = "http://3.8.154.118";


export {loadUser, signupRequest} from "./auth";
export {initiatePayment, applyCoupon, getPaymentStatus} from "./payment";
export {createSupportCases, getSupportTableDetails} from "./support";
export {updateUserDetailsRequest, getUserDetails, addUserAddress, getUserAddress, UpdateUserAddress } from "./user";
export {
  getFundingEvaluationPlansRequest,
  getPaymentHistoryRequest,
  getCompetitionListRequest,
  getUserAccountListRequest,
  getAccountMetricsRequest,
  getBalanceChartRequest,
  getTraderJournalRequest,
  createJournalTraderRequest,
  updateJournalTraderRequest,
  deleteJournalTraderRequest,
} from "./account";
