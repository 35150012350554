import axios from "axios";
import {baseUrl} from ".";
import {useSelector} from "react-redux";

const getFundingEvaluationPlansRequest = async (idToken) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };
    const response = await axios.get(`${baseUrl}/account/funding-evaluation/`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};
const getPaymentHistoryRequest = async (idToken) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  const output = await axios
    .get(`${baseUrl}/payment/payment-history/`, config)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      throw error;
    });
  return output;
};
const getCompetitionListRequest = async (idToken) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };
    const response = await axios.get(`${baseUrl}/account/competition-list/`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const enterCompetitionRequest = async (idToken, body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };
    const response = await axios.post(`${baseUrl}/account/participate-competition/`, body, config);
    return response;
  } catch (error) {
    return error;
  }
};

const getUserAccountListRequest = async (idToken) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  const output = await axios
    .get(`${baseUrl}/account/list/`, config)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      throw error;
    });
  return output;
};
const getCertificateListRequest = async (idToken) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  const output = await axios
    .get(`${baseUrl}/certificate/list/`, config)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      throw error;
    });
  return output;
};
const getContractListRequest = async (idToken) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  const output = await axios
    .get(`${baseUrl}/account/contracts/`, config)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      throw error;
    });
  return output;
};

const getAccountMetricsRequest = async ({idToken, loginId}) => {
  try {
    let config = {
      headers: {Authorization: `Bearer ${idToken}`},
    };

    const response = await axios.get(`${baseUrl}/account/metrics/${loginId}`, config);
    if (response.status >= 400) {
      throw new Error(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};
const getBalanceChartRequest = async ({idToken, loginId}) => {
  
  try {
    let config = {
      headers: {Authorization: `Bearer ${idToken}`},
    };
    const response = await axios.get(`${baseUrl}/account/balance-equity/${loginId}`, config);

    return response.data;
  } catch (error) {
    throw error;
  }
};

//  for payout request

const getProfitSplittPayoutRequest = async (idToken) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  const output = await axios
    .get(`${baseUrl}/user/payout/accounts/`, config)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      throw error;
    });
  return output;
};
const getAffiliatePayoutRequest = async (idToken) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  const output = await axios
    .get(`${baseUrl}/user/eligible-commission/`, config)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      throw error;
    });
  return output;
};
const getTraderJournalRequest = async (idToken) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  const output = await axios
    .get(`${baseUrl}/account/trade-journal/`, config)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      throw error;
    });
  return output;
};
const createJournalTraderRequest = async (idToken, body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };
    const response = await axios.post(`${baseUrl}/account/trade-journal/`, body, config);
    return response;
  } catch (error) {
    return error;
  }
};
const updateJournalTraderRequest = async (idToken, body, id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };
    const response = await axios.patch(`${baseUrl}/account/trade-journal/${id}`, body, config);
    return response;
  } catch (error) {
    return error;
  }
};
const deleteJournalTraderRequest = async (idToken, id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };
    const response = await axios.delete(`${baseUrl}/account/trade-journal/${id}`, config);
    return response;
  } catch (error) {
    return error;
  }
};
const getLeaderboardRequest = async (idToken, id) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  try {
    const response = await axios.get(`${baseUrl}/user/competition/${id}/`, config);
    return response;
  } catch (error) {
    return error;
  }
};

export {
  getFundingEvaluationPlansRequest,
  getCompetitionListRequest,
  enterCompetitionRequest,
  getPaymentHistoryRequest,
  getUserAccountListRequest,
  getAccountMetricsRequest,
  getBalanceChartRequest,
  getProfitSplittPayoutRequest,
  getAffiliatePayoutRequest,
  getTraderJournalRequest,
  createJournalTraderRequest,
  updateJournalTraderRequest,
  deleteJournalTraderRequest,
  getLeaderboardRequest,
  getCertificateListRequest,
  getContractListRequest
};
