import React from "react";
import "./style.scss";

function PaymentModuleHeader() {
  return (
    <div className="paymentmodule_header">
      <span className="heading">Complete Your Purchase</span>
      <span className="text">Money Back Guarantee on All Premium Plans</span>
    </div>
  );
}

export default PaymentModuleHeader;
