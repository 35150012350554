import "./index.scss";
// import {reuleaux} from "ldrs";

// reuleaux.register();

// Default values shown
import {hourglass} from "ldrs";

hourglass.register();

// Default values shown

function LoaderOverlay({desc}) {
  return (
    <>
      <div className="loader_overlay">
        <div className="container">
          {/* <div className="loader"></div> */}
          {/* <l-reuleaux
            size="70"
            stroke="5"
            stroke-length="0.15"
            bg-opacity="0.3"
            speed="1.2"
            color="#36d66b"
          ></l-reuleaux> */}
          <l-hourglass
            size="70"
            stroke="5"
            stroke-length="0.15"
            bg-opacity="0.3"
            speed="1.2"
            color="#FFFFFF"
          ></l-hourglass>
          {<h1>{desc} </h1> ? <h1>Loading...</h1> : ""}
        </div>
      </div>
    </>
  );
}

export default LoaderOverlay;