import React, {useEffect, useState} from "react";
import "./style.scss";
import SupportCases from "../../layouts/SupportCenter/SupportCases";
import Tabination from "../../reusableComponents/Tabination";
import CaseHistory from "../../layouts/SupportCenter/CaseHistory";
import CreateCase from "../../layouts/SupportCenter/CreateCase";
import Faq from "../../layouts/SupportCenter/Faq";
import LayoutBox from "../../reusableComponents/LayoutBox";
import {useDispatch, useSelector} from "react-redux";
import {getSupportData, getSupportDataSuccess} from "../../store/reducers/supportSlice";
import ErrorModal from "../../reusableComponents/ErrorModal";
import LoaderOverlay from "../../reusableComponents/Loader";
import MainHeader from "../../layouts/pageLayout/MainHeader";
import {Navigate, Route, Routes} from "react-router-dom";
import {returnErrors} from "../../store/reducers/error";

const SupportCenter = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [clickEvent, setClickEvent] = useState(false);
  const dispatch = useDispatch();
  const idToken = useSelector((state) => state.auth.idToken);
  const {support, error, isLoading, message} = useSelector((state) => state.support);
  const [supportCase, setSupportcase] = useState(support || []);
  const [errorModalOpen, setErrorModalOpen] = useState(error);
  const [isLoading1, setIsLoading] = useState(isLoading);

  if (support === undefined || errorModalOpen) {
    const msg = "No cases create";
    const status = 404;
    // dispatch(returnErrors(msg, status));
  }
  useEffect(() => {
    setErrorModalOpen(error);
    setIsLoading(isLoading);
  }, [error, isLoading]);

  useEffect(() => {
    dispatch(getSupportData(idToken));
  }, [idToken]);

  useEffect(() => {
    setSupportcase(support || []);
  }, [support]);

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
    setClickEvent(true);
  };
  const toggleClickEvent = (value) => {
    setClickEvent(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const items = [
    {
      name: "Support Cases",
      path: "support-case",
    },
    // {
    //   name: "Case History",
    //   path: "case-history",
    // },
    {
      name: "Create Case",
      path: "create-case",
    },
    {
      name: "FAQ",
      path: "faq",
    },
  ];
  // if (support?.loading) {
  //   return "TEST";
  // }
  useEffect(() => {
    if (isLoading1) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isLoading1]);
  return (
    <>
      <div className="mainPageHolder">
        <div className="mainPageHolder-T-C">
          <div className="mainPageHolder-T">
            <MainHeader
              headerName="Support"
              tabItems={items}
              activeTab={activeTab}
              showTabs={false}
              onTabChange={handleTabChange}
            />
          </div>
        </div>
        <div className="mainPageHolder-B">
          <div className="">
            {(isLoading1 || error) && <LoaderOverlay desc={error && message} />}

            <Routes>
              <Route
                path="/"
                element={
                  <Navigate
                    to="support-case"
                    replace={true}
                  />
                }
              />
              <Route
                path="support-case"
                element={
                  <LayoutBox className="supportcenter-container">
                    <SupportCases
                      supportCase={supportCase}
                      onTabChange={handleTabChange}
                      setActiveTab={setActiveTab}
                    />
                  </LayoutBox>
                }
              />
              <Route
                path="case-history"
                element={
                  <LayoutBox className="supportcenter-container">
                    <CaseHistory
                      onTabChange={handleTabChange}
                      setActiveTab={setActiveTab}
                      activeTab={activeTab}
                      tabItems={items}
                    />
                  </LayoutBox>
                }
              />
              <Route
                path="create-case"
                element={
                  <LayoutBox className="supportcenter-container">
                    <CreateCase setActiveTab={setActiveTab} />
                  </LayoutBox>
                }
              />
              <Route
                path="faq"
                element={
                  <LayoutBox className="supportcenter-container">
                    <Faq />
                  </LayoutBox>
                }
              />
            </Routes>
          </div>
        </div>
      </div>
      {/* <div id="support-center">
        {errorModalOpen && (
          <ErrorModal
            desc={support.message}
            setModalOpen={setErrorModalOpen}
            btnText="Cancel"
          />
        )}
        {(isLoading || support.error) && <LoaderOverlay desc={support.error && support.message} />}
        {!support.error && !support.loading && (
          <Tabination
            variant={3}
            tabItems={items}
            activeTab={activeTab}
            onTabChange={clickEvent}
            resetClickEvent={toggleClickEvent}
          />
        )}
      </div> */}
    </>
  );
};

export default SupportCenter;
