import React, { useEffect, useState } from "react";
import "./style.scss";

import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import Tabination from "../../reusableComponents/Tabination";

import Overview from "../../layouts/AccountMetrics2/Overview";
import GetALink from "../../layouts/AccountMetrics2/GetALink";
import LayoutBox from "../../reusableComponents/LayoutBox";

function AccountMetrics2() {
  const location = useLocation();
  const path = location.pathname.split("/")[2];

  const [activeTab, setActiveTab] = useState(0);

  const items = [
    {
      name: "Overview",
      path: "overview",
    },
    {
      name: "Get A Link",
      path: "link",
    },
  ];

  useEffect(() => {
    switch (path) {
      case "overview":
        setActiveTab(0);
        break;
      case "link":
        setActiveTab(1);
        break;
      default:
        break;
    }
  }, [path]);

  return (
    <>
      <Tabination
        variant={3}
        tabItems={items}
        activeTab={activeTab}
        style={{ margin: "0 14px" }}
      />
      <LayoutBox className="accountmetrics2-container">
        <Routes>
          <Route path="/" element={<Navigate to="overview" replace={true} />} />
          <Route path="overview" element={<Overview />} />
          <Route path="link" element={<GetALink />} />
        </Routes>
      </LayoutBox>
    </>
  );
}

export default AccountMetrics2;
