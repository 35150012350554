import React, {useEffect, useState} from "react";
import "./style.scss";
import ReactApexChart from "react-apexcharts";
import Tabination from "../../../reusableComponents/Tabination";
import {useSelector} from "react-redux";
import ApexCharts from "apexcharts"; // Add this line
import moment from "moment";

function FinancialPerformance() {
  const items = [
    // { name: "Growth", content: <GrowthChart /> },
    {name: "Balance", content: <BalanceChart />},
    {name: "Profit", content: <ProfitChart />},
    // {name: "Drawdown", content: <DrawdownChart />},
  ];
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="financialperformance-container">
      <h4>Financial Performance</h4>
      <div className="financialperformance-subcontainer">
        <div className="financialperformance-innercontainer">
          <Tabination
            variant={5}
            tabItems={items}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            isChart={true}
            isToolbar={true}
            balanceChart={true}
          />
        </div>
      </div>
    </div>
  );
}

export default FinancialPerformance;
export function FilterChart() {
  const accountsData = useSelector((state) => state.account.accountMetrics.data);
  const [balance_chart, setbalance_chart] = useState([]);
  const [selection, setSelection] = useState("all");

  useEffect(() => {
    setbalance_chart(accountsData?.balance_chart || []);
  }, [accountsData]);

  const firstDate = moment(balance_chart[0]?.time).format("DD MMM YYYY");

  const updateData = (timeline) => {
    setSelection(timeline);

    let startDate, endDate;

    switch (timeline) {
      case "1_min":
        startDate = new Date();
        startDate.setMinutes(startDate.getMinutes() - 1);
        endDate = new Date();
        break;
      case "5_min":
        startDate = new Date();
        startDate.setMinutes(startDate.getMinutes() - 5);
        endDate = new Date();
        break;
      case "1_day":
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        endDate = new Date();
        break;
      case "1_week":
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        endDate = new Date();
        break;
      case "1_month":
        startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        endDate = new Date();
        break;
      case "all":
        startDate = new Date(balance_chart[0]?.time);
        endDate = new Date(balance_chart[balance_chart.length - 1]?.time);
        break;
      default:
        startDate = new Date(balance_chart[0]?.time);
        endDate = new Date(balance_chart[balance_chart.length - 1]?.time);
    }

    ApexCharts.exec("area-datetime", "zoomX", startDate.getTime(), endDate.getTime());
  };

  return (
    <div className="toolbar">
      <p
        id="1_day"
        onClick={() => updateData("1_day")}
        className={selection === "1_day" ? "active" : ""}
      >
        1D
      </p>
      <p
        id="1_week"
        onClick={() => updateData("1_week")}
        className={selection === "1_week" ? "active" : ""}
      >
        1W
      </p>
      <p
        id="1_month"
        onClick={() => updateData("1_month")}
        className={selection === "1_month" ? "active" : ""}
      >
        1M
      </p>
      <p
        id="all"
        onClick={() => updateData("all")}
        className={selection === "all" ? "active" : ""}
      >
        ALL
      </p>
    </div>
  );
}

export function BalanceChart() {
  const accountsData = useSelector((state) => state.account.accountMetrics.data);
  const [balance_chart, setbalance_chart] = useState([]);

  useEffect(() => {
    setbalance_chart(accountsData?.balance_chart || []);
  }, [accountsData]);

  const series = [
    {
      name: "Balance",
      data: balance_chart.map((entry) => [new Date(entry.time).getTime(), entry.balance]),
    },
    {
      name: "Equity",
      data: balance_chart.map((entry) => [new Date(entry.time).getTime(), entry.equity]),
    },
  ];

  const options = {
    colors: ["#F86B36", "#2B84EE"],
    grid: {
      show: false,
    },
    chart: {
      id: "area-datetime",
      type: "line",
      height: 350,
      zoom: {
        autoScaleYaxis: true,
      },
    },
    stroke: {
      width: [3, 3],
      curve: "smooth",
    },
    tooltip: {
      enabled: true,
      x: {
        formatter: (value) => `$${value?.toFixed(2) ?? ""}`,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "20%",
      },
    },
    xaxis: {
      labels: {
        rotate: 0,
        style: {
          colors: "#526C7B",
        },
      },
      tickAmount: 6,
      type: "datetime",
      tooltip: {
        format: "dd MMM yyyy",
      },
    },
    yaxis: [
      {
        labels: {
          formatter: (value) => `$${value?.toFixed(2) ?? ""}`,
          style: {
            colors: "#526C7B",
          },
        },
      },
    ],
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
      fontWeight: 700,
      markers: {
        radius: 50,
      },
      itemMargin: {
        horizontal: 10,
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={300}
      id="balance_chart"
    />
  );
}

function ProfitChart() {
  const accountsData = useSelector((state) => state.account.accountMetrics);
  const [date, setdate] = useState([]);
  const [profit, setprofit] = useState([]);

  // const [metrics, setmetrics] = useState([]);
  useEffect(() => {
    setdate(accountsData?.data?.profit_chart?.date || []);
    setprofit(accountsData?.data?.profit_chart?.profit || []);
    // setmetrics(accountsData.data.account_metrics);
  }, [accountsData]);

  const positiveArray = profit?.filter((value) => value > 0);

  const formattedDates = date?.map((dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    });
  });

  const series = [
    {
      name: "Profit",
      type: "column",
      // data: [12, 2, 452],
      data: positiveArray,
    },
    // {
    //   name: "Balance",
    //   type: "column",
    //   data: positiveArray,
    // },
    // {
    //   name: "profit",
    //   type: "line",
    //   // data: [12, 2, 452],
    //   data: positiveArray,
    // },
  ];

  const options = {
    grid: {
      show: false, // Hide the horizontal grid lines
    },
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: true,
        offsetY: -100,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: false,
        },
        zoom: {
          enabled: false,
        },
      },
    },
    tooltip: {
      enabled: true,

      x: {
        formatter: formattedDates,
      },
    },
    stroke: {
      width: [0, 2],
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
    },
    xaxis: {
      categories: formattedDates,
      labels: {
        style: {
          colors: "#fff",
        },
        rotate: 0,
      },
      tickAmount: 6, // Adjust as needed based on your preference
      formatter: function (value, index) {
        // Display every 10th label to avoid clutter
        return index % 10 === 0 ? value : "";
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "10%",
      },
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
      fontWeight: 700,
      markers: {
        radius: 50,
      },
      itemMargin: {
        horizontal: 10,
      },
    },

    yaxis: [
      {
        labels: {
          style: {
            colors: "#fff",
          },
          formatter: (value) => `$${value?.toFixed(2) ?? ""}`,
        },
      },
    ],
    colors: ["#35d269", "#F25F5C"],
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      // type="line"
      height={300}
    />
  );
}

function DrawdownChart() {
  const accountsData = useSelector((state) => state.account.accountMetrics);
  const [date, setdate] = useState([]);
  const [draw_down, setdraw_down] = useState([]);
  // const [metrics, setmetrics] = useState([]);
  useEffect(() => {
    const dateData = accountsData?.data?.drawdown_chart?.date || [];
    const drawDownData = accountsData?.data?.drawdown_chart?.draw_down || [];

    setdate(dateData || []);
    setdraw_down(drawDownData || []);

    // setmetrics(accountsData.data.account_metrics);
  }, [accountsData]);

  const negativeArray = draw_down?.filter((value) => value < 0);

  const formattedDates = date?.map((dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    });
  });

  const series = [
    {
      name: "Loss",
      data: negativeArray,
      // data: [129, 132, 112, 321, 2422, 241],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: true,
        offsetY: -10,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: false,
        },
        zoom: {
          enabled: false,
        },
      },
    },
    labels: formattedDates,
    xaxis: {
      labels: {
        style: {
          colors: "#fff", // Change x-axis label color to white
        },
      },
    },

    yaxis: [
      {
        labels: {
          style: {
            colors: "#fff",
          },
          formatter: (value) => `$${Math.abs(value)?.toFixed(2) ?? ""}`,
        },
        max: 10,
      },
    ],

    fill: {
      // type: "gradient",
      // gradient: {
      //   shade: "dark",
      //   type: "vertical",
      //   shadeIntensity: 0,
      //   opacityFrom: 1,
      //   opacityTo: 0,
      //   stops: [0, 100],
      //   colorStops: [],
      //   inverseColors: false,
      // },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: true,
    },
    grid: {
      show: false, // Hide the horizontal grid lines
    },
    plotOptions: {
      // bar: {
      //   horizontal: false,
      //   columnWidth: "20%",
      //   borderRadius: 0,
      //   color: "#35d269",
      //   fill: "#ffffff",
      //   strokeColor: "#775DD0",
      //   colors: {
      //     ranges: [
      //       // {
      //       //   from: Infinity, // Negative infinity to 0
      //       //   to: 0,
      //       //   color: "#ffffff",
      //       //   fill: "#ffffff",
      //       //   // Red color for negative values
      //       // },
      //       // {
      //       //   from: 100,
      //       //   to: Infinity, // 0 to positive infinity
      //       //   color: "#35d269", // Green color for non-negative values
      //       //   fill: "#ffffff",
      //       // },
      //     ],
      //   },
      // },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
    },
    legend: {
      position: "bottom",
      show: true,
      horizontalAlign: "left",
      offsetX: 40,
      fontWeight: 700,
      markers: {
        radius: 50,
      },
      itemMargin: {
        horizontal: 10,
      },
    },
    colors: ["#35d269"],
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height={300}
    />
  );
}
