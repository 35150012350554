import React, {useEffect, useRef, useState} from "react";
import "./style.scss";
import {getContractListRequest} from "../../utils/apis/account";
import {useDispatch, useSelector} from "react-redux";
import ReactTable from "../../components/ReactTable/ReactTable";
import moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {ToastContainer, toast} from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import {Box, IconButton, Menu, MenuItem, Tooltip} from "@mui/material";
import {digiSigner} from "../../utils/apis/user";
import {returnErrors} from "../../store/reducers/error";
import {useNavigate} from "react-router-dom";
import {setActiveAccount} from "../../store/reducers/accountSlice";
export const Contactlits = () => {
  const idToken = useSelector((state) => state.auth.idToken);
  const [contactList, setContactList] = useState([]);
  const fetchContactList = async () => {
    const response = await getContractListRequest(idToken);
    setContactList(response.results);
  };
  
  useEffect(() => {
    fetchContactList();
  }, [idToken]);
  const truncateString = (inputString, maxLength) => {
    const formattedString = inputString.toString();
    if (formattedString.length > maxLength) {
      return inputString.toString().substring(0, maxLength) + "...";
    }
    return inputString;
  };
  const handleCopyToClipboard = (text) => {
    toast("Copied Url", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      type: "success",
      theme: "dark",
      className: "custom-toast-container",
    });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const count = useRef(1);
  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        Cell: ({row}) => {
          return (
            <p
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#071E0D",
                color: "white",
                display: "grid",
                placeItems: "center",
                borderRadius: "50%",
              }}
            >
              {count.current++}
            </p>
          );
        },
      },
      {
        Header: "Account",
        accessor: "account",
        Cell: ({row}) => (
          <span
            style={{fontSize: "12px", cursor: "pointer"}}
            onClick={() => {
              navigate("/account-metrics/program-objectives");
              dispatch(setActiveAccount(row?.original?.account));
            }}
          >
            {row.original.account}
          </span>
        ),
      },
      {
        Header: "Funded Account",
        accessor: "funded_account",
        Cell: ({row}) => (
          <span
            style={{fontSize: "12px", cursor: "pointer"}}
            onClick={() => {
              navigate("/account-metrics/program-objectives");
              dispatch(setActiveAccount(row?.original?.funded_account));
            }}
          >
            {row.original.funded_account}
          </span>
        ),
      },
      {
        Header: "Verified",
        accessor: "is_verified",
        Cell: ({row}) => (
          <>
            {row.original.is_verified ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  width="24"
                  height="24"
                  rx="12"
                  fill="#209829"
                  fill-opacity="0.1"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.7344 7.22486C18.072 7.53992 18.0902 8.06897 17.7751 8.40653L10.8899 15.7836C10.7352 15.9493 10.5198 16.0451 10.2931 16.0491C10.0664 16.053 9.84783 15.9646 9.6875 15.8043L6.24488 12.3617C5.91837 12.0352 5.91837 11.5058 6.24488 11.1793C6.57138 10.8528 7.10075 10.8528 7.42725 11.1793L10.258 14.01L16.5527 7.26561C16.8678 6.92804 17.3968 6.9098 17.7344 7.22486Z"
                  fill="#209829"
                />
              </svg>
            ) : (
              <>
                {" "}
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      width="24"
                      height="24"
                      rx="12"
                      fill="#FA4D4D"
                      fill-opacity="0.1"
                    />
                    <path
                      d="M6.99979 18C6.80204 18 6.60874 17.9412 6.44433 17.8312C6.27992 17.7212 6.15178 17.5649 6.07611 17.382C6.00044 17.1991 5.98064 16.9979 6.0192 16.8037C6.05777 16.6096 6.15298 16.4312 6.29279 16.2912L16.2928 6.281C16.4814 6.09866 16.734 5.99776 16.9962 6.00004C17.2584 6.00232 17.5092 6.1076 17.6946 6.29319C17.88 6.47879 17.9852 6.72986 17.9875 6.99233C17.9897 7.25479 17.8889 7.50765 17.7068 7.69645L7.70679 17.7067C7.61405 17.7998 7.50385 17.8736 7.38252 17.924C7.26119 17.9743 7.13112 18.0001 6.99979 18Z"
                      fill="#FA4D4D"
                    />
                    <path
                      d="M16.9994 18C16.8681 18.0001 16.738 17.9743 16.6167 17.924C16.4954 17.8736 16.3852 17.7998 16.2924 17.7067L6.29243 7.69645C6.11027 7.50765 6.00948 7.25479 6.01176 6.99233C6.01403 6.72986 6.1192 6.47879 6.30461 6.29319C6.49002 6.1076 6.74083 6.00232 7.00303 6.00004C7.26523 5.99776 7.51783 6.09866 7.70643 6.281L17.7064 16.2912C17.8462 16.4312 17.9414 16.6096 17.98 16.8037C18.0186 16.9979 17.9988 17.1991 17.9231 17.382C17.8474 17.5649 17.7193 17.7212 17.5549 17.8312C17.3905 17.9412 17.1972 18 16.9994 18Z"
                      fill="#FA4D4D"
                    />
                  </svg>
                </>
              </>
            )}
          </>
        ),
      },
      {
        Header: "Account Type",
        accessor: "account_type",
        Cell: ({row}) => <>{row.original.account_type.replace(/_/g, "-")}</>,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({row}) => (
          <span style={{color: row.original.status === "approved" ? "#209829" : row.original.status === "denied" ? "#FA4D4D" : "#FFB100", textTransform: "capitalize"}}>
            {row.original.status.replace("_", " ")}
          </span>
        ),
      },
      // {
      //   Header: "URL",
      //   accessor: "url",
      //   Cell: ({row}) => (
      //     <>
      //       <ToastContainer />
      //       {row.original.url && (
      //         <div
      //           className="column_one"
      //           style={{
      //             display: "flex",
      //             alignItems: "center",
      //             width: "200px",
      //             justifyContent: "space-between",
      //           }}
      //         >
      //           <p> {truncateString(row.original.url, 20)}</p>
      //           <CopyToClipboard
      //             onCopy={handleCopyToClipboard}
      //             text={row.original.url}
      //           >
      //             <p
      //               className="table_copy_button"
      //               style={{
      //                 marginRight: "20px",
      //                 cursor: "pointer",
      //               }}
      //             >
      //               <svg
      //                 width="28"
      //                 height="28"
      //                 viewBox="0 0 24 24"
      //                 fill="none"
      //                 xmlns="http://www.w3.org/2000/svg"
      //               >
      //                 <rect
      //                   width="24"
      //                   height="24"
      //                   rx="12"
      //                   fill="#071E0D"
      //                 />
      //                 <path
      //                   d="M17.886 12.574C18 12.2987 18 11.9487 18 11.25C18 10.5513 18 10.2013 17.886 9.926C17.8106 9.74395 17.7001 9.57854 17.5608 9.43922C17.4215 9.29989 17.256 9.18938 17.074 9.114C16.7987 9 16.4487 9 15.75 9H11.4C10.56 9 10.14 9 9.81933 9.16333C9.53684 9.30719 9.30719 9.53684 9.16333 9.81933C9 10.1393 9 10.5593 9 11.4V15.75C9 16.4487 9 16.7987 9.114 17.074C9.26667 17.4413 9.55867 17.734 9.926 17.886C10.2013 18 10.5513 18 11.25 18C11.9487 18 12.2987 18 12.574 17.886M17.886 12.574C17.8106 12.756 17.7001 12.9215 17.5608 13.0608C17.4215 13.2001 17.256 13.3106 17.074 13.386C16.7987 13.5 16.4487 13.5 15.75 13.5C15.0513 13.5 14.7013 13.5 14.426 13.614C14.244 13.6894 14.0785 13.7999 13.9392 13.9392C13.7999 14.0785 13.6894 14.244 13.614 14.426C13.5 14.7013 13.5 15.0513 13.5 15.75C13.5 16.4487 13.5 16.7987 13.386 17.074C13.3106 17.256 13.2001 17.4215 13.0608 17.5608C12.9215 17.7001 12.756 17.8106 12.574 17.886M17.886 12.574C17.5275 13.8107 16.8678 14.9391 15.9661 15.8582C15.0645 16.7774 13.9489 17.4585 12.7193 17.8407L12.574 17.886M15 9V8.4C15 7.56 15 7.14 14.8367 6.81933C14.693 6.53694 14.4636 6.3073 14.1813 6.16333C13.86 6 13.44 6 12.6 6H8.4C7.56 6 7.14 6 6.81933 6.16333C6.53684 6.30719 6.30719 6.53684 6.16333 6.81933C6 7.13933 6 7.55933 6 8.4V12.6C6 13.44 6 13.86 6.16333 14.1807C6.30733 14.4633 6.53667 14.6927 6.81933 14.8367C7.13933 15 7.56 15 8.40067 15H9"
      //                   stroke="#36D66B"
      //                   stroke-linecap="round"
      //                   stroke-linejoin="round"
      //                 />
      //               </svg>
      //             </p>
      //             {/* </button> */}
      //           </CopyToClipboard>
      //         </div>
      //       )}
      //     </>
      //   ),
      // },
      {
        Header: "Contract Issued",
        accessor: "contract_issued",
        Cell: ({row}) => <>{row.original.contract_issued ? "Yes" : "No"}</>,
      },
      {
        Header: "Created on",
        accessor: "created_at",
        Cell: ({row}) => <>{moment(row.original.created_at).format("DD-MMM-YYYY")}</>,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({row}) => {
          const [anchorEl, setAnchorEl] = React.useState(null);

          const open = Boolean(anchorEl);
          const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
          };
          const handleClose = () => {
            setAnchorEl(null);
          };
          const [loading, setLoading] = useState(false);
          const dispatch = useDispatch();
          const handleSignContract = async (e) => {
            if (row.original.url) {
              window.location.replace(row.original.url);
            } else if (!row.original.url) {
              const response = await digiSigner(idToken, row.original.id);
              
              if (response?.status < 399) {
                window.location.replace(response?.data?.url);
              } else {
                dispatch(returnErrors(response.response.data.detail, response.response.status));
                setLoading(false);
              }
            } else {
              dispatch(returnErrors("Something went wrong", 500));
            }
          };
          return (
            <div>
              <div>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ml: 2}}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Tooltip>
                </Box>

                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      minWidth: "150px",
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{horizontal: "right", vertical: "top"}}
                  anchorOrigin={{horizontal: "right", vertical: "bottom"}}
                >
                  {row.original.is_verified ? <MenuItem>Contact already signed</MenuItem> : <MenuItem onClick={() => handleSignContract(row)}>Sign Contract</MenuItem>}
                </Menu>
              </div>
            </div>
          );
        },
      },
    ],
    [],
  );
  return (
    <div className="contract_list">
      <ReactTable
        data={contactList}
        columns={columns}
      />
    </div>
  );
};
