import {all, call, put, takeEvery} from "redux-saga/effects";
import {
  getPlans,
  getPlansSuccess,
  getPlansFailure,
  setSelectedChallenge,
  getPaymentHistory,
  getPaymentHistorySuccess,
  getPaymentHistoryFailure,
  getCompetitionList,
  getCompetitionListSuccess,
  getCompetitionListFailure,
  getAccountList,
  getAccountListFailure,
  getAccountListSuccess,
  getAccountMetrics,
  getAccountMetricsSuccess,
  getAccountMetricsFailure,
  getPayoutSplitListSuccess,
  getPayoutAffiliateListSuccess,
  getPayoutAffiliateListFailure,
  getPayoutSplitListFailure,
  getPayoutSplitList,
  getPayoutAffiliateList,
  getTraderJournal,
  getTraderJournalSuccess,
  getTraderJournalFailure,
} from "../../reducers/accountSlice";
import {getUser} from "../../reducers/userSlice";
import {
  getFundingEvaluationPlansRequest,
  getPaymentHistoryRequest,
  getCompetitionListRequest,
  getUserAccountListRequest,
  getAccountMetricsRequest,
  getBalanceChartRequest,
  getPayoutListRequest,
  getTraderJournalRequest,
} from "../../../utils/apis";
import {getAffiliatePayoutRequest, getProfitSplittPayoutRequest} from "../../../utils/apis/account";
import {number} from "echarts";

function* fetchPlans(action) {
  try {
    const plans = yield call(getFundingEvaluationPlansRequest, "");

    yield put(getPlansSuccess(plans));
  } catch (error) {
    yield put(getPlansFailure(error));
  }
}

function* fetchPaymentHistory(action) {
  try {
    const paymentHistory = yield call(getPaymentHistoryRequest, action.payload);

    yield put(getPaymentHistorySuccess(paymentHistory));
  } catch (error) {
    yield put(getPaymentHistoryFailure(error));
  }
}

function* fetchCompetitionList(action) {
  try {
    const competitionList = yield call(getCompetitionListRequest, action.payload);

    yield put(getCompetitionListSuccess(competitionList));
  } catch (error) {
    yield put(getCompetitionListFailure(error));
  }
}
function* fetchAccountList(action) {
  try {
    const accountList = yield call(getUserAccountListRequest, action.payload);

    yield put(getAccountListSuccess(accountList));
  } catch (error) {
    yield put(getAccountListFailure(error));
  }
}

function* fetchAccountMetrics(action) {
  try {
    if (action.payload.loginId !== null) {
      const accountMetrics = yield call(getAccountMetricsRequest, action.payload);
      const balance_chart = yield call(getBalanceChartRequest, action.payload);
      const data = {
        ...accountMetrics.data,
        balance_chart: balance_chart,
      };
      if (accountMetrics?.response?.status >= 400) {
        yield put(getAccountMetricsFailure(accountMetrics));
      } else {
        yield put(getAccountMetricsSuccess(data));
      }
    }
  } catch (error) {}
}
function* fetchPayoutSplitList(action) {
  try {
    const payoutList = yield call(getProfitSplittPayoutRequest, action.payload);

    yield put(getPayoutSplitListSuccess(payoutList));
  } catch (error) {
    yield put(getPayoutSplitListFailure(error));
  }
}
function* fetchPayoutAffliateList(action) {
  try {
    const payoutList = yield call(getAffiliatePayoutRequest, action.payload);

    yield put(getPayoutAffiliateListSuccess(payoutList));
  } catch (error) {
    yield put(getPayoutAffiliateListFailure(error));
  }
}

function* fetchTraderJournal(action) {
  try {
    const traderJournal = yield call(getTraderJournalRequest, action.payload);
    yield put(getTraderJournalSuccess(traderJournal));
  } catch (error) {
    yield put(getTraderJournalFailure(error));
  }
}

export default function* accountSaga() {
  yield all([
    takeEvery(getPlans.type, fetchPlans),
    takeEvery(getPaymentHistory.type, fetchPaymentHistory),
    takeEvery(getCompetitionList.type, fetchCompetitionList),
    takeEvery(getAccountList.type, fetchAccountList),
    takeEvery(getAccountMetrics.type, fetchAccountMetrics),
    takeEvery(getPayoutSplitList.type, fetchPayoutSplitList),
    takeEvery(getPayoutAffiliateList.type, fetchPayoutAffliateList),
    takeEvery(getTraderJournal.type, fetchTraderJournal),
    takeEvery(getUser.type, function* (action) {
      yield all([call(fetchAccountList, action), call(fetchAccountMetrics, action)]);
    }),
  ]);
}
