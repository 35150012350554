import React, {useEffect, useState} from "react";
import "./PageLayout.scss";
import MainSidebar from "./MainSidebar.jsx";
import MainHeader from "./MainHeader.jsx";
import {useDispatch, useSelector} from "react-redux";
import {getAccountList, getAccountMetrics, getPlans} from "../../store/reducers/accountSlice.js";

const PageLayout = ({headerName, children}) => {
  const [mobileMenu, setMobileMenu] = useState(false);

  const handleMenu = () => {
    setMobileMenu(true);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPlans());
  }, []);

  const defaultData = {
    user_id: 1,
    challenge_id: 4,
    challenge_type: "0",
    unique_id: "0",
    email: "0",
    login_id: "0",
    password: "0",
    server: "0",
    name: "0",
    challenge: "0",
    start_date: "0",
    phase: "0",
    trading_platform: "0",
    competition: null,
    is_active: true,
    status: "0",
  };

  useEffect(() => {
    if (idToken) {
      dispatch(getAccountList(idToken));
    }
  }, []);

  const idToken = useSelector((state) => state.auth.idToken);
  const activeAccount = useSelector((state) => state?.account?.accountList?.active);
  const firstAccount = useSelector((state) => (state?.account?.accountList?.data && state?.account?.accountList?.data?.length > 0 ? state.account.accountList.data[0] : defaultData));
  const loginId = activeAccount || firstAccount?.login_id;

  // if (firstAccount) {
  //   const convertedAccount = convertValues(firstAccount);
  //   const loginId = activeAccount || convertedAccount.login_id;
  //   const defaultData = Object.keys(convertedAccount);
  // } else {
  //   const loginId = activeAccount || "";
  //   const defaultData = [];
  // }

  console.log("firstAccount", firstAccount);
  useEffect(() => {
    firstAccount && dispatch(getAccountMetrics({idToken, loginId}));
  }, [loginId]);

  return (
    <div className="mainPageLayout">
      <div className="mainPageHolder">
        <div className="mainPageHolder-T-C">
          <div className="mainPageHolder-T">
            <MainHeader
              headerName={headerName}
              showTabs={false}
              mobileMenu={mobileMenu}
              setMobileMenu={setMobileMenu}
              handleMenu={handleMenu}
            />
          </div>
        </div>
        <div className="mainCon">
          <div className={`mainSidebarCon ${mobileMenu ? "show" : ""}`}>
            <MainSidebar
              headerName={headerName}
              mobileMenu={mobileMenu}
              setMobileMenu={setMobileMenu}
              handleMenu={handleMenu}
            />
          </div>

          <div className="mainContentCon">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default PageLayout;

//THIS IS BASE CODE FOR THE CONTAINER ,
//need to add that on all the existing propstone port pages

{
  /* <div className="mainPageHolder">
  <div className="mainPageHolder-T-C">
    <div className="mainPageHolder-T">
      <MainHeader headerName="Dashboard" />
    </div>
  </div>
  <div className="mainPageHolder-B">
  
  //THE COMPONENT CODE SHOULD BE HERE (OLD CODE OF THE PAGE)
  
  </div>
</div> */
}
