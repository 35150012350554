import React from "react";
import "./style.scss";
import {useSelector} from "react-redux";

import EquityIcon from "../../../assets/icons/equityIcon.svg";
import BalanceIcon from "../../../assets/icons/balanceIcon.svg";
import TradesIcon from "../../../assets/icons/NtradesIcon.svg";
import WinRateIcon from "../../../assets/icons/winRateIcon.svg";
import AccountIcon from "../../../assets/icons/accountsIcon.svg";
import minimunTradingDay from "../../../assets/icons/mininumTradingDays.svg";

function Statistic({value, value2, lastUpdated, challenge}) {
  const now = new Date();
  const options = {timeZoneName: "short"};
  const timeZone = new Intl.DateTimeFormat(undefined, options).formatToParts(now).find((part) => part.type === "timeZoneName").value;

  const accountsData = useSelector((state) => state.account);
  function formatAsUSD(amount) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  }
  const accountList = accountsData?.accountList;
  const account = accountList?.data?.find((acc) => acc?.login_id === accountList?.active);
  console.log(account, "accountaccount");

  return (
    <div className="statistic-container">
      <div className="statistic-subcontainer">
        <h4>Statistic</h4>
        <div className="statistic_details_container">
          <div className="statistic_details_Wrapper">
            <img
              src={EquityIcon}
              alt=""
            />
            <div className="statistic_info">
              <h5>Equity</h5>
              <p>{formatAsUSD(accountsData?.accountMetrics?.data?.balance_equity?.equity)}</p>
            </div>
          </div>
          <div className="statistic_details_Wrapper">
            <img
              src={BalanceIcon}
              alt=""
            />
            <div className="statistic_info">
              <h5>Balance</h5>
              <p>{formatAsUSD(accountsData?.accountMetrics?.data?.balance_equity?.balance)}</p>
            </div>
          </div>
          <div className="statistic_details_Wrapper">
            <img
              src={TradesIcon}
              alt=""
            />
            <div className="statistic_info">
              <h5>No. of trade</h5>
              <p>{accountsData?.accountMetrics?.data?.account_metrics?.number_of_trades}</p>
            </div>
          </div>
          <div className="statistic_details_Wrapper">
            <img
              src={WinRateIcon}
              alt=""
            />
            <div className="statistic_info">
              <h5>Win rate</h5>
              <p>{Math.trunc(value2?.win_rate * 100) / 100}%</p>
            </div>
          </div>
          <div className="statistic_details_Wrapper">
            <img
              src={AccountIcon}
              alt=""
            />
            <div className="statistic_info">
              <h5>Account size</h5>
              <p>{challenge}</p>
            </div>
          </div>
          {account?.next_payout_date && (
            <div className="statistic_details_Wrapper">
              <img
                src={minimunTradingDay}
                alt=""
              />
              <div className="statistic_info">
                <h5>Next payout date</h5>
                <p>{(account && account?.next_payout_date) || "NA"}</p>
              </div>
            </div>
          )}
          {/* <div className="statistic_details_Wrapper">
          <img src={WinRateIcon} alt="" />
            <div className="statistic_info">
            <h5>Lots</h5>
            <p>{value2?.lots?.toFixed(2)}</p>
            </div>
          </div> */}
          {/* <div>
            <p className={accountsData?.accountMetrics?.data?.account_metrics?.avg_win < 0 ? "average_loss_active" : "average_profit"}>
              ${accountsData?.accountMetrics?.data?.account_metrics?.avg_win}
            </p>
            <h5>Average profit</h5>
          </div>
          <div>
            <p className={value?.average_loss ? "loss" : "profit"}>${value?.average_loss}</p>
            <h5>Average loss</h5>
          </div>
          <div>
            <p>{value2?.rrr}</p>
            <h5>Average RRR</h5>
          </div>
          <div>
            <p>{value2?.expectancy}</p>
            <h5>Expectancy</h5>
          </div>
          <div>
            <p>{value2?.profit_factor}</p>
            <h5>Profit factor</h5>
          </div>
          <div>
            <p>{value2?.interest}</p>
            <h5>Interest</h5>
          </div> */}
        </div>
      </div>
      {/* <div className="statistic-details">
        <p>
          Timezone : <span>{timeZone}</span>
        </p>
        <p className="last_update">
          Last Updated : <span>{lastUpdated}</span>
        </p>
      </div> */}
    </div>
  );
}

export default Statistic;
