import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
const initialState = {
  error: null,
  message: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      if (action.payload !== null) {
        
        return { ...action.payload, error: false, message: "" };
      } else {
        return null;
      }
    },
    getUser: (action) => {},
    patchUser: (state, action) => {
      state.error = false;
    },
    patchUserSuccess: (state, action) => {
      state.error = false;
      state.message = "";
    },
    patchUserFailure: (state, action) => {
      state.error = true;
      state.message =
        (action?.payload &&
          action.payload.response &&
          action.payload.response.data &&
          action.payload.response.data.detail) ||
        "An error occurred.";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const {
  setUser,
  getUser,
  patchUser,
  patchUserFailure,
  patchUserSuccess,
} = userSlice.actions;
export default userSlice.reducer;
