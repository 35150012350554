import {useEffect, useState} from "react";
import "./style.scss";

import CompetitionDetailsBox from "../../components/CompetitionList/CompetitionDetailsBox";
import Tabination from "../../reusableComponents/Tabination";
import Ongoing from "../../components/CompetitionList/Ongoing";
import Participated from "../../components/CompetitionList/Participated";
import Upcoming from "../../components/CompetitionList/Upcoming";
// import { getCompetitionList } from "../../utils/apis";
import {useDispatch, useSelector} from "react-redux";

import {getCompetitionList} from "../../store/reducers/accountSlice";
import ErrorModal from "../../reusableComponents/ErrorModal";

import LoaderOverlay from "../../reusableComponents/Loader";
import MainHeader from "../../layouts/pageLayout/MainHeader";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import CustomSeparator from "../../components/Breadcrumbs/Breadcrumbs";
// import { setCompetitionList } from "../../store/reducers/accountSlice";

function CompetitionList() {
  const dispatch = useDispatch();
  const idToken = useSelector((state) => state.auth.idToken);
  const location = useLocation();
  const path = location.pathname.split("/")[2];

  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    dispatch(getCompetitionList(idToken));
  }, [idToken]);

  // const [competitionList, setCompetitionList] = useState([]);
  const competitionListValue = useSelector((state) => state?.account?.competitionList);
  const [competitionList, setCompetitionList] = useState(competitionListValue);

  const [errorModalOpen, setErrorModalOpen] = useState(competitionListValue.error);
  useEffect(() => {
    setCompetitionList(competitionListValue);
  }, [competitionListValue]);

  useEffect(() => {
    dispatch(getCompetitionList(idToken));
  }, [idToken]);
  useEffect(() => {
    setErrorModalOpen(competitionListValue.error);
    setCompetitionList(competitionListValue);
  }, [competitionListValue.data.loading]);

  const items = [
    {
      name: "Ongoing",
      path: "ongoing",
    },
    {
      name: "Upcoming",
      path: "upcoming",
    },
    {
      name: "Participated",
      path: "participated",
    },
    {
      name: "Ended competitions",
      path: "ended",
    },
  ];
  const navigation = [
    {pathName: "Competiton List", link: "/competition-list/ongoing"},
    // {pathName: "Checkout", link: "/funding-evaluation/plans"},
  ];
  if (competitionListValue?.loading === true) {
    return (
      <div style={{position: "relative", width: "100%", height: "100%"}}>
        <LoaderOverlay />
      </div>
    );
  } else {
    return (
      <>
        {errorModalOpen && (
          <ErrorModal
            desc={competitionListValue.message}
            setModalOpen={setErrorModalOpen}
            btnText="Cancel"
          />
        )}
        <div className="mainPageHolder">
          <div className="mainPageHolder-T-C">
            <div className="mainPageHolder-T">
              <MainHeader
                headerName="Competiton List"
                variant={3}
                tabItems={items}
                activeTab={0}
                showTabs={true}
              />
            </div>
          </div>
          <div className="mainPageHolder-B">
            <div className="all_header">
              <h2>Competiton List</h2>
            </div>
            <Tabination
              variant={3}
              tabItems={items}
              activeTab={activeTab}
              style={{margin: "0 14px"}}
              className="tabination_all"
            />
            <CustomSeparator navigation={navigation} />

            <div className="accountmetrics-container">
              {/* {(isLoading || accountMetrics.error) && <LoaderOverlay desc={accountMetrics.error && accountMetrics.message} />} */}

              <Routes>
                <Route
                  path="/"
                  element={
                    <Navigate
                      to="ongoing"
                      replace={true}
                    />
                  }
                />
                <Route
                  path="ongoing"
                  element={
                    <Ongoing
                      enterCompetition={false}
                      competitions={competitionList?.data?.Ongoing || []}
                    />
                  }
                />
                <Route
                  path="upcoming"
                  element={
                    <Upcoming
                      enterCompetition={true}
                      competitions={competitionList?.data?.Upcoming || []}
                    />
                  }
                />
                <Route
                  path="participated"
                  element={
                    <Participated
                      timer={true}
                      enterCompetition={false}
                      competitions={competitionList?.data?.Participated || []}
                      closed={true}
                    />
                  }
                />
                <Route
                  path="ended"
                  element={
                    <Participated
                      enterCompetition={false}
                      competitions={competitionList?.data?.End || []}
                      closed={true}
                      timer={false}
                      // competitionList1={competitionListValue}
                    />
                  }
                />
              </Routes>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CompetitionList;
/* <div className="mainPageHolder">
  <div className="mainPageHolder-T-C">
    <div className="mainPageHolder-T">
      <MainHeader headerName="Dashboard" />
    </div>
  </div>
  <div className="mainPageHolder-B">
  
  //THE COMPONENT CODE SHOULD BE HERE (OLD CODE OF THE PAGE)
  
  </div>
</div> */
