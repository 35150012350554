import React, {useEffect, useRef, useState} from "react";
import "./style.scss";
import Tabination from "../../../reusableComponents/Tabination";
import EmailIcon from "../../../assets/icons/email.svg";
import CheckSolidGreenIcon from "../../../assets/icons/check-solid-green.svg";
import UserIcon from "../../../assets/icons/user.svg";
import PhoneIcon from "../../../assets/icons/phone.svg";
import ApartmentNoIcon from "../../../assets/icons/apartment-no.svg";
import StateIcon from "../../../assets/icons/state.svg";
import CountryIcon from "../../../assets/icons/country.svg";
import Warning from "../Verification/Warning";
import Close from "../../../assets/icons/circle-cross.svg";
import Select from "react-select";
import ErrorModal from "../../../reusableComponents/ErrorModal";
// import SuccessModal from "../../reusableComponents/SuccessModal";
import SuccessModal from "../../../reusableComponents/SuccessModal";
import {returnMessages} from "../../../store/reducers/message";
import {returnErrors} from "../../../store/reducers/error";

import {addACH, addCrypto, addCtypto, addDeel, addPaypal, addSwift, addWire} from "../../../utils/apis/user";
import {useDispatch, useSelector} from "react-redux";
import {country} from "../../../utils/constants/country";
import {Checkbox, FormControlLabel, Modal} from "@mui/material";
import {validateEmail, validatePassword} from "../../../utils/helpers/string";
import customStyles from "../../../customstyle/customstyle";
import {paymentMethods} from "../../../utils/apis/payment";
import Spinner from "../../../reusableComponents/Spinner";

function RecipientBankDetails({closeBankDetailsModal, value, setValue, setRefreshBanksDetails, refreshBanksDetails}) {
  const bankName = ["", "Deel", "ACH", "Wire", "Swift", "Paypal", "USDT TRC20"];
  const [defaultValue, setdefaultValue] = useState([]);
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  const fetchPaymentMethods = async (value) => {
    const response = await paymentMethods(idToken, dispatch);

    const banksDefault = Object.keys(response)?.map((item) => {
      const array = response[item];
      console.log(array[0], "array");
      return {...array[0], name: item};
    });
    const newValue = value === 5 ? 1 : value;
    setdefaultValue(banksDefault);
    setInputValues(banksDefault[Number(newValue)]);
    console.log(banksDefault[Number(newValue)], "banksDefault[value]");
  };

  useEffect(() => {
    fetchPaymentMethods(value);
    setInputValues(defaultValue);
  }, [value]);

  const [inputValues, setInputValues] = useState({
    account_name: "",
    wallet: "",
    email: "",
    account_number: "",
    bic_code: "",
    account_type: "",
    mobile_number: "",
    username: "",
    routing_number: "",
    default: false,
    address: {
      country: "",
      state: "",
      city: "",
      apartment_no: "",
      street_address: "",
      zip_code: "",
      first_name: "",
      last_name: "",
    },
  });
  console.log(inputValues, "defaultValuedefaultValue");
  console.log(inputValues, "inputValues");
  const handleSelectChange = (name, selectedOption) => {
    setInputValues((prev) => ({...prev, [name]: selectedOption}));
    // setInputValues((prev) => ({ ...prev }));
  };

  const banks = [
    {
      name: "dummy",
      content: (
        <div className="form_container">
          <GeneralInfo
            refreshBanksDetails={refreshBanksDetails}
            setRefreshBanksDetails={setRefreshBanksDetails}
            email={true}
            setInputValues={setInputValues}
            inputValues={inputValues}
            value={value}
          />
          {/* <Warning /> */}
          {/* <Button /> */}
        </div>
      ),
    },
    {
      name: "Deel",
      content: (
        <div className="form_container">
          <GeneralInfo
            refreshBanksDetails={refreshBanksDetails}
            setRefreshBanksDetails={setRefreshBanksDetails}
            setInputValues={setInputValues}
            inputValues={inputValues}
            value={value}
            closeBankDetailsModal={closeBankDetailsModal}
            defaultValue={defaultValue}
            fetchPaymentMethods={fetchPaymentMethods}
          />
          {/* <Warning /> */}
          {/* <Button /> */}
        </div>
      ),
    },
    {
      name: "ACH",
      content: (
        <div className="form_container">
          <GeneralInfo
            refreshBanksDetails={refreshBanksDetails}
            setRefreshBanksDetails={setRefreshBanksDetails}
            email={true}
            setInputValues={setInputValues}
            inputValues={inputValues}
            value={value}
            closeBankDetailsModal={closeBankDetailsModal}
            defaultValue={defaultValue}
            fetchPaymentMethods={fetchPaymentMethods}
          />
          {/* <Button /> */}
        </div>
      ),
    },
    {
      name: "Wire",
      content: (
        <div className="form_container">
          {/* <Warning /> */}
          <RecipientAddress
            setInputValues={setInputValues}
            inputValues={inputValues}
            handleSelectChange={handleSelectChange}
          />
          <GeneralInfo
            refreshBanksDetails={refreshBanksDetails}
            setRefreshBanksDetails={setRefreshBanksDetails}
            email={true}
            setInputValues={setInputValues}
            inputValues={inputValues}
            value={value}
            closeBankDetailsModal={closeBankDetailsModal}
            defaultValue={defaultValue}
            fetchPaymentMethods={fetchPaymentMethods}
          />
          {/* <Button /> */}
        </div>
      ),
    },
    {
      name: "Swift",
      content: (
        <div className="form_container">
          {/* <Warning /> */}
          <RecipientAddress
            setInputValues={setInputValues}
            inputValues={inputValues}
            handleSelectChange={handleSelectChange}
          />
          <GeneralInfo
            refreshBanksDetails={refreshBanksDetails}
            setRefreshBanksDetails={setRefreshBanksDetails}
            email={true}
            setInputValues={setInputValues}
            inputValues={inputValues}
            value={value}
            closeBankDetailsModal={closeBankDetailsModal}
            defaultValue={defaultValue}
            fetchPaymentMethods={fetchPaymentMethods}
          />
          {/* <Button /> */}
        </div>
      ),
    },
    {
      name: "Paypal",
      content: (
        <div className="form_container">
          <GeneralInfo
            refreshBanksDetails={refreshBanksDetails}
            setRefreshBanksDetails={setRefreshBanksDetails}
            email={false}
            setInputValues={setInputValues}
            inputValues={inputValues}
            value={value}
            closeBankDetailsModal={closeBankDetailsModal}
            defaultValue={defaultValue}
            fetchPaymentMethods={fetchPaymentMethods}
          />
          {/* <Button /> */}
        </div>
      ),
    },
    {
      name: "USDT TRC20",
      content: (
        <div className="form_container">
          <GeneralInfo
            refreshBanksDetails={refreshBanksDetails}
            setRefreshBanksDetails={setRefreshBanksDetails}
            email={false}
            setInputValues={setInputValues}
            inputValues={inputValues}
            value={value}
            closeBankDetailsModal={closeBankDetailsModal}
            defaultValue={defaultValue}
            fetchPaymentMethods={fetchPaymentMethods}
          />
          {/* <Button /> */}
        </div>
      ),
    },
  ];
  let items = [];
  if (value) {
    if (banks.name === "dummmy") {
    } else {
      items.push(banks[value]);
    }
  }
  const handleClose = () => {
    setValue(false);
  };
  return (
    value && (
      <Modal
        className="mui_modal"
        // sx={{border: "none", outline: "none"}}
        // isModalOpen={true}
        open={value}
        // onClose={value === 3 ? "" : handleClose}
      >
        <div
          className="bankdetails_modal_overlay"
          // onClick={closeBankDetailsModal}
        >
          <div
            className="bankdetails_modal_container"
            // onClick={closeBankDetailsModal}
          >
            <div className="modal_header">
              <p>{bankName[value]} Details</p>
              {/* {value === 3 ? (
                ""
              ) : (
                <img
                  src={Close}
                  alt="close icon"
                  onClick={closeBankDetailsModal}
                />
              )} */}
            </div>
            <Tabination
              variant={3}
              tabItems={items}
              activeTab={0}
              showTabs={false}
            />
          </div>
        </div>
      </Modal>
    )
  );
}

export default RecipientBankDetails;

const GeneralInfo = ({email, setInputValues, inputValues, value, closeBankDetailsModal, defaultValue, fetchPaymentMethods, refreshBanksDetails, setRefreshBanksDetails}) => {
  const idToken = useSelector((state) => state.auth.idToken);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isErrorModal, setErrorModal] = useState(false);
  const [isInputError, setIsInputError] = useState({
    email: false,
    password: false,
  });
  const [isModal, setModal] = useState(null);

  let emailRef = useRef(null);
  let passwordRef = useRef(null);
  console.log(value, "valuevalue");

  // submit

  // const handleBlur = (e) => {
  //   const {id} = e.target;
  //   id === "email" && emailRef.current.classList.remove("input-error");
  //   id === "password" && passwordRef.current.classList.remove("input-error");
  // };
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleFocus = (e) => {
    const {id} = e.target;
    setIsInputError((prev) => ({...prev, [id]: false}));
  };
  const [isSpinner, setIsSpinner] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    // Validation for specific API call
    let isValid = true;
    switch (value) {
      case 3: // Wire API
        if (!inputValues?.bic_code || !inputValues?.iban_number || !inputValues?.address.country || !inputValues?.address.state || !inputValues?.address.city || !inputValues?.address.apartment_no || !inputValues?.address.street_address || !inputValues?.address.zip_code || !inputValues?.address.first_name || !inputValues?.address.last_name) {
          dispatch(returnErrors("Please fill all the fields", 400));
          isValid = false;
        }
        break;
      case 5: // Paypal API
        if (!inputValues?.username || !inputValues?.email || !inputValues?.mobile_number) {
          dispatch(returnErrors("Please fill all required fields for Paypal", 400));
          isValid = false;
        }
        break;
      case 6: // Crypto API
        if (!inputValues?.wallet) {
          dispatch(returnErrors("wallet address is compulsory for payout", 400));
          isValid = false;
        }
        break;
      default:
        break;
    }
  
    if (!isValid) {
      return;
    }
  
    // Construct objects for API call
    const paypal = {
      username: inputValues?.username,
      email: inputValues?.email,
      mobile_number: inputValues?.mobile_number,
    };
    const crypto = {
      wallet: inputValues?.wallet,
    };
    const wire = {
      bic_code: inputValues?.bic_code,
      iban_number: inputValues?.iban_number,
      address: {
        country: inputValues?.address?.country,
        state: inputValues?.address?.state,
        city: inputValues?.address?.city,
        apartment_no: inputValues?.address?.apartment_no,
        street_address: inputValues?.address?.street_address,
        zip_code: inputValues?.address?.zip_code,
        first_name: inputValues?.address?.first_name,
        last_name: inputValues?.address?.last_name,
      },
    };
  
    // Proceed with API call based on the selected option
    try {
      setIsSpinner(true);
      let res;
      switch (value) {
        case 3: // Wire API
          if (!checked) {
            dispatch(returnErrors("Please acknowledge the provided information", 500));
            setIsSpinner(false);
            return;
          }
          res = await addWire(idToken, wire);
          break;
        case 5: // Paypal API
          res = await addPaypal(idToken, paypal);
          break;
        case 6: // Crypto API
          res = await addCrypto(idToken, crypto);
          break;
        default:
          break;
      }
  
      // Handle API response
      if (res.status < 203) {
        setIsSpinner(false);
        // dispatch(returnMessages("Successfully updated", 200));
        setRefreshBanksDetails(!refreshBanksDetails);
        closeBankDetailsModal();
      } else if (res.response?.status >= 399) {
        setIsSpinner(false);
        // Handle error response
        const error = res.response.data;
        let msg;
        if (Object.keys(error).length > 0) {
          const firstKey = Object.keys(error)[0];
          if (firstKey === "address") {
            const subError = error[firstKey];
            if (Object.keys(subError).length > 0) {
              const firstKey1 = Object.keys(subError)[0];
              console.log(firstKey1, "submenu");
              // const firstString = subError[firstKey1] || subError[firstKey1][0];
              msg = firstKey1;
            }
          } else {
            const firstString = error[firstKey] || error[firstKey][0];
            console.log(firstKey, firstString, "key1");
            msg = firstKey;
          }
        } else {
          msg = "Something went wrong";
        }
        const newmsg = `${msg?.replace(/_/g, " ") + " field be required"}`;
        dispatch(returnErrors(newmsg, 500));
      }
    } catch (error) {
      setIsSpinner(false);
      console.log(error, "submenu");
      dispatch(returnErrors("An error occurred", 500));
    }
  };
  
  return (
    <>
      <div className="general_info_form_wrapper">
        <div className="general_info_form_container">
          <div className="general_info_form">
            {/* name */}
            {email === true && !value === 3 ? (
              <div className="general_info_form_input_container">
                <img
                  src={UserIcon}
                  alt=""
                  className="input_icon"
                />
                <div className=" general_info_input">
                  <label
                    className="general_info_label"
                    htmlFor="name"
                  >
                    Account Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Account Name"
                    value={inputValues?.account_name}
                    onChange={(e) => setInputValues({...inputValues, account_name: e.target.value})}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {value === 6 && (
              <>
                <div>
                  <label
                    // className="general_info_label"
                    style={{color: "black"}}
                    htmlFor="contact"
                  >
                    Wallet Address (USDT TRC20)
                  </label>
                  <div className="general_info_form_input_container">
                    <img
                      src={UserIcon}
                      alt=""
                      className="input_icon"
                    />
                    <div className=" general_info_input">
                      <input
                        type="text"
                        name="USDT TRC20"
                        id="USDT TRC20"
                        placeholder="USDT TRC20"
                        value={inputValues?.wallet}
                        onChange={(e) => setInputValues({...inputValues, wallet: e.target.value})}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {value === 5 && (
              <div className="general_info_form_input_container">
                <img
                  src={UserIcon}
                  alt=""
                  className="input_icon"
                />
                <div className=" general_info_input">
                  <label
                    className="general_info_label"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    value={inputValues?.username}
                    onChange={(e) => setInputValues({...inputValues, username: e.target.value})}
                  />
                </div>
              </div>
            )}

            {/* email */}

            {value === 5 && (
              <div className="general_info_form_input_container">
                <img
                  src={EmailIcon}
                  alt=""
                  className="input_icon"
                />
                <div className=" general_info_input">
                  <label
                    className="general_info_label"
                    htmlFor="email"
                  >
                    Email Address
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    value={inputValues?.email}
                    onChange={(e) => setInputValues({...inputValues, email: e.target.value})}
                    // onBlur={handleBlur}
                    onFocus={handleFocus}
                  />
                </div>
                <div className="signinform-container_icon">
                  {validateEmail(inputValues?.email) && (
                    <img
                      src={CheckSolidGreenIcon}
                      alt=""
                      className="signinform-icon"
                    />
                  )}
                </div>
              </div>
            )}
            {value === 1 || value === 2 || value === 4 ? (
              <div className="account_type">
                <p>Default type</p>
                <div className="radio_container">
                  <div>
                    <input
                      type="radio"
                      name="account_type"
                      value={true}
                      // checked={inputValues?.default === false ? true : ""}
                      onChange={(e) => setInputValues({...inputValues, default: e.target.value})}
                    />
                    <label>Yes </label>
                  </div>

                  <div>
                    <input
                      type="radio"
                      name="account_type"
                      value={false}
                      // checked={inputValues?.default === false}
                      onChange={(e) => setInputValues({...inputValues, default: e.target.value})}
                    />
                    <label>No</label>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* <div className="account_type">
          <p>Default type</p>
          <div className="radio_container">
            <div>
              <input
                type="radio"
                name="account_type"
                value={true}
                // checked={inputValues?.default === false ? true : ""}
                onChange={(e) => setInputValues({...inputValues, default: e.target.value})}
              />
              <label>Yes </label>
            </div>

            <div>
              <input
                type="radio"
                name="account_type"
                value={false}
                // checked={inputValues?.default === false}
                onChange={(e) => setInputValues({...inputValues, default: e.target.value})}
              />
              <label>No</label>
            </div>
          </div>
        </div> */}
            {value === 2 || value === 4 ? (
              <>
                <div className="general_info_form_input_container">
                  <img
                    src={UserIcon}
                    alt=""
                    className="input_icon"
                  />
                  <div className=" general_info_input">
                    <label
                      className="general_info_label"
                      htmlFor="name"
                    >
                      Routing Number
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Routing Number"
                      value={inputValues?.routing_number}
                      onChange={(e) => setInputValues({...inputValues, routing_number: e.target.value})}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {/* contact */}
            {email === true ? (
              <>
                {email === true && !value === 3 && (
                  <div>
                    <label
                      // className="general_info_label"
                      style={{color: "black"}}
                      htmlFor="contact"
                    >
                      Account Number
                    </label>
                    <div className="general_info_form_input_container">
                      <img
                        src={UserIcon}
                        alt=""
                        className="input_icon"
                      />
                      <div className=" general_info_input">
                        <input
                          type="number"
                          name="contact"
                          id="contact"
                          placeholder="Account Number"
                          value={inputValues?.account_number}
                          onChange={(e) => setInputValues({...inputValues, account_number: e.target.value})}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  <label
                    // className="general_info_label"
                    style={{color: "black"}}
                    htmlFor="contact"
                  >
                    IBAN Number
                  </label>
                  <div className="general_info_form_input_container">
                    <img
                      src={UserIcon}
                      alt=""
                      className="input_icon"
                    />
                    <div className=" general_info_input">
                      <input
                        type="text"
                        name="contact"
                        id="contact"
                        placeholder="IBAN Number"
                        value={inputValues?.iban_number}
                        onChange={(e) => setInputValues({...inputValues, iban_number: e.target.value})}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <label
                    // className="general_info_label"
                    style={{color: "black"}}
                    htmlFor="contact"
                  >
                    BIC Code
                  </label>
                  <div className="general_info_form_input_container">
                    <img
                      src={UserIcon}
                      alt=""
                      className="input_icon"
                    />
                    <div className=" general_info_input">
                      <input
                        type="text"
                        name="contact"
                        id="contact"
                        placeholder="Bic code"
                        value={inputValues?.bic_code}
                        onChange={(e) => setInputValues({...inputValues, bic_code: e.target.value})}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {value === 5 ? (
              <div className="general_info_form_input_container">
                <img
                  src={PhoneIcon}
                  alt=""
                  className="input_icon"
                />
                <div className=" general_info_input">
                  <label
                    className="general_info_label"
                    htmlFor="contact"
                  >
                    Contact Number
                  </label>
                  <input
                    type="text"
                    name="contact"
                    id="contact"
                    placeholder="Contact Number"
                    value={inputValues?.mobile_number}
                    onChange={(e) => setInputValues({...inputValues, mobile_number: e.target.value})}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {value === 3 && (
            <>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleChange}
                    />
                  }
                  sx={{
                    "& .MuiSvgIcon-root": {
                      color: "#0C2D44", // Default color
                      borderRadius: "11px",
                    },
                    "&.Mui-checked": {
                      backgroundColor: "#158F91", // Background color when checked
                    },
                  }}
                  label={<div style={{color: "black", fontSize: "15px"}}>i hereby acknowledge that the above information is correct</div>}
                />
              </div>
            </>
          )}
          {/* {
          (value === 2,
          value === 3,
          value === 4 ? (
            <div className="account_type">
              <p>Account type</p>
              <div className="radio_container">
                <div>
                  <input
                    type="radio"
                    name="account_type"
                    value="SAVINGS"
                    checked={inputValues?.account_type === "SAVINGS"}
                    onChange={(e) => setInputValues({...inputValues, account_type: e.target.value})}
                  />
                  <label>Saving </label>
                </div>

                <div>
                  <input
                    type="radio"
                    name="account_type"
                    value="current"
                    checked={inputValues?.account_type === "current"}
                    onChange={(e) => setInputValues({...inputValues, account_type: e.target.value})}
                  />
                  <label>Current acccount</label>
                </div>
              </div>
            </div>
          ) : (
            ""
          ))
        } */}

          {isModalOpen && (
            <SuccessModal
              setModalOpen={closeBankDetailsModal}
              title="Case Created"
              desc={isModal?.message}
              btnText="Close"
              modal={closeBankDetailsModal}
            />
          )}
          {isErrorModal && (
            <ErrorModal
              setModalOpen={setErrorModal}
              title="Something Error"
              desc={isModal?.message}
              btnText="Close"
            />
          )}
        </div>
      </div>
      <div className="btn_container">
        <Button
          handleSubmit={handleSubmit}
          isSpinner={isSpinner}
        />
      </div>
    </>
  );
};

export const Button = ({handleSubmit, isSpinner}) => {
  return (
    <>
      <div className="btn_container">
        <button
          className="save_info_btn"
          onClick={handleSubmit}
        >
          {isSpinner ? <Spinner /> : "Next"}
        </button>
      </div>
    </>
  );
};

const RecipientAddress = ({inputValues, setInputValues, handleSelectChange}) => {
  const stateOptions = [{value: "ca", label: "California"}];
  const countryOptions = [{value: "ca", label: "Canada"}];

  return (
    <div className="recipient_info_form_container">
      <p>Recipients Address</p>
      <div className="recipient_info_form">
        <div className="recipient_info_form_input_container">
          <img
            src={CountryIcon}
            alt="country"
            className="input_icon"
          />
          <div className=" recipient_info_input">
            <label
              className="general_info_label"
              htmlFor="country"
            >
              Country
            </label>
            <Select
              styles={customStyles}
              options={country?.map((item) => ({
                ...item,
                value: `${item?.country}`,
                label: `${item?.country}`,
              }))}
              className="react_select_container"
              classNamePrefix="react-select"
              name="country"
              placeholder="Country"
              value={{label: inputValues?.address?.country, value: inputValues?.address?.country}}
              onChange={(selectedOption) =>
                handleSelectChange(
                  "country",
                  setInputValues((prevValues) => ({
                    ...prevValues,
                    address: {
                      ...prevValues.address,
                      country: selectedOption.label,
                    },
                  })),
                )
              }
            />
          </div>
        </div>

        <div className="recipient_info_form_input_container">
          <img
            src={StateIcon}
            alt="state"
            className="input_icon"
          />
          <div className=" recipient_info_input">
            <label
              className="general_info_label"
              htmlFor="state"
            >
              First Name
            </label>
            <input
              type="text"
              name="first_name"
              id="first_name"
              placeholder="First Name"
              value={inputValues?.address?.first_name}
              onChange={(e) =>
                setInputValues({
                  ...inputValues,
                  address: {
                    ...inputValues?.address,
                    first_name: e?.target?.value,
                  },
                })
              }
            />
          </div>
        </div>
        <div className="recipient_info_form_input_container">
          <img
            src={StateIcon}
            alt="state"
            className="input_icon"
          />
          <div className=" recipient_info_input">
            <label
              className="general_info_label"
              htmlFor="state"
            >
              Last Name
            </label>
            <input
              type="text"
              name="last_name"
              id="last_name"
              placeholder="Last Name"
              value={inputValues?.address?.last_name}
              onChange={(e) =>
                setInputValues({
                  ...inputValues,
                  address: {
                    ...inputValues?.address,
                    last_name: e?.target?.value,
                  },
                })
              }
            />
          </div>
        </div>
        <div className="recipient_info_form_input_container">
          <img
            src={StateIcon}
            alt="state"
            className="input_icon"
          />
          <div className=" recipient_info_input">
            <label
              className="general_info_label"
              htmlFor="state"
            >
              State
            </label>
            <input
              type="text"
              name="apartmentNo"
              id="apartmentNo"
              placeholder="State"
              value={inputValues?.address?.state}
              onChange={(e) =>
                setInputValues({
                  ...inputValues,
                  address: {
                    ...inputValues?.address,
                    state: e?.target?.value,
                  },
                })
              }
            />
          </div>
        </div>

        <div className="recipient_info_form_input_container">
          <img
            src={ApartmentNoIcon}
            alt="apartment icon"
            className="input_icon"
          />
          <div className="form_input recipient_info_input">
            <label
              className="general_info_label"
              htmlFor="aprtmentNo"
            >
              Apartment No
            </label>
            <input
              type="text"
              name="apartmentNo"
              id="apartmentNo"
              placeholder="Apartment No"
              value={inputValues?.address?.apartment_no}
              onChange={(e) =>
                setInputValues({
                  ...inputValues,
                  address: {
                    ...inputValues?.address,
                    apartment_no: e?.target?.value,
                  },
                })
              }
            />
          </div>
        </div>

        <div className="recipient_info_form_input_container">
          <img
            src={ApartmentNoIcon}
            alt="apartment icon"
            className="input_icon"
          />
          <div className="form_input recipient_info_input">
            <label
              className="general_info_label"
              htmlFor="aprtmentNo"
            >
              City
            </label>
            <input
              type="text"
              name="apartmentNo"
              id="apartmentNo"
              placeholder="City"
              value={inputValues?.address?.city}
              onChange={(e) =>
                setInputValues({
                  ...inputValues,
                  address: {
                    ...inputValues?.address,
                    city: e.target?.value,
                  },
                })
              }
            />
          </div>
        </div>

        <div className="recipient_info_form_input_container">
          <img
            src={ApartmentNoIcon}
            alt="apartment icon"
            className="input_icon"
          />
          <div className="form_input recipient_info_input">
            <label
              className="general_info_label"
              htmlFor="aprtmentNo"
            >
              Street Address
            </label>
            <input
              type="text"
              name="apartmentNo"
              id="apartmentNo"
              placeholder="Street Address"
              value={inputValues?.address?.street_address}
              onChange={(e) =>
                setInputValues({
                  ...inputValues,
                  address: {
                    ...inputValues?.address,
                    street_address: e.target.value,
                  },
                })
              }
            />
          </div>
        </div>

        <div className="recipient_info_form_input_container">
          <img
            src={ApartmentNoIcon}
            alt="apartment icon"
            className="input_icon"
          />
          <div className="form_input recipient_info_input">
            <label
              className="general_info_label"
              htmlFor="aprtmentNo"
            >
              Zip Code
            </label>
            <input
              type="text"
              name="apartmentNo"
              id="apartmentNo"
              placeholder="Zip Code"
              value={inputValues?.address?.zip_code}
              onChange={(e) =>
                setInputValues({
                  ...inputValues,
                  address: {
                    ...inputValues?.address,
                    zip_code: e.target.value,
                  },
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
