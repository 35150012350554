import React, {useRef, useState} from "react";
import "./style.scss";

import EmailIcon from "../../../assets/icons/email.svg";
import LockIcon from "../../../assets/icons/lock.svg";
import EyeIcon from "../../../assets/icons/eye.svg";
import EyeSlashedIcon from "../../../assets/icons/eye-slashed.svg";
import CheckSolidGreenIcon from "../../../assets/icons/check-solid-green.svg";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg";

import {Link} from "react-router-dom";
import {validateEmail} from "../../../utils/helpers/string";
import Tooltip from "../../../reusableComponents/Tooltip";
import axios from "axios";
import {returnMessages} from "../../../store/reducers/message";
import {useDispatch} from "react-redux";
import {returnErrors} from "../../../store/reducers/error";
import Spinner from "../../../reusableComponents/Spinner";
import {baseUrl} from "../../../utils/apis";

import CompanyLogo from "../../../assets/icons/equityEdgeLogo.svg";

function ForgotPasswordForm() {
  const [email, setEmail] = useState("");
  const [inputError, setInputError] = useState("");
  const [isInputError, setIsInputError] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);

  const emailRef = useRef(null);

  const handleInputChange = (e) => {
    const {value} = e.target;
    setEmail(value);
    setInputError("");
  };

  const handleBlur = (e) => {
    emailRef.current.classList.remove("input-error");
  };

  const handleFocus = () => {
    setIsInputError(false);
  };
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSpinner(true);

    if (email === "") {
      setInputError("Please enter the email!");
    } else if (!validateEmail(email)) {
      setInputError("The email entered is wrong!");
    }
    if (email === "" || !validateEmail(email)) {
      emailRef.current.classList.add("input-error");
      setIsInputError(true);
    }

    try {
      const formData = new FormData();
      formData.append("email", email);

      axios
        .post(`${baseUrl}/auth/reset-password/`, formData)
        .then((res) => {
          if (res.status === 200) {
            const msg = `An email has been sent to ${email} with instructions for resetting your password.`;
            const status = res.status;
            dispatch(returnMessages(msg, status));
            setIsSpinner(false);
          } else {
            const msg = `Please check the email !`;
            const status = 500;
            dispatch(returnErrors(msg, status));
            setIsSpinner(false);
          }
        })
        .catch(() => {
          alert("Failed");
          setIsSpinner(false);
        });
    } catch (err) {}
  };

  return (
    <div className="forgotpasswordform-container">
      <div className="companyLogo">
        <img
          src={CompanyLogo}
          alt="companyLogo"
        />
      </div>
      <div className="forgotpasswordform-subcontainer">
        <h2 className="forgotpasswordform-title">Forgot Password</h2>
        <p className="forgotpasswordform-subtitle">Don&#39;t worry! It happens please enter the email address associated with your account</p>
        <form className="forgotpasswordform-innercontainer forgotpasswordform-container_form">
          <div className="forgotpasswordform-subcontainer_form">
            <div
              ref={emailRef}
              className="forgotpasswordform-innercontainer_form"
            >
              <div className="forgotpasswordform-container_input">
                {/* <label
                  className="forgotpasswordform-label"
                  htmlFor="email"
                ></label> */}
                <div className="forgotpasswordform-icon_email">
                  <img
                    src={EmailIcon}
                    alt=""
                    className="forgotpasswordform-icon"
                  />
                </div>
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="forgotpasswordform-input"
                  value={email}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  placeholder="Email Address"
                />
                {validateEmail(email) && (
                  <img
                    src={CheckSolidGreenIcon}
                    alt=""
                    className="forgotpasswordform-icon_emailVerify"
                  />
                )}
              </div>
            </div>
            <Tooltip isActive={isInputError}>{inputError}</Tooltip>
          </div>
          {/* <p className="forgotpasswordform-error">{inputError}</p> */}
          <div className="forgotpasswordform-container_button">
            <button
              className="forgotpasswordform-button"
              onClick={handleSubmit}
              disabled={isSpinner}
            >
              {isSpinner ? <Spinner /> : "Submit"}
              {/* <img
                src={ArrowRightIcon}
                alt=""
                className="forgotpasswordform-icon"
              /> */}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPasswordForm;
