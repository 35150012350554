import React from "react";
import "./style.scss";
import CrossIcon from "../../assets/icons/errorCrossIcon.svg";

function ErrorModal({setModalOpen,handleNavigation, desc, btnText}) {
  return (
    <div className="error_modal_overlay">
      <div className="errorModalWrapper">
        <div className="error_modal_container_top">
          <div className="tick_icon">
            <img
              src={CrossIcon}
              alt="error"
            />
          </div>
        </div>
        <div className="error_modal_container">
          <div className="error_modal_container_bottom">
            <div>
              <h1>Error</h1>
            </div>
            <div>
              <p>{desc}</p>
            </div>
            <div>
              <button
                className="error_modal_close_btn"
                onClick={() => {
                  {
                    setModalOpen && setModalOpen();
                  }
                  {
                    handleNavigation && handleNavigation();
                  }
                }}
              >
                {btnText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorModal;
