import {Checkbox, FormControlLabel} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import Spinner from "../../../../reusableComponents/Spinner";
import SuccessModal from "../../../../reusableComponents/SuccessModal";
import {setLastPayment} from "../../../../store/reducers/accountSlice";
import {returnErrors} from "../../../../store/reducers/error";
import {applyCoupon, initiatePayment} from "../../../../utils/apis";
import {createFreeAccount, initiatePaymentpublic} from "../../../../utils/apis/payment";
import "./style.scss";

function Summary({
  user,
  email,
  paymentMethod,
  publicCheckout,
  accBalances,
  selectedValues,
  selectedAddress,
  formData,
  handleSave,
  paymentMode,
  sequrePaymentLink,
  selectedCountry,
  selectedState,
  addressDetails,
  validateofEmail,
  broker,
  selectRapydCountry,
}) {
  const {idToken} = useSelector((state) => state.auth);
  const selectedChallenge = useSelector((state) => state.account.selectedChallenge);
  const plans = useSelector((state) => state.account.plans.data);
  const [accBalance, setAccBalance] = useState(accBalances);
  useEffect(() => {
    setAccBalance(accBalances);
  }, [accBalances]);

  const [currentChallenge, setCurrentChallenge] = useState(null);
  const navigate = useNavigate();

  const [couponData, setCouponData] = useState({});

  const [isRefundPolicy, setIsRefundPolicy] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const challenge = plans.flatMap((plan) => plan?.challenges.filter((item) => item.id === accBalances?.id));

    setCurrentChallenge(challenge?.[0]);
  }, [accBalances, plans]);

  const [transactionStatus, setTransactionStatus] = useState("success");
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentCode, setCurrentCode] = useState("");

  const [isSpinner, setIsSpinner] = useState(false);
  const [isSpinner1, setIsSpinner1] = useState(false);
  const handleCoupon = async (e) => {
    setIsSpinner(true);

    // e?.preventDefault();
    const code = currentCode;
    const id = currentChallenge?.id;
    const params = {
      coupon: code,
      ...(broker === "tradelocker" && {platform: "tradelocker"}),
    };
    try {
      const res = await applyCoupon(idToken, id, params);
      setCouponData(res);
      setIsSpinner(false);
    } catch (err) {
      const msg = "Invalid Coupon Code";
      const status = err.response.status;
      dispatch(returnErrors(msg, status));
      setIsSpinner(false);
    }
  };

  // const handleInputChange = async (e) => {
  //   const newValue = e.target.value;
  //   setCouponCode(newValue);

  //   // e?.preventDefault();
  //   const body = {
  //     coupon_code: couponCode,
  //   };
  //   try {
  //     const res = await applyCoupon(idToken, body);
  //     setCouponData(res);
  //   } catch (err) {}

  //   // Use the debounce function to delay the execution of handleCoupon
  //   debounce(handleCoupon, 1000); // 1000ms (1 second) delay
  // };
  useEffect(() => {}, [paymentMethod]);
  const userdata = useSelector((state) => state.user);
  const [userDetails, setUserDetails] = useState(userdata);
  useEffect(() => {
    setUserDetails(userdata);
  }, [userdata]);
  const [naceId, setNaceId] = useState([]);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  console.log("selectedCountry", selectedCountry);

  const handleSubmit = async (e) => {
    if (!selectRapydCountry?.value || paymentMethod === "rapyd") {
      dispatch(returnErrors("Please select your country", 400));
      return;
    }
    setIsSpinner1(true);
    e.preventDefault();

    if (publicCheckout && !formData?.email === null) {
      dispatch(returnErrors("Add your email address", 400));
      setIsSpinner1(false);
      return;
    }

    if (!formData?.first_name?.trim()) {
      dispatch(returnErrors("Add your first name", 400));
      setIsSpinner1(false);
      return;
    }
    if (!formData?.last_name?.trim()) {
      dispatch(returnErrors("Add your last name", 400));
      setIsSpinner1(false);
      return;
    }

    if (paymentMethod.value === "fibonatix" && selectedCountry === null) {
      dispatch(returnErrors("Please select your country", 400));
      setIsSpinner1(false);
      return;
    }

    // checking some country don't have any state so add validation to check if state is there or not
    if (selectedCountry?.states?.length > 0) {
      if (paymentMethod.value === "fibonatix" && selectedState === null) {
        dispatch(returnErrors("Please select your state", 400));
        setIsSpinner1(false);
        return;
      }
    }

    if (paymentMethod.value === "fibonatix")
      if (addressDetails.zipCode === "" || addressDetails.city === "" || addressDetails.phone === "") {
        {
          dispatch(returnErrors("Please fill all the details", 400));
          setIsSpinner1(false);
          return;
        }
      }

    const body =
      publicCheckout === true && paymentMethod.value !== "coinpayments"
        ? {
            challenge_id: currentChallenge.id,
            payment_method: paymentMethod.active,
            email: formData?.email,
            first_name: formData?.first_name,
            last_name: formData?.last_name,
            promo: couponData?.final_price ? currentCode : "",
            broker: broker,
            address: selectRapydCountry
              ? {country: selectRapydCountry.value}
              : {
                  city: addressDetails?.city,
                  zip_code: addressDetails?.zipCode,
                  country: selectedCountry?.value,
                  state: selectedState?.value || "",
                  phone: addressDetails?.phone,
                },
          }
        : paymentMethod.value === "coinpayments"
        ? {
            challenge_id: currentChallenge.id,
            payment_method: paymentMethod.active,
            crypto: paymentMode.value,
            promo: couponData?.final_price ? currentCode : "",
            email: formData?.email,
            first_name: formData?.first_name,
            last_name: formData?.last_name,
            broker: broker,
          }
        : paymentMethod.value === "coinpayments" && publicCheckout === true
        ? {
            challenge_id: currentChallenge.id,
            payment_method: paymentMethod.active,
            crypto: paymentMode.value,
            promo: couponData?.final_price ? currentCode : "",
            email: formData?.email,
            first_name: formData?.first_name,
            last_name: formData?.last_name,
            broker: broker,
          }
        : paymentMethod.value === "fibonatix"
        ? {
            challenge_id: currentChallenge.id,
            payment_method: "fibonatix",
            promo: couponData?.final_price ? currentCode : "",
            email: formData?.email,
            broker: broker,

            address: {
              city: addressDetails.city,
              zip_code: addressDetails.zipCode,
              country: selectedCountry?.value,
              state: selectedState?.value || "",
              phone: addressDetails.phone,
            },
          }
        : paymentMethod.value === "fibonatix" && publicCheckout === true
        ? {
            challenge_id: currentChallenge.id,
            payment_method: "fibonatix",
            promo: couponData?.final_price ? currentCode : "",
            email: formData?.email,
            broker: broker,

            address: {
              city: addressDetails.city,
              zip_code: addressDetails.zipCode,
              country: selectedCountry.value,
              state: selectedState?.value || "",
              phone: addressDetails.phone,
            },
          }
        : {
            challenge_id: currentChallenge.id,
            payment_method: paymentMethod.active,
            promo: couponData?.final_price ? currentCode : "",
            address: selectRapydCountry ? {country: selectRapydCountry.value} : selectedAddress,
            first_name: formData?.first_name,
            last_name: formData?.last_name,
            broker: broker,
            promo: couponData?.final_price ? currentCode : "",
            email: formData?.email,
          };

    try {
      if (!formData.email?.trim() && !idToken) {
        dispatch(returnErrors("Please enter your email", 400));
        setIsSpinner1(false);
        return;
      }

      if (formData?.email && publicCheckout) {
        const res = await initiatePaymentpublic(body, dispatch);

        dispatch(setLastPayment(res));
        if (res.url) {
          // let payload = {data: res?.auth};
          // // res.status < 399 &&
          // dispatch(setAuthenticationSuccess(payload));
          // dispatch(getUser(res?.auth?.idToken));

          window.location.replace(res?.url);
          setIsSpinner1(false);
        }
        if (paymentMethod.active === "nace") {
          setNaceId(res?.data);
          setOpen(true);
          setIsSpinner1(false);
        }
      } else {
        // hitting free account api if price is 0
        setIsSpinner1(true);
        // const response = await handleSave();

        // if (response) {
        if (
          !publicCheckout &&
          (formData.first_name?.trim() === "" || formData.last_name?.trim() === "" || formData?.email?.trim() === "" || formData.country === null || formData.country?.trim() === "")
        ) {
          dispatch(returnErrors("Please enter your Invoice details", 400));
          setIsSpinner1(false);
          return;
        }

        if (currentChallenge?.price <= 0) {
          const res = await createFreeAccount(idToken, {challenge_id: currentChallenge.id}, dispatch);
          dispatch(setLastPayment(res));
          setIsSpinner1(false);
          res.status < 399 && navigate("/");
          return;
        }
        const res = await initiatePayment(idToken, body, dispatch);
        console.log("res", res);
        dispatch(setLastPayment(res));
        if (res.url) {
          window.location.replace(res.url);
          setIsSpinner1(false);
        }
        if (paymentMethod.active === "nace") {
          // window.location.replace("https://apps.newagecrypto.com/widget/nacepay.js?v4");
          setNaceId(res?.data);
          setOpen(true);
          setIsSpinner1(false);
        }
      }
    } catch (err) {
      setModalOpen(true);
      setTransactionStatus("error");
      setIsSpinner1(false);
    }
  };

  // let planOptions = plans.map((plan) => ({
  //   label: plan.name,
  //   value: plan.name,
  //   id: plan.id,
  // }));
  // const [selectedValue, setSelectedValue] = useState(planOptions.find((plan) => plan.id === currentChallenge?.id) || planOptions[0]);
  const [selectedValue, setSelectedValue] = useState(selectedValues);

  useEffect(() => {
    setSelectedValue(selectedValues);
  }, [selectedValues]);

  // const handlePlanChange = (selectedOption) => {
  //   setCurrentCode("");
  //   setCouponData({});
  //   const selectedPlanId = selectedOption.id;

  //   const selectedPlan = plans.find((plan) => plan.id === selectedPlanId);
  //   setSelectedValue(planOptions.find((plan) => plan.id === selectedPlanId));
  //   setPlanType(selectedPlan?.name);
  //   // setAccBalance("");
  //   setSelectedPlan(selectedPlanId);

  //   const selectedPlanChallenges = selectedPlan ? selectedPlan.challenges : [];

  //   // const uniqueAccountBalances = [...new Set(selectedPlanChallenges.map((challenge) => challenge.starting_balance))];
  //   let array = [];
  //   const uniqueAccountBalances = selectedPlanChallenges.map((challenge) => {
  //     array.push({
  //       label: challenge.starting_balance,
  //       value: challenge.starting_balance,
  //       id: challenge.id,
  //     });
  //   });
  //   setAccBalanceOptions(array);
  //   // setAccBalanceOptions(
  //   //   uniqueAccountBalances.map((balance) => ({
  //   //     label: balance,
  //   //     value: balance,
  //   //   })),

  //   // );
  //   setAccBalance(array[0]);
  //   setCurrentChallenge(selectedPlanChallenges[0]);
  //   // setAccBalance(balaceOptions.find((option) => option.value === currentChallenge.starting_balance));
  //   //   balaceOptions.find((option) => option.value === currentChallenge.starting_balance),
  //   //   "SIDD",
  //   // );
  // };
  // useEffect(() => {
  //   const challenges = plans.flatMap((plan) => plan.challenges);
  //   const challenge = challenges.find((item) => item.id === selectedChallenge);

  //   setCurrentChallenge(challenge);

  //   const defaultSelectedPlan = challenge ? challenge.plan : "";

  //   const defaultPlanType = challenge ? plans.find((plan) => plan.challenges.some((c) => c.id === challenge.id))?.name : "";

  //   const selectedPlan = plans?.find((plan) => plan.id === defaultSelectedPlan);

  //   setPlanType(selectedPlan?.name);
  //   setSelectedPlan(defaultSelectedPlan || "");
  //   setSelectedValue(planOptions.find((plan) => plan.id === defaultSelectedPlan));
  //   const defaultAccBalance = challenge ? challenge.starting_balance : "";
  //   setAccBalance({label: defaultAccBalance, value: defaultAccBalance} || "");

  //   const selectedPlanChallenges = selectedPlan ? selectedPlan.challenges : [];

  //   // const uniqueAccountBalances = [...new Set(selectedPlanChallenges.map((challenge) => challenge.starting_balance))];
  //   let array = [];
  //   const uniqueAccountBalances = selectedPlanChallenges.map((challenge) => {
  //     array.push({
  //       label: challenge.starting_balance,
  //       value: challenge.starting_balance,
  //       id: challenge.id,
  //     });
  //   });
  //   setAccBalanceOptions(array);

  //   // setAccBalance(balaceOptions.find((option) => option.value === currentChallenge.starting_balance));
  //   //   balaceOptions.find((option) => option.value === currentChallenge.starting_balance),
  //   //   "SIDD",
  //   // );
  // }, [selectedChallenge, plans]);

  // useEffect(() => {
  //   setAccBalance({});
  // }, [currentChallenge]);

  // useEffect(() => {
  //   handlePlanChange(planOptions[0]?.id);
  // }, []);
  // const handleAccBalanceChange = (selectedOption) => {
  //   setCurrentCode("");
  //   setCouponData({});
  //
  //   setAccBalance(selectedOption.value);
  //   // const selectedAccBalance = e.value;

  //   const matchingChallenge = plans.flatMap((plan) => plan.challenges).find((challenge) => challenge.id === selectedOption.id);

  //   if (matchingChallenge) {
  //
  //     setCurrentChallenge(matchingChallenge);

  //     setAccBalance({label: selectedOption.label, value: selectedOption.value});
  //   } else {
  //     console.error("No matching challenge found for the selected account balance");
  //   }
  // };

  useEffect(() => {
    const conditionMet = true;

    if (conditionMet) {
      const script = document.createElement("script");

      script.src = "https://apps.newagecrypto.com/widget/nacepay.js?v4";

      document.body.appendChild(script);

      // return () => {
      //   document.body.removeChild(script);
      // };
    }
  }, [open]);

  const bellRef = useRef(null);
  const profileRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (bellRef.current && !bellRef.current.contains(event.target) && profileRef.current && !profileRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  console.log(bellRef, profileRef);

  useEffect(() => {
    if (couponData?.discount) {
      handleCoupon();
    }
  }, [accBalance]);

  useEffect(() => {
    // Function to check if the payment modal is open
    function checkModal() {
      const paymentModal = document.querySelector(".btnpay");
      if (paymentModal) {
        paymentModal.addEventListener("click", () => {
          setOpen(false);
          navigate("/");
        });
      }
    }

    // Use MtationObserver to watch for changes in the DOM
    const observer = new MutationObserver(checkModal);

    // Start observing changes in the body (or specific parent element where the modal might be added)
    observer.observe(document.body, {childList: true, subtree: true});

    // Clean up function
    return () => {
      observer.disconnect(); // Disconnect the observer when the component unmounts
    };
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  // This component doesn't render anything visible

  return (
    <>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      > */}
      <div
        style={{display: open ? "block" : "none"}}
        className="nacemodal_container"
        onClick={() => setOpen(false)}
      >
        <div
          className="nacemodal"
          onClick={(e) => e.stopPropagation()}
          ref={profileRef}
        >
          <div
            ref={bellRef}
            nacepay="Paywidget"
            nacepay_id="d46168ab-6e60-45c6-a673-27d23c416068"
            amount={naceId.amount}
            currency="USD"
            ref_id={naceId.id}
            className="nacepaypaybtn"
          ></div>
          <p>Please click on this button to continue</p>
        </div>
      </div>
      {/* </Modal> */}
      {/* <div
            nacepay="Paywidget"
            nacepay_id="d46168ab-6e60-45c6-a673-27d23c416068"
            amount={30.00}
            currency="USD"
            ref_id={"jerejrgyar6"}
            className="nacepaypaybtn"
          ></div> */}

      <div className="summary">
        <div className="summary_wrapper">
          <h4>Summary</h4>
          <div className="summary_upper">
            <div className="summary_upper_wrapper">
              <label>Challenge Type</label>
              <div
                className="summary_upper_coupon"
                style={{color: "black", padding: "15px 20px"}}
              >
                {selectedValue?.label || "NA"}
              </div>
              {/* <div>
                <Select
                  value={selectedValue}
                  // options={publicCheckout ? planOptions.filter((plan) => plan.value !== "Free Trial") : planOptions}
                  styles={customStyles}
                  // onChange={(selectedOption) => handlePlanChange(selectedOption)}
                  isDisabled={true}
                />
              </div> */}
              <label>Challenge Amount</label>
              <div
                className="summary_upper_coupon"
                style={{color: "black", padding: "15px 20px"}}
              >
                {accBalance?.label || "NA"}
              </div>
              {/* <div>
                <Select
                  value={accBalance}
                  // options={accBalanceOptions}
                  styles={customStyles}
                  // onChange={(selectedOption) => {
                  //   handleAccBalanceChange(selectedOption);
                  // }}
                  isDisabled={true}
                />
              </div> */}
              <div className="summary_upper_coupon">
                <input
                  onSubmit={handleCoupon}
                  type="text"
                  placeholder="Coupon Code"
                  onChange={async (e) => {
                    setCurrentCode(e.target.value);
                  }}
                />
                <button
                  style={{
                    background: couponData?.coupon_applied === currentCode ? "#7AE582" : "",
                    maxWidth: "130px",
                  }}
                  onClick={handleCoupon}
                  disabled={isSpinner || couponData?.coupon_applied === currentCode || currentCode.length <= 0}
                  className="applyButton"
                >
                  {isSpinner ? <Spinner /> : <>{couponData?.coupon_applied === currentCode ? <span>APPLIED&#10003;</span> : "APPLY"}</>}
                </button>
              </div>
            </div>
          </div>
          <div className="summary_lower">
            <div className="summary_lower_wrapper">
              <div className="summary_lower_top">
                <div className="summary_lower_top_wrapper">
                  <div>
                    <p className="title">Challenge Name</p>
                    <p className="value">{currentChallenge?.name}</p>
                  </div>
                  {/* <div>
                    <p>Transaction ID:</p>
                  </div> */}
                  <div>
                    <p className="title">Price</p>
                    <p className="value">
                      {broker === "tradelocker"
                        ? `${Number(currentChallenge.tradelocker_price ? currentChallenge.tradelocker_price : "0").toFixed(2)}`
                        : `${Number(currentChallenge?.price ? currentChallenge?.price : "0").toFixed(2)}`}
                    </p>
                  </div>
                  {couponData.final_price && (
                    <div style={{marginBottom: "20px"}}>
                      <p className="title">Discount</p>
                      <p className="value discount">-${couponData?.discount || "$0"}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="summary_lower_middle">
                <div className="summary_lower_middle_wrapper">
                  <div>
                    <h5 className="title">To be Paid</h5>
                    <p className="value">
                      $
                      {(Number(couponData?.final_price) ? Number(couponData?.final_price) : Number(broker === "tradelocker" ? currentChallenge.tradelocker_price : currentChallenge?.price)) ===
                      undefined
                        ? "0.00"
                        : (Number(couponData?.final_price) ? Number(couponData?.final_price) : Number(broker === "tradelocker" ? currentChallenge.tradelocker_price : currentChallenge?.price)).toFixed(
                            2,
                          )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="summary_lower_bottom">
                <div className="summary_lower_bottom_top">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isRefundPolicy}
                        onChange={() => setIsRefundPolicy((prev) => !prev)}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: "#0C2D44", // Default color
                            borderRadius: "11px",
                          },
                          "&.Mui-checked": {
                            // backgroundColor: "#158F91", // Background color when checked
                          },
                        }}
                      />
                    }
                  />
                  <p>
                    I declare that I have read and agree with
                    <Link to="https://equityedge.co.uk/refunds/">
                      <span> Cancellation & Refund Policy</span>
                    </Link>
                  </p>
                </div>
                <div className="red_text">
                  {
                    <p
                      style={{
                        visibility: !isRefundPolicy ? "visible" : "hidden",
                      }}
                    >
                      Please accept the Cancellation and Refund Policy
                    </p>
                  }
                </div>
                <button
                  className="buy_now"
                  onClick={handleSubmit}
                  disabled={!isRefundPolicy || paymentMethod.value === null}
                >
                  {isSpinner1 ? (
                    <div
                      style={{
                        margin: "0 auto",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        justifyContent: "center",
                      }}
                    >
                      <span>Buy Now:</span>$
                      {(Number(couponData?.final_price) ? Number(couponData?.final_price) : Number(broker === "tradelocker" ? currentChallenge.tradelocker_price : currentChallenge?.price)) ===
                      undefined
                        ? "0.00"
                        : (Number(couponData?.final_price) ? Number(couponData?.final_price) : Number(broker === "tradelocker" ? currentChallenge.tradelocker_price : currentChallenge?.price)).toFixed(
                            2,
                          )}
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {transactionStatus === "success" && isModalOpen ? (
        <SuccessModal
          setModalOpen={setModalOpen}
          title="Successful!!"
          desc="Congratulation
        transaction was successful"
          btnText="Close"
        />
      ) : transactionStatus === "error" && isModalOpen ? (
        <></>
      ) : null}
    </>
  );
}

export default Summary;
