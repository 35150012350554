import axios from "axios";
import {baseUrl} from ".";

//User
const getUserDetails = async (idToken) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  const output = await axios
    .get(`${baseUrl}/user/profile/`, config)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
  return output;
};

const updateUserDetailsRequest = async ({formData, idToken}) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  let output;
  await axios
    .patch(`${baseUrl}/user/rofile/`, formData, config)
    .then((res) => {
      output = res.data;
      return output;
    })
    .catch(function (error) {
      output = error;
      throw output;
    });
  return output;
};

// UserAddress

const getUserAddress = async (idToken) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  const output = await axios
    .get(`${baseUrl}/user/billing/address/`, config)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
  return output;
};

// Post
const addUserAddress = async (idToken, data) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  const output = await axios
    .post(`${baseUrl}/user/billing/address/`, data, config)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return error;
    });
  return output;
};

// Patch
const UpdateUserAddress = async (idToken, data) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  const output = await axios
    .patch(`${baseUrl}/user/billing/address/`, data, config)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return error;
    });
  return output;
};

// Apis for deels

const addDeel = async (idToken, data) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  const output = await axios
    .post(`${baseUrl}/user/api/deel/`, data, config)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return error;
    });
  return output;
};

const addACH = async (idToken, data) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  const output = await axios
    .post(`${baseUrl}/user/api/ach/`, data, config)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return error;
    });
  return output;
};

const addWire = async (idToken, data) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  const output = await axios
    .post(`${baseUrl}/user/api/swift/`, data, config)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      throw error;
    });
  return output;
};

const addSwift = async (idToken, data) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  const output = await axios
    .post(`${baseUrl}/user/api/swift/`, data, config)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return error;
    });
  return output;
};
const addCrypto = async (idToken, data) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  const output = await axios
    .post(`${baseUrl}/user/api/crypto/`, data, config)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return error;
    });
  return output;
};

const addPaypal = async (idToken, data) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  const output = await axios
    .post(`${baseUrl}/user/api/paypal/`, data, config)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return error;
    });
  return output;
};

const loadUser = async (idToken) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  const output = await axios
    .get(`${baseUrl}/auth/user/`, config)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return error;
    });
  return output;
};

const digiSigner = async (idToken, id) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };

  const output = await axios
    .get(`${baseUrl}/account/sign-contract/${id}`, config)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return error;
    });
  return output;
};

const getSumsubTokenRequest = async (idToken) => {
  try {
    let config = {
      headers: {Authorization: `Bearer ${idToken}`},
    };
    const response = await axios.get(`${baseUrl}/user/payout/sumsub-token/`, config);
    return response;
  } catch (error) {
    throw error;
  }
};
const getSumsubVerifyReq = async (idToken) => {
  try {
    let config = {
      headers: {Authorization: `Bearer ${idToken}`},
    };
    const response = await axios.get(`${baseUrl}/user/payout/sumsub-verify/`, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export {
  updateUserDetailsRequest,
  getSumsubVerifyReq,
  getSumsubTokenRequest,
  addCrypto,
  getUserDetails,
  addUserAddress,
  getUserAddress,
  UpdateUserAddress,
  addDeel,
  addACH,
  addWire,
  addPaypal,
  addSwift,
  loadUser,
  digiSigner,
};
