import React, {useEffect, useRef, useState} from "react";
import "./style.scss";
import {usePagination, useTable} from "react-table";
import {useDispatch, useSelector} from "react-redux";

import TrophyGreen from "../../../assets/icons/trophy-green.svg";
import DropdownIcon from "../../../assets/icons/dropdown-arrow-gray.svg";

import CaretDownIcon from "../../../assets/icons/caret-down.svg";
import CaretLeftIcon from "../../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../../assets/icons/caret-right.svg";
import BlueTickIcon from "../../../assets/icons/tick-blue-circle.svg";
import CalenderIcon from "../../../assets/icons/calender.svg";
import InvoiceIcon from "../../../assets/icons/download.svg";

import Calendar from "../../../reusableComponents/Calendar";
import Card from "../../../reusableComponents/Card";
// import { getPaymentHistory } from "../../../utils/apis";
// import { setPaymentHistory } from "../../../store/reducers/accountSlice";
import {formatDateToCustomFormat} from "../../../utils/helpers/date";
import {capitalizeFirstLetter} from "../../../utils/helpers/string";
import {getPaymentHistory} from "../../../store/reducers/accountSlice";

function PaymentHistoryTable() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const idToken = useSelector((state) => state.auth.idToken);
  const paymentValue = useSelector((state) => state.account.paymentHistory);
  const [paymentHistory, setPaymentHistory] = useState(paymentValue);

  useEffect(() => {
    dispatch(getPaymentHistory(idToken));
  }, [idToken]);
  useEffect(() => {
    setPaymentHistory(paymentValue);
  }, [paymentValue]);
  const [data, setData] = useState([]);
  const [isCalender, setIsCalender] = useState(false);
  const [date, setDate] = useState(new Date());

  return (
    <Card className="paymenthistory_table">
      <div className=" table_wrapper">
        <div className="table_header">
          <p>Payment History</p>
          <div className="select_wrapper">
            {/* <div
              className="calendar-button"
              onClick={() => setIsCalender((prev) => !prev)}
            >
              <img src={CalenderIcon} alt="calender icon" />
              <p>Past 90 Days</p>
              <img src={CaretDownIcon} alt="" />
            </div> */}

            <div className="calendar_wrapper">
              {isCalender && (
                <Calendar
                  onChange={(date) => setDate(date)}
                  value={date}
                  // formatMonth={"MMM"}
                  selectRange={true}
                  showNeighboringMonth={false}
                />
              )}
            </div>
          </div>
        </div>
        <div className="table_scroll_wrapper">
          <Table data={(Array.isArray(paymentHistory?.data) && paymentHistory.data) || []} />
        </div>
      </div>
    </Card>
  );
}

export default PaymentHistoryTable;

const Table = ({data}) => {
  const [isFundingDropdown, setFundingDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [isBalanceDropdown, setBalanceDropdown] = useState(false);
  const balanceRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !event.target.classList.contains("column_header")) {
        setFundingDropdown(false);
      }

      if (balanceRef.current && !balanceRef.current.contains(event.target) && !event.target.classList.contains("column_header")) {
        setBalanceDropdown(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // const data = React.useMemo(
  //   () => [
  //     {
  //       id: 1,
  //       funding: "Aggressive Plan",
  //       accountNumber: "123456",
  //       transactionId: "ABC123",
  //       date: "12 Jan, 2022",
  //       amount: "$1000",
  //       invoice: "Invoice Link",
  //       status: <button className="completed_tag">Successful</button>,
  //     },
  //     {
  //       id: 2,
  //       funding: "Standard Plan",
  //       accountNumber: "789012",
  //       transactionId: "XYZ456",
  //       date: "15 Feb, 2022",
  //       amount: "$1500",
  //       invoice: "Invoice Link",
  //       status: <button className="progress_tag">In Progress</button>,
  //     },
  //     {
  //       id: 3,
  //       funding: "Swing Plan",
  //       accountNumber: "345678",
  //       transactionId: "PQR789",
  //       date: "20 Mar, 2022",
  //       amount: "$2000",
  //       invoice: "Invoice Link",
  //       status: <button className="error_tag">Error</button>,
  //     },
  //     {
  //       id: 4,
  //       funding: "Aggressive Plan",
  //       accountNumber: "901234",
  //       transactionId: "LMN456",
  //       date: "25 Apr, 2022",
  //       amount: "$2500",
  //       invoice: "Invoice Link",
  //       status: <button className="completed_tag">Successful</button>,
  //     },
  //     {
  //       id: 5,
  //       funding: "Standard Plan",
  //       accountNumber: "567890",
  //       transactionId: "QRS789",
  //       date: "30 May, 2022",
  //       amount: "$3000",
  //       invoice: "Invoice Link",
  //       status: <button className="progress_tag">In Progress</button>,
  //     },
  //     {
  //       id: 6,
  //       funding: "Swing Plan",
  //       accountNumber: "234567",
  //       transactionId: "UVW123",
  //       date: "05 Jun, 2022",
  //       amount: "$3500",
  //       invoice: "Invoice Link",
  //       status: <button className="error_tag">Error</button>,
  //     },
  //     {
  //       id: 7,
  //       funding: "Aggressive Plan",
  //       accountNumber: "890123",
  //       transactionId: "XYZ456",
  //       date: "10 Jul, 2022",
  //       amount: "$4000",
  //       invoice: "Invoice Link",
  //       status: <button className="completed_tag">Successful</button>,
  //     },
  //     {
  //       id: 8,
  //       funding: "Standard Plan",
  //       accountNumber: "456789",
  //       transactionId: "ABC123",
  //       date: "15 Aug, 2022",
  //       amount: "$4500",
  //       invoice: "Invoice Link",
  //       status: <button className="progress_tag">In Progress</button>,
  //     },
  //     {
  //       id: 9,
  //       funding: "Swing Plan",
  //       accountNumber: "012345",
  //       transactionId: "PQR789",
  //       date: "20 Sep, 2022",
  //       amount: "$5000",
  //       invoice: "Invoice Link",
  //       status: <button className="error_tag">Error</button>,
  //     },
  //     {
  //       id: 10,
  //       funding: "Aggressive Plan",
  //       accountNumber: "678901",
  //       transactionId: "LMN456",
  //       date: "25 Oct, 2022",
  //       amount: "$5500",
  //       invoice: "Invoice Link",
  //       status: <button className="progress_tag">In Progress</button>,
  //     },
  //   ],
  //   []
  // );

  const fundingBackgroundColors = {
    "Free Plan": "free_background",
    "Aggressive Plan": "aggressive_background",
    "Standard Plan": "standard_background",
    "Swing Plan": "swing_background",
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <>
            <div className="column_header">
              Funding Evaluation{" "}
              <img
                src={DropdownIcon}
                alt="arrow-down"
              />
            </div>
            {isFundingDropdown && (
              <div
                className="table_dropdown funding_dropdown"
                ref={dropdownRef}
              >
                <ul>
                  <li>
                    All
                    <img
                      src={BlueTickIcon}
                      alt="blue tick"
                    />
                  </li>
                  <li>Standard Plan</li>
                  <li>Aggressive Plan</li>
                  <li>Swing Plan</li>
                  <li>Instant Plan</li>
                </ul>
              </div>
            )}
          </>
        ),
        accessor: "funding_evaluation",
        Cell: ({value, row}) => (
          <div className="column_one_wrapper">
            <div className={`sno_wrapper ${fundingBackgroundColors[value]}`}>{`${row.index + 1}.`}</div>
            <div>{value}</div>
          </div>
        ),
      },
      {
        Header: (
          <>
            <div
              // onClick={(e) => {
              //   e.stopPropagation();
              //   setBalanceDropdown(!isBalanceDropdown);
              // }}
              className="column_header"
            >
              Account Number{" "}
              <img
                src={DropdownIcon}
                alt="arrow-down"
              />{" "}
            </div>
            {isBalanceDropdown && (
              <div
                className="table_dropdown balance_dropdown"
                ref={balanceRef}
              >
                <ul>
                  <li>
                    All
                    <img
                      src={BlueTickIcon}
                      alt="blue tick"
                    />
                  </li>
                  <li>25K Challenge</li>
                  <li>50K Challenge</li>
                  <li>100K Challenge</li>
                  <li>200K Challenge</li>
                </ul>
              </div>
            )}
          </>
        ),
        accessor: "account",
        Cell: ({value}) => (
          <div className="column_one_wrapper">
            <div>{value ? value : "-"}</div>
          </div>
        ),
      },
      {
        Header: (
          <div className="column_header">
            Transaction Id{" "}
            <img
              src={DropdownIcon}
              alt="arrow-down"
            />
          </div>
        ),
        // accessor: "transactionId",
        accessor: "id",
      },
      {
        Header: (
          <div className="column_header">
            Date{" "}
            <img
              src={DropdownIcon}
              alt="arrow-down"
            />
          </div>
        ),
        accessor: "created_at",
        sortType: "datetime",
        Cell: ({value}) => (
          <div className="column_one_wrapper">
            <div>{formatDateToCustomFormat(value)}</div>
          </div>
        ),
      },
      {
        Header: (
          <div className="column_header">
            Amount{" "}
            <img
              src={DropdownIcon}
              alt="arrow-down"
            />
          </div>
        ),
        accessor: "amount",
        Cell: ({value}) => (
          <div className="column_one_wrapper">
            <div>{Number(value)?.toFixed(2)}</div>
          </div>
        ),
      },

      // {
      //   Header: "Invoice",
      //   accessor: "invoice",
      //   Cell: ({ value }) => (
      //     <div className="column_one_wrapper">
      //       {value ? <img src={InvoiceIcon} alt="download icon" /> : "-"}
      //     </div>
      //   ),
      // },
      {
        Header: "Status",
        accessor: "payment_status",
        Cell: ({value}) => (
          <div className="column_one_wrapper">
            <div className={`status_tag ${value === "open" ? "status-open" : "successed" === "successed" ? "status-sucsses" : "status-error"}`}>{capitalizeFirstLetter(value)}</div>
          </div>
        ),
      },
    ],
    [isFundingDropdown, isBalanceDropdown],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: {pageIndex, pageSize},
  } = useTable(
    {
      columns,
      data,
      initialState: {pageIndex: 0, pageSize: 10},
    },
    usePagination,
  );

  return (
    <div className="table_scroll">
      <div className="table_main">
        <table
          {...getTableProps()}
          className="leaderboard-table"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {pageOptions.length > 1 && (
        <div className="economiccalender-pagination pagination">
          <img
            src={CaretLeftIcon}
            alt="left-arrow"
          />
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </button>
          {pageOptions?.map((pageNumber, index) => (
            <>
              <button
                key={index}
                onClick={() => gotoPage(index)}
                className={pageIndex === index ? "btn-active" : "non-active"}
              >
                {pageNumber < 10 ? `0${pageNumber + 1}` : pageNumber}
              </button>
            </>
          ))}
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </button>
          <img
            src={CaretRightIcon}
            alt="right-arrow"
          />
        </div>
      )}
    </div>
  );
};
