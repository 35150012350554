import React, {useEffect, useState} from "react";
import "./style.scss";
import LeaderboardWinners from "../../components/LeaderboardList/LeaderboardWinners/Index";
import LeaderboardTable from "../../components/LeaderboardList/LeaderboardTable.js";
import ArrowIncrease from "../../assets/icons/arrow-increase.svg";
import ArrowDecrease from "../../assets/icons/arrow-decrease.svg";
import InfoBox from "../../reusableComponents/InfoBox";
import LeaderboardNews from "../../components/LeaderboardList/LeaderboardNews";
import MainHeader from "../../layouts/pageLayout/MainHeader.jsx";
import {getLeaderboardRequest} from "../../utils/apis/account.js";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import {returnErrors} from "../../store/reducers/error.js";
import CustomSeparator from "../../components/Breadcrumbs/Breadcrumbs.jsx";

function LeaderboardTwo() {
  const urlParams = new URLSearchParams(window.location.search);
  const [loading, setIsLoading] = useState(false);
  const id = urlParams.get("id");
  const [data, setData] = useState([]);
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();

  const fetch = async () => {
    setIsLoading(true);
    const response = id && (await getLeaderboardRequest(idToken, id));
    
    if (response.status > 399 || response?.response?.status > 399) {
      // dispatch(returnErrors("Something went wrong", 400));
    }
    const res = response?.data?.detail === "" ? [] : response?.data;
    

    if (res !== undefined) {
      setData(res);
    }
    setIsLoading(false);
  };
  
  useEffect(() => {
    fetch();
  }, [idToken, id]);

  const infoBoxArr = [
    {
      title: "Equity",
      value: "30,872",
      icon: ArrowIncrease,
      percentage: "+2.53%",
      // color: "#F75C03",
    },
    {
      title: "Balance",
      value: "$5000",
      icon: ArrowIncrease,
      percentage: "-1.2%",
      // color: "#4CB944",
    },
    {
      title: "PnL",
      value: "0.51",
      icon: ArrowIncrease,
      percentage: "+5.8%",
      // color: "#1877F2",
      textcolor: "red",
    },
    {
      title: "Win Rate",
      value: "500%",
      icon: ArrowDecrease,
      percentage: "+10%",
      // color: "#DF2935",
      textcolor: "green",
    },
  ];
  const awards = useLocation();
  
  const navigation = [
    {pathName: "Competition List", link: "/competition-list/ongoing"},
    {pathName: "Leaderboard", link: `/leaderboard-2?id=${id}`},
  ];
  return (
    <>
      <div className="mainPageHolder-T"></div>

      <div className="leaderboard_two">
        <div className="all_haeder">
          <h2>Competition Leaderboard</h2>
        </div>
        <CustomSeparator navigation={navigation} />

        <div className="leaderboard_upper">
          <div className="grid_container">
            <div className="leaderboard_winner_wrapper">
              <LeaderboardWinners data={data} />
            </div>
            {/* <div className="leaderboard_infobox_wrapper">
              {infoBoxArr.map((info) => (
                <InfoBox
                  key={info.title}
                  title={info.title}
                  value={info.value}
                  icon={info.icon}
                  percentage={info.percentage}
                  color={info.color}
                  textcolor={info.textcolor}
                />
              ))}
            </div> */}
            {/* <div className="leaderboard_news_wrapper">
              <LeaderboardNews />
            </div> */}
          </div>
        </div>
        <div className="leaderboard_lower">
          <LeaderboardTable
            loading={loading}
            data={data}
            // setRefresh={setRefresh}
          />
        </div>
      </div>
    </>
  );
}

export default LeaderboardTwo;
