import React from "react";
import "./style.scss";
import ArrowIncrease from "../../assets/icons/arrow-increase.svg";
import ArrowDecrease from "../../assets/icons/arrow-decrease.svg";
import Card from "../../reusableComponents/Card";
import InfoBox from "../../reusableComponents/InfoBox";
import DashboardChart from "../../components/LeaderboardList/DashboardChart";
import LeaderboardTable from "../../components/LeaderboardList/LeaderboardTable.js";
import TrophyIcon from "../../assets/icons/trophy-white.svg";
import ContestantsIcon from "../../assets/icons/people-white.svg";

import LeaderboardStatistics from "../../components/LeaderboardList/LeaderboardStatistics";
import LayoutBox from "../../reusableComponents/LayoutBox";
import MainHeader from "../../layouts/pageLayout/MainHeader.jsx";

function LeaderboardList() {
  const infoBoxArr = [
    {
      title: "Equity",
      value: "$30,872",
      icon: ArrowIncrease,
      percentage: "+2.53%",
      color: "#F75C03",
    },
    {
      title: "Balance",
      value: "$5000",
      icon: ArrowIncrease,
      percentage: "-1.2%",
      color: "#4CB944",
    },
    {
      title: "PnL",
      value: "0.51",
      icon: ArrowIncrease,
      percentage: "+5.8%",
      color: "#1877F2",
      textcolor: "red",
    },
    {
      title: "Win Rate",
      value: "500%",
      icon: ArrowDecrease,
      percentage: "+10%",
      color: "#DF2935",
      textcolor: "green",
    },
  ];

  const competitionsArr = [
    {
      title: "Current Winner",
      value: "Jump250",
      icon: TrophyIcon,
      percentage: "+2.53%",
      color: "#1877F2",
    },
    {
      title: "Total Contestants",
      value: "20,000",
      icon: ContestantsIcon,
      percentage: "-1.2%",
      color: "#F9C80E",
    },
  ];

  return (
    <>
    <div className="mainPageHolder-T">
 
  </div>
    <LayoutBox className="leaderboard">
      <div className="grid_container">
        <div className="column column-70">
          <div className="row-1">
            <div className="infobox_col1_container">
              {infoBoxArr.map((info) => (
                <InfoBox
                  key={info.title}
                  title={info.title}
                  value={info.value}
                  icon={info.icon}
                  percentage={info.percentage}
                  color={info.color}
                  textcolor={info.textcolor}
                />
              ))}
            </div>
          </div>
          <Card className="box row-2 dashboard_chart">
            <DashboardChart />
          </Card>
        </div>
        <div className="column column-30">
          <div className="row-1">
            <div className="infobox_col2_container">
              {competitionsArr.map((info) => (
                <InfoBox
                  key={info.title}
                  title={info.title}
                  value={info.value}
                  icon={info.icon}
                  percentage={info.percentage}
                  color={info.color}
                />
              ))}
            </div>
          </div>
          <div className="box statistics row-2">
            <LeaderboardStatistics />
          </div>
        </div>
      </div>
      <Card className="box table_container">
        <LeaderboardTable />
      </Card>
    </LayoutBox>
    </>
  );
}

export default LeaderboardList;
