import axios from "axios";
import {baseUrl} from ".";
import {returnErrors} from "../../store/reducers/error";
import {returnMessages} from "../../store/reducers/message";

const initiatePayment = async (idToken, body, dispatch) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  try {
    const res = await axios.post(`${body.payment_method === "rapyd" ? "https://api-uat-live.equityedge.co.uk" : "https://backend.equityedge.co.uk"}/payment/initiate-payment/`, body, config);

    res?.response?.status > 399 && dispatch(returnErrors(res.response.data.detail || "Something went wrong", 400));
    return res.data;
  } catch (err) {
    throw err;
  }
};
const getPaymentStatusPublic = async (body) => {
  try {
    const res = await axios.post(`${baseUrl}/payment/status/`, body);
    return res;
  } catch (err) {
    return err;
  }
};
const initiatePaymentpublic = async (body, dispatch) => {
  try {
    const res = await axios.post(`${body.payment_method === "rapyd" ? "https://api-uat-live.equityedge.co.uk" : "https://backend.equityedge.co.uk"}/payment/initiate-payment/`, body);

    res?.response?.status > 399 && dispatch(returnErrors(res.response.data.detail, 400));
    return res.data;
  } catch (err) {
    throw err;
  }
};
const createFreeAccount = async (idToken, body, dispatch) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  try {
    const res = await axios.post(`${baseUrl}/account/create-free-account/`, body, config);

    res?.response?.status > 399 ? dispatch(returnErrors(res.response.data.detail || "Something went wrong", 400)) : dispatch(returnMessages("Purchase Successful", 200));

    return res;
  } catch (err) {
    throw err;
  }
};
const payoutRequest = async (idToken, body) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  try {
    const res = await axios.post(`${baseUrl}/user/payout/request/`, body, config);
    return res;
  } catch (err) {
    return err;
  }
};

const applyCoupon = async (idToken, id, params) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
    params: params,
  };
  try {
    const res = await axios.get(
      `${baseUrl}/payment/final-price/${id}/`,

      config,
    );
    if (res.data) return res.data;
    throw res;
  } catch (err) {
    throw err;
  }
};

const getPaymentStatus = async (idToken, body) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  try {
    const res = await axios.post(`${baseUrl}/payment/payment-status/`, body, config);
    return res;
  } catch (err) {
    return err;
  }
};
const sumsubVerification = async (idToken) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  try {
    const res = await axios.get(`${baseUrl}/user/veriff/get/link/`, config);
    return res.data;
  } catch (err) {
    throw err;
  }
};
const veriffStatus = async (idToken) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  try {
    const res = await axios.get(`${baseUrl}/user/veriff/get/decision/`, config);
    return res;
  } catch (err) {
    throw err;
  }
};

const paymentMethods = async (idToken, dispatch) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  try {
    const res = await axios.get(`${baseUrl}/user/api/payment-details/`, config);
    if (res?.response?.status > 399) {
      // dispatch(returnErrors("Failed to get bank details", 400));
      return {};
    }
    return res.data;
  } catch (err) {
    throw err;
  }
};
const digiSignerStatus = async (idToken) => {
  let config = {
    headers: {Authorization: `Bearer ${idToken}`},
  };
  try {
    const res = await axios.get(`${baseUrl}/user/digisigner-status/`, config);
  } catch (error) {}
};

export {
  initiatePayment,
  getPaymentStatusPublic,
  veriffStatus,
  initiatePaymentpublic,
  createFreeAccount,
  applyCoupon,
  payoutRequest,
  getPaymentStatus,
  sumsubVerification,
  paymentMethods,
  digiSignerStatus,
};
