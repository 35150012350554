import React, {useEffect, useState} from "react";
import "./style.scss";
import ParticipatedDetailsBox from "../ParticipatedDetailsBox";
import CompetitionDetailsBox from "../CompetitionDetailsBox";

function Participated({competitions, closed, timer, enterCompetition}) {
  const [competitionList, setCompetitionList] = useState([]);
  useEffect(() => {
    setCompetitionList(competitions);
  }, [competitions]);


  return (
    <div className="participated">
      {/* <ParticipatedDetailsBox tag="progress" />
      <ParticipatedDetailsBox tag="completed" />*/}

      {competitionList?.map((competition, index) => (
        <CompetitionDetailsBox
          key={index}
          competition={competition}
          tag="progress"
          closed={closed}
          timer={timer}
          enterCompetition={enterCompetition}
          competitionListAwards={competition?.awards}
        />
      ))}
    </div>
  );
}

export default Participated;
