import React, {useEffect, useState} from "react";
import "./style.scss";
import RequestToChange from "../RequestToChange";
import InvoiceDetails from "../../FundingEvaluation/PaymentModule/InvoiceDetails";
import {useDispatch, useSelector} from "react-redux";
import {getUserAddress} from "../../../utils/apis";
import Accordion from "../../../reusableComponents/Accordion";
import {paymentMethods} from "../../../utils/apis/payment";
import moment from "moment/moment";
import deel from "../../../assets/images/deel.png";
import ach from "../../../assets/images/ach.jpg";
import swift from "../../../assets/images/swift.png";
import wire from "../../../assets/images/wire.png";
import paypal from "../../../assets/images/paypal.png";
function Billing() {
  const idToken = useSelector((state) => state.auth.idToken);
  const [userAddress, setUserAddress] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(0);
  const fetchData = async () => {
    const data = await getUserAddress(idToken);
    setUserAddress(data);
  };
  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="billing">
      <div className="grid_container">
        {/* <div className="col1">
          <RequestToChange text={"Billing Address"} />
        </div> */}
        <div className="col2">
          <div className="invoice_details">
            <InvoiceDetails
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
              data={userAddress}
              setUserAddress={setUserAddress}
              fetchData={fetchData}
            />
          </div>
          <AchDetails />
          {/* <AccountType /> */}
        </div>
      </div>
    </div>
  );
}

export default Billing;

const AchDetails = () => {
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  const [verifiedBanks, setVerifiedBanks] = useState([]);
  const [accordionData, setAccordionData] = useState([
    {
      title: "Profit withdrawal payment methods?",
      content: "This is the content of Section 2.",
    },
    {
      title: "If I complete all the trading objectives, am I guaranteed funding?",
      content: "This is the content of Section 3.",
    },
    {
      title: "If I complete all the trading objectives, am I guaranteed funding?",
      content: "This is the content of Section 5.",
    },
  ]);
  const imageImports = {
    deel,
    ach,
    swift,
    wire,
    paypal,
    // Add other bank names and their corresponding imports here
  };

  useEffect(() => {
    let array = [];
    verifiedBanks.map((bank) => {
      let data = {
        title: `${bank.name.toUpperCase()} DETAILS`,
        is_verified: Object.entries(bank.data)
          .map(([key, value]) => {
            if (key === "is_verified") {
              return value;
            } else {
              return undefined;
            }
          })
          .filter((value) => value !== undefined),

        content: (
          <div className="section_content">
            {Object.entries(bank.data).map(([key, value]) => {
              if (key === "address") {
                return null;
              }
              return (
                <div
                  className="row"
                  key={key}
                >
                  {key === "is_verified" || key === "user" || key === "default" || key === "id" || key === "account_type" ? null : (
                    <>
                      <p>{key.replace(/_/g, " ")}: </p>
                      <span>{key === "created" || key === "updated" ? moment(value).format("ll") : value}</span>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        ),
        icon: (
          <img
            src={imageImports[bank.name]}
            alt={bank.name}
            style={{width: "40px"}}
          />
        ),
      };
      array.push(data);
    });
    setAccordionData(array);
  }, [verifiedBanks]);

  useEffect(() => {
    
  }, [accordionData]);
  const fetchPaymentMethods = async () => {
    const response = await paymentMethods(idToken, dispatch);

    const data = Object.keys(response)
      .filter((key) => {
        const array = response[key];
        return Array.isArray(array) ? array.length > 0 : !!Object.keys(array).length;
      })
      .map((key) => ({
        name: key,
        data: response[key][0],
      }));

    
    setVerifiedBanks(data);
  };
  // useEffect(() => {
  //   fetchPaymentMethods();
  // }, []);
  // Accordion logic
  const [openAccordion, setOpenAccordion] = useState(0);

  const handleToggleAccordion = (index) => {
    setOpenAccordion((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="ach_details">
      <div className="ach_details_header">
        <p>Account Type</p>
        {/* <span>Save</span> */}
      </div>
      <div className="accordion_container">
        <div className="faq-accordian">
          {accordionData.map((section, index) => (
            <Accordion
              key={index}
              title={section.title}
              is_verified={section.is_verified}
              icon={section.icon}
              isOpen={openAccordion === index}
              onToggle={() => handleToggleAccordion(index)}
            >
              <div className="section_wrapper">{section.content}</div>
            </Accordion>
          ))}
        </div>
      </div>
      {/* <div className="ach_details_input_container">
        <div className="account_input">
          <label
            htmlFor="achRoutingNumber"
            className="label_black"
          >
            Ach Routing Number
          </label>
          <br></br>
          <input
            className="ach_details_input"
            type="text"
            value="1232343"
            name="achRoutingNumber"
          />
        </div>

        <div className="account_input">
          <label
            htmlFor="accNumber"
            className="label_black"
          >
            Account Number
          </label>
          <br></br>
          <input
            className="ach_details_input"
            type="password"
            value="kghjhgkd"
            name="accNumber"
          />
        </div>
      </div> */}
    </div>
  );
};

const AccountType = () => {
  return (
    <div className="account_type">
      <p>Account type</p>
      <div className="radio_container">
        <div>
          <input
            type="radio"
            name="account_type"
          />
          <label>Saving </label>
        </div>

        <div>
          <input
            type="radio"
            name="account_type"
          />
          <label>Current acccount</label>
        </div>
      </div>
    </div>
  );
};
