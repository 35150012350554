const customStyles = {
  singleValue: (provided) => ({
    ...provided,
    fontFamily: "DM Sans",
    color: "#000000",
  }),
  control: (provided) => ({
    ...provided,
    fontFamily: "DM Sans",
    color: "#000000",
    width: "100%",
    height: 48,
    borderRadius: 32,
    background: "#F4F5F4",
    border: "1px solid #e1e4e2",
    cursor: "pointer",
    boxShadow:
      "5px 5px 13px 0px rgba(220, 221, 220, 0.90) inset, -5px -5px 10px 0px rgba(255, 255, 255, 0.90) inset, 5px -5px 10px 0px rgba(220, 221, 220, 0.20) inset, -5px 5px 10px 0px rgba(220, 221, 220, 0.20) inset, -1px -1px 2px 0px rgba(220, 221, 220, 0.50), 1px 1px 2px 0px rgba(255, 255, 255, 0.30)",
    paddingRight: "20px",
    paddingLeft: "10px",

    fontSize: "14px",
    fontWeight: 500,
  }),
  option: (styles, {isFocused, isSelected}) => {
    return {
      ...styles,
      paddingLeft: 20,
      color: isSelected ? "white" : "#111512",
      backgroundColor: isSelected ? "#36d66b" : undefined,
      border: "none",
      paddingTop: 10,
      paddingBottom: 10,
      cursor: "pointer",
      "&:hover": {
        borderColor: "#A4A8AE",
        backgroundColor: "#7ed69b",
        color: "white",
      },
    };
  },
  menu: (provided) => ({
    ...provided,
    maxHeight: "maxContent",
    backgroundColor: "#F6FFFA",
    border: "none",
    borderRadius: "16px",
    zIndex: "1 !important",
    overflow: "hidden",
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#071E0D",
  }),
};
export default customStyles;
