import React, {useEffect, useState} from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import "./style.scss";

import Tabination from "../../reusableComponents/Tabination";

import MarketScreener from "../../layouts/Trademate/MarketScreener";
import TradeJournal from "../../layouts/Trademate/TradeJournal";
import EconomicCalender from "../../layouts/Trademate/EconomicCalender";
import StrategyTester from "../../layouts/Trademate/StrategyTester";
import MarketNews from "../../layouts/Trademate/MarketNews";
import LayoutBox from "../../reusableComponents/LayoutBox";
import {useDispatch, useSelector} from "react-redux";
import {getTraderJournal} from "../../store/reducers/accountSlice";
import CreateTradeJournal from "../../components/Trademate/TradingActivity/CreateTradeJournal";
import MainHeader from "../../layouts/pageLayout/MainHeader";
import ErrorModal from "../../reusableComponents/ErrorModal";
import LoaderOverlay from "../../reusableComponents/Loader";
import {Item} from "@adobe/react-spectrum";

function Trademate() {
  const location = useLocation();
  const path = location.pathname.split("/")[2];

  const [activeTab, setActiveTab] = useState(0);

  const tabItems = [
    {
      name: "Trade History",
      path: "trade-journal",
    },
    {
      name: "Economic Calendar",
      path: "economic-calendar",
    },

    {
      name: "Market News",
      path: "market-news",
    },
    {
      // name: "Create Journal",
      path: "create-trade",
    },
  ];

  useEffect(() => {
    switch (path) {
      case "trade-journal":
        setActiveTab(0);
        break;
      case "economic-calendar":
        setActiveTab(1);
        break;
      case "market-news":
        setActiveTab(3);
        break;
      default:
        break;
    }
  }, [path]);
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTraderJournal(idToken));
  }, [dispatch, idToken]);

  const traderData = useSelector((state) => state.account.traderJournal);
  const [traderjournal, setTraderJournal] = useState([]);
  useEffect(() => {
    if (traderData && traderData.data) {
      setTraderJournal(traderData?.data);
    }
  }, []);

  const [errorModalOpen, setErrorModalOpen] = useState(traderData.error);
  const loading = useSelector((state) => state.account.traderJournal.loading);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isLoading]);

  return (
    <>
      <div className="mainPageHolder">
        <div className="mainPageHolder-T-C">
          <div className="mainPageHolder-T">
            <MainHeader
              headerName="Market Analysis"
              showTabs={false}
              tabItems={tabItems}
              activeTab={activeTab}
            />
          </div>
        </div>
        <div className="mainPageHolder-B">
          <div className="dashboard">
            {errorModalOpen && (
              <ErrorModal
                desc={traderData.message}
                setModalOpen={setErrorModalOpen}
                btnText="Cancel"
              />
            )}
            <div
              className="dashboard_wrapper"
              style={{height: "100%", width: "100%"}}
            >
              {(isLoading || traderData.error) && <LoaderOverlay desc={traderData.error && traderData.message} />}

              <div className="all_header">
                <h2>Trade Journal</h2>
              </div>
              {/* <Tabination
                variant={3}
                tabItems={tabItems}
                activeTab={activeTab}
                style={{margin: "0 14px"}}
                className="tabination_all"
              /> */}
              <Routes>
                <Route
                  path="/"
                  element={
                    <Navigate
                      to="trade-journal"
                      replace={true}
                    />
                  }
                />
                <Route
                  path="trade-journal"
                  element={<TradeJournal traderData={traderjournal} />}
                />
                <Route
                  path="economic-calendar"
                  element={<EconomicCalender />}
                />
                <Route
                  path="market-news"
                  element={<MarketNews />}
                />
                <Route
                  path="create-trade"
                  element={<CreateTradeJournal />}
                />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Trademate;
