import React, {useEffect, useState} from "react";
import "./style.scss";
import Winner from "../../../assets/icons/profile.svg";
import FIrstPosition from "../../../assets/images/first-position.png";
import SecondPosition from "../../../assets/images/second-position.png";
import ThirdPosition from "../../../assets/images/third-position.png";
import {useLocation} from "react-router-dom";

function LeaderboardWinners({data}) {
  const [userdata, setUserData] = useState(null);
  useEffect(() => {
    setUserData(data);
  }, [data]);

  const awards = useLocation();
  
  const awardsList = awards?.state?.awards;

  return (
    <div className="winners2">
      <div className="winners_header">
        <h4>Leaderboard Winners</h4>
        {/* <span>10 Jan, 2022</span> */}
      </div>
      <div className="winners_list">
        {awardsList?.map((item, ind) => (
          <>
            {item.rank === 2 ? (
              <div className="winner-container">
                <img
                  src={Winner}
                  alt="winner"
                  className="winner-2"
                />
                {/* <div className="winner-bg-content-position">
                  <img
                    src={SecondPosition}
                    alt=""
                  />
                </div> */}
                <div className="winnerDetails">
                  <div className="winner-name secondWinner">
                    <p> {userdata && userdata[1]?.email}</p>
                  </div>
                  <div className="winner-content-prize">
                    <p>{item.prize || "NA"}</p>
                  </div>
                </div>
                <div className="winner-2-bg"></div>
                {/* <p>Harry Potter</p>
<div className='winner-2-bg'>
  <div className='winner-bg-content'>
  <h2>2nd</h2>
  <p>Iphone 14 pro</p>
  </div>
</div> */}
              </div>
            ) : item.rank === 1 ? (
              <div className="winner-container">
                <img
                  src={Winner}
                  alt="winner"
                  className="winner-1"
                />
                {/* <div className="winner-bg-content-position">
                  <img
                    src={FIrstPosition}
                    alt=""
                    className="winner-1-position"
                  />
                </div> */}
                <div className="winnerDetails">
                  <div className="winner-name firstWinner">
                    <p>{userdata && userdata[0]?.email}</p>
                  </div>
                  <div className="winner-content-prize">
                    <p>{item.prize || "NA"}</p>
                  </div>
                </div>
                <div className="winner-1-bg"></div>
                {/* <p>Harry Potter</p>
              <div className='winner-1-bg'>
                <div className='winner-bg-content'>
                  <h2>1st</h2>
                  <p>Iphone 14 pro</p>
                </div>
              </div> */}
              </div>
            ) : item.rank === 3 ? (
              <div className="winner-container">
                <img
                  src={Winner}
                  alt="winner"
                  className="winner-3"
                />
                {/* <div className="winner-bg-content-position">
                  <img
                    src={ThirdPosition}
                    alt=""
                  />
                </div> */}
                <div className="winnerDetails">
                  <div className="winner-name thirdWinner">
                    <p>{userdata && userdata[2]?.email}</p>
                  </div>
                  <div className="winner-content-prize">
                    <p>{item.prize || "NA"}</p>
                  </div>
                </div>
                <div className="winner-3-bg"></div>
                {/* <p>Harry Potter</p>
          <div className='winner-3-bg'>
            <div className='winner-bg-content'>
              <h2>3rd</h2>
              <p>Iphone 14 pro</p>
            </div>
          </div> */}
              </div>
            ) : null}
          </>
        ))}
      </div>
    </div>
  );
}

export default LeaderboardWinners;
