import React from "react";
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";

import NavPanel from "../layouts/NavPanel";
import Dashboard from "../pages/Dashboard";
import AccountMetrics from "../pages/AccountMetrics";
import Trademate from "../pages/Trademate";
import SupportCenter from "../pages/SupportCenter";
import Signin from "../pages/Auth/Signin";
import Signup from "../pages/Auth/Signup";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import ResetPassword from "../pages/Auth/ResetPassword";
import PasswordOTPForm from "../pages/Auth/PasswordOTP";
import MyProfile from "../pages/MyProfile";
import FundingEvaluation from "../pages/FundingEvaluation";
import LeaderboardList from "../pages/LeaderboardList";
import LeaderboardTwo from "../pages/Leaderboard2";
import CompetitionList from "../pages/CompetitionList";
import CompetitionLeaderboard from "../pages/CompetitionLeaderboard";
import Plans from "../pages/FundingEvaluation/Plans/index";
import NotFound from "../pages/NotFound";
import Certificates from "../pages/Certificates";
import PaymentHistory from "../pages/FundingEvaluation/PaymentHistory";
import GeneralInformation from "../components/MyProfile/GeneralInformation";
import Verification from "../components/MyProfile/Verification/Index";
import Billing from "../components/MyProfile/Billing";
import Payout from "../components/MyProfile/Payout";
import AccountMetrics2 from "../pages/AccountMetrics2";
import PrivateRoutes from "./PrivateRoutes";
import PaymentModuleHeader from "../components/FundingEvaluation/PaymentModule/PaymentModuleHeader";
import PostPayment from "../pages/FundingEvaluation/PostPayment";
import CreateTradeJournal from "../components/Trademate/TradingActivity/CreateTradeJournal";
import PageLayout from "../layouts/pageLayout/PageLayout";
import Affiliate from "../pages/AffiliateMarketing";
import GoogleSignin from "../pages/Auth/Signin/GoogleSignin";
import FundingEvaluationPublic from "../pages/FundingEvaluation/PublicCheckoutPage";
import RequestPayout from "../components/MyProfile/Payout/RequestPayout";
import BuyFundedAc from "../pages/FundingEvaluation/BuyFundedAc";
import Kyc from "../pages/Kyc/Kyc";
import BillingDetails from "../pages/Billing/Billing";
import Downloads from "../pages/DownloadsPage/index";
import DocSigning from "../pages/Doc/docSigning";
import {KycVerification} from "../components/Kyc/KycVerification";
import ContactList from "../pages/Contract list";
import AdminLogin from "../pages/AdminLogin/AdminLogin";
import LoaderOverlay from "../reusableComponents/Loader";
import ErrorPage from "../components/Erroe404page/ErrorPage";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        {/* PUBLIC ROUTES  */}
        <Route
          path="/signin"
          element={<Signin />}
        />
        <Route
          path="/signin/AL/:id"
          element={<AdminLogin />}
        />
        {/* {
          <Route
            path="404"
            element=<ErrorPage />
          />
        }
        ,
        {
          <Route
            path="*"
            element=<Navigate to="/404" />
          />
        } */}
        ,
        <Route
          path="/funding-evaluation/public/checkout/:id"
          element={<FundingEvaluationPublic />}
        />
        <Route
          path="/auth/google/"
          element={<GoogleSignin />}
        />
        <Route
          path="/evaluation/payment/success"
          element={
            <PageLayout headerName="Funding Evaluation">
              <PostPayment status={"success"} />
            </PageLayout>
          }
        />
        {/* <Route
          path="/kyc-verification"
          element={<KycVerification />}
        /> */}
        <Route
          path="/signup"
          element={<Signup />}
        />
        <Route
          path="/signup/:referalcode"
          element={<Signup />}
        />
        <Route
          path="/forgot-password"
          element={<ForgotPassword />}
        />
        <Route
          path="/reset-password"
          element={<ResetPassword />}
        />
        <Route
          path="/password-otp"
          element={<PasswordOTPForm />}
        />
        {/* PUBLIC ROUTES END  */}
        {/* PRIVATE ROUTES  */}
        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/"
            element={
              <PageLayout headerName="Dashboard">
                <Dashboard />
              </PageLayout>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/account-metrics/*"
            element={
              <PageLayout headerName="Account Metrics">
                <AccountMetrics />
              </PageLayout>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/doc/signing_completed/"
            element={
              <PageLayout headerName="Document Signing">
                <DocSigning />
              </PageLayout>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/certificates/*"
            element={
              <PageLayout headerName="Certificates">
                <Certificates />
              </PageLayout>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/Contract-list"
            element={
              <PageLayout headerName="Contract List">
                <ContactList />
              </PageLayout>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/funding-evaluation-payment/payment-history/"
            element={
              <PageLayout headerName="Payments">
                <PaymentHistory />
                {/* <BuyFundedAc/> */}
              </PageLayout>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/funding-evaluation/plans/:id"
            element={
              <PageLayout headerName="Funding Evaluation">
                <Plans />
              </PageLayout>
            }
          />
        </Route>
        {/* <Route element={<PrivateRoutes />}>
          <Route
            path="/funding-evaluation/checkout/:id"
            element={
              <PageLayout headerName="Funding Evaluation">
                <FundingEvaluation />
              </PageLayout>
            }
          />
        </Route> */}
        <Route element={<PrivateRoutes />}>
          <Route
            path="/evaluation/payment/cancelled"
            element={
              <PageLayout headerName="Payment History">
                <PaymentHistory status={"cancelled"} />
              </PageLayout>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/evaluation/payment/failure"
            element={
              <PageLayout headerName="Funding Evaluation">
                <PostPayment status={"failure"} />
              </PageLayout>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/funding-evaluation/payments/:id"
            element={
              <PageLayout headerName="Funding Evaluation">
                <BuyFundedAc publicCheckout={false} />
              </PageLayout>
            }
          />
        </Route>
        {/* <Route element={<PrivateRoutes />}>
          <Route
            path="/trademate/*"
            element={
              <PageLayout headerName="Trademate">
                <Trademate />
              </PageLayout>
            }
          />
        </Route> */}
        <Route element={<PrivateRoutes />}>
          <Route
            path="/support-center/*"
            element={
              <PageLayout headerName="Support Center">
                <SupportCenter />
              </PageLayout>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/leaderboard"
            element={
              <PageLayout headerName="Competition Leaderboard">
                <LeaderboardList />
              </PageLayout>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/kyc-verification"
            element={
              <PageLayout headerName="Kyc">
                <KycVerification />
              </PageLayout>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/leaderboard-2"
            element={
              <PageLayout headerName="Competition Leaderboard">
                <LeaderboardTwo />
              </PageLayout>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/competition-list/*"
            element={
              <PageLayout headerName="Competition List">
                <CompetitionList />
              </PageLayout>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/verification-kyc/*"
            element={
              <PageLayout headerName="KYC">
                <Kyc />
              </PageLayout>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/billing-details/*"
            element={
              <PageLayout headerName="KYC">
                <BillingDetails />
              </PageLayout>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/competition-leaderboard"
            element={
              <PageLayout headerName="Competition Leaderboard">
                <CompetitionLeaderboard />
              </PageLayout>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/affiliate/*"
            element={
              <PageLayout headerName="My Affiliate">
                <Affiliate />
              </PageLayout>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/myprofile/*"
            element={
              <PageLayout headerName="My Profile">
                <MyProfile />
              </PageLayout>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/request-payout/*"
            element={
              <PageLayout headerName="Request Payout">
                <RequestPayout />
              </PageLayout>
            }
          />
        </Route>
        {/* <Route element={<PrivateRoutes />}>
          <Route
            path="/download"
            element={
              <PageLayout headerName="Downloads">
                <Downloads />
              </PageLayout>
            }
          />
        </Route> */}
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
