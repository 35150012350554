import React from "react";
import Frame from "../../assets/icons/Frame.svg";
const NoData = ({msg}) => {
  return (
    <>
      <div
        className="frame_not"
        style={{width: "100%", display: "flex", flexDirection: "column", gap: "30px", justifyContent: "center", alignItems: "center", height: "80vh"}}
      >
        <img
          style={{width: "50%"}}
          src={Frame}
          alt=""
        />
        <div className="no_data">
          <p style={{color: "black", fontSize: "20px", textAlign: "center", fontWeight: "600"}}>{msg}</p>
        </div>
      </div>
    </>
  );
};

export default NoData;
