import React, { useEffect, useState } from "react";
import "./style.scss";

function Tooltip({
  children,
  variant = "appear",
  isActive,
  setIsActive,
  moveY = 0,
}) {
  useEffect(() => {
    let timer;
    if (variant === "disappear") {
      timer = setTimeout(() => {
        setIsActive();
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [variant, setIsActive]);

  const top = moveY !== 0 ? 100 + +`${moveY}` : "";

  return (
    <div
      id="tooltip"
      style={{ top: `${top}%` }}
      className={`tooltip ${variant === "disappear" ? "tooltip-variant" : ""} ${
        isActive ? "active" : ""
      }`}
    >
      {children}
    </div>
  );
}

export default Tooltip;
